import * as React from 'react';
import { Icon } from '../../../UIKit/components/Icon/Icon.component';
import allowIcon from '../../../../resources/icons/ic-checkbox-allow-green.svg';
import denyIcon from '../../../../resources/icons/ic-checkbox-deny-color.svg';
import inheritIcon from '../../../../resources/icons/ic-checkbox-inherit-empty.svg';
import { TSpriteSymbol } from '../../../../models/spriteSymbol.types';
import theme from './PermissionSwitch.scss';
import { FC } from 'react';
import { Button } from '@/modules/UIKit/components/Button/Button.component';

type TPermissionSwitchProps = {
    isGranting: boolean | undefined;
    onClick?: () => any;
    dataKey?: string;
    propagation?: boolean;
};

export const PermissionSwitch: FC<TPermissionSwitchProps> = ({
    isGranting,
    onClick = (f?: any) => f,
    dataKey = '',
    propagation,
}) => {
    const icon = (): TSpriteSymbol => {
        if (isGranting === undefined) {
            return inheritIcon;
        }

        return isGranting ? allowIcon : denyIcon;
    };

    let status = 'negative';
    if (isGranting === undefined) {
        status = 'empty';
    }
    if (isGranting) status = 'positive';

    return (
        <div className={theme.permissionSwitchContainer} data-test-id={`additional-rights_checkbox-status_${status}`}>
            <Button
                visualStyle="text"
                size="small"
                onClick={(event) => {
                    if (!propagation) event.stopPropagation();
                    onClick();
                }}
                dataTest={`additional-rights_table_column_${dataKey}`}
            >
                <Icon spriteSymbol={icon()} />
            </Button>
        </div>
    );
};
