import { defineMessages } from 'react-intl';

export default defineMessages({
    generalSettings: {
        id: 'ModelType.generalSettings',
        defaultMessage: 'Общие настройки',
    },
    any: {
        id: 'ModelType.any',
        defaultMessage: 'Любой',
    },
    name: {
        id: 'ModelType.name',
        defaultMessage: 'Название',
    },
    description: {
        id: 'ModelType.description',
        defaultMessage: 'Описание',
    },
    modelTypeGroups: {
        id: 'ModelType.modelTypeGroups',
        defaultMessage: 'Группа типа модели',
    },
    symbol: {
        id: 'ModelType.symbol',
        defaultMessage: 'Символ',
    },
    icon: {
        id: 'ModelType.icon',
        defaultMessage: 'Иконка',
    },
    noIcon: {
        id: 'ModelType.noIcon',
        defaultMessage: 'Нет иконки',
    },
    newObject: {
        id: 'ModelType.newObject',
        defaultMessage: 'Новый объект',
    },
    newSymbol: {
        id: 'ModelType.newSymbol',
        defaultMessage: 'Новый символ',
    },
    id: {
        id: 'ModelType.id',
        defaultMessage: 'ID',
    },
    objectType: {
        id: 'ModelType.objectType',
        defaultMessage: 'Тип объекта',
    },
    symbols: {
        id: 'ModelType.symbols',
        defaultMessage: 'Символы модели',
    },
    attributes: {
        id: 'ModelType.attributes',
        defaultMessage: 'Атрибуты модели',
    },
    symbolSrc: {
        id: 'ModelType.symbolSrc',
        defaultMessage: 'Символ - источник',
    },
    symbolDest: {
        id: 'ModelType.symbolDest',
        defaultMessage: 'Символ - цель',
    },
    connectionType: {
        id: 'ModelType.connectionType',
        defaultMessage: 'Тип связи',
    },
    waypoint: {
        id: 'ModelType.waypoint',
        defaultMessage: 'Направленность',
    },
    addEdge: {
        id: 'ModelType.addEdge',
        defaultMessage: 'Добавить связь',
    },
    newEdge: {
        id: 'ModelType.newEdge',
        defaultMessage: 'Новая связь',
    },
    edgesOnModel: {
        id: 'ModelType.edgesOnModel',
        defaultMessage: 'Связи на модели',
    },
    addSymbolConfirmButton: {
        id: 'ModelType.addSymbolConfirmButton',
        defaultMessage: 'Создать',
    },
    addSymbolDeclineButton: {
        id: 'ModelType.addSymbolDeclineButton',
        defaultMessage: 'Отмена',
    },
    back: {
        id: 'ModelType.back',
        defaultMessage: 'Назад',
    },
    next: {
        id: 'ModelType.next',
        defaultMessage: 'Далее',
    },

    addSymbolsTitle: {
        id: 'ModelType.addSymbolsTitle',
        defaultMessage: 'Добавление символов',
    },

    symbolsDefaultName: {
        id: 'ModelType.symbolsDefaultName',
        defaultMessage: 'Любой элемент',
    },

    errorSymbolDrawTitle: {
        id: 'ModelType.errorSymbolDrawTitle',
        defaultMessage: 'Ошибка отрисовки символа',
    },
    errorSymbolDrawMessage: {
        id: 'ModelType.errorSymbolDrawMessage',
        defaultMessage: 'Не удалось сконвертировать символ',
    },
    edgeType: {
        id: 'ModelType.edgeType',
        defaultMessage: 'Вид связи',
    },
    source: {
        id: 'ModelType.source',
        defaultMessage: 'Источник',
    },
    target: {
        id: 'ModelType.target',
        defaultMessage: 'Цель',
    },
    errorRender: {
        id: 'ModelType.errorRender',
        defaultMessage: 'Ошибка отрисовки символа',
    },
    errorRenderDescription: {
        id: 'ModelType.errorRenderDescription',
        defaultMessage: 'Не удалось сконвертировать символ',
    },
    addEdges: {
        id: 'ModelType.addEdges',
        defaultMessage: 'Добавление связей',
    },
    editingEdge: {
        id: 'ModelType.editingEdge',
        defaultMessage: 'Редактирование связи',
    },
    addButton: {
        id: 'ModelType.addButton',
        defaultMessage: 'Добавить',
    },
    saveButton: {
        id: 'ModelType.saveButton',
        defaultMessage: 'Сохранить',
    },
    cancelButton: {
        id: 'ModelType.cancelButton',
        defaultMessage: 'Отмена',
    },
    EDIT_SYMBOL: {
        id: 'ModelType.EDIT_SYMBOL',
        defaultMessage: 'Редактировать символ',
    },
    EDIT_OBJECT: {
        id: 'ModelType.EDIT_OBJECT',
        defaultMessage: 'Редактировать тип объекта',
    },
    typeModel: {
        id: 'ModelType.typeModel',
        defaultMessage: 'Тип модели',
    },
    groupSymbol: {
        id: 'ModelType.groupSymbol',
        defaultMessage: 'Группировать символы',
    },
    enableModel: {
        id: 'ModelType.enableModel',
        defaultMessage: 'Видимость типа модели при создании',
    },
    editEdgeAttributeStyle: {
        id: 'ModelType.editEdgeAttributeStyle',
        defaultMessage: 'Редактировать стиль атрибутов',
    },
    editEdge: {
        id: 'ModelType.editEdge',
        defaultMessage: 'Редактировать связь',
    },
    addLanes: {
        id: 'ModelType.addLanes',
        defaultMessage: 'При добавлении пулов необходимо добавить соответствующие дорожки',
    },
    deleteSymbolsDialogTitle: {
        id: 'ModelType.deleteSymbolsDialogTitle',
        defaultMessage: 'Удалить выбранные символы?',
    },
    deleteSymbols: {
        id: 'ModelType.deleteSymbols',
        defaultMessage: 'Будут удалены символы: ',
    },
    deleteEdgesDialogTitle: {
        id: 'ModelType.deleteEdgesDialogTitle',
        defaultMessage: 'Удалить выбранные типы связей?',
    },
    deleteEdges: {
        id: 'ModelType.deleteEdges',
        defaultMessage: 'Будут удалены типы связей',
    },
    allowAnyObject: {
        id: 'ModelType.allowAnyObject',
        defaultMessage: 'Разрешить все типы объектов',
    },
    allowApprovals: {
        id: 'ModelType.allowApprovals',
        defaultMessage: 'Разрешить согласование типа модели',
    },
    autoCreateEdge: {
        id: 'ModelType.autoCreateEdge',
        defaultMessage: 'Разрешить создавать связи автоматически',
    },
    noData: {
        id: 'ModelType.noData',
        defaultMessage: 'Нет данных',
    },
    allowDrawInvisibleEdges: {
        id: 'ModelType.allowDrawInvisibleEdges',
        defaultMessage: 'Разрешить отрисовку невидимых связей',
    },
    templates: {
        id: 'ModelType.templates',
        defaultMessage: 'Шаблоны модели',
    },
});
