import React, { FC } from 'react';
import theme from './Filters.scss';
import { useIntl } from 'react-intl';
import { Select } from '@/modules/UIKit/components/Select/Select.component';
import { Input } from '@/modules/UIKit/components/Input/Input.component';
import messages from './Filters.messages';
import { TFilterType, TNumericFilter, TNumericFilterRules } from '../../../reducers/reportEditor.reducer.types';
import { NodeId } from '@/serverapi/api';
import { useDispatch, useSelector } from 'react-redux';
import { reportSetColumnFilter } from '@/modules/Report/actions/report.actions';
import { ReportSelectors } from '@/modules/Report/selectors/report.selectors';

type TNumericFilterProps = {
    reportNodeId: NodeId;
    columnId: string;
    columnName: string;
    minWidth: number;
};

export const NumericFilter: FC<TNumericFilterProps> = ({ reportNodeId, columnId, columnName, minWidth }) => {
    const intl = useIntl();

    const { rule, value1, value2 }: TNumericFilter = useSelector(
        ReportSelectors.getFilterValue(reportNodeId, columnId, TFilterType.NUMERIC),
    ) as TNumericFilter;

    const dispatch = useDispatch();

    const handleChangeColumnFilter = (data: TNumericFilter) => {
        dispatch(reportSetColumnFilter(reportNodeId, columnId, TFilterType.NUMERIC, data));
    };

    const inputHandler = (event: React.ChangeEvent<HTMLInputElement>, secondValue?: boolean) => {
        const filterValue = Number(event.target.value);
        if (!secondValue) {
            handleChangeColumnFilter({ rule, value1: filterValue, value2 });
        } else {
            handleChangeColumnFilter({ rule, value1, value2: filterValue });
        }
    };

    const handleRuleChange = (newRule: TNumericFilterRules | undefined) => {
        const newValue1: number | undefined = newRule && rule !== TNumericFilterRules.BETWEEN ? value1 : undefined;
        const newValue2: number | undefined = newRule === TNumericFilterRules.BETWEEN ? value2 : undefined;

        handleChangeColumnFilter({ rule: newRule, value1: newValue1, value2: newValue2 });
    };

    return (
        <div className={theme.container} style={{ minWidth, maxWidth: minWidth }} key={columnId}>
            <Select
                value={rule ? intl.formatMessage(messages[rule]) : ''}
                onChange={handleRuleChange}
                wrapperClassName={theme.select}
                placeholder={columnName}
                allowClear
            >
                {Object.values(TNumericFilterRules).map((value) => (
                    <Select.Option label={intl.formatMessage(messages[value])} value={value} checked={rule === value} />
                ))}
            </Select>
            {rule === TNumericFilterRules.BETWEEN ? (
                <>
                    <Input
                        name="filterValueFrom"
                        type="number"
                        value={value1}
                        onChange={inputHandler}
                        className={theme.input}
                        dir="rtl"
                    />
                    <Input
                        name="filterValueTo"
                        type="number"
                        value={value2}
                        onChange={(e) => inputHandler(e, true)}
                        className={theme.input}
                        dir="rtl"
                    />
                </>
            ) : (
                <Input
                    name="filterValue"
                    type="number"
                    value={value1}
                    onChange={inputHandler}
                    className={theme.input}
                    dir="rtl"
                />
            )}
        </div>
    );
};
