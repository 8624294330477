import { defineMessages } from 'react-intl';

export default defineMessages({
    noData: {
        id: 'ReportEditor.noData',
        defaultMessage: 'Нет данных',
    },
    allColumnsHidden: {
        id: 'ReportEditor.allColumnsHidden',
        defaultMessage: 'Все столбцы скрыты',
    },
});
