export const ADD_STORE_EDIT_MODEL_TYPE_WORKSPACE_TAB = 'ADD_STORE_EDIT_MODEL_TYPE_WORKSPACE_TAB';

export const DELETE_SYMBOL_EDIT_MODEL_TYPE = 'DELETE_SYMBOL_EDIT_MODEL_TYPE';

export const CHANGE_MODEL_TYPE_NAME = 'CHANGE_MODEL_TYPE_NAME';

export const CHANGE_MODEL_TYPE_ID = 'CHANGE_MODEL_TYPE_ID';

export const CHANGE_MODEL_TYPE_SYNONYMS_IDS = 'CHANGE_MODEL_TYPE_SYNONYMS_IDS';

export const CHANGE_MODEL_TYPE_DESCRIPTION = 'CHANGE_MODEL_TYPE_DESCRIPTION';

export const CHANGE_MODEL_TYPE_GROUP = 'CHANGE_MODEL_TYPE_GROUP';

export const CHANGE_MODEL_TYPE_GROUP_SYMBOL = 'CHANGE_MODEL_TYPE_GROUP_SYMBOL';

export const CHANGE_MODEL_TYPE_ENABLE_MODEL = 'CHANGE_MODEL_TYPE_ENABLE_MODEL';

export const ADD_ATTRIBUTE_TYPES = 'ADD_ATTRIBUTE_TYPES';

export const REMOVE_ATTRIBUTE_TYPES = 'REMOVE_ATTRIBUTE_TYPES';

export const REMOVE_ATTRIBUTE_TYPES_GROUPS = 'REMOVE_ATTRIBUTE_TYPES_GROUPS';

export const ADD_SYMBOLS_MODEL_TYPE = 'ADD_SYMBOLS_MODEL_TYPE';

export const DELETE_MODEL_TYPE_EDGES = 'DELETE_MODEL_TYPE_EDGES';

export const ADD_MODEL_TYPE_EDGES = 'ADD_MODEL_TYPE_EDGES';

export const CHANGE_MODEL_TYPE_ALLOW_ANY_OBJECT = 'CHANGE_MODEL_TYPE_ALLOW_ANY_OBJECT';

export const CHANGE_MODEL_TYPE_ALLOW_APPROVALS = 'CHANGE_MODEL_TYPE_ALLOW_APPROVALS';

export const CHANGE_MODEL_TYPE_AUTO_CREATE_EDGE = 'CHANGE_MODEL_TYPE_AUTO_CREATE_EDGE';

export const CHANGE_MODEL_TYPE_ALLOW_DRAW_INVISIBLE_EDGES = 'CHANGE_MODEL_TYPE_ALLOW_DRAW_INVISIBLE_EDGES';

export const DELETE_TEMPLATE_EDIT_MODEL_TYPE = 'DELETE_TEMPLATE_EDIT_MODEL_TYPE';

export const EDIT_TEMPLATE_EDIT_MODEL_TYPE = 'EDIT_TEMPLATE_EDIT_MODEL_TYPE';
