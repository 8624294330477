import React, { FC } from 'react';
import { AutoSizer, CellMeasurer, CellMeasurerCache, Column, Table, TableCellProps } from 'react-virtualized';
import theme from './DeletedElementsTable.scss';
import { useIntl } from 'react-intl';
import messages from './DeletedElementsTable.messages';
import { Icon } from '@/modules/UIKit';
import icDelete from '../../resources/icons/Deleted.svg';
import icRestore from '../../resources/icons/restore.svg';
import icGo from '../../resources/icons/ic-go.svg';
import icSorter from '../../resources/icons/ic-sorter.svg';
import { NodeId, SearchResult } from '@/serverapi/api';
import { dateTimeFormat, timestampToMomentDate } from '@/utils/date.time.utils';
import { TreeItemType } from '../Tree/models/tree';
import { Tooltip } from 'antd';
import { compareNodeIds } from '@/utils/nodeId.utils';
import { LocalesService } from '@/services/LocalesService';
import { getNodeIconSpriteSymbol } from '../Tree/utils/tree.utils';
import { TreeNode } from '@/models/tree.types';
import { Checkbox } from '../UIKit/components/Checkbox/Checkbox.component';

type TRowData = {
    rowData: SearchResult;
};

const ROW_HEIGHT = 48;

type TDeletedElementsTableProps = {
    tableData: SearchResult[];
    selectedDataNodeIds: NodeId[];
    onDelete: (id: NodeId) => void;
    onRestore: (id: NodeId) => void;
    onRowClick: (id: NodeId) => void;
    onAllRowsClick: () => void;
    onMoveTo: (treeNode: TreeNode) => void;
    sortDirection: string;
    setSortDirection: (direction: string) => void;
};

const cellHeightCache = new CellMeasurerCache({
    fixedWidth: true,
    minHeight: ROW_HEIGHT,
});

export const DeletedElementsTable: FC<TDeletedElementsTableProps> = (props): JSX.Element => {
    const {
        tableData,
        selectedDataNodeIds,
        onDelete,
        onRestore,
        onRowClick,
        onAllRowsClick,
        onMoveTo,
        sortDirection,
        setSortDirection,
    } = props;
    const intl = useIntl();

    const handleGoToElement = (searchResult: SearchResult) => {
        const treeNode: TreeNode = {
            hasChildren: false,
            nodeId: searchResult.nodeId,
            name: LocalesService.internationalStringToString(searchResult.multilingualName),
            multilingualName: searchResult.multilingualName,
            type: searchResult.nodeType as TreeItemType,
            countChildren: 0,
        };

        onMoveTo(treeNode);
    };

    const firstColumnRenderer = ({ dataKey, parent, rowIndex }: TableCellProps): JSX.Element => {
        const { nodeId } = tableData[rowIndex];
        const isChecked = selectedDataNodeIds.some((selectedNodeId) => compareNodeIds(selectedNodeId, nodeId));

        return (
            <CellMeasurer cache={cellHeightCache} key={dataKey} parent={parent} rowIndex={rowIndex} columnIndex={0}>
                <Checkbox
                    onChange={() => onRowClick(nodeId)}
                    checked={isChecked}
                    onClick={(event: React.MouseEvent) => event.stopPropagation()}
                />
            </CellMeasurer>
        );
    };

    const multilingualNameColumnRenderer = ({ dataKey, parent, rowIndex }: TableCellProps): JSX.Element => {
        const { multilingualName, nodeType } = tableData[rowIndex];

        return (
            <CellMeasurer cache={cellHeightCache} key={dataKey} parent={parent} rowIndex={rowIndex} columnIndex={1}>
                <div className={theme.flexCenter} title={LocalesService.internationalStringToString(multilingualName)}>
                    <div className={theme.elementIconContainer}>
                        <Icon
                            className={theme.entityIcon}
                            spriteSymbol={getNodeIconSpriteSymbol(nodeType as TreeItemType)}
                        />
                    </div>
                    <div>{LocalesService.internationalStringToString(multilingualName)}</div>
                </div>
            </CellMeasurer>
        );
    };

    const pathColumnRenderer = ({ dataKey, parent, rowIndex }: TableCellProps): JSX.Element => {
        const { path } = tableData[rowIndex];

        return (
            <CellMeasurer cache={cellHeightCache} key={dataKey} parent={parent} rowIndex={rowIndex} columnIndex={2}>
                <span title={path}>{path}</span>
            </CellMeasurer>
        );
    };

    const deletedAtColumnRenderer = ({ dataKey, parent, rowIndex }: TableCellProps): JSX.Element => {
        const { deletedAt } = tableData[rowIndex];
        const title = deletedAt ? `${timestampToMomentDate(deletedAt)?.format(`${dateTimeFormat}`)}` : '';

        return (
            <CellMeasurer cache={cellHeightCache} key={dataKey} parent={parent} rowIndex={rowIndex} columnIndex={3}>
                <span title={title}>{title}</span>
            </CellMeasurer>
        );
    };

    const deletedByColumnRenderer = ({ dataKey, parent, rowIndex }: TableCellProps): JSX.Element => {
        const { deletedBy } = tableData[rowIndex];

        return (
            <CellMeasurer cache={cellHeightCache} key={dataKey} parent={parent} rowIndex={rowIndex} columnIndex={4}>
                <span title={deletedBy || ''}>{deletedBy || ''}</span>
            </CellMeasurer>
        );
    };

    const actionsColumnRenderer = ({ dataKey, parent, rowIndex }: TableCellProps) => {
        const { nodeId } = tableData[rowIndex];

        return (
            <CellMeasurer cache={cellHeightCache} key={dataKey} parent={parent} rowIndex={rowIndex} columnIndex={5}>
                <div className={theme.actionBtnsContainer}>
                    <Tooltip title={intl.formatMessage(messages.goToElementInNavigator)} placement="left">
                        <span
                            data-test="deleted-elements_go-to-element_icon"
                            onClick={(e) => {
                                e.stopPropagation();
                                handleGoToElement(tableData[rowIndex]);
                            }}
                        >
                            <Icon spriteSymbol={icGo} />
                        </span>
                    </Tooltip>
                    <Tooltip title={intl.formatMessage(messages.delete)} placement="left">
                        <span
                            data-test="deleted-elements_delete-element_icon"
                            onClick={(e) => {
                                e.stopPropagation();
                                onDelete(nodeId);
                            }}
                        >
                            <Icon spriteSymbol={icDelete} />
                        </span>
                    </Tooltip>
                    <Tooltip title={intl.formatMessage(messages.restore)} placement="left">
                        <span
                            data-test="deleted-elements_restore-element_icon"
                            onClick={(e) => {
                                e.stopPropagation();
                                onRestore(nodeId);
                            }}
                        >
                            <Icon spriteSymbol={icRestore} />
                        </span>
                    </Tooltip>
                </div>
            </CellMeasurer>
        );
    };

    const rowGetter = ({ index }) => tableData[index];

    const headerRender = (): JSX.Element => {
        return (
            <Checkbox
                checked={!!selectedDataNodeIds.length && selectedDataNodeIds.length === tableData.length}
                onChange={onAllRowsClick}
            />
        );
    };

    const deletedAtHeaderRenderer = (): JSX.Element => {
        return (
            <div className={theme.sortableColumn}>
                <span>{intl.formatMessage(messages.deletedDate)}</span>
                <span
                    onClick={() => {
                        setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
                    }}
                >
                    <Icon spriteSymbol={icSorter} />
                </span>
            </div>
        );
    };

    const handleRowClick = ({ rowData }: TRowData) => {
        const { nodeId } = rowData;
        onRowClick(nodeId);
    };

    return (
        <div className={theme.tableContainer}>
            <AutoSizer>
                {({ height, width }) => (
                    <Table
                        width={width}
                        height={height}
                        rowHeight={cellHeightCache.rowHeight}
                        className={theme.table}
                        headerHeight={ROW_HEIGHT}
                        rowCount={tableData.length}
                        rowGetter={rowGetter}
                        headerClassName={theme.headerName}
                        rowClassName={theme.rowClassName}
                        onRowClick={handleRowClick}
                    >
                        <Column
                            flexGrow={1}
                            maxWidth={40}
                            minWidth={40}
                            width={40}
                            dataKey="check"
                            headerRenderer={headerRender}
                            cellRenderer={firstColumnRenderer}
                        />
                        <Column
                            width={width}
                            dataKey="multilingualName"
                            label={intl.formatMessage(messages.name)}
                            cellRenderer={multilingualNameColumnRenderer}
                            className={theme.columnClassName}
                        />

                        <Column
                            width={width}
                            dataKey="path"
                            label={intl.formatMessage(messages.path)}
                            cellRenderer={pathColumnRenderer}
                        />

                        <Column
                            width={width}
                            dataKey="deletedAt"
                            maxWidth={150}
                            minWidth={150}
                            label={intl.formatMessage(messages.deletedDate)}
                            cellRenderer={deletedAtColumnRenderer}
                            headerRenderer={deletedAtHeaderRenderer}
                        />

                        <Column
                            width={width}
                            dataKey="deletedBy"
                            label={intl.formatMessage(messages.user)}
                            cellRenderer={deletedByColumnRenderer}
                        />

                        <Column
                            width={width}
                            dataKey="actions"
                            maxWidth={100}
                            minWidth={100}
                            cellRenderer={actionsColumnRenderer}
                        />
                    </Table>
                )}
            </AutoSizer>
        </div>
    );
};
