import React, { FC, useCallback, useEffect, useState } from 'react';
import messages from '../SelectExistingDefinitionDialogs.messages';
import { useIntl } from 'react-intl';
import { ObjectDefinitionImpl } from '../../../../../models/bpm/bpm-model-impl';
import { SelectedStrategy } from '../../../../../models/selectObjectDialog.types';
import theme from '../SelectExistingDefinitionDialogs.scss';
import { Icon } from '../../../../UIKit';
import Flag from '../../../../../resources/icons/ic-tree-e-epc-event.svg';
import { useDispatch, useSelector } from 'react-redux';
import { SelectObjectDefinitionDialogSelectors } from '../../../../../selectors/selectObjectDefinitionDialog.selectors';
import { selectObjectDialogPrepareDataRequest } from '../../../../../actions/selectObjectDefinitionDialog.actions';
import { Table } from 'antd';
import { closeDialog } from '../../../../../actions/dialogs.actions';
import { DialogType } from '../../../../DialogRoot/DialogRoot.constants';
import { objectDecompositionChooseObjectDialogSubmit } from '../../../../../actions/entities/objectDecomposition.actions';
import { Symbol } from '../../../../../serverapi/api';
import { TreeNode } from '../../../../../models/tree.types';
import { useObjectTableData } from '../hooks/useObjectTableData';
import { Dialog } from '@/modules/UIKit/components/Dialog/Dialog.component';
import { DialogFooterButtons } from '@/modules/UIKit/components/DialogFooterButtoms/DialogFooterButtons.component';

type TSelectObjectMovelMoveContainerProps = {
    open: boolean;
    instances: ObjectDefinitionImpl[];
    draggedModelNode: TreeNode;
    symbol: Symbol;
    serverUrl: string;
};

export const SelectObjectMovelMoveContainer: FC<TSelectObjectMovelMoveContainerProps> = ({
    open,
    instances,
    draggedModelNode,
    symbol,
    serverUrl,
}) => {
    const intl = useIntl();
    const dispatch = useDispatch();

    const [selectedInstanceId, setSelectedInstanceId] = useState<string | null>(instances?.[0]?.nodeId.id || null);

    useEffect(() => {
        dispatch(selectObjectDialogPrepareDataRequest(instances));
    }, []);

    const nameRenderer = (objectDefinition: ObjectDefinitionImpl) => {
        const isSelected = objectDefinition.nodeId.id === selectedInstanceId;

        return (
            <div className={!isSelected ? theme.name : ''}>
                {isSelected && <Icon className={theme.flag} spriteSymbol={Flag} />}
                {objectDefinition.name}
            </div>
        );
    };

    const { columns, dataSource, onRow } = useObjectTableData(instances, nameRenderer, (key) =>
        setSelectedInstanceId(key),
    );
    const { objectTypeTitle } = useSelector(SelectObjectDefinitionDialogSelectors.selectObjectDefinitionDialogState);

    const onClose = useCallback(() => {
        dispatch(closeDialog(DialogType.SELECT_OBJECT_FOR_MODEL_DECOMPOSITION));
    }, []);

    const handleSubmit = (selectedStrategy: SelectedStrategy) => () => {
        const selectedInstance = instances.find(({ nodeId: { id } }) => id === selectedInstanceId);

        if (!selectedInstance) return;

        dispatch(
            objectDecompositionChooseObjectDialogSubmit({
                draggedModelNode,
                symbol,
                selectedStrategy,
                instance: selectedInstance,
                serverUrl,
            }),
        );
    };

    const footer = (
        <DialogFooterButtons
            buttons={[
                {
                    key: 'cancel',
                    onClick: onClose,
                    value: intl.formatMessage(messages.cancelButton),
                },
                {
                    key: 'useSelected',
                    onClick: handleSubmit(SelectedStrategy.useExisting),
                    value: intl.formatMessage(messages.useExisting),
                    visualStyle: 'primary',
                },
                {
                    key: 'createNew',
                    onClick: handleSubmit(SelectedStrategy.setNewName),
                    value: intl.formatMessage(messages.createNewObject),
                    visualStyle: 'primary',
                },
            ]}
        />
    );

    return (
        <Dialog
            open={open}
            onCancel={onClose}
            title={intl.formatMessage(messages.selectObject)}
            width="620px"
            footer={footer}
        >
            <div className={theme.container} data-test="existing_object_dialog">
                <div className={theme.message}>
                    {intl.formatMessage(messages.objectHeaderMessage, { objectTypeTitle })}
                </div>
                <div>{intl.formatMessage(messages.useSelectedObject)}</div>
                {dataSource && (
                    <div className={theme.tableContainer}>
                        <Table
                            className={theme.table}
                            rowKey={(record) => record.key}
                            onRow={onRow}
                            dataSource={dataSource}
                            bordered
                            pagination={false}
                            size="middle"
                            columns={columns}
                            scroll={{
                                y: 'max-content',
                                x: 'max-content',
                            }}
                        />
                    </div>
                )}
            </div>
        </Dialog>
    );
};
