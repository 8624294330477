import React from 'react';
import { Checkbox, Input, Select } from 'antd';
import {
    AttributeTypeStyleFormatEnum,
    PresetImage,
    KanbanCardSectorAttribute,
    KanbanCardSectorAttributeSettingsFormatEnum,
} from '../../../../../../../../serverapi/api';
import theme from './KanbanCardAttributesEditor.scss';
import {
    FontColorPickerButton,
    FontSizeInput,
    FontStyleButton,
} from '../../../../../../../UIKit/components/FontSettings/FontSettings.component';
import icBold from '../../../../../../../../resources/icons/ic-ribbon-text-bold.svg';
import icItalic from '../../../../../../../../resources/icons/ic-ribbon-text-italic.svg';
import icUnderline from '../../../../../../../../resources/icons/ic-ribbon-text-underline.svg';
import { CheckboxChangeEvent } from 'antd/es/checkbox/Checkbox';
import { KanbanCardAttributeImageStyleSelector } from './KanbanCardAttributeImageStyleSelector.component';
import { DEFAULT_KANBAN_ATTRIBUTE_TYPE_ID } from '../../../util/KanbanCardEditor.utils';
import { KanbanCardSectorAttributeSettingsFormat } from '../../../../../../../../models/kanban.types';
import { useIntl } from 'react-intl';
import messages from '../KanbanCardEditor.messages';
import { TRadioOption } from '@/modules/UIKit/components/Radio/Radio.types';
import { RadioGroup } from '@/modules/UIKit/components/Radio/RadioGroup.component';

type TKanbanCardAttributeDisplayFormatelectorProps = {
    currentAttribute: KanbanCardSectorAttribute;
    onAttributeFormatChange: (format: AttributeTypeStyleFormatEnum) => void;
    onAttributeTextValueChange: (value: string | undefined) => void;
    onAttributeFontStyleToggle: (propName: string) => void;
    onAttributeColorChange: (propName: string) => void;
    onAttributeFontSizeChange: (val: number) => void;
    onAttributeNewLineOutputChange: (value: boolean) => void;
    onAttributeImageIdChange: (id: string) => void;
    onAttributeImageSizeChange: (size: number) => void;
    presetImages: PresetImage[];
};

export const KanbanCardAttributeDisplayFormatSelector = (props: TKanbanCardAttributeDisplayFormatelectorProps) => {
    const {
        currentAttribute,
        presetImages,
        onAttributeFormatChange,
        onAttributeTextValueChange,
        onAttributeFontStyleToggle,
        onAttributeColorChange,
        onAttributeFontSizeChange,
        onAttributeNewLineOutputChange,
        onAttributeImageIdChange,
        onAttributeImageSizeChange,
    } = props;
    const intl = useIntl();
    const { style } = currentAttribute.settings;

    const onFormatChange = (value: KanbanCardSectorAttributeSettingsFormatEnum) => {
        onAttributeFormatChange(value);
    };

    const onTextValueChange = (e) => {
        onAttributeTextValueChange(e.target.value);
    };

    const onTextDisplayFormatChange = (val: 'attribute' | 'arbitrary') => {
        if (val === 'arbitrary') return onAttributeTextValueChange('');

        onAttributeTextValueChange(undefined);
    };

    const onFontSizeChange = (val: string) => {
        const valNumber = +val.replace(/pt/gi, '');
        onAttributeFontSizeChange(valNumber);
    };

    const onNewLineOutputChange = (e: CheckboxChangeEvent) => {
        onAttributeNewLineOutputChange(e.target.checked);
    };

    const onImageIdChange = (imageId: string) => {
        onAttributeImageIdChange(imageId);
    };

    const radioOptions: TRadioOption<KanbanCardSectorAttributeSettingsFormatEnum>[] = [
        {
            title: intl.formatMessage(messages.text),
            value: "TEXT",
        },
        {
            title: intl.formatMessage(messages.icon),
            value: "IMAGE",
        },
    ];

    return (
        <div>
            <div className={theme.attributeFormatLabel}>{intl.formatMessage(messages.selectViewFormat)}</div>
            <RadioGroup<KanbanCardSectorAttributeSettingsFormatEnum>
                options={radioOptions}
                onChange={onFormatChange}
                value={currentAttribute.settings.format}
                direction="Row"
                margin={8}
            />

            {currentAttribute.settings.format === KanbanCardSectorAttributeSettingsFormat.TEXT ? (
                <>
                    <div className={theme.attributeValueWrapper}>
                        <Select
                            dropdownClassName={theme.attributeSelectDropdown}
                            value={'textValue' in currentAttribute ? 'arbitrary' : 'attribute'}
                            onChange={onTextDisplayFormatChange}
                            getPopupContainer={(trigger) => trigger.parentNode}
                            disabled={currentAttribute.attributeTypeId === DEFAULT_KANBAN_ATTRIBUTE_TYPE_ID}
                        >
                            {currentAttribute.attributeTypeId !== DEFAULT_KANBAN_ATTRIBUTE_TYPE_ID ? (
                                <Select.Option value="attribute">
                                    {intl.formatMessage(messages.displayAttributeValue)}
                                </Select.Option>
                            ) : null}
                            <Select.Option value="arbitrary">{intl.formatMessage(messages.arbitrary)}</Select.Option>
                        </Select>
                        <div className={theme.attributeValueText}>
                            <div className={theme.attributeValueLabel}>{intl.formatMessage(messages.text)}</div>
                            <Input
                                value={currentAttribute?.textValue}
                                disabled={currentAttribute?.textValue === undefined}
                                onChange={onTextValueChange}
                            />
                        </div>
                    </div>

                    <div className={theme.attributeFontStyle}>
                        <FontStyleButton
                            icon={icBold}
                            tooltip={intl.formatMessage(messages.bold)}
                            selected={style.bold}
                            disabled={false}
                            padding
                            onClick={() => {
                                onAttributeFontStyleToggle('bold');
                            }}
                        />
                        <FontStyleButton
                            icon={icItalic}
                            tooltip={intl.formatMessage(messages.italic)}
                            selected={style.italic}
                            disabled={false}
                            padding
                            onClick={() => {
                                onAttributeFontStyleToggle('italic');
                            }}
                        />
                        <FontStyleButton
                            icon={icUnderline}
                            tooltip={intl.formatMessage(messages.underline)}
                            selected={style.underline}
                            disabled={false}
                            onClick={() => {
                                onAttributeFontStyleToggle('underline');
                            }}
                        />
                        <FontColorPickerButton disabled={false} value={style.color} onChange={onAttributeColorChange} />
                        <FontSizeInput disabled={false} value={`${style.fontSize}pt`} onChange={onFontSizeChange} />
                    </div>
                </>
            ) : (
                <KanbanCardAttributeImageStyleSelector
                    presetImages={presetImages}
                    currentAttribute={currentAttribute}
                    onImageIdChange={onImageIdChange}
                    onImageSizeChange={onAttributeImageSizeChange}
                />
            )}

            <Checkbox checked={props.currentAttribute.fromNewLine} onChange={onNewLineOutputChange}>
                {intl.formatMessage(messages.displayOnNewLine)}
            </Checkbox>
        </div>
    );
};
