import { FormInstance } from 'antd';
import React, { ChangeEvent, FC } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { getCurrentLocale } from '../../../../../../selectors/locale.selectors';
import { EdgeType, EdgeTypeGroup, InternationalString } from '../../../../../../serverapi/api';
import { MultiLangInputDialog } from '../../../../../MultiLangInputDialog/MultiLangInputDialog.component';
import theme from './EdgeTypeGeneralTab.scss';
import messages from './EdgeType.messages';
import { LocalesService } from '../../../../../../services/LocalesService';
import { InputId } from '../../../../../InputId/InputId.component';
import { InputSynonymsIds } from '../../../../../InputSynonymsIds/InputSynonymsIds.component';
import { Select } from '../../../../../UIKit/components/Select/Select.component';
import { Checkbox } from '../../../../../UIKit/components/Checkbox/Checkbox.component';

type TGeneralTabProps = {
    edgeType: EdgeType;
    createMode: boolean;
    edgeTypeGroups: EdgeTypeGroup[];
    generalForm: FormInstance<any> | undefined;
    exportStyle: () => void;
    onChangeEdgeTypeGroup: (edgeTypeGroup: EdgeTypeGroup) => void;
    onChangeEdgeTypeName: (value: InternationalString) => void;
    onChangeEdgeTypeId: (id: string) => void;
    onChangeEdgeTypeDescription: (value: InternationalString) => void;
    onChangeEdgeTypeInvisible: (invisible: boolean) => void;
    onChangeEdgeTypeSynonymsIds: (e: ChangeEvent<HTMLInputElement>) => void;
    onChangeEdgeTypeAlwaysCreateDefintion: (alwaysCreateDefintion: boolean) => void;
    onChangeEdgeTypeApprovals: (allowApprovals: boolean) => void;
};

export const GeneralTab: FC<TGeneralTabProps> = (props) => {
    const { createMode, edgeType, generalForm, edgeTypeGroups } = props;
    const currentLocale = useSelector(getCurrentLocale);
    const intl = useIntl();

    const selectContainerRef = React.useRef<HTMLDivElement>(null);

    const onChangeEdgeTypeGroup = (groupId: string) => {
        const changeGroup = edgeTypeGroups.find((etg) => etg.id === groupId);
        if (changeGroup) {
            props.onChangeEdgeTypeGroup(changeGroup);
        }
    };

    return (
        <div ref={selectContainerRef} className={theme.form}>
            <MultiLangInputDialog
                placeholder={intl.formatMessage(messages.name)}
                multiLangValue={edgeType.multilingualName}
                onChange={props.onChangeEdgeTypeName}
                label={intl.formatMessage(messages.name)}
                mainInputName="multilingualName"
                mainInputClassName={theme.input}
                generalForm={generalForm}
                required
                autoFocus
                data-test="edge-type-name-input"
            />
            <InputId
                disabled={!createMode}
                value={edgeType.id}
                required
                mainInputName="GUID"
                mainInputClassName={theme.input}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    props.onChangeEdgeTypeId(e.target.value.trim());
                }}
            />
            <div className={theme.selectWrapper}>
                <div className={theme.customRequired}>
                    <Select
                        label={<span className={theme.selectLabel}>{intl.formatMessage(messages.edgeTypeGroups)}</span>}
                        originalTheme
                        data-test="edge-type-group-select"
                        onChange={onChangeEdgeTypeGroup}
                        value={LocalesService.internationalStringToString(
                            edgeType.edgeTypeGroup?.multilingualName,
                            currentLocale,
                        )}
                    >
                        {edgeTypeGroups.map((group: EdgeTypeGroup) => (
                            <Select.Option
                                key={group.id}
                                value={group.id}
                                data-test="edge-select-option-GroupType"
                                label={LocalesService.internationalStringToString(
                                    group.multilingualName,
                                    currentLocale,
                                )}
                            />
                        ))}
                    </Select>
                </div>
            </div>
            <InputSynonymsIds
                value={edgeType.synonymsIds}
                mainInputName="synonymsIds"
                mainInputClassName={theme.input}
                onChange={props.onChangeEdgeTypeSynonymsIds}
            />
            <MultiLangInputDialog
                placeholder={intl.formatMessage(messages.description)}
                multiLangValue={edgeType.multilingualDescription}
                onChange={props.onChangeEdgeTypeDescription}
                textarea
                label={intl.formatMessage(messages.description)}
                mainInputName="multilingualDescription"
                mainInputClassName={theme.textArea}
                generalForm={generalForm}
                data-test="edge-type-description-input"
            />
            <div className={theme.checkboxWrapper}>
                <Checkbox
                    checked={edgeType.canBeInvisible}
                    onChange={() => props.onChangeEdgeTypeInvisible(!edgeType.canBeInvisible)}
                    data-test="invisible-edge-type_checkbox"
                >
                    {intl.formatMessage(messages.canBeInvisible)}
                </Checkbox>
                <Checkbox
                    checked={edgeType.alwaysCreateDefinition}
                    onChange={() => props.onChangeEdgeTypeAlwaysCreateDefintion(!edgeType.alwaysCreateDefinition)}
                    data-test="auto-definition-edge-type_checkbox"
                >
                    {intl.formatMessage(messages.alwaysCreateDefintion)}
                </Checkbox>
                <Checkbox
                    checked={edgeType.allowApprovals}
                    onChange={() => props.onChangeEdgeTypeApprovals(!edgeType.allowApprovals)}
                    data-test="approvals-edge-type_checkbox"
                >
                    {intl.formatMessage(messages.allowEdgeApproval)}
                </Checkbox>
            </div>
        </div>
    );
};
