import React, { FC, useEffect, useRef, useState } from 'react';
import theme from './ReportEditorFilterBar.scss';
import { SearchInput } from '@/modules/UIKit/components/Select/SearchInput.component';
import { Button } from '@/modules/UIKit/components/Button/Button.component';
import { DownOutlined, UpOutlined } from '@ant-design/icons';
import icRefresh from '@/resources/icons/ic-update.svg';
import icDelete from '@/resources/icons/Deleted.svg';
import icArrowUp from '@/resources/icons/ic-ribbon-arrow-up.svg';
import icArrowDown from '@/resources/icons/ic-ribbon-arrow-down.svg';
import messages from './ReportEditorFilterBar.messages';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { reportDeleteNodes, reportRefresh } from '../../actions/report.actions';
import { AttributeType, AttributeTypeValueTypeEnum, NodeId, ReportColumnData } from '@/serverapi/api';
import { ConfirmationDialog } from './ConfirmationDialog.component';
import { systemAttributeTypes } from '@/utils/constants/systemAttributes.const';
import { getValueTypeByAttrId } from '../../utils/report.utils';
import { TTableData } from '@/modules/UIKit/components/Table/TableUIKit.types';
import { ReportSelectors } from '../../selectors/report.selectors';
import { StringFilter } from './FilterComponents/StringFilter.component';
import { NumericFilter } from './FilterComponents/NumericFilter.component';
import { uniq } from 'lodash-es';
import { DateFilter } from './FilterComponents/DateFilter.component';

type TReportEditorFilterBarProps = {
    reportNodeId: NodeId;
    selectedNodeIds: NodeId[];
    searchValue: string;
    disableSearchButtons: boolean;
    showDeleteBtn: boolean;
    reportColumns: ReportColumnData[];
    tableData: TTableData[];
    setSearchValue: (value: string) => void;
    incIndex: () => void;
    decIndex: () => void;
};

const GAP = 12;
const SEARCH_CONTAINER_MIN_WIDTH = 220;
const BUTTONS_CONTAINER_MIN_WIDTH = 92;
const STRING_FILTER_MIN_WIDTH = 220;
const NUMERIC_FILTER_MIN_WIDTH = 220;
const DATE_FILTER_MIN_WIDTH = 300;
const DATE_AND_TIME_FILTER_MIN_WIDTH = 350;

const STRING_ATTRIBUTES: AttributeTypeValueTypeEnum[] = [
    'STRING',
    'MULTI_STRING',
    'BOOLEAN',
    'SELECT',
    'MULTI_SELECT',
    'JSON',
    'PRINCIPAL',
    'URL',
    'NODE',
];

const DATE_ATTRIBUTES: AttributeTypeValueTypeEnum[] = [
    'TIME',
    'DATE',
    'DATE_TIME',
    'DATE_TIME_WITHOUT_TIMEZONE',
    'TIME_WITHOUT_TIMEZONE',
];

const NUMERIC_ATTRIBUTES: AttributeTypeValueTypeEnum[] = ['NUMERIC'];

export const ReportEditorFilterBar: FC<TReportEditorFilterBarProps> = ({
    reportNodeId,
    searchValue,
    disableSearchButtons,
    selectedNodeIds,
    showDeleteBtn,
    reportColumns,
    tableData,
    setSearchValue,
    incIndex,
    decIndex,
}) => {
    const intl = useIntl();
    const dispatch = useDispatch();

    const attributeTypes: AttributeType[] = useSelector(ReportSelectors.getAccessibleAttributeTypes(reportNodeId));

    const [open, setOpen] = useState<boolean>(false);
    const [showFilters, setShowFilters] = useState<boolean>(false);
    const [widthForFilters, setWidthForFilters] = useState<number>(0);
    const filtersBarContainer = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        const observer = new ResizeObserver((entries: ResizeObserverEntry[]) => {
            const { width } = entries[0].contentRect;
            setWidthForFilters(width - SEARCH_CONTAINER_MIN_WIDTH - BUTTONS_CONTAINER_MIN_WIDTH);
        });
        if (filtersBarContainer.current) {
            observer.observe(filtersBarContainer.current);
        }

        return () => {
            filtersBarContainer.current && observer.unobserve(filtersBarContainer.current);
        };
    }, [filtersBarContainer.current]);

    const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.value !== searchValue) {
            setSearchValue(e.target.value.trim().toLowerCase());
        }
    };

    const handleClearSearch = () => setSearchValue('');

    const refreshHandler = () => {
        dispatch(reportRefresh(reportNodeId));
    };

    const deletehHandler = () => {
        dispatch(reportDeleteNodes(reportNodeId, selectedNodeIds));
        setOpen(false);
    };

    const getColumnFilters = () => {
        const columnsWithFilter: ReportColumnData[] = reportColumns.filter((column) => column.filterEnabled);

        let currentWidth: number = 0;
        const firstLane: JSX.Element[] = [];
        const secondLane: JSX.Element[] = [];

        columnsWithFilter.forEach((column) => {
            const currentAttributeTypes: AttributeType[] =
                column.attributeType === 'SYSTEM' ? systemAttributeTypes : attributeTypes;
            const valueType: AttributeTypeValueTypeEnum | undefined = getValueTypeByAttrId(
                column.attributeTypeId,
                currentAttributeTypes,
            );

            if (!valueType) return;

            const columnName = column.columnName || '';
            const columnId = column.columnId;

            let component: JSX.Element | null = null;

            if (STRING_ATTRIBUTES.includes(valueType)) {
                currentWidth += STRING_FILTER_MIN_WIDTH + GAP;

                const values: string[] = uniq(
                    tableData
                        .map((row) => row[`${column.attributeType}_${column.attributeTypeId}`] as string)
                        .filter((value) => value),
                );

                component = (
                    <StringFilter
                        reportNodeId={reportNodeId}
                        key={columnId}
                        columnId={columnId}
                        columnName={columnName}
                        values={values}
                        minWidth={STRING_FILTER_MIN_WIDTH}
                    />
                );
            }

            if (NUMERIC_ATTRIBUTES.includes(valueType)) {
                currentWidth += NUMERIC_FILTER_MIN_WIDTH + GAP;
                component = (
                    <NumericFilter
                        reportNodeId={reportNodeId}
                        key={columnId}
                        columnId={columnId}
                        columnName={columnName}
                        minWidth={NUMERIC_FILTER_MIN_WIDTH}
                    />
                );
            }

            if (DATE_ATTRIBUTES.includes(valueType)) {
                const width =
                    valueType === 'DATE_TIME_WITHOUT_TIMEZONE' || valueType === 'DATE_TIME'
                        ? DATE_AND_TIME_FILTER_MIN_WIDTH
                        : DATE_FILTER_MIN_WIDTH;
                currentWidth += width + GAP;

                component = (
                    <DateFilter
                        reportNodeId={reportNodeId}
                        key={columnId}
                        columnId={columnId}
                        columnName={columnName}
                        minWidth={width}
                        valueType={valueType}
                    />
                );
            }

            if (component) {
                if (currentWidth < widthForFilters) {
                    firstLane.push(component);
                } else {
                    secondLane.push(component);
                }
            }
        });

        return { firstLane, secondLane };
    };

    const { firstLane, secondLane } = getColumnFilters();

    return (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div className={theme.filterBarContainer} ref={filtersBarContainer} style={{ gap: GAP }}>
                <div className={theme.searchContainer} style={{ minWidth: SEARCH_CONTAINER_MIN_WIDTH }}>
                    <SearchInput
                        showSearch
                        originalTheme
                        searchValue={searchValue}
                        onSearch={handleSearch}
                        allowClear
                        onClear={handleClearSearch}
                    />

                    <Button visualStyle="text" size="small" onClick={decIndex} disabled={disableSearchButtons}>
                        <UpOutlined />
                    </Button>
                    <Button visualStyle="text" size="small" onClick={incIndex} disabled={disableSearchButtons}>
                        <DownOutlined />
                    </Button>
                </div>
                {firstLane}
                <div className={theme.actionBtnsContainer}>
                    {secondLane.length > 0 && (
                        <Button
                            onClick={() => setShowFilters((prev) => !prev)}
                            visualStyle={{ type: 'text' }}
                            size="small"
                            tooltip={
                                showFilters
                                    ? intl.formatMessage(messages.hideFilters)
                                    : intl.formatMessage(messages.showFilters)
                            }
                            icon={showFilters ? icArrowUp : icArrowDown}
                        />
                    )}
                    <Button
                        onClick={refreshHandler}
                        visualStyle={{ type: 'text' }}
                        size="small"
                        tooltip={intl.formatMessage(messages.refresh)}
                        icon={icRefresh}
                    />
                    {showDeleteBtn && (
                        <>
                            <Button
                                disabled={selectedNodeIds.length === 0}
                                onClick={() => setOpen(true)}
                                visualStyle={{ type: 'text' }}
                                size="small"
                                tooltip={intl.formatMessage(messages.deleteMessage)}
                                icon={icDelete}
                            />
                            <ConfirmationDialog open={open} onDelete={deletehHandler} onCancel={() => setOpen(false)} />
                        </>
                    )}
                </div>
            </div>
            {showFilters && secondLane.length > 0 && (
                <div className={theme.secondLaneContainer} style={{ gap: GAP }}>
                    {secondLane}
                </div>
            )}
        </div>
    );
};
