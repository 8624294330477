import * as React from 'react';
import { connect, Provider } from 'react-redux';
import { TRootState } from '../../../reducers/root.reducer.types';
import { App } from '../components/App/App.component';
import { NavigatorContainer } from '../../Navigator/containers/Navigator.container';
import { TabContainer } from '../../Workspace/containers/Tab.container';
import { HeaderContainer } from '../../Header/containers/Header.container';
import { MainMenuContentContainer } from '../../MainMenu/containers/MainMenuContainer.container';
import { ModelDialogContainer } from '../../ModelDialog/containers/ModelDialog/ModelDialog.container';
import { FormattedMessage, IntlProvider } from 'react-intl';
import { getCurrentLocale } from '../../../selectors/locale.selectors';
import { StatusBarContainer } from '../../StatusBar/containers/StatusBar.container';
import { DialogRootContainer } from '../../DialogRoot/DialogRoot.container';
import { StartScreenContainer } from '../../StartScreen/containers/StartScreen.container';
import {
    getIsStarted,
    getStatus,
    getFullModelDefinition,
    getAppColor,
    getAppColorScheme,
} from '../../../selectors/app.selector';
import { AppStatus } from '../../../models/app';
import { getNotification } from '../../../selectors/errorNotification.selectors';
import { closeNotification } from '../../../actions/notification.actions';
import { TNotificationEntity } from '../../../models/notification.types';
import { fileDownloadedNotificationShow, setReportDownloadDate } from '../../../actions/entities/script.actions';
import { AppColorScheme, FullModelDefinition, KeycloakSettings, NodeId } from '../../../serverapi/api';
import { SimpleEditorContainer } from '../../Editor/containers/SimpleEditor.container';
import { LocalesService } from '../../../services/LocalesService';
import NotificationMessages from '../messages/AppNotifications.messages';
import { fileDownload } from '../../../actions/file.actions';
import { TAppColor } from '../../../reducers/app.reducer.types';
import { TAppContainerProps } from './App.container.types';
import { ConfigProvider } from 'antd';
import { WidgetSettingsPanel } from '@/modules/Dashboard/WidgetSettingsPanel/WidgetSettingsPanel.component';
import { TabsSelectors } from '@/selectors/tabs.selectors';
import { DashboardSelector } from '@/selectors/entities/dashboard.selectors';
import { EditorMode } from '@/models/editorMode';
import { KeycloakAuth } from '@/modules/StartScreen/components/KeycloakAuth.component';
import { KeycloakSettingsSelectors } from '@/selectors/keycloakSettings.selectors';
import { setButtonColorScheme } from '@/utils/colorScheme';
import { ExternalLinkBLLService } from '@/services/bll/ExternalLinkBLLService';

const mainMenu = <MainMenuContentContainer />;
const sidePanel = <NavigatorContainer />;
const mainPanel = <TabContainer />;
const statusBar = <StatusBarContainer />;
const header = <HeaderContainer />;
const dialogRoot = <DialogRootContainer />;
const modelDialog = <ModelDialogContainer />;
const widgetSettings = <WidgetSettingsPanel />;

type TMapStateToProps = {
    showSidePanel: boolean;
    showWidgetPanel: boolean;
    status: AppStatus;
    notifications: { [key: string]: TNotificationEntity };
    isStarted: boolean;
    locale: string;
    messages: any;
    fullModelDefinition: FullModelDefinition | undefined;
    appColor: TAppColor;
    keycloakSettings: KeycloakSettings | undefined;
    appColorScheme: AppColorScheme;
};

type TMapDispatchToProps = {
    onCloseErrorNotification: (id: string) => void; // tslint:disable-line:no-any
    setDownloadDate: (serverId: string, scriptExecutionId: string) => void; // tslint:disable-line:no-any
    sendDownloadedNotification: (fileName: string, filePath: string) => void; // tslint:disable-line:no-any
    downloadFile: (fileId: NodeId) => void;
};

type TAppWrapperProps = TMapStateToProps & TMapDispatchToProps;

function AppWrapper(props: TAppWrapperProps) {
    const {
        isStarted,
        status,
        notifications,
        onCloseErrorNotification,
        setDownloadDate,
        sendDownloadedNotification,
        downloadFile,
        fullModelDefinition,
        appColor,
        appColorScheme,
        locale,
        keycloakSettings,
    } = props;

    const style = {
        height: '100%',
        width: '100%',
    } as React.CSSProperties;

    const [reRender, setReRender] = React.useState(false);

    const handleReRender = () => {
        setReRender(!reRender);
    };

    React.useEffect(() => {
        document.documentElement.style.setProperty('--mainColor', appColor.mainColor);
        document.documentElement.style.setProperty('--secondColor', appColor.secondColor);
        document.documentElement.style.setProperty('--selectedRowColor', appColor.selectedRowColor);
        setButtonColorScheme(appColorScheme.button);
    }, [appColor.mainColor, appColor.secondColor, appColor.selectedRowColor, appColorScheme.button]);

    if (fullModelDefinition) {
        return (
            <IntlProvider locale={props.locale} key={props.locale} messages={props.messages}>
                <div style={style}>
                    {status === AppStatus.Bootstrapping && (
                        <FormattedMessage id={NotificationMessages.loadingPage.id} />
                    )}
                    {status !== AppStatus.Bootstrapping && <SimpleEditorContainer definition={fullModelDefinition!} />}
                </div>
            </IntlProvider>
        );
    }

    const shouldOpenKeycloak = ExternalLinkBLLService.hasKeycloakParamInUrl(window.location.href) && keycloakSettings;

    return (
        <IntlProvider locale={props.locale} key={props.locale} messages={props.messages}>
            <div style={style}>
                {status === AppStatus.Bootstrapping && <FormattedMessage id={NotificationMessages.loadingPage.id} />}
                {status !== AppStatus.Bootstrapping && !isStarted && !shouldOpenKeycloak && (
                    <StartScreenContainer appStatus={status} handleReRender={handleReRender} />
                )}
                {status !== AppStatus.Bootstrapping && !isStarted && shouldOpenKeycloak && (
                    <KeycloakAuth keycloakSettings={keycloakSettings} handleReRender={handleReRender} />
                )}
                {status !== AppStatus.Bootstrapping && isStarted && (
                    <App
                        notifications={notifications}
                        header={header}
                        onCloseErrorNotification={onCloseErrorNotification}
                        mainMenu={mainMenu}
                        mainPanel={mainPanel}
                        sidePanel={sidePanel}
                        statusBar={statusBar}
                        showSidePanel={props.showSidePanel}
                        showWidgetPanel={props.showWidgetPanel}
                        modelDialog={modelDialog}
                        dialogRoot={dialogRoot}
                        widgetSettings={widgetSettings}
                        locale={locale}
                        setDownloadDate={setDownloadDate}
                        sendDownloadedNotification={sendDownloadedNotification}
                        dowloadFile={downloadFile}
                    />
                )}
            </div>
        </IntlProvider>
    );
}

const mapStateToProps: (state: TRootState) => TMapStateToProps = (state) => {
    const activeTab = TabsSelectors.getActiveTabId(state);
    const selectedWidget = !!DashboardSelector.selectedWidget(activeTab)(state);
    const editorMode: EditorMode | undefined = TabsSelectors.getEditorModeById(activeTab)(state);
    const keycloakSettings: KeycloakSettings | undefined = KeycloakSettingsSelectors.getKeycloakSettings(state);

    return {
        notifications: getNotification(state),
        showSidePanel: state.navigator.activeTabs.length !== 0,
        showWidgetPanel: selectedWidget && editorMode === EditorMode.Edit,
        status: getStatus(state),
        isStarted: getIsStarted(state),
        locale: getCurrentLocale(state),
        messages: LocalesService.getMessagesByLocale(getCurrentLocale(state)),
        fullModelDefinition: getFullModelDefinition(state),
        appColor: getAppColor(state),
        appColorScheme: getAppColorScheme(state),
        keycloakSettings,
    };
};

const mapDispatchToProps = (dispatch): TMapDispatchToProps => ({
    onCloseErrorNotification: (id: string) => dispatch(closeNotification(id)),
    setDownloadDate: (serverId: string, scriptExecutionId: string) =>
        dispatch(setReportDownloadDate(serverId, scriptExecutionId)),
    sendDownloadedNotification: (fileName: string, filePath: string) =>
        dispatch(fileDownloadedNotificationShow(fileName, filePath)),
    downloadFile: (fileId: NodeId) => dispatch(fileDownload(fileId)),
});

const AppWrapperConnected = connect(mapStateToProps, mapDispatchToProps)(AppWrapper);

export const AppContainer: React.FC<TAppContainerProps> = ({ store }) => (
    <Provider store={store}>
        <ConfigProvider
            theme={{
                components: {
                    Table: {
                        lineHeight: 1.2,
                    },
                },
                token: {
                    colorText: 'rgba(0, 0, 0, 0.65)',
                    colorPrimary: '#41bcfb',
                    colorPrimaryBorderHover: 'rgba(255, 255, 255, 0.3)',
                    fontFamilyCode: 'Consolas, Menlo, Courier, monospace',
                    fontSize: 12,
                    fontSizeLG: 14,
                    lineHeight: 1.5715,
                    borderRadius: 2,
                    borderRadiusLG: 0,
                    borderRadiusOuter: 0,
                    borderRadiusSM: 0,
                    borderRadiusXS: 0,
                },
            }}
        >
            <AppWrapperConnected />
        </ConfigProvider>
    </Provider>
);

// @ts-ignore
module?.hot?.accept();
