import type { IExtendedImageOptions, TImageAttributes } from '../../extensions/image.types';
import type { NodeViewProps } from '@tiptap/core';
import { Resizable } from 're-resizable';
import React from 'react';
import { NodeViewWrapper } from '@tiptap/react';
import { useResizeHandlers } from './useResizeImage';
import theme from './imageRenderer.scss';

export const ImageRenderer = (props: NodeViewProps) => {
    const { node, extension, updateAttributes } = props;
    const { id, src, width, height, alt, title } = node.attrs as TImageAttributes;
    const { linkMapper } = extension.options as IExtendedImageOptions;
    const imageSrc = linkMapper(id, src);

    const { onResizeStart, onResizeStop, lockAspectRatio } = useResizeHandlers({ width, height, updateAttributes });

    return (
        <NodeViewWrapper>
            <Resizable
                size={{ width, height }}
                onResizeStart={onResizeStart}
                onResizeStop={onResizeStop}
                lockAspectRatio={lockAspectRatio}
            >
                <img src={imageSrc} alt={alt} title={title} className={theme.image} />
            </Resizable>
        </NodeViewWrapper>
    );
};
