import React, { FC, useEffect, useState } from 'react';
import theme from './Filters.scss';
import { Select } from '@/modules/UIKit/components/Select/Select.component';
import { TFilterType } from '../../../reducers/reportEditor.reducer.types';
import { useDispatch, useSelector } from 'react-redux';
import { reportSetColumnFilter } from '@/modules/Report/actions/report.actions';
import { NodeId } from '@/serverapi/api';
import { ReportSelectors } from '@/modules/Report/selectors/report.selectors';

type TStringFilterProps = {
    reportNodeId: NodeId;
    columnId: string;
    columnName: string;
    values: string[];
    minWidth: number;
};

export const StringFilter: FC<TStringFilterProps> = ({ reportNodeId, columnId, columnName, values, minWidth }) => {
    const selectedValues: string[] = useSelector(
        ReportSelectors.getFilterValue(reportNodeId, columnId, TFilterType.STRING),
    ) as string[];

    const [searchValue, setSearchValue] = useState<string>('');

    useEffect(() => {
        const existedSelectedValues: string[] = selectedValues.filter((selectedValue) =>
            values.includes(selectedValue),
        );
        // может быть ситуация, когда выбранное значение пропало из списка уникальных значений
        // для Select. Тогда нужно очистить фильтр для этих значений, т.к. в Select их уже нет
        if (existedSelectedValues.length < selectedValues.length) {
            dispatch(reportSetColumnFilter(reportNodeId, columnId, TFilterType.STRING, existedSelectedValues));
        }
    }, [values, selectedValues]);

    const dispatch = useDispatch();

    const onChangeHandler = (values: string[] | undefined) => {
        dispatch(reportSetColumnFilter(reportNodeId, columnId, TFilterType.STRING, values || []));
    };

    const filteredValues = values.filter((value) => value.toLowerCase().includes(searchValue.toLowerCase()));

    return (
        <div className={theme.conatiner} style={{ minWidth, maxWidth: minWidth }} key={columnId}>
            <Select
                isMultiSelect
                showSearch
                allowSelectAll
                searchValue={searchValue}
                onSearch={setSearchValue}
                onChange={onChangeHandler}
                wrapperClassName={theme.select}
                placeholder={columnName}
                allowClear
                dropdownWidth={400}
                dropdownAlignment="auto"
            >
                {filteredValues.map((value) => (
                    <Select.Option
                        key={`${columnId}_${value}`}
                        label={value}
                        value={value}
                        checked={selectedValues.includes(value)}
                    />
                ))}
            </Select>
        </div>
    );
};
