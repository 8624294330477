import type { TTocSettingsDialogProps } from './WikiTocSettingsDialog.types';
import React, { useState } from 'react';
import { Dialog } from '../../UIKit/components/Dialog/Dialog.component';
import { DEFAULT_DIALOG_WIDTH } from '../../../config/config';
import { DialogFooterButtons } from '@/modules/UIKit/components/DialogFooterButtoms/DialogFooterButtons.component';
import { useIntl } from 'react-intl';
import { Form } from 'antd';
import messages from './WikiTocSettingsDialog.messages';
import { useDispatch } from 'react-redux';
import { closeDialog } from '@/actions/dialogs.actions';
import { DialogType } from '@/modules/DialogRoot/DialogRoot.constants';
import { Select } from '@/modules/UIKit/components/Select/Select.component';
import theme from './WikiTocSettingsDialog.scss';
import {
    TocIndex,
    TocView,
} from '@/modules/UIKit/components/TipTapTextEditor/Editor/extensions/table-of-contents.types';
import { Icon } from '@/modules/UIKit';
import icClose from '../../../resources/icons/UIKit/Close2.svg';
import { tocHeadingLevels } from '@/utils/configuration';

const TOC_HEADING_LEVELS = tocHeadingLevels.map(String);

export const WikiTocSettingsDialog = ({ settings, onSubmit }: TTocSettingsDialogProps) => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const [levels, setLevels] = useState(settings.levels.map(String));
    const [index, setIndex] = useState(settings.index);
    const [view, setView] = useState(settings.view);

    const handleCancel = () => {
        dispatch(closeDialog(DialogType.TOC_SETTINGS_DIALOG));
    };

    const handleSubmit = () => {
        dispatch(closeDialog(DialogType.TOC_SETTINGS_DIALOG));
        onSubmit({
            levels: Object.values(levels).map(Number),
            index: index,
            view: view,
        });
    };

    const children = (
        <Form form={form} className={theme.contentContainer}>
            <Select
                label={intl.formatMessage(messages.headingsSelectLabel)}
                value={intl.formatMessage(messages.headingsSelectPlaceholder)}
                onChange={(level: string) => setLevels((prev) => [...prev, level])}
                wrapperClassName={theme.settingsSelect}
                originalTheme
            >
                {TOC_HEADING_LEVELS.filter((el) => !levels.includes(el)).map((i) => (
                    <Select.Option
                        key={`heading_option_${i}`}
                        value={i}
                        label={`${intl.formatMessage(messages.heading)} ${i}`}
                    />
                ))}
            </Select>
            <div className={theme.selectedHeadings}>
                {levels?.sort().map((level) => (
                    <div className={theme.selectedHeading}>
                        <div>{`${intl.formatMessage(messages.heading)} ${level}`}</div>
                        <Icon
                            className={theme.deselectButton}
                            spriteSymbol={icClose}
                            onClick={() => setLevels(levels.filter((lvl) => lvl !== level))}
                        />
                    </div>
                ))}
            </div>
            <Select
                label={intl.formatMessage(messages.indexSelectLabel)}
                value={intl.formatMessage(messages[`${index}Index`])}
                onChange={(index: TocIndex) => setIndex(index || TocIndex.HIDE)}
                wrapperClassName={theme.settingsSelect}
                originalTheme
            >
                {[TocIndex.HIDE, TocIndex.ONE_LEVEL, TocIndex.ALL_LEVELS].map((i) => (
                    <Select.Option
                        key={`index_option_${i}`}
                        checked={i === index}
                        value={i}
                        label={intl.formatMessage(messages[`${i}Index`])}
                    />
                ))}
            </Select>
            <Select
                label={intl.formatMessage(messages.viewTypeSelectLabel)}
                value={intl.formatMessage(messages[`${view}View`])}
                onChange={(view: TocView) => setView(view || TocView.TREE)}
                wrapperClassName={theme.settingsSelect}
                originalTheme
            >
                {[TocView.TREE, TocView.FLAT].map((i) => (
                    <Select.Option
                        key={`view_option_${i}`}
                        checked={i === view}
                        value={i}
                        label={intl.formatMessage(messages[`${i}View`])}
                    />
                ))}
            </Select>
        </Form>
    );

    const footer = (
        <DialogFooterButtons
            buttons={[
                {
                    key: 'cancel',
                    onClick: handleCancel,
                    value: intl.formatMessage(messages.formDeclineButton),
                },
                {
                    key: 'ok',
                    onClick: handleSubmit,
                    value: intl.formatMessage(messages.formConfirmButton),
                    visualStyle: 'primary',
                },
            ]}
        />
    );

    return (
        <Dialog
            onOk={handleSubmit}
            onCancel={handleCancel}
            title={intl.formatMessage(messages.formName)}
            open
            width={DEFAULT_DIALOG_WIDTH}
            footer={footer}
        >
            {children}
        </Dialog>
    );
};
