import {
    AttributeType,
    AttributeTypeStyle,
    AttributeValue,
    Comment,
    DiagramElement,
    DiagramElementTypeEnum,
    EdgeInstance,
    EdgeWaypoint,
    LayoutInstance,
    ModelAssignment,
    Node as NodeOriginal,
    NodeId,
    NodeTypeEnum,
    ObjectDefinitionNode,
    ObjectInstance,
    ParentModelOfObjectDefinition,
    ObjectType,
    ObjectTypeGroup,
    DiagramElementAttributeStyle,
    InternationalString,
    ObjectModelConnections,
    AttributeTypeValueTypeEnum,
} from '../../serverapi/api';
import { PsdCell } from '../../mxgraph/psdDiagram/psdTable';
import { isUndefined } from 'is-what';
import { SymbolType } from '../Symbols.constants';
import { CustomMap } from '../../utils/map';
import { TAttributeDiscriminator } from '@/modules/FloatingAttributes/FloatingAttributes.types';

class DiagramElementImpl implements DiagramElement {
    type: DiagramElementTypeEnum;
    id: string;
    attributes?: Array<AttributeValue>;
    attributeStyles?: Array<DiagramElementAttributeStyle>;

    constructor(type: DiagramElementTypeEnum) {
        this.type = type;
    }
}

export class CommentMarker {
    comment: Comment;
    label: string;
    // нужен тип string, т.к. в DiagramElementTypeEnum маркеры не входят
    type: string = SymbolType.COMMENT;

    constructor(comment: Comment, label: string) {
        this.comment = comment;
        this.label = label;
    }
}

export class StyledAttributeType {
    id: string;
    name: string;
    valueType?: AttributeTypeValueTypeEnum;
    readOnly?: boolean;
    styles?: AttributeTypeStyle[];
    isDefinitionAttribute?: boolean;
    isInstanceAttribute?: boolean;
    attributeDiscriminator?: TAttributeDiscriminator;
}

export class LabelSymbol {
    objectDefinitionId: string;
    mainCellId: string;
    type: string = SymbolType.LABEL;

    constructor(data: { mainCellId: string; objectDefinitionId: string }) {
        this.mainCellId = data.mainCellId;
        this.objectDefinitionId = data.objectDefinitionId;
    }
}

export class ObjectInstanceImpl extends DiagramElementImpl implements ObjectInstance {
    source: string;
    target: string;
    style: string;
    objectDefinitionId?: string;
    x: number;
    y: number;
    width: number;
    height: number;
    symbolId: string;
    parent: string;
    id: string;
    showLabel: boolean = true;
    labelStyle?: string;
    labelWidth?: number;
    labelHeight?: number;
    labelXOffset?: number;
    labelYOffset?: number;

    constructor(obj?: Partial<ObjectInstanceImpl>) {
        super('object');
        if (obj) {
            Object.assign(this, obj);
        }
    }
}

export class LayoutInstanceImpl extends DiagramElementImpl implements LayoutInstance {
    style: string;
    x: number;
    y: number;
    width: number;
    height: number;
    parent: string;
    id: string;
    metaInfo: string;
    isPSDCell: boolean = true;
    isFrame: boolean = false;
    psdCellMetaInfo: PsdCell | null = null;

    constructor(obj?: Partial<LayoutInstanceImpl>) {
        super('object');
        if (obj) {
            Object.assign(this, obj);
        }
    }
}

export class ObjectDefinitionImpl implements ObjectDefinitionNode {
    nodeId: NodeId;
    parentNodeId?: NodeId;
    attributes: Array<AttributeValue>;
    version: number;
    updatedBy: string;
    description: InternationalString;
    name: string;
    modelAssignments: ModelAssignment[];
    createdBy: string;
    createdAt: number;
    updatedAt: number;
    // cellIds: CustomMap<NodeId, string[]> = new CustomMap();
    userForceSelected: boolean = true;
    isDirty: boolean = false;
    type: NodeTypeEnum;
    children?: Array<NodeOriginal>;
    countChildren?: number;
    inaccessible?: boolean;
    objectTypeId: string;
    idSymbol?: string;
    confidential?: boolean;
    style?: AttributeTypeStyle;
    objectEntries?: Array<ParentModelOfObjectDefinition>;
    objectModelConnections?: Array<ObjectModelConnections>;
    multilingualName?: InternationalString;
    tradeSecret?: boolean;
    personalData?: boolean;

    constructor(obj?: Partial<ObjectDefinitionImpl>) {
        // tslint:disable-line:no-any
        if (obj) {
            Object.assign(this, {
                attributes: [],
                modelAssignments: [],
                cellIds: new CustomMap(),
                ...obj,
            });
        }
    }

    /**
     * Object is just created and hasn't been sent to server yet.
     */
    isJustCreated(): boolean {
        return isUndefined(this.version);
    }
}

export class ObjectTypeImpl implements ObjectType {
    nodeAttributes: Array<AttributeType>;
    diagramElementAttributes: Array<AttributeType>;
    id: string;
    name: string;
    alwaysCreateNew: boolean;
    description: string;
    groupId: string;
    objectTypeGroup: ObjectTypeGroup;
    presetId: string;
    allowAnyDecomposition: boolean;

    constructor(obj?: any) {
        // tslint:disable-line:no-any
        if (obj) {
            Object.assign(this, obj);
            // this.id = obj.elementId;
        }
    }
}

export class EdgeInstanceImpl extends DiagramElementImpl implements EdgeInstance {
    edgeDefinitionId?: string | undefined;
    name: string;
    edgeTypeId: string;
    source: string;
    target: string;
    waypoints: EdgeWaypoint[];
    style: string;
    labelYOffset: number;
    labelXOffset: number;
    allowAnyDecomposition: boolean;
    multilingualName: InternationalString;

    constructor(obj?: any) {
        // tslint:disable-line:no-any
        super('edge');
        if (obj) {
            Object.assign(this, obj);
            // this.id = obj.elementId;
        }
    }
}
