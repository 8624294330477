import React from 'react';
import { InputNumber } from 'antd';
import { KanbanSizeType } from '@/models/kanban.types';
import { TRadioOption } from '@/modules/UIKit/components/Radio/Radio.types';
import { RadioGroup } from '@/modules/UIKit/components/Radio/RadioGroup.component';
import messages from './KanbanCardEditor.messages';
import theme from './KanbanCardEditor.scss';
import { useIntl } from 'react-intl';

type TGridSectionRadioGroupProps = {
    name: string;
    value: KanbanSizeType;
    isDisabled: boolean;
    onChange: (value: KanbanSizeType) => void;
    onInputNumberChange: (value: number) => void;
    inputValue: number;
    inputDataRadio?: string;
    isRow?: boolean;
};

/**
 * Компонент радиогруппы для управления настройками сетки
 * @param {string} name Название текущей секции
 * @param {KanbanSizeType} value Текущее значение типа KanbanSizeType
 * @param {boolean} isDisabled Определяет, отключена ли группа
 * @param {(value: KanbanSizeType) => void} onChange Функция для изменения типа
 * @param {(value: number) => void} onInputNumberChange Функция для изменения значения InputNumber
 * @param {number} inputValue Текущее значение для InputNumber
 * @param {string} inputDataRadio Идентификатор для data-radio
 * @param {boolean | undefined} isRow Это строка если истина, иначе колонка
 */
export const GridSectionRadioGroup: React.FC<TGridSectionRadioGroupProps> = ({
    name,
    value,
    isDisabled,
    onChange,
    onInputNumberChange,
    inputValue,
    inputDataRadio,
    isRow,
}) => {
    const intl = useIntl();
    const options: TRadioOption<KanbanSizeType>[] = [
        {
            value: KanbanSizeType.RELATIVE,
            title: isRow ? intl.formatMessage(messages.adaptiveHeight) : intl.formatMessage(messages.adaptiveWidth),
            dataTest: `${name}-relative`,
            disabled: isDisabled,
        },
        {
            value: KanbanSizeType.ABSOLUTE,
            dataTest: `${name}-absolute`,
            disabled: isDisabled,
            title: (
                <div className={theme.absoluteRadioContent}>
                    <span>{isRow ? intl.formatMessage(messages.fixedHeight) : intl.formatMessage(messages.fixedWidth)} (px) </span>
                    <InputNumber
                        min={1}
                        max={500}
                        defaultValue={inputValue}
                        onChange={onInputNumberChange}
                        disabled={isDisabled}
                        data-radio={inputDataRadio}
                    />
                </div>
            ),
        },
    ];

    return (
        <RadioGroup<KanbanSizeType>
            options={options}
            value={value}
            onChange={onChange}
            disabled={isDisabled}
            direction="Row"
            margin={8}
        />
    );
};
