import { AppColorScheme, ButtonVisualStyle, FullModelDefinition, UserProperty } from '../serverapi/api';
import {
    APP_CLOSE_REQUEST,
    APP_SIZE_TOGGLE,
    APP_MINIMIZE_REQUEST,
    APP_LOADED,
    APP_START,
    APP_STOP,
    APP_BOOTSTRAP_SUCCESS,
    APP_BOOTSTRAP_REQUEST,
    APP_CHANGE_APP_MODE_TO_ONLY_DIAGRAM,
    APP_OPEN_URI,
    APP_ABOUT_CLICK,
    APP_LOAD_INITIAL_VIEW_SETTING,
    APP_LOAD_VIEW_COLORS,
    APP_EXIT,
    APP_SET_IS_EN_LOCALE_ALLOWED,
    DOWNLOAD_DOCX,
    APP_LOAD_VIEW_COLOR_SCHEME,
    SET_TMP_BTN_COLOR,
    CLEAR_TMP_BTNS_COLORS,
    SET_TMP_BTNS_COLORS,
} from '../actionsTypes/app.actionTypes';
import {
    TAppAboutClickAction,
    TAppCloseRequestAction,
    TAppExit,
    TAppLoadCallbackAction,
    TAppLoadInitialViewSetting,
    TAppLoadViewColorScheme,
    TAppLoadViewMainColor,
    TAppMinimizeRequestAction,
    TAppOpenUriAction,
    TAppSetIsEnLocaleAllowed,
    TAppSizeToggleAction,
    TAppStartAction,
    TAppStopAction,
    TBootstrapRequest,
    TBootstrapSuccess,
    TButtonItemColor,
    TChangeAppMode,
    TClearTmpBtnsColors,
    TSetTmpBtnColor,
    TSetTmpBtnsColors,
} from './app.actions.types';
import { AppExitMethod, AppStatus } from '../models/app';
import { TAppColor } from '../reducers/app.reducer.types';
import { TDownloadFileAction } from './agreement.actions.types';
import { DownloadUrl } from '@/modules/Header/components/Logo/logo.types';

export const loadModelDefinitionSuccess = (definition: FullModelDefinition | undefined): TChangeAppMode => ({
    type: APP_CHANGE_APP_MODE_TO_ONLY_DIAGRAM,
    payload: {
        definition,
    },
});

export const bootstrapRequest = (): TBootstrapRequest => ({
    type: APP_BOOTSTRAP_REQUEST,
});

export const bootstrapSuccess = (): TBootstrapSuccess => {
    return {
        type: APP_BOOTSTRAP_SUCCESS,
    };
};

export const appStart = (): TAppStartAction => {
    return {
        type: APP_START,
    };
};

export const appStop = (status: AppStatus): TAppStopAction => {
    return {
        type: APP_STOP,
        payload: {
            status,
        },
    };
};

export const appExit = (method: AppExitMethod): TAppExit => {
    return {
        type: APP_EXIT,
        payload: {
            method,
        },
    };
};

export const appCloseRequest = (): TAppCloseRequestAction => {
    return {
        type: APP_CLOSE_REQUEST,
    };
};

export const appLoaded = (): TAppLoadCallbackAction => {
    return {
        type: APP_LOADED,
    };
};

export const appSizeToggle = (): TAppSizeToggleAction => {
    return {
        type: APP_SIZE_TOGGLE,
    };
};

export const appMinimizeRequest = (): TAppMinimizeRequestAction => {
    return {
        type: APP_MINIMIZE_REQUEST,
    };
};

export const appOpenUri = (uri: string): TAppOpenUriAction => ({
    type: APP_OPEN_URI,
    payload: { uri },
});

export const appAboutClick = (): TAppAboutClickAction => ({
    type: APP_ABOUT_CLICK,
});

export const appLoadInitialViewSetting = (properties: UserProperty): TAppLoadInitialViewSetting => ({
    type: APP_LOAD_INITIAL_VIEW_SETTING,
    payload: { properties },
});

export const appLoadViewMainColor = (appColor: TAppColor): TAppLoadViewMainColor => ({
    type: APP_LOAD_VIEW_COLORS,
    payload: { appColor },
});

export const appLoadViewColorScheme = (appColorScheme: AppColorScheme): TAppLoadViewColorScheme => ({
    type: APP_LOAD_VIEW_COLOR_SCHEME,
    payload: { appColorScheme },
});

export const appSetIsEnLocaleAllowed = (isEnLocaleAllowed: boolean): TAppSetIsEnLocaleAllowed => ({
    type: APP_SET_IS_EN_LOCALE_ALLOWED,
    payload: { isEnLocaleAllowed },
});

export const downloadDocx = (url: DownloadUrl): TDownloadFileAction => ({
    payload: { url },
    type: DOWNLOAD_DOCX,
});

export const setTmpBtnColor = (btnItemColor: TButtonItemColor): TSetTmpBtnColor => ({
    payload: { btnItemColor },
    type: SET_TMP_BTN_COLOR,
});

export const setTmpBtnsColors = (buttonStyle: ButtonVisualStyle): TSetTmpBtnsColors => ({
    payload: { buttonStyle },
    type: SET_TMP_BTNS_COLORS,
});

export const clearTmpBtnsColors: TClearTmpBtnsColors = { type: CLEAR_TMP_BTNS_COLORS };
