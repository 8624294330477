import { TNodeState } from '@/reducers/entities/TNodeState';
import { TReducer } from '@/utils/types';
import {
    FETCH_NODES_WITH_ATTRIBUTES_REQUEST_SUCCESS,
    REPORT_ADD_COLUMN,
    REPORT_ADD_NODES,
    REPORT_CHANGE_COLUMN_FILTER_VISIBILITY,
    REPORT_CHANGE_COLUMN_VISIBILITY,
    REPORT_CHANGE_FILLING_TYPE,
    REPORT_CLEAR_SEARCH_REQUEST,
    REPORT_CLEAR_SELECT_COLUMN,
    REPORT_DELETE_COLUMN,
    REPORT_DELETE_NODES,
    REPORT_REQUEST_SUCCESS,
    REPORT_SAVE_REQUEST_SUCCESS,
    REPORT_SEARCH_REQUEST,
    REPORT_SEARCH_REQUEST_FAILURE,
    REPORT_SEARCH_REQUEST_SUCCESS,
    REPORT_SELECT_COLUMN,
    REPORT_SET_COLUMN_DATA,
    REPORT_SET_COLUMN_FILTER,
    REPORT_SET_FILTER_VALUE,
    REPORT_SET_SEARCH_REQUESTS,
} from '../actions/report.actionTypes';
import { TReportEditor, TReportEditorState, TReportFilters } from './reportEditor.reducer.types';
import { TExtendedNode } from '@/selectors/types/nodesSelector.types';

const INITIAL_REPORT_EDITOR_STATE: TReportEditorState = {
    editors: new TNodeState<TReportEditor>(),
    nodes: new TNodeState<TExtendedNode>(),
};

export const reportEditorReducer: TReducer<TReportEditorState> = (state = INITIAL_REPORT_EDITOR_STATE, action) => {
    switch (action.type) {
        case REPORT_ADD_NODES:
        case REPORT_SET_COLUMN_DATA:
        case REPORT_DELETE_COLUMN:
        case REPORT_CHANGE_FILLING_TYPE:
        case REPORT_SET_SEARCH_REQUESTS:
        case REPORT_DELETE_NODES:
        case REPORT_CHANGE_COLUMN_VISIBILITY: {
            const {
                payload: { reportNodeId },
            } = action;

            const reportEditor: TReportEditor = state.editors.byNodeId.get(reportNodeId) || {};

            return {
                ...state,
                editors: state.editors.set(reportNodeId, { ...reportEditor, unsaved: true }),
            };
        }

        case REPORT_SAVE_REQUEST_SUCCESS:
        case REPORT_REQUEST_SUCCESS: {
            const {
                payload: {
                    report: { nodeId },
                },
            } = action;

            const reportEditor: TReportEditor = state.editors.byNodeId.get(nodeId) || {};

            return {
                ...state,
                editors: state.editors.set(nodeId, { ...reportEditor, unsaved: false }),
            };
        }

        case REPORT_SELECT_COLUMN: {
            const {
                payload: { reportNodeId, columnId },
            } = action;

            const reportEditor: TReportEditor = state.editors.byNodeId.get(reportNodeId) || {};

            if (reportEditor.selectedColumnId === columnId) {
                return {
                    ...state,
                    editors: state.editors.set(reportNodeId, {
                        ...reportEditor,
                        selectedColumnId: '',
                    }),
                };
            } else {
                return {
                    ...state,
                    editors: state.editors.set(reportNodeId, { ...reportEditor, selectedColumnId: columnId }),
                };
            }
        }

        case REPORT_ADD_COLUMN: {
            const {
                payload: { reportNodeId, newColumn },
            } = action;

            const reportEditor: TReportEditor = state.editors.byNodeId.get(reportNodeId) || {};

            return {
                ...state,
                editors: state.editors.set(reportNodeId, {
                    ...reportEditor,
                    selectedColumnId: newColumn.columnId,
                    unsaved: true,
                }),
            };
        }

        case REPORT_CLEAR_SELECT_COLUMN: {
            const {
                payload: { reportNodeId },
            } = action;

            const reportEditor: TReportEditor = state.editors.byNodeId.get(reportNodeId) || {};

            return {
                ...state,
                editors: state.editors.set(reportNodeId, {
                    ...reportEditor,
                    selectedColumnId: '',
                }),
            };
        }

        case FETCH_NODES_WITH_ATTRIBUTES_REQUEST_SUCCESS: {
            const { nodes, overwrite } = action.payload;

            if (!nodes.length) {
                return {
                    ...state,
                    nodes: new TNodeState<TExtendedNode>(),
                };
            }

            let nodesState: TNodeState<TExtendedNode> = new TNodeState<TExtendedNode>();

            const initState = overwrite ? nodesState : state.nodes;

            nodes.reduce((acc, cur) => {
                nodesState = acc.set(cur.nodeId, cur);

                return nodesState;
            }, initState);

            return {
                ...state,
                nodes: nodesState,
            };
        }

        case REPORT_SEARCH_REQUEST_SUCCESS: {
            const {
                payload: { reportNodeId, searchResults },
            } = action;

            const reportEditor: TReportEditor = state.editors.byNodeId.get(reportNodeId) || {};

            return {
                ...state,
                editors: state.editors.set(reportNodeId, {
                    ...reportEditor,
                    unsaved: true,
                    searchResults,
                    loading: false,
                }),
            };
        }

        case REPORT_CLEAR_SEARCH_REQUEST: {
            const {
                payload: { reportNodeId },
            } = action;

            const reportEditor: TReportEditor = state.editors.byNodeId.get(reportNodeId) || {};

            return {
                ...state,
                editors: state.editors.set(reportNodeId, {
                    ...reportEditor,
                    searchResults: [],
                    unsaved: true,
                }),
            };
        }

        case REPORT_SEARCH_REQUEST: {
            const {
                payload: { reportNodeId },
            } = action;

            const reportEditor: TReportEditor = state.editors.byNodeId.get(reportNodeId) || {};

            return {
                ...state,
                editors: state.editors.set(reportNodeId, {
                    ...reportEditor,
                    loading: true,
                }),
            };
        }

        case REPORT_SEARCH_REQUEST_FAILURE: {
            const {
                payload: { reportNodeId },
            } = action;

            const reportEditor: TReportEditor = state.editors.byNodeId.get(reportNodeId) || {};

            return {
                ...state,
                editors: state.editors.set(reportNodeId, {
                    ...reportEditor,
                    loading: false,
                }),
            };
        }

        case REPORT_SET_FILTER_VALUE: {
            const {
                payload: { reportNodeId, searchValue },
            } = action;

            const reportEditor: TReportEditor = state.editors.byNodeId.get(reportNodeId) || {};

            return {
                ...state,
                editors: state.editors.set(reportNodeId, {
                    ...reportEditor,
                    searchValue,
                }),
            };
        }

        case REPORT_SET_COLUMN_FILTER: {
            const {
                payload: { reportNodeId, columnId, filterType, data },
            } = action;

            const reportEditor: TReportEditor = state.editors.byNodeId.get(reportNodeId) || {};
            const columnFilters: TReportFilters = reportEditor.columnFilters || {};

            return {
                ...state,
                editors: state.editors.set(reportNodeId, {
                    ...reportEditor,
                    columnFilters: {
                        ...columnFilters,
                        [columnId]: {
                            filterType,
                            data,
                        },
                    },
                }),
            };
        }

        case REPORT_CHANGE_COLUMN_FILTER_VISIBILITY: {
            const {
                payload: { reportNodeId, columnId },
            } = action;

            const reportEditor: TReportEditor = state.editors.byNodeId.get(reportNodeId) || {};
            const { [columnId]: _, ...columnFilters } = reportEditor.columnFilters || {};

            return {
                ...state,
                editors: state.editors.set(reportNodeId, {
                    ...reportEditor,
                    columnFilters,
                    unsaved: true,
                }),
            };
        }

        default:
            return state;
    }
};
