import { Select } from '@/modules/UIKit/components/Select/Select.component';
import React, { FC, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import messages from './CommonSettings.messages';
import theme from './CommonSettings.scss';
import { Button } from '@/modules/UIKit/components/Button/Button.component';
import { reportChangeFillingType, reportClearSearchRequest } from '../../../actions/report.actions';
import { NodeId, ReportDataFillingTypeEnum, SearchRequest } from '@/serverapi/api';
import { ReportSelectors } from '../../../selectors/report.selectors';
import { openDialog } from '@/actions/dialogs.actions';
import { DialogType } from '@/modules/DialogRoot/DialogRoot.constants';
import { TDbSearchDialogProps } from '@/modules/Search/DbSearchDialog/DbSearchDialog.types';
import { openSearchInDialog } from '@/actions/search.actions';
import { ConfirmationDialog } from './ConfirmationDialog.component';
import { generateCustomNodeId } from '@/utils/nodeId.utils';
import { ReportSearchDialogPrefix } from '@/utils/constants/dbSearch.const';

type TCommonSettingsProps = {
    reportNodeId: NodeId;
};

enum FillingType {
    MANUAL = 'MANUAL',
    AUTO = 'AUTO',
}

export const CommonSettings: FC<TCommonSettingsProps> = ({ reportNodeId }) => {
    const intl = useIntl();
    const dispatch = useDispatch();

    const searchNodeId: NodeId = generateCustomNodeId(reportNodeId, ReportSearchDialogPrefix);

    const fillingType: ReportDataFillingTypeEnum = useSelector(ReportSelectors.fillingType(reportNodeId));
    const searchRequests: SearchRequest[] = useSelector(ReportSelectors.searchRequests(reportNodeId));

    const [open, setOpen] = useState<boolean>(false);

    const selectReportType = (fillingType: ReportDataFillingTypeEnum) => {
        dispatch(reportChangeFillingType(reportNodeId, fillingType));
    };

    const searchRequestClick = () => {
        const dialogProps: TDbSearchDialogProps = {
            nodeId: reportNodeId,
        };
        dispatch(openDialog(DialogType.DB_SEARCH_DIALOG, dialogProps));
        dispatch(openSearchInDialog(searchNodeId, reportNodeId, searchRequests));
    };

    const cleatSearchRequest = () => {
        dispatch(reportClearSearchRequest(reportNodeId));
        setOpen(false);
    };

    return (
        <>
            <div className={theme.group}>
                <Select
                    label={intl.formatMessage(messages.fillingTypeTitle)}
                    wrapperClassName={theme.select}
                    onChange={selectReportType}
                    value={intl.formatMessage(messages[fillingType])}
                >
                    <Select.Option
                        value={FillingType.MANUAL}
                        label={intl.formatMessage(messages[FillingType.MANUAL])}
                    />
                    <Select.Option value={FillingType.AUTO} label={intl.formatMessage(messages[FillingType.AUTO])} />
                </Select>
            </div>
            {fillingType === 'AUTO' && (
                <>
                    <div className={theme.container}>
                        <Button onClick={searchRequestClick}>{intl.formatMessage(messages.searchRequests)}</Button>
                    </div>
                    <div className={theme.container}>
                        <Button disabled={searchRequests.length === 0} onClick={() => setOpen(true)}>
                            {intl.formatMessage(messages.clear)}
                        </Button>
                        <ConfirmationDialog open={open} onClear={cleatSearchRequest} onCancel={() => setOpen(false)} />
                    </div>
                </>
            )}
        </>
    );
};
