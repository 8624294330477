import { defineMessages } from 'react-intl';

export default defineMessages({
    EQUALS: {
        id: 'Filters.EQUALS',
        defaultMessage: 'Равно',
    },
    GREATER: {
        id: 'Filters.GREATER',
        defaultMessage: 'Больше',
    },
    LESS: {
        id: 'Filters.LESS',
        defaultMessage: 'Меньше',
    },
    BETWEEN: {
        id: 'Filters.BETWEEN',
        defaultMessage: 'Между',
    },
    selectTime: {
        id: 'Filters.selectTime',
        defaultMessage: 'Выберите время',
    },
    selectDate: {
        id: 'Filters.selectDate',
        defaultMessage: 'Выберите дату',
    },
    selectDateAndTime: {
        id: 'Filters.selectDateAndTime',
        defaultMessage: 'Выберите дату и время',
    },
    startDate: {
        id: 'Filters.startDate',
        defaultMessage: 'Начальная дата',
    },
    endDate: {
        id: 'Filters.endDate',
        defaultMessage: 'Конечная дата',
    },
});
