import { createSelector } from 'reselect';
import { TRootState } from '../reducers/root.reducer.types';
import { DefaultId, EdgeType, ModelEdgeDefinition, ModelType, Symbol } from '@/serverapi/api';
import { MethodologiesGraph } from '@/mxgraph/MethodologiesGraph';
import { IntlShape } from 'react-intl';
import { LocalesService } from '@/services/LocalesService';
import { contains } from '@/modules/AdminTools/Methodology/components/Presets/util/preset.utils';
import { SymbolToImageConverterGraph } from '@/modules/AdminTools/Methodology/components/Presets/SymbolToImageConverterGraph.component';
import { SymbolConstants } from '@/models/Symbols.constants';
import { EdgeTypeSelectors } from './edgeType.selectors';
import { SymbolSelectors } from './symbol.selectors';

export const workspaceTabData = (state: TRootState) => state.workspaceTabData.editModelTypeWorkspaceTab;

export const workspaceTabKanbanData = (state: TRootState) => state.workspaceTabData.editKanbanModelTypeWorkspaceTab;

export const workspaceTabKanbanCardData = (state: TRootState) => state.workspaceTabData.editKanbanCardTypeWorkspaceTab;

export const workspaceTabMatrixData = (state: TRootState) => state.workspaceTabData.editMatrixModelTypeWorkspaceTab;

export const workspaceTabReportData = (state: TRootState) => state.workspaceTabData.editReportModelTypeWorkspaceTab;

export namespace WorkspaceTabDataSelectors {
    export const getEditModelType = (presetId: string, serverId: string, modelTypeId: string) =>
        createSelector(workspaceTabData, (state) => state[serverId]?.[presetId]?.[modelTypeId]);

    export const getSymbolsMap = (
        modelType: ModelType,
        serverId: string,
        presetId: string,
        searchFilter: string,
        graph?: MethodologiesGraph,
    ) =>
        createSelector<
            TRootState,
            EdgeType[],
            Symbol[],
            { symbolsMap: Map<string, JSX.Element>; edgeSymbolsMap: Map<string, JSX.Element> }
        >(
            EdgeTypeSelectors.listByPresetIdWithName(serverId, presetId),
            SymbolSelectors.byServerIdPresetId(serverId, presetId),
            (edgeTypes, symbols) => {
                const intl: IntlShape = LocalesService.useIntl();

                const availableSymbols: Symbol[] = SymbolSelectors.chooseModelTypeSymbols(
                    modelType?.symbols || [],
                    symbols,
                );

                const symbolsMap = new Map<string, JSX.Element>();
                const edgeSymbolsMap = new Map<string, JSX.Element>();

                const anyElement: JSX.Element = SymbolToImageConverterGraph.renderSymbol(
                    SymbolConstants.ANY_ELEMENT,
                    intl,
                    graph,
                );
                const endpointElement: JSX.Element = SymbolToImageConverterGraph.renderSymbol(
                    { ...SymbolConstants.ENDPOINT_SYMBOL, name: DefaultId.DEFAULT_PRESET_ID },
                    intl,
                    graph,
                );
                symbolsMap.set(SymbolConstants.ANY_ELEMENT.id, anyElement);
                symbolsMap.set(SymbolConstants.ENDPOINT_SYMBOL.id, endpointElement);

                const modelEdges: ModelEdgeDefinition[] = modelType.modelEdgeDefinitions.filter((type) => {
                    const edgeType = edgeTypes.find((e) => e.id === type.edgeType);

                    return contains([edgeType?.name || '', edgeType?.direction || ''], searchFilter);
                });

                const getEdgeTypeById = (edgeTypeId: string): EdgeType | undefined =>
                    edgeTypes.find((availableEdgeType) => availableEdgeType.id === edgeTypeId);

                modelEdges.forEach((modelEdge: ModelEdgeDefinition) => {
                    const sourceSymbol: Symbol | undefined = availableSymbols.find((s) => s.id === modelEdge.source);
                    if (sourceSymbol && modelEdge.source && !symbolsMap.has(modelEdge.source)) {
                        const sourceImg: JSX.Element = SymbolToImageConverterGraph.renderSymbol(
                            sourceSymbol,
                            intl,
                            graph,
                        );
                        symbolsMap.set(modelEdge.source, sourceImg);
                    } else {
                        const sourceSymbolForObject: Symbol = {
                            ...(modelType.objectTypes.find(
                                (objectType) => objectType.id === modelEdge.sourceObject,
                            ) || {
                                id: modelEdge.sourceObject,
                                name: modelEdge.sourceObject,
                            }),
                            ...(modelEdge.anySourceAllowed
                                ? SymbolConstants.ANY_ELEMENT
                                : SymbolConstants.ENDPOINT_SYMBOL),
                        } as Symbol;
                        if (modelEdge.sourceObject && !symbolsMap.has(modelEdge.sourceObject)) {
                            const sourceImg: JSX.Element = SymbolToImageConverterGraph.renderSymbol(
                                sourceSymbolForObject,
                                intl,
                                graph,
                            );
                            symbolsMap.set(modelEdge.sourceObject, sourceImg);
                        }
                    }

                    const destinationSymbol: Symbol | undefined = availableSymbols.find(
                        (s) => s.id === modelEdge.destination,
                    );

                    if (destinationSymbol && modelEdge.destination && !symbolsMap.has(modelEdge.destination)) {
                        const destinationImg: JSX.Element = SymbolToImageConverterGraph.renderSymbol(
                            destinationSymbol,
                            intl,
                            graph,
                        );

                        symbolsMap.set(modelEdge.destination, destinationImg);
                    } else {
                        const destinationSymbolForObject: Symbol = {
                            ...(modelType.objectTypes.find(
                                (objectType) => objectType.id === modelEdge.destinationObject,
                            ) || {
                                id: modelEdge.destinationObject,
                                name: modelEdge.destinationObject,
                            }),
                            ...(modelEdge.anyTargetAllowed
                                ? SymbolConstants.ANY_ELEMENT
                                : SymbolConstants.ENDPOINT_SYMBOL),
                        } as Symbol;

                        if (modelEdge.destinationObject && !symbolsMap.has(modelEdge.destinationObject)) {
                            const destinationImg: JSX.Element = SymbolToImageConverterGraph.renderSymbol(
                                destinationSymbolForObject,
                                intl,
                                graph,
                            );
                            symbolsMap.set(modelEdge.destinationObject, destinationImg);
                        }
                    }

                    const edgeType: EdgeType | undefined = getEdgeTypeById(modelEdge.edgeType);
                    if (edgeType && !edgeSymbolsMap.has(edgeType.id)) {
                        edgeSymbolsMap.set(edgeType.id, SymbolToImageConverterGraph.renderEdge(edgeType, intl, graph));
                    }
                });

                return { symbolsMap, edgeSymbolsMap };
            },
        );

    export const getEditKanbanModelType = (presetId: string, modelTypeId: string) =>
        createSelector(workspaceTabKanbanData, (state) => state[presetId]?.[modelTypeId]);

    export const getEditKanbanCardType = (presetId: string, kanbanCardTypeId: string) =>
        createSelector(workspaceTabKanbanCardData, (state) => state[presetId]?.[kanbanCardTypeId]);

    export const getEditMatrixModelType = (presetId: string, modelTypeId: string) =>
        createSelector(workspaceTabMatrixData, (state) => state[presetId]?.[modelTypeId]);

    export const getEditReportModelType = (presetId: string, reportTypeId: string) =>
        createSelector(workspaceTabReportData, (state) => state[presetId]?.[reportTypeId]);
}
