import type { TFileUploadAction, TImageFileUploadAction } from '../actions/uploader.actions.types';
import type { TServerEntity } from '../models/entities.types';
import type { TreeNode } from '../models/tree.types';
import type { FileNodeDTO } from '../serverapi/api';
import { put, select, takeEvery } from 'redux-saga/effects';
import { FILE_UPLOAD, IMAGE_FILE_UPLOAD } from '../actionsTypes/uploader.actionTypes';
import { fileUpload, fileUploadFail, fileUploadSuccess } from '../actions/uploader.actions';
import { ServerSelectors } from '../selectors/entities/server.selectors';
import { showNotification } from '../actions/notification.actions';
import { NotificationType } from '../models/notificationType';
import { TreeItemType } from '../modules/Tree/models/tree';
import { TabsSelectors } from '../selectors/tabs.selectors';
import { fileReplaceAttributesUpdate, treeItemChildAdd } from '../actions/tree.actions';
import { getExtension, isImageFile } from '../utils/files.utils';
import { FileDaoService } from '@/services/dao/FileDaoService';

// todo: обьединить с file.saga

/**
 * Загрузка файла.
 * @param action
 */

function* showNotificationFileUploadFail(fileId: string, notificationDescriptor: NotificationType) {
    yield put(
        showNotification({
            id: fileId,
            type: notificationDescriptor,
        }),
    );
    yield put(fileUploadFail(fileId));
}

function* saveFileNode(action: TFileUploadAction) {
    const { nodeId, file, parentNodeId } = action.payload;
    const server: TServerEntity = yield select(ServerSelectors.server(nodeId.serverId));
    const tab = yield select(TabsSelectors.getActiveTab); // todo: проверить где есть загрузка картини без вкладки
    const parentId = parentNodeId || tab?.content?.parentNodeId;
    const extension = getExtension(file?.name);

    if (extension && parentId) {
        const fileNode: FileNodeDTO = {
            nodeId,
            parentNodeId: parentId,
            type: TreeItemType.File,
            name: file.name,
            extension,
            file,
        };

        const uploadedFile: FileNodeDTO = yield FileDaoService.uploadFile(nodeId.serverId, fileNode);

        if (!uploadedFile) {
            yield showNotificationFileUploadFail(nodeId.id, NotificationType.UPLOAD_FILE_ERROR);

            return;
        }

        yield put(fileUploadSuccess(uploadedFile, server, nodeId.id));
        yield put(
            treeItemChildAdd({ parentNodeId: parentId, child: [{ ...fileNode, hasChildren: false } as TreeNode] }),
        );
        yield put(fileReplaceAttributesUpdate(nodeId));
    } else {
        yield showNotificationFileUploadFail(nodeId.id, NotificationType.UPLOAD_FILE_ERROR);
    }
}

function* handleUploadImageFile(action: TImageFileUploadAction) {
    const { nodeId, file, parentNodeId } = action.payload;

    if (!isImageFile(file)) {
        yield showNotificationFileUploadFail(nodeId.id, NotificationType.INCORRECT_IMAGE_ERROR);

        return;
    }

    yield put(fileUpload(file, nodeId, parentNodeId));
}

export function* uploaderSaga() {
    yield takeEvery(FILE_UPLOAD, saveFileNode);
    yield takeEvery(IMAGE_FILE_UPLOAD, handleUploadImageFile);
}
