import React, { FC, useEffect, useState } from 'react';
import { AutoSizer, CellMeasurer, CellMeasurerCache, Column, Table, TableCellProps } from 'react-virtualized';
import theme from './myApprovalsTab.scss';
import { useIntl } from 'react-intl';
import messages from './myApprovalsTab.messages';
import { useDispatch } from 'react-redux';
import { timestampToMomentDate } from '../../utils/date.time.utils';
import { Badge } from 'antd';
import { ElementNames } from '../AdminTools/ApprovalsTable/ElementNames.component';
import { cloneDeep } from 'lodash-es';
import { Icon } from '@/modules/UIKit';
import icSorter from '../../resources/icons/ic-sorter.svg';
import icGo from '../../resources/icons/ic-go.svg';
import icApprovalCopy from '../../resources/icons/ic-copy.svg';
import icApprovalEdit from '../../resources/icons/ic-approval-edit.svg';
import icOpen from '../../resources/icons/ic-open.svg';
import { TMyApprovalsTableData } from './myApprovals.types';
import { ApprovalDTOStatus } from '../ApprovalDialog/ApprovalDialog.types';
import { NodeId } from '@/serverapi/api';
import { editExistingApproval, goToApproval, openApprovalElement } from '@/actions/approval.actions';
import { openDialog } from '@/actions/dialogs.actions';
import { DialogType } from '../DialogRoot/DialogRoot.constants';
import { Button } from '../UIKit/components/Button/Button.component';

enum statusColor {
    IN_PROCESS = '#8F8F8F',
    APPROVED = '#00A455',
    NOT_APPROVED = '#FF3733',
    CANCELLED = '#676767',
    DRAFT = '#323232',
}

type TSortStatusFieldValue = null | 'asc' | 'desc';

type TSortStatus = {
    createdAt: TSortStatusFieldValue;
    status: TSortStatusFieldValue;
};

type TSortableColumnName = 'createdAt' | 'status';

type TLastSortedColumnStatus = TSortableColumnName | null;

const ROW_HEIGHT = 48;

const STATUS_SORT_ORDER = {
    IN_PROCESS: 0,
    APPROVED: 1,
    NOT_APPROVED: 2,
    CANCELLED: 3,
    DRAFT: 4,
};

const sortApprovals = (
    approvalTableData: TMyApprovalsTableData[],
    sortStatus: TSortStatus,
    lastSortedColumnStatus: TLastSortedColumnStatus,
) => {
    if (!lastSortedColumnStatus) return approvalTableData;

    const approvalDataCopy = cloneDeep(approvalTableData);

    const sortByCreatedAt = (data: TMyApprovalsTableData[]) =>
        data.sort((a, b) =>
            sortStatus.createdAt === 'asc' ? b.createdAt! - a.createdAt! : a.createdAt! - b.createdAt!,
        );
    if (!sortStatus.status) {
        return sortByCreatedAt(approvalDataCopy);
    }

    const sortByStatus = (data: TMyApprovalsTableData[]) =>
        data.sort((a, b) =>
            sortStatus.status === 'desc'
                ? STATUS_SORT_ORDER[b.status!] - STATUS_SORT_ORDER[a.status!]
                : STATUS_SORT_ORDER[a.status!] - STATUS_SORT_ORDER[b.status!],
        );
    if (!sortStatus.createdAt) {
        return sortByStatus(approvalDataCopy);
    }

    return lastSortedColumnStatus === 'createdAt'
        ? sortByCreatedAt(sortByStatus(approvalDataCopy))
        : sortByStatus(sortByCreatedAt(approvalDataCopy));
};

type TApprovalTableProps = {
    approvalTableData: TMyApprovalsTableData[];
    hasApprovalAdminAccess: boolean;
};

export const MyApprovalsTable: FC<TApprovalTableProps> = ({
    approvalTableData,
    hasApprovalAdminAccess,
}): JSX.Element => {
    const intl = useIntl();
    // требуется обновить таблицу после раскрытия списка элементов в столбце Элемент(ы) запуска
    // так как данные в сторе не поменялись перерендер не происходит,
    // поэтому используем useState для перерендера
    const [needToRender, setNeedToRender] = useState<boolean>(false);
    const [sortStatus, setSortStatus] = useState<TSortStatus>({ createdAt: 'desc', status: null });
    const [lastSortedColumnName, setLastSortedColumnName] = useState<TLastSortedColumnStatus>('createdAt');

    const tableData: TMyApprovalsTableData[] = sortApprovals(approvalTableData, sortStatus, lastSortedColumnName);

    const dispatch = useDispatch();

    const cellHeightCache = new CellMeasurerCache({
        fixedWidth: true,
        minHeight: ROW_HEIGHT,
    });

    useEffect(() => {
        cellHeightCache.clearAll();
        if (needToRender) setNeedToRender(false);
    }, [needToRender, tableData]);

    const nameColumnRenderer = ({ dataKey, parent, rowIndex }: TableCellProps): JSX.Element => {
        const { name } = tableData[rowIndex];

        return (
            <CellMeasurer cache={cellHeightCache} key={dataKey} parent={parent} rowIndex={rowIndex} columnIndex={0}>
                <span title={name}>{name}</span>
            </CellMeasurer>
        );
    };

    const stageNameColumnRenderer = ({ dataKey, parent, rowIndex }: TableCellProps): JSX.Element => {
        const { stageName } = tableData[rowIndex];

        return (
            <CellMeasurer cache={cellHeightCache} key={dataKey} parent={parent} rowIndex={rowIndex} columnIndex={1}>
                <span title={stageName}>{stageName}</span>
            </CellMeasurer>
        );
    };

    const elementsColumnRenderer = ({ dataKey, parent, rowIndex }: TableCellProps) => {
        const { elementNames } = tableData[rowIndex];

        return (
            <CellMeasurer cache={cellHeightCache} key={dataKey} parent={parent} rowIndex={rowIndex} columnIndex={2}>
                <ElementNames setNeedToRender={setNeedToRender} elementNames={elementNames} />
            </CellMeasurer>
        );
    };

    const approvalPeriodColumnRenderer = ({ dataKey, parent, rowIndex }: TableCellProps): JSX.Element => {
        const { approvalPeriod } = tableData[rowIndex];
        const periodStr = approvalPeriod
            ? `${timestampToMomentDate(approvalPeriod)?.format(`DD.MM.YYYY ${intl.formatMessage(messages.at)} HH:mm`)}`
            : '';

        return (
            <CellMeasurer cache={cellHeightCache} key={dataKey} parent={parent} rowIndex={rowIndex} columnIndex={3}>
                <span title={periodStr}>{periodStr}</span>
            </CellMeasurer>
        );
    };

    const createdAtColumnRenderer = ({ dataKey, parent, rowIndex }: TableCellProps): JSX.Element => {
        const { createdAt } = tableData[rowIndex];
        const createdAtStr = `${timestampToMomentDate(createdAt || -1)?.format(
            `DD.MM.YYYY ${intl.formatMessage(messages.at)} HH:mm`,
        )}`;

        return (
            <CellMeasurer cache={cellHeightCache} key={dataKey} parent={parent} rowIndex={rowIndex} columnIndex={4}>
                <span title={createdAtStr}>{createdAtStr}</span>
            </CellMeasurer>
        );
    };

    const statusColumnRenderer = ({ dataKey, parent, rowIndex }: TableCellProps): JSX.Element => {
        const { status } = tableData[rowIndex];

        if (status === ApprovalDTOStatus.IN_PROCESS) {
            const usersText = tableData[rowIndex].usersToVote?.join(', ') || '';
            return (
                <CellMeasurer cache={cellHeightCache} key={dataKey} parent={parent} rowIndex={rowIndex} columnIndex={5}>
                    <div title={intl.formatMessage(messages.waitingToVote)} className={theme.displayEllipsis}>
                        {intl.formatMessage(messages.waitingToVote)}
                    </div>
                    <div title={usersText} className={theme.displayEllipsis}>
                        {usersText}
                    </div>
                </CellMeasurer>
            );
        }

        return (
            <CellMeasurer cache={cellHeightCache} key={dataKey} parent={parent} rowIndex={rowIndex} columnIndex={5}>
                {status && (
                    <Badge
                        className={theme.badgeStatus}
                        count={intl.formatMessage(messages[status])}
                        style={{ backgroundColor: statusColor[status] }}
                    />
                )}
            </CellMeasurer>
        );
    };

    const userStatusesColumnRenderer = ({ dataKey, parent, rowIndex }: TableCellProps): JSX.Element => {
        const { userStatuses } = tableData[rowIndex];
        const statusesStr = userStatuses?.length
            ? userStatuses.map((status) => intl.formatMessage(messages[status])).join(', ')
            : '';

        return (
            <CellMeasurer cache={cellHeightCache} key={dataKey} parent={parent} rowIndex={rowIndex} columnIndex={6}>
                <span title={statusesStr}>{statusesStr}</span>
            </CellMeasurer>
        );
    };

    const createdByColumnRenderer = ({ dataKey, parent, rowIndex }: TableCellProps): JSX.Element => {
        const { createdBy } = tableData[rowIndex];

        return (
            <CellMeasurer cache={cellHeightCache} key={dataKey} parent={parent} rowIndex={rowIndex} columnIndex={7}>
                <span title={createdBy}>{createdBy}</span>
            </CellMeasurer>
        );
    };

    const onCopyApproval = (id: NodeId) => {
        dispatch(
            openDialog(DialogType.SELECT_TREE_ITEM_APPROVAL_DIALOG, {
                copiedApprovalId: id,
            }),
        );
    };

    const onGoToApproval = (nodeId: NodeId, elementIds: string[]) => {
        if (elementIds.length) {
            dispatch(goToApproval({ nodeId, approvalId: elementIds[0] }));
        }
    };

    const actionsColumnRenderer = ({ dataKey, parent, rowIndex }: TableCellProps) => {
        const { id, elementIds, status } = tableData[rowIndex];

        return (
            <CellMeasurer cache={cellHeightCache} key={dataKey} parent={parent} rowIndex={rowIndex} columnIndex={8}>
                <div className={theme.actionBtnsContainer}>
                    {hasApprovalAdminAccess &&
                    (status === ApprovalDTOStatus.IN_PROCESS || status === ApprovalDTOStatus.DRAFT) ? (
                        <Button
                            visualStyle={{ type: 'text' }}
                            onClick={(e) => {
                                e.stopPropagation();
                                dispatch(editExistingApproval({ approvalId: id }));
                            }}
                            icon={icApprovalEdit}
                            tooltip={intl.formatMessage(messages.editApproval)}
                            size="small"
                            dataTest='edit-approval_btn'
                        ></Button>
                    ) : null}

                    {hasApprovalAdminAccess ? (
                        <Button
                            visualStyle={{ type: 'text' }}
                            onClick={(e) => {
                                e.stopPropagation();
                                onCopyApproval(id);
                            }}
                            icon={icApprovalCopy}
                            tooltip={intl.formatMessage(messages.copyApproval)}
                            size="small"
                            dataTest='copy-approval_btn'
                        ></Button>
                    ) : null}
                    <Button
                        visualStyle={{ type: 'text' }}
                        onClick={() => onGoToApproval(id, elementIds)}
                        icon={icGo}
                        tooltip={intl.formatMessage(messages.goToApproval)}
                        size="small"
                        dataTest='go-to-approval_btn'
                    ></Button>
                    <Button
                        visualStyle={{ type: 'text' }}
                        onClick={(e) => {
                            e.stopPropagation();
                            if (elementIds.length) {
                                dispatch(openApprovalElement({ elementNodeId: { ...id, id: elementIds[0] } }));
                            }
                        }}
                        icon={icOpen}
                        tooltip={intl.formatMessage(messages.openApprovalElement)}
                        size="small"
                    ></Button>
                </div>
            </CellMeasurer>
        );
    };

    const rowGetter = ({ index }) => tableData[index];

    const handleSortClick = (columnName: TSortableColumnName) => {
        const newSortStatusFieldValue: TSortStatusFieldValue =
            sortStatus[columnName] === null || sortStatus[columnName] === 'desc' ? 'asc' : 'desc';
        setSortStatus({ ...sortStatus, [columnName]: newSortStatusFieldValue });
        setLastSortedColumnName(columnName);
    };

    const createdAtHeaderRenderer = (): JSX.Element => {
        return (
            <div className={theme.sortableColumn}>
                <span title={intl.formatMessage(messages.createdAt)}>{intl.formatMessage(messages.createdAt)}</span>
                <span onClick={() => handleSortClick('createdAt')}>
                    <Icon spriteSymbol={icSorter} />
                </span>
            </div>
        );
    };

    const statusHeaderRenderer = (): JSX.Element => {
        return (
            <div className={theme.sortableColumn}>
                <span>{intl.formatMessage(messages.status)}</span>
                <span onClick={() => handleSortClick('status')}>
                    <Icon spriteSymbol={icSorter} />
                </span>
            </div>
        );
    };

    return (
        <div className={theme.tableContainer}>
            <AutoSizer>
                {({ height, width }) => (
                    <Table
                        width={width}
                        height={height}
                        rowHeight={cellHeightCache.rowHeight}
                        className={theme.table}
                        headerHeight={ROW_HEIGHT}
                        rowCount={tableData.length}
                        rowGetter={rowGetter}
                        headerClassName={theme.headerName}
                        rowClassName={theme.rowClassName}
                    >
                        <Column
                            width={width}
                            dataKey="name"
                            label={intl.formatMessage(messages.name)}
                            cellRenderer={nameColumnRenderer}
                        />
                        <Column
                            width={width}
                            dataKey="stageName"
                            label={intl.formatMessage(messages.stage)}
                            cellRenderer={stageNameColumnRenderer}
                        />
                        <Column
                            width={width}
                            dataKey="elements"
                            label={intl.formatMessage(messages.elements)}
                            cellRenderer={elementsColumnRenderer}
                        />
                        <Column
                            width={width}
                            dataKey="approvalPeriod"
                            label={intl.formatMessage(messages.approvalPeriod)}
                            cellRenderer={approvalPeriodColumnRenderer}
                        />
                        <Column
                            minWidth={120}
                            width={width}
                            dataKey="createdAt"
                            cellRenderer={createdAtColumnRenderer}
                            headerRenderer={createdAtHeaderRenderer}
                        />
                        <Column
                            width={width}
                            dataKey="status"
                            cellRenderer={statusColumnRenderer}
                            headerRenderer={statusHeaderRenderer}
                        />
                        <Column
                            width={width}
                            dataKey="userStatuses"
                            label={intl.formatMessage(messages.userStatuses)}
                            cellRenderer={userStatusesColumnRenderer}
                        />
                        <Column
                            maxWidth={150}
                            width={width}
                            dataKey="createdBy"
                            label={intl.formatMessage(messages.createdBy)}
                            cellRenderer={createdByColumnRenderer}
                        />
                        <Column
                            flexGrow={1}
                            maxWidth={104}
                            minWidth={104}
                            width={104}
                            dataKey="actions"
                            cellRenderer={actionsColumnRenderer}
                        />
                    </Table>
                )}
            </AutoSizer>
        </div>
    );
};
