import type { THeadline, TTableOfContentsOptions } from './table-of-contents.types';
import { TocIndex, TocView } from './table-of-contents.types';
import { ReactNodeViewRenderer } from '@tiptap/react';
import { mergeAttributes } from '@tiptap/core';
import { Node } from '@tiptap/core';
import { v4 as uuid } from 'uuid';
import { TableOfContentsRenderer } from '../renderers/tableOfContents/TableOfContents.component';
import { TableOfContentsPlugin } from '../plugins/tableOfContents/table-of-contents.plugin';
import { getData, updateData } from './table-of-contents.utils';
import { tocHeadingLevels } from '@/utils/configuration';

declare module '@tiptap/core' {
    interface Commands<ReturnType> {
        tableOfContents: {
            insertTableOfContents: () => ReturnType;
        };
    }
}

export const TableOfContents = Node.create<TTableOfContentsOptions>({
    name: 'tableOfContents',

    group: 'block',

    atom: true,

    addStorage: () => ({
        content: [],
        anchors: [],
        scrollHandler: () => null,
        scrollPosition: 0,
    }),

    addOptions: () => ({
        onUpdate: () => {},
        getId: () => uuid(),
        scrollParent: 'undefined' !== typeof window ? () => window : void 0,
        anchorTypes: ['heading'],
        emptyContentTitle: 'Start editing your document to see the outline.',
    }),

    parseHTML() {
        return [{ tag: 'toc' }];
    },

    renderHTML({ HTMLAttributes }) {
        return ['toc', mergeAttributes(HTMLAttributes)];
    },

    addCommands() {
        return {
            insertTableOfContents:
                () =>
                ({ chain }) => {
                    return chain().focus().insertContent({ type: this.name }).run();
                },
        };
    },

    addNodeView() {
        return ReactNodeViewRenderer(TableOfContentsRenderer);
    },

    onUpdate() {
        const onUpdate = this.options.onUpdate;

        updateData({
            editor: this.editor,
            storage: this.storage,
            anchorTypes: this.options.anchorTypes,
            onUpdate: null === onUpdate || void 0 === onUpdate ? void 0 : onUpdate.bind(this),
        });
    },

    onCreate() {
        const { anchorTypes, onUpdate } = this.options;
        const headlines: THeadline[] = [];
        const { tr } = this.editor.state;
        const ids: (string | null)[] = [];

        if (this.options.scrollParent && 'function' !== typeof this.options.scrollParent) {
            console.warn(
                "[Tiptap Table of Contents Deprecation Notice]: The 'scrollParent' option must now be provided as a callback function that returns the 'scrollParent' element. The ability to pass this option directly will be deprecated in future releases.",
            );
        }

        this.editor.state.doc.descendants((node, pos) => {
            if (anchorTypes && anchorTypes.includes(node.type.name) && node.textContent.length !== 0) {
                headlines.push({ node, pos });
            }
        });

        headlines.forEach(({ node, pos }) => {
            const tocId: string | null = node.attrs['data-toc-id'];

            if (!tocId || ids.includes(tocId)) {
                const id = this.options.getId ? this.options.getId(node.textContent) : uuid();

                tr.setNodeMarkup(pos, null, { ...node.attrs, 'data-toc-id': id, id });
            }

            ids.push(tocId);
        });

        this.editor.view.dispatch(tr);

        updateData({
            editor: this.editor,
            storage: this.storage,
            anchorTypes: this.options.anchorTypes,
            onUpdate: null === onUpdate || void 0 === onUpdate ? void 0 : onUpdate.bind(this),
        });

        this.storage.scrollHandler = () => {
            const onUpdate = this.options.onUpdate;

            if (!this.options.scrollParent) {
                return;
            }

            const scrollParent =
                'function' === typeof this.options.scrollParent
                    ? this.options.scrollParent()
                    : this.options.scrollParent;
            const scrollPosition = scrollParent instanceof HTMLElement ? scrollParent.scrollTop : scrollParent.scrollY;

            this.storage.scrollPosition = scrollPosition || 0;

            const content = getData(this.storage.content, {
                editor: this.editor,
                anchorTypes: this.options.anchorTypes,
                storage: this.storage,
                onUpdate: null === onUpdate || void 0 === onUpdate ? void 0 : onUpdate.bind(this),
            });

            this.storage.content = content;
        };

        if (!this.options.scrollParent) {
            return;
        }

        const scrollParent =
            'function' === typeof this.options.scrollParent ? this.options.scrollParent() : this.options.scrollParent;

        scrollParent && scrollParent.addEventListener('scroll', this.storage.scrollHandler);
    },

    onDestroy() {
        if (!this.options.scrollParent) {
            return;
        }

        const scrollParent =
            'function' === typeof this.options.scrollParent ? this.options.scrollParent() : this.options.scrollParent;

        scrollParent && scrollParent.removeEventListener('scroll', this.storage.scrollHandler);
    },

    addProseMirrorPlugins() {
        return [TableOfContentsPlugin({ getId: this.options.getId, anchorTypes: this.options.anchorTypes })];
    },

    addAttributes() {
        return {
            levels: {
                default: tocHeadingLevels,
                parseHTML: (element) => {
                    return element.getAttribute('levels')?.split('-').map(Number) || null;
                },
                renderHTML: (attributes) => {
                    if (!attributes.levels) {
                        return {};
                    }

                    return {
                        levels: attributes.levels.join('-'),
                    };
                },
            },
            index: {
                default: TocIndex.ALL_LEVELS,
                parseHTML: (element) => {
                    return element.getAttribute('index') || null;
                },
                renderHTML: (attributes) => {
                    if (!attributes.index) {
                        return {};
                    }

                    return {
                        index: attributes.index,
                    };
                },
            },
            view: {
                default: TocView.TREE,
                parseHTML: (element) => {
                    return element.getAttribute('view') || null;
                },
                renderHTML: (attributes) => {
                    if (!attributes.view) {
                        return {};
                    }

                    return {
                        view: attributes.view,
                    };
                },
            },
        };
    },

    addGlobalAttributes() {
        return [
            {
                types: this.options.anchorTypes,
                attributes: {
                    id: {
                        default: null,
                        renderHTML: (attributes) => ({ id: attributes.id }),
                        parseHTML: (element) => element.id || null,
                    },
                    'data-toc-id': {
                        default: null,
                        renderHTML: (attributes) => ({ 'data-toc-id': attributes['data-toc-id'] }),
                        parseHTML: (element) => element.dataset.tocId || null,
                    },
                },
            },
        ];
    },
});
