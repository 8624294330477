import { cloneDeep } from 'lodash-es';
import { TReducer } from '@/utils/types';
import { TNodeStatisticsState } from './nodeStatisticsReducer.types';
import { NODE_STATISTICS_DELETE, NODE_STATISTICS_LOAD_SUCCESS } from '@/actionsTypes/nodeStatistics.actionTypes';

const initial: TNodeStatisticsState = {
    byRepositoryId: {},
};

export const nodeStatisticsReducer: TReducer<TNodeStatisticsState> = (state = initial, action) => {
    switch (action.type) {
        case NODE_STATISTICS_LOAD_SUCCESS: {
            const {
                payload: { statistics, nodeId },
            } = action;
            const stateClone: TNodeStatisticsState = cloneDeep(state);

            if (!stateClone.byRepositoryId[nodeId.repositoryId])
                stateClone.byRepositoryId[nodeId.repositoryId] = { byId: {} };

            stateClone.byRepositoryId[nodeId.repositoryId].byId[nodeId.id] = { ...statistics };

            return stateClone;
        }

        case NODE_STATISTICS_DELETE: {
            const {
                payload: { nodeId },
            } = action;
            const stateClone: TNodeStatisticsState = cloneDeep(state);
            delete stateClone.byRepositoryId[nodeId.repositoryId]?.byId[nodeId.id];

            return stateClone;
        }

        default:
            return state;
    }
};
