import type { EditorView } from '@tiptap/pm/view';
import { Node, mergeAttributes } from '@tiptap/core';
import { Plugin, PluginKey } from '@tiptap/pm/state';
import { isLeafNodeEvent } from '../../common/helpers';
import { LINK_NODE_NAME } from './constants';

type TLinkOptions = {
    HTMLAttributes: Record<string, any>;
    onLinkClick?: (id: string) => void;
};

type TLinkClickProps = {
    extensionName: string;
    onLinkClick?: (id: string) => void;
};

declare module '@tiptap/core' {
    interface Commands<ReturnType> {
        [LINK_NODE_NAME]: {
            /**
             * Insert an link node
             */
            insertLink: (id: string, title: string) => ReturnType;
        };
    }
}

const getClickHandler =
    ({ extensionName, onLinkClick }: TLinkClickProps) =>
    (view: EditorView, pos: number, event: MouseEvent) => {
        const target = event.target as HTMLElement;
        const position = view.posAtDOM(target, 0);
        const node = view.state.doc.nodeAt(position);

        if (node?.type?.name === extensionName && isLeafNodeEvent(view, event, position)) {
            onLinkClick?.(node.attrs.id);
        }
    };

export const LinkBlock = Node.create<TLinkOptions>({
    name: LINK_NODE_NAME,

    group: 'inline',

    inline: true,

    selectable: false,

    atom: true,

    addOptions() {
        return {
            HTMLAttributes: {},
        };
    },

    addAttributes() {
        return {
            id: {
                default: null,
                parseHTML: (element) => {
                    return element.getAttribute('link-id');
                },
                renderHTML: (attributes) => {
                    if (!attributes.id) {
                        return {};
                    }

                    return {
                        'link-id': attributes.id,
                    };
                },
            },
            title: {
                default: null,
                parseHTML: (element) => element.getAttribute('link-title'),
                renderHTML: (attributes) => {
                    if (!attributes.title) {
                        return {};
                    }

                    return {
                        'link-title': attributes.title,
                    };
                },
            },
        };
    },

    parseHTML() {
        return [{ tag: 'a[link-id]' }];
    },

    renderText({ node }) {
        return node.attrs.title;
    },

    renderHTML({ node, HTMLAttributes }) {
        return ['a', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes), node.attrs.title];
    },

    addCommands() {
        return {
            insertLink:
                (id: string, title: string) =>
                ({ state, chain }) => {
                    const attributes = { id, title };
                    const { $from: from, $to: to } = state.selection;
                    const range = { from: from.pos, to: to.pos };

                    return chain()
                        .focus()
                        .insertContentAt(range, [
                            {
                                type: this.name,
                                attrs: attributes,
                            },
                        ])
                        .run();
                },
        };
    },

    addProseMirrorPlugins() {
        const extensionName = this.name;
        const onLinkClick = this.options.onLinkClick;

        return [
            new Plugin({
                key: new PluginKey('handleClickLinkBlock'),
                props: {
                    handleClick: getClickHandler({ extensionName, onLinkClick }),
                },
            }),
        ];
    },
});
