import {
    TDeleteObjectTypeAction,
    TDeleteObjectTypeAndGroupAction,
    TEditObjectTypeAction,
    TSubmitObjectTypeAction,
} from '../actions/objectType.actions.types';
import { MethodologySettingDaoService } from '../services/dao/MethodologySettingDaoService';
import { all, call, CallEffect, put, select, takeEvery } from 'redux-saga/effects';
import {
    objectTypeDeleteRequestSuccess,
    objectTypeRequesFailure,
    objectTypeRequestSuccess,
} from '../actions/objectType.actions';
import {
    CREATE_OBJECT_TYPE,
    DELETE_OBJECT_TYPE,
    DELETE_OBJECT_TYPE_AND_GROUP,
    EDIT_OBJECT_TYPE,
    SUBMIT_OBJECT_TYPE,
} from '../actionsTypes/objectType.actionTypes';
import {
    CREATE_OBJECT_TYPE_GROUP,
    DELETE_OBJECT_TYPE_GROUP,
    EDIT_OBJECT_TYPE_GROUP,
    SUBMIT_OBJECT_TYPE_GROUP,
} from '../actionsTypes/objectTypeGroup.actionTypes';
import {
    TCreateObjectTypeGroupAction,
    TDeleteObjectTypeGroupRequestAction,
    TEditObjectTypeGroupAction,
    TSubmitObjectTypeGroupAction,
} from '../actions/objectTypeGroup.actions.types';
import { KanbanCardType, ObjectType, ObjectTypeGroup } from '../serverapi/api';
import { ObjectTypeSelectors } from '../selectors/objectType.selectors';
import { deleteObjectTypeGroupSuccess, submitObjectTypeGroup } from '../actions/objectTypeGroup.actions';
import { LocalesService } from '../services/LocalesService';
import { getCurrentLocale } from '../selectors/locale.selectors';
import messages from '../modules/AdminTools/Methodology/messages/MethodologySetting.messages';
import { workspaceAddTab, workspaceRemoveTabByNodeId } from '../actions/tabs.actions';
import { symbolsDeleteRequest } from '../actions/symbol.actions';
import { ObjectTypesDaoService } from '../services/dao/ObjectTypesDaoService';
import { v4 as uuid } from 'uuid';
import { IWorkspaceTabItemObjectTypeEditorParams, TWorkspaceTab } from '../models/tab.types';
import { WorkSpaceTabTypes } from '../modules/Workspace/WorkSpaceTabTypesEnum';
import { EditorMode } from '../models/editorMode';
import { defaultWorkspaceTabActions } from '../models/tab';
import { PresetSettingsModelTypeSelectors } from '../selectors/presetSettings/presetSettingsModelType.selectors';
import { TPresetId } from '../modules/AdminTools/Methodology/components/Presets/TPresetId.types';
import { ServerErrorType } from '../models/serverErrorType';
import { showNotificationByType } from '../actions/notification.actions';
import { NotificationType } from '../models/notificationType';
import { KanbanDaoService } from '../services/dao/KanbanDaoService';
import { submitKanbanCardTypes } from '../actions/presetSettings/presetSettingsKanbanCardType.actions';
import { closeDialog, openDialog } from '../actions/dialogs.actions';
import { DialogType } from '../modules/DialogRoot/DialogRoot.constants';

function* handleDeleteObjectType({ payload }: TDeleteObjectTypeAction) {
    const { serverNode, objectTypes } = payload;
    const { serverId } = serverNode.nodeId;
    yield ObjectTypesDaoService.objectTypeBulkDelete(serverId, objectTypes);
    yield put(objectTypeDeleteRequestSuccess(serverNode.nodeId.serverId, objectTypes));
}

function* handleDeleteObjectTypeGroup({ payload }: TDeleteObjectTypeGroupRequestAction) {
    const { serverNode, objectTypeGroups } = payload;
    const { serverId } = serverNode.nodeId;

    if (objectTypeGroups?.length > 0) {
        const { presetId } = objectTypeGroups[0];
        const objectTypesFromGroup: ObjectType[] = (yield select(
            ObjectTypeSelectors.listAllByPreset(serverId, presetId),
        )).filter((ot) =>
            objectTypeGroups.some(
                (otg) => otg.id === ot.objectTypeGroup?.id && otg.presetId === ot.objectTypeGroup?.presetId,
            ),
        );
        yield ObjectTypesDaoService.deleteObjectTypesAndGroupsRequest(serverId, objectTypesFromGroup, objectTypeGroups);
    }
    yield put(deleteObjectTypeGroupSuccess(payload));
}

function* deleteKanbanCardTypes(serverId: string, presetId: string, kanbanCardTypesForDelete: KanbanCardType[]) {
    const effects: CallEffect<void>[] = [];

    kanbanCardTypesForDelete.forEach((type) => {
        effects.push(call(() => KanbanDaoService.deleteKanbanCardType(serverId, presetId, type.id)));
    });

    yield all(effects);
}

function* handleSubmitObjectType({ payload }: TSubmitObjectTypeAction) {
    const {
        serverNode,
        objectTypes,
        preset,
        symbolsToRemove,
        kanbanCardTypesForDelete,
        preventTabClose,
        createMode,
        tabNodeId,
    } = payload;
    const { serverId } = serverNode.nodeId;
    const intl = LocalesService.useIntl(yield select(getCurrentLocale));

    if (serverId && preset) {
        try {
            if (createMode) {
                yield ObjectTypesDaoService.createObjectType(serverId, objectTypes[0]);
            } else {
                if (!preventTabClose && tabNodeId) {
                    yield put(workspaceRemoveTabByNodeId(tabNodeId));
                }
                if (symbolsToRemove) {
                    yield MethodologySettingDaoService.deleteSymbols(serverId, symbolsToRemove);
                    yield put(
                        symbolsDeleteRequest({
                            symbols: symbolsToRemove,
                            presetId: preset.id,
                            serverId,
                        }),
                    );
                }
                yield ObjectTypesDaoService.setObjectTypeBulkSave(serverId, objectTypes);
            }

            if (kanbanCardTypesForDelete?.length) {
                yield deleteKanbanCardTypes(serverId, preset.id, kanbanCardTypesForDelete);
                yield put(
                    submitKanbanCardTypes({
                        presetId: preset.id,
                        kanbanCardTypesForDelete,
                    }),
                );
            }
        } catch (err) {
            yield put(objectTypeRequesFailure());
            if (ServerErrorType.DUPLICATE_ENTITY === err.status) {
                throw Error(intl.formatMessage(messages.idAlreadyTaken));
            } else {
                throw err;
            }
        }
        yield put(
            objectTypeRequestSuccess({
                serverId,
                presetId: preset.id,
                objectTypes,
            }),
        );
    }
}

function* handleCreateObjectTypeGroup(action: TCreateObjectTypeGroupAction) {
    const { serverNode, preset } = action.payload;
    const intl = LocalesService.useIntl(yield select(getCurrentLocale));

    const objectTypeGroup: ObjectTypeGroup = {
        id: uuid(),
        presetId: preset.id,
        name: intl.formatMessage(messages.newGroup),
        description: '',
    };

    const getSubmitAction = (objectTypeGroups: ObjectTypeGroup[]) =>
        submitObjectTypeGroup({
            serverNode,
            objectTypeGroups,
            preset,
            createMode: true,
        });

    yield put(
        openDialog(DialogType.METHODOLOGY_GROUP_CREATE_DIALOG, {
            elementName: intl.formatMessage(messages.objectFolderType),
            elemenTypeGroup: objectTypeGroup,
            createMode: true,
            getSubmitAction,
        }),
    );
}

function* handleCreateObjectType(action: TEditObjectTypeAction) {
    const { serverNode, preset } = action.payload;
    const intl = LocalesService.useIntl(yield select(getCurrentLocale));
    const modelTypes = yield select(
        PresetSettingsModelTypeSelectors.getAllByServerIdPresetId(preset?.id, serverNode.nodeId.serverId),
    );
    const objectType: ObjectType = {
        id: uuid(),
        name: intl.formatMessage(messages.newObject),
        presetId: preset.id,
        alwaysCreateNew: false,
        nodeAttributes: [],
        diagramElementAttributes: [],
        description: '',
        objectTypeGroup: undefined!,
        allowAnyDecomposition: true,
    };
    const createObjectTypeTab: TWorkspaceTab & TPresetId = {
        title: intl.formatMessage(messages.newObjectTitle),
        nodeId: {
            ...serverNode.nodeId,
            id: `${serverNode.nodeId.serverId}_${preset.id}_${objectType.id}`,
        },
        type: WorkSpaceTabTypes.EDIT_OBJECT_TYPE_TAB,
        mode: EditorMode.Read,
        presetId: preset.id,
        params: {
            serverNode,
            objectType,
            preset,
            modelTypes: modelTypes?.byId ? Object.values(modelTypes.byId) : [],
            createMode: true,
        } as IWorkspaceTabItemObjectTypeEditorParams,
        actions: {
            ...defaultWorkspaceTabActions,
        },
    };
    yield put(workspaceAddTab(createObjectTypeTab));
}

function* handleSubmitObjectTypeGroup({ payload }: TSubmitObjectTypeGroupAction) {
    const { serverNode, objectTypeGroups, createMode } = payload;
    const { serverId } = serverNode.nodeId;
    const intl = LocalesService.useIntl(yield select(getCurrentLocale));

    if (serverId) {
        if (createMode) {
            yield ObjectTypesDaoService.createObjectTypeGroup(serverId, objectTypeGroups[0]).catch((err) => {
                if (ServerErrorType.DUPLICATE_ENTITY === err.status) {
                    throw Error(intl.formatMessage(messages.idAlreadyTaken));
                } else {
                    throw err;
                }
            });
        } else {
            yield ObjectTypesDaoService.setObjectTypeGroupBulkSave(serverId, objectTypeGroups);
        }
    }

    yield put(closeDialog(DialogType.METHODOLOGY_GROUP_CREATE_DIALOG));
}

function* handleEditObjectType(action: TEditObjectTypeAction) {
    const { objectTypeId, serverNode, preset } = action.payload;
    const intl = LocalesService.useIntl(yield select(getCurrentLocale));

    const objectType: ObjectType | undefined = yield select(
        ObjectTypeSelectors.byId({
            objectTypeId,
            presetId: preset.id,
            serverId: serverNode.nodeId.serverId,
        }),
    );

    if (!objectType) {
        yield put(showNotificationByType(NotificationType.OBJECT_TO_EDIT_NOT_FOUND));

        return;
    }

    const modelTypes = yield select(
        PresetSettingsModelTypeSelectors.getAllByServerIdPresetId(preset?.id, serverNode.nodeId.serverId),
    );
    const editObjectTypeTab: TWorkspaceTab & TPresetId = {
        title: `${intl.formatMessage(messages.typeObject)}: ${objectType.name}`,
        nodeId: {
            ...serverNode.nodeId,
            id: `${serverNode.nodeId.serverId}_${preset.id}_${objectType.id}`,
        },
        type: WorkSpaceTabTypes.EDIT_OBJECT_TYPE_TAB,
        mode: EditorMode.Read,
        presetId: preset.id,
        params: {
            serverNode,
            objectType,
            preset,
            modelTypes: modelTypes?.byId ? Object.values(modelTypes.byId) : [],
            createMode: false,
        } as IWorkspaceTabItemObjectTypeEditorParams,
        actions: {
            ...defaultWorkspaceTabActions,
        },
    };

    yield put(workspaceAddTab(editObjectTypeTab));
}

function* handleEditObjectTypeGroup(action: TEditObjectTypeGroupAction) {
    const { serverNode, preset, objectTypeGroup } = action.payload;
    const intl = LocalesService.useIntl(yield select(getCurrentLocale));

    const getSubmitAction = (objectTypeGroups: ObjectTypeGroup[]) =>
        submitObjectTypeGroup({
            serverNode,
            objectTypeGroups,
            preset,
            createMode: false,
        });

    yield put(
        openDialog(DialogType.METHODOLOGY_GROUP_CREATE_DIALOG, {
            elementName: intl.formatMessage(messages.objectFolderType),
            elemenTypeGroup: objectTypeGroup,
            createMode: false,
            getSubmitAction,
        }),
    );
}

function* handleDeleteObjectTypeAndGroup({ payload }: TDeleteObjectTypeAndGroupAction) {
    const { serverNode, objectTypes, objectTypeGroups } = payload;
    const { serverId } = serverNode.nodeId;
    yield ObjectTypesDaoService.deleteObjectTypesAndGroupsRequest(serverId, objectTypes, objectTypeGroups);
    yield put(deleteObjectTypeGroupSuccess({ serverNode, objectTypeGroups }));
    yield put(objectTypeDeleteRequestSuccess(serverId, objectTypes));
}

export function* objectSagaInit() {
    yield takeEvery(DELETE_OBJECT_TYPE, handleDeleteObjectType);
    yield takeEvery(DELETE_OBJECT_TYPE_GROUP, handleDeleteObjectTypeGroup);
    yield takeEvery(SUBMIT_OBJECT_TYPE, handleSubmitObjectType);
    yield takeEvery(CREATE_OBJECT_TYPE_GROUP, handleCreateObjectTypeGroup);
    yield takeEvery(CREATE_OBJECT_TYPE, handleCreateObjectType);
    yield takeEvery(SUBMIT_OBJECT_TYPE_GROUP, handleSubmitObjectTypeGroup);
    yield takeEvery(EDIT_OBJECT_TYPE, handleEditObjectType);
    yield takeEvery(EDIT_OBJECT_TYPE_GROUP, handleEditObjectTypeGroup);
    yield takeEvery(DELETE_OBJECT_TYPE_AND_GROUP, handleDeleteObjectTypeAndGroup);
}
