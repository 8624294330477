import type { Node, NodeType } from '@tiptap/pm/model';
import type { Editor } from '@tiptap/core';
import type {
    TGetTableOfContentIndexFunction,
    TGetTableOfContentLevelFunction,
    THandleTableOfContentUpdateFunction,
    THeadline,
    TTableOfContentData,
    TTableOfContentDataItem,
    TTableOfContentsStorage,
} from './table-of-contents.types';

type TAnchorTypes = (string | NodeType)[];

type TOptions = {
    editor: Editor;
    anchorTypes?: TAnchorTypes;
    storage: TTableOfContentsStorage;
    onUpdate: THandleTableOfContentUpdateFunction;
};

// TODO нужно исправить логику работы
// export const getLastHeadingOnLevel: TGetTableOfContentLevelFunction = (headline, previousItems) => {
//     let o = headline.filter((t) => t.level === previousItems).pop();

//     if (0 !== previousItems) return o || (o = getLastHeadingOnLevel(headline, previousItems - 1)), o;
// };

/**
 *
 * Формирует уровень отступа пункта оглавления для разных уровней заголовков.
 * Отступ сокращается при отсутствии промежуточных заголовков.
 *
 * @param headline      текущий заголовок
 * @param previousItems список предыдущих заголовков
 * @param levels        список уровней заголовка, включенных в оглавление
 *
 */

export const getReducedLevel: TGetTableOfContentLevelFunction = (headline, previousItems, levels) => {
    const levelIndex = levels.findIndex((el) => el === headline.node.attrs.level);

    return levelIndex > 0 ? levelIndex + 1 : 1;
};

/**
 *
 * Формирует уровень отступа пункта оглавления для разных уровней заголовков
 *
 * @param headline      текущий заголовок
 * @param previousItems список предыдущих заголовков
 *
 */

export const getHeadlineLevel: TGetTableOfContentLevelFunction = (headline, previousItems) => {
    const lastItem: TTableOfContentDataItem | undefined = previousItems.at(-1);
    const baseLevel: number = lastItem?.originalLevel || 1;

    if (headline.node.attrs.level > baseLevel) {
        return (lastItem?.level || 1) + 1;
    }

    if (headline.node.attrs.level < baseLevel) {
        const headItem: TTableOfContentDataItem | undefined = previousItems.findLast(
            (item) => item.originalLevel <= headline.node.attrs.level,
        );

        return headItem?.level || 1;
    }

    return lastItem?.level || 1;
};

/**
 *
 * Формирует индекс для пункта оглавления независимо от уровня заголовка
 *
 * @param headline      текущий заголовок
 * @param previousItems список предыдущих заголовков
 *
 */

export const getLinearIndexes: TGetTableOfContentIndexFunction = (headline, previousItems) => {
    const lastItem: TTableOfContentDataItem | undefined = previousItems.at(-1);
    const lastItemIndex: number | undefined = lastItem?.itemIndex ? Number(lastItem.itemIndex) : undefined;

    const index: number = lastItem ? (lastItemIndex || 1) + 1 : 1;

    return `${index}.`;
};

/**
 *
 * Формирует индекс для пункта оглавления в сокращенном формате (например, '2.')
 *
 * @param headline      текущий заголовок
 * @param previousItems список предыдущих заголовков
 * @param currentLevel  вычисленный уровень текущего заголовка
 *
 */

export const getHierarchicalIndexes: TGetTableOfContentIndexFunction = (headline, previousItems, currentLevel) => {
    const level: number = currentLevel || headline.node.attrs.level || 1;
    const lastItem: TTableOfContentDataItem | undefined = previousItems
        .filter((item) => !item.level || item.level <= level)
        .at(-1);
    const lastItemIndex: number | undefined = lastItem?.itemIndex ? Number(lastItem.itemIndex) : undefined;

    const index: number = lastItem?.level === level ? (lastItemIndex || 1) + 1 : 1;

    return `${index}.`;
};

/**
 *
 * Формирует индекс для пункта оглавления в полном формате (например, '1.2.1.')
 *
 * @param headline      текущий заголовок
 * @param previousItems список предыдущих заголовков
 * @param currentLevel  вычисленный уровень текущего заголовка
 *
 */

export const getFullHierarchicalIndexes: TGetTableOfContentIndexFunction = (headline, previousItems, currentLevel) => {
    const level: number = currentLevel || headline.node.attrs.level || 1;
    const lastItem: TTableOfContentDataItem | undefined = previousItems
        .filter((item) => !item.level || item.level <= level)
        .at(-1);
    const lastItemIndex: number | undefined = lastItem?.itemIndex
        ? Number(lastItem?.itemIndex.split('.').at(-2))
        : undefined;

    const index: number = lastItem?.level === level ? (lastItemIndex || 1) + 1 : 1;
    const parentItem: TTableOfContentDataItem | undefined = currentLevel
        ? previousItems.findLast((item) => !item.level || item.level < currentLevel)
        : undefined;

    return parentItem ? `${parentItem.itemIndex}${index}.` : `${index}.`;
};

export const getData = (data: TTableOfContentData, options: TOptions): TTableOfContentData => {
    const { editor, anchorTypes, storage, onUpdate } = options;
    const headlines: THeadline[] = [];
    const ids: string[] = [];
    let tocId = null;

    editor.state.doc.descendants((node: Node, pos: number) => {
        if (anchorTypes && anchorTypes.includes(node.type.name)) {
            headlines.push({ node, pos });
        }
    });

    headlines.forEach(({ node, pos }) => {
        const domNode: HTMLElement = editor.view.domAtPos(pos + 1).node as HTMLElement;

        if (storage.scrollPosition >= domNode.offsetTop) {
            tocId = node.attrs['data-toc-id'];

            ids.push(node.attrs['data-toc-id']);
        }
    });

    data = data.map((item) => ({
        ...item,
        isActive: item.id === tocId,
        isScrolledOver: ids.includes(item.id),
    }));

    if (onUpdate) {
        onUpdate(data, storage.content.length === 0);
    }

    return data;
};

export const updateData = (options: TOptions) => {
    const { editor, anchorTypes, storage, onUpdate } = options;
    const headlines: THeadline[] = [];
    const anchors: (HTMLElement | HTMLHeadingElement)[] = [];
    let data: TTableOfContentData = [];

    editor.state.doc.descendants((node: Node, pos: number) => {
        if (anchorTypes && anchorTypes.includes(node.type.name)) {
            headlines.push({ node, pos });
        }
    });

    headlines.forEach(({ node, pos }, index) => {
        if (node.textContent.length === 0) {
            return;
        }

        const domNode = editor.view.domAtPos(pos + 1).node as HTMLElement;
        const isScrolledOver = storage.scrollPosition >= domNode.offsetTop;

        anchors.push(domNode);

        const originalLevel = node.attrs.level;
        const previousHeadline = headlines[index - 1];

        data = [
            ...data,
            {
                id: node.attrs['data-toc-id'],
                originalLevel,
                textContent: node.textContent,
                pos,
                editor,
                isActive: false,
                isScrolledOver: previousHeadline ? false : isScrolledOver,
                node: node,
                dom: domNode as HTMLHeadingElement,
            },
        ];
    });

    data = getData(data, options);

    if (onUpdate) {
        onUpdate(data, storage.content.length === 0);
    }

    storage.anchors = anchors;
    storage.content = data;
    editor.state.tr.setMeta('toc', data);
    editor.view.dispatch(editor.state.tr);
};
