import { defineMessages } from 'react-intl';

export default defineMessages({
    formName: {
        id: 'TreeItemSelectDialog.formName',
        defaultMessage: 'Выберите узел',
    },
    formStatusError: {
        id: 'TreeItemSelectDialog.formStatusError',
        defaultMessage: 'Ошибка',
    },
    formConfirmButton: {
        id: 'TreeItemSelectDialog.formConfirmButton',
        defaultMessage: 'ОК',
    },
    formDeclineButton: {
        id: 'TreeItemSelectDialog.wikiFormDeclineButton',
        defaultMessage: 'Отмена',
    },
    formClearAttributeButton: {
        id: 'TreeItemSelectDialog.formClearAttributeButton',
        defaultMessage: 'Очистить атрибут',
    },
});
