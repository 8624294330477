import { defineMessages } from 'react-intl';

export default defineMessages({
    delete: {
        id: 'ModelTemplatesTab.delete',
        defaultMessage: 'Удалить',
    },
    edit: {
        id: 'ModelTemplatesTab.edit',
        defaultMessage: 'Редактировать',
    },
    yes: {
        id: 'ModelTemplatesTab.yes',
        defaultMessage: 'Да',
    },
    no: {
        id: 'ModelTemplatesTab.no',
        defaultMessage: 'Нет',
    },
    name: {
        id: 'ModelTemplatesTab.name',
        defaultMessage: 'Название',
    },
    createdBy: {
        id: 'ModelTemplatesTab.createdBy',
        defaultMessage: 'Автор',
    },
    hiddenTemplate: {
        id: 'ModelTemplatesTab.hiddenTemplate',
        defaultMessage: 'Разрешен для использования',
    },
    deleteTemplatesQuestion: {
        id: 'ModelTemplatesTab.deleteTemplatesQuestion',
        defaultMessage: 'Удалить выбранные шаблоны?',
    },
    deleteTemplatesDialogContent: {
        id: 'ModelTemplatesTab.deleteTemplatesDialogContent',
        defaultMessage: 'Будут удалены шаблоны: {templateNames}',
    },
    modelType: {
        id: 'ModelTemplatesTab.modelType',
        defaultMessage: 'Тип модели',
    },
    modelTemplate: {
        id: 'ModelTemplatesTab.modelTemplate',
        defaultMessage: 'Шаблон модели модели',
    },
    cancel: {
        id: 'ModelTemplatesTab.cancel',
        defaultMessage: 'Отмена',
    },
    save: {
        id: 'ModelTemplatesTab.save',
        defaultMessage: 'Сохранить',
    },
});
