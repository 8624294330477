import React, { useContext } from 'react';
import { useIntl } from 'react-intl';
import { v4 as uuid } from 'uuid';
import messages from '../CommonToolbar.messages';
import icImage from 'icons/toolbar/controls/ic-attach-image.svg';
import { ToolbarButton } from 'UIKit';
import { insertAtomicBlock } from '../../common/editorState.utils';
import ControlsContext from '../Controls.context';
import { TRichEditorImage } from '../Toolbar.types';
import { useSharedState } from '../UseSharedState.hook';

const getChangedBlock = (editorState, image: TRichEditorImage) => {
    const entityKey = editorState.getCurrentContent().createEntity('IMAGE', 'MUTABLE', image).getLastCreatedEntityKey();

    return insertAtomicBlock(editorState, entityKey, ' ');
};

const ImageComponent = ({ openDialog, uploadFile }) => {
    const stateObserver = useContext(ControlsContext);
    const intl = useIntl();
    const { setCurrentState } = useSharedState<string, TRichEditorImage>({ stateObserver, getChangedBlock });

    const onReceiveImage = (file: File, { alt, title }: { alt: string; title: string }) => {
        const id = uuid();
        const { src } = uploadFile(file, id);

        setCurrentState({ src, alt, title });
    };

    return (
        <ToolbarButton
            tooltip={intl.formatMessage(messages.insertImage)}
            spriteSymbol={icImage}
            onClick={() => openDialog(onReceiveImage)}
            dataTest="wiki-toolbar-group_add-image-button"
        />
    );
};

export default ImageComponent;
