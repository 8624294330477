import React from 'react';
import { ButtonStyleColorPicker } from './components/ButtonStyleColorPicker/ButtonStyleColorPicker.component';
import { TPropsWithFormRef } from '@/modules/AdminTools/ServerSettings/SystemProperties.types';
import {
    BUTTON_COLOR_SCHEME_ITEMS,
    EVENT_TYPES,
} from './components/ButtonStyleColorPicker/ButtonStyleColorPicker.constants';
import { TButtonType } from './components/ButtonStyleColorPicker/ButtonStyleColorPicker.types';

type TButtonEventTypeColorPickersProps = TPropsWithFormRef & {
    buttonType: TButtonType;
};

export const getButtonColorSchemePicker = ({ buttonType, formRef }: TButtonEventTypeColorPickersProps) => {
    return EVENT_TYPES.reduce(
        (acc, eventType) => [
            ...acc,
            ...BUTTON_COLOR_SCHEME_ITEMS.map((schemeItem) => (
                <td key={`${buttonType}.${eventType}.${schemeItem}`}>
                    <ButtonStyleColorPicker
                        buttonType={buttonType}
                        eventType={eventType}
                        buttonColorSchemeItem={schemeItem}
                        formRef={formRef}
                    />
                </td>
            )),
        ],
        [],
    );
};
