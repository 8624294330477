import { defineMessages } from 'react-intl';

export default defineMessages({
    attribute: {
        id: 'ColumnSettings.attribute',
        defaultMessage: 'Атрибут',
    },
    SYSTEM: {
        id: 'ColumnSettings.SYSTEM',
        defaultMessage: 'Системный',
    },
    USER: {
        id: 'ColumnSettings.USER',
        defaultMessage: 'Пользовательский',
    },
    attributePlaceholder: {
        id: 'ColumnSettings.attributePlaceholder',
        defaultMessage: 'Укажите атрибут',
    },
    deleteColumn: {
        id: 'ColumnSettings.deleteColumn',
        defaultMessage: 'Удалить колонку',
    },
    visibility: {
        id: 'ColumnSettings.visibility',
        defaultMessage: 'Видимость',
    },
    filter: {
        id: 'ColumnSettings.filter',
        defaultMessage: 'Фильтр',
    },
});
