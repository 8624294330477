import { TButtonRowProps } from './ButtonRow.types';
import { getButtonColorSchemePicker } from './components/ButtonEventTypeColorPickers/ButtonEventTypeColorPickers.component';
import { Button } from '@/modules/UIKit/components/Button/Button.component';
import React, { useEffect } from 'react';
import { getTmpBtnStyles } from '../../../../../../../../../selectors/app.selector';
import { useSelector } from 'react-redux';
import { ButtonVisualStyle } from '../../../../../../../../../serverapi/api';
import { setTmpBtnColors } from './components/ButtonEventTypeColorPickers/components/ButtonStyleColorPicker/ButtonStyleColorPicker.utils';
import { useIntl } from 'react-intl';
import messages from '../../../../../../SystemProperties.messages';
import { TButtonType } from './components/ButtonEventTypeColorPickers/components/ButtonStyleColorPicker/ButtonStyleColorPicker.types';

export const ButtonRow = ({ buttonType, danger, formRef }: TButtonRowProps) => {
    const intl = useIntl();
    const buttonTypeColorScheme: TButtonType = `${buttonType}${danger ? 'Danger' : ''}`;
    const buttonStyle: ButtonVisualStyle = useSelector(getTmpBtnStyles);
    useEffect(() => {
        const element: Element | null = document.querySelector(`[data-id=${buttonTypeColorScheme}]`);
        const button: ChildNode | null | undefined = element?.firstChild;
        if (button) {
            setTmpBtnColors(button as HTMLElement, buttonStyle, buttonTypeColorScheme);
        }
    }, [buttonStyle]);

    return (
        <tr>
            <td>{intl.formatMessage(messages[buttonTypeColorScheme])}</td>
            <td data-id={`${buttonTypeColorScheme}`}>
                <Button visualStyle={buttonType === 'buttonText' ? 'text' : buttonType} danger={danger}>
                    Button text
                </Button>
            </td>

            {getButtonColorSchemePicker({ buttonType: buttonTypeColorScheme, formRef })}
        </tr>
    );
};
