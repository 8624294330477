import { defineMessages } from 'react-intl';

export default defineMessages({
    formName: {
        id: 'WikiTocSettingsDialog.formName',
        defaultMessage: 'Задайте параметры оглавления',
    },
    formConfirmButton: {
        id: 'WikiTocSettingsDialog.formConfirmButton',
        defaultMessage: 'ОК',
    },
    formDeclineButton: {
        id: 'WikiTocSettingsDialog.formDeclineButton',
        defaultMessage: 'Отмена',
    },
    headingsSelectLabel: {
        id: 'WikiTocSettingsDialog.headingsSelectLabel',
        defaultMessage: 'Уровни заголовков',
    },
    headingsSelectPlaceholder: {
        id: 'WikiTocSettingsDialog.headingsSelectPlaceholder',
        defaultMessage: 'Выберите уровни заголовков',
    },
    heading: {
        id: 'WikiTocSettingsDialog.heading',
        defaultMessage: 'Заголовок',
    },
    indexSelectLabel: {
        id: 'WikiTocSettingsDialog.indexSelectLabel',
        defaultMessage: 'Индексы',
    },
    hideIndex: {
        id: 'WikiTocSettingsDialog.hideIndex',
        defaultMessage: 'Не показывать',
    },
    onelevelIndex: {
        id: 'WikiTocSettingsDialog.onelevelIndex',
        defaultMessage: 'Короткие',
    },
    alllevelsIndex: {
        id: 'WikiTocSettingsDialog.alllevelsIndex',
        defaultMessage: 'Полные',
    },
    viewTypeSelectLabel: {
        id: 'WikiTocSettingsDialog.viewTypeSelectLabel',
        defaultMessage: 'Вид вывода',
    },
    treeView: {
        id: 'WikiTocSettingsDialog.treeView',
        defaultMessage: 'Древовидный список',
    },
    flatView: {
        id: 'WikiTocSettingsDialog.flatView',
        defaultMessage: 'Плоский список',
    },
});
