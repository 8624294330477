import React, { FC } from 'react';
import { ColumnsType } from 'antd/es/table';
import { Col, Row, Table } from 'antd';
import theme from './MessagesList.scss';
import { TTableRowData } from './MessagesList.types';
import messages from './MessagesList.messages';
import { useIntl } from 'react-intl';
import { MailMessageDTO, NotificationDTO, NotificationTemplateDTO } from '@/serverapi/api';
import { getFormattedRows } from './MessagesList.utils';
import { useDispatch, useSelector } from 'react-redux';
import { openDialog } from '@/actions/dialogs.actions';
import { DialogType } from '@/modules/DialogRoot/DialogRoot.constants';
import { LocalesService } from '@/services/LocalesService';
import { Locale } from '@/modules/Header/components/Header/header.types';
import { getCurrentLocale } from '@/selectors/locale.selectors';

type TMessagesListProps = {
    messages: MailMessageDTO[];
    notifications: NotificationDTO[];
    notificationTemplates: NotificationTemplateDTO[];
};

enum statusColor {
    ERROR = '#FF3733',
    NEW = '#41bcfb',
    SENT = '#363636',
}

export const MessagesList: FC<TMessagesListProps> = ({
    messages: mailMessages,
    notifications,
    notificationTemplates,
}) => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const currentLocale: Locale = useSelector(getCurrentLocale);

    const columns: ColumnsType<TTableRowData> = [
        {
            title: intl.formatMessage(messages.id),
            dataIndex: 'id',
            key: 'id',
            width: '15%',
        },
        {
            title: intl.formatMessage(messages.dateTime),
            dataIndex: 'createdAt',
            width: '15%',
            key: 'id',
            ellipsis: true,
        },
        {
            title: intl.formatMessage(messages.notificationType),
            dataIndex: 'notificationType',
            width: '25%',
            key: 'id',
            ellipsis: true,
        },
        {
            title: intl.formatMessage(messages.toWhom),
            dataIndex: 'notificationRecipientType',
            width: '15%',
            key: 'id',
            ellipsis: true,
        },
        {
            title: 'Email',
            dataIndex: 'emailsList',
            width: '15%',
            key: 'id',
            ellipsis: true,
        },
        {
            title: intl.formatMessage(messages.status),
            dataIndex: 'status',
            width: '15%',
            key: 'id',
            ellipsis: true,
            render: (value, record) => {
                return (
                    <span style={{ color: statusColor[record.status] }}>
                        {
                        (messages[record.status] == undefined) ? record.status : intl.formatMessage(messages[record.status])
                        }
                    </span>
                );
            },
        },
    ];
    const rows: TTableRowData[] = getFormattedRows(mailMessages, notifications);

    const handleRowClick = (row: TTableRowData) => {
        return {
            onClick: () => {
                const notificationTemplate = notificationTemplates.find(
                    (notificationTemplate) => notificationTemplate.id === row.templateId,
                );
                dispatch(
                    openDialog(DialogType.MESSAGE_CONTENT_DIALOG, {
                        subject: LocalesService.internationalStringToString(
                            notificationTemplate?.subject,
                            currentLocale,
                        ),
                        template: LocalesService.internationalStringToString(
                            notificationTemplate?.template,
                            currentLocale,
                        ),
                    }),
                );
            },
        };
    };

    return (
        <Col className={theme.container}>
            <Row className={theme.row}>
                <Table
                    scroll={{ y: 600 }}
                    pagination={false}
                    columns={columns}
                    dataSource={rows}
                    onRow={handleRowClick}
                />
            </Row>
        </Col>
    );
};
