// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SearchDuplicates__container__DR1Qq{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center;width:475px}.SearchDuplicates__wrapper__pxGN_{padding:10px;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column;height:100%;border-bottom:1px solid var(--Neutral100);overflow:overlay}.SearchDuplicates__path__Vy_Tb{padding:10px;color:red}", "",{"version":3,"sources":["webpack://./src/modules/FindDuplicates/components/SearchDuplicates.scss"],"names":[],"mappings":"AAAA,oCACI,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,wBAAA,CAAA,qBAAA,CAAA,kBAAA,CACA,WAAA,CAEJ,kCACI,YAAA,CACA,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,2BAAA,CAAA,4BAAA,CAAA,yBAAA,CAAA,qBAAA,CACA,WAAA,CACA,yCAAA,CACA,gBAAA,CAGJ,+BACI,YAAA,CACA,SAAA","sourcesContent":[".container {\r\n    display: flex;\r\n    align-items: center;\r\n    width: 475px;\r\n}\r\n.wrapper {\r\n    padding: 10px;\r\n    display: flex;\r\n    flex-direction: column;\r\n    height: 100%;\r\n    border-bottom: 1px solid var(--Neutral100);\r\n    overflow: overlay;\r\n}\r\n\r\n.path {\r\n    padding: 10px;\r\n    color: red;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "SearchDuplicates__container__DR1Qq",
	"wrapper": "SearchDuplicates__wrapper__pxGN_",
	"path": "SearchDuplicates__path__Vy_Tb"
};
export default ___CSS_LOADER_EXPORT___;
