import { put, select, takeEvery } from 'redux-saga/effects';
import { AIP_SEARCH_ALIASES_REQUEST, AIP_SEARCH_CREATE_ALIAS_REQUEST, AIP_SEARCH_DELETE_BY_ID_ALIAS_REQUEST, GET_AIP_SEARCH_RESULT } from '../actionsTypes/assistantInterface.actionTypes';
import { AipAlias, AipSearch, SearchResponse, SearchResult } from '@/serverapi/api';
import { TSearchDataListItem } from '@/reducers/search.reducer.types';
import { TreeItemType } from '@/modules/Tree/models/tree';
import { getAipSearchAliasesRequestSuccess, setAipSearchData } from '../actions/assistantInterface.actions';
import { TAipSearchCreateAliasRequestAction, TAipSearchDeleteByIdAliasRequestAction, TGetSearchResultAction } from '../actions/assiastantInterface.actions.types';
import { getPresetFromIndexedDb } from '@/utils/indexedDb';
import { TCacheDb } from '@/utils/indexedDb.types';
import { aipPreset } from '../bll/AipPreset';
import { PresetMetadataToAipPresetDataAdapter } from '../bll/PresetMetadataToAipPresetDataAdapter';
import { aipSearchRequestBuilder } from '../bll/AipSearchRequestBuilder';
import { aipConfig } from '../bll/AipConfig';
import { AipDaoService } from '../dao/AipDaoService';
import { TreeSelectors } from '@/selectors/tree.selectors';
import { TFoundInConfig } from '../bll/types/AipConfig.types';


function* handleGetSearchResult({
    payload: { searchText, searchRules, searchVisibility, searchNodeTypes, nodeId},
}: TGetSearchResultAction) {
    const id: string = nodeId.id;
    const token: TFoundInConfig = aipConfig.findSinglewordToken(searchText);
    // если первое слово в запросе это призыв о помощи, устанавливаем isShowingHelp в true
    if (token && token.aipConfigGroup === 'COMMAND' && token.tokens.includes('HELP')) {
        yield put(setAipSearchData({ id, isShowingHelp: true }));
        return;
    }

    try {
        yield put(setAipSearchData({ id, isLoading: true }));

        if (!aipPreset.whetherHasBeenInitialized()) {
            const presetId: string | undefined = yield select(TreeSelectors.presetById(nodeId));
            if (!presetId) {
                // ошибка при получении ИД пресета. Тут нужно что-то сделать и как-то сообщить пользователю
                return;
            }
            const res: TCacheDb = yield getPresetFromIndexedDb(presetId);
            if (!res?.preset) {
                // ошибка при получении пресета. Тут нужно что-то сделать и как-то сообщить пользователю
                return;
            }
            const aliases: AipAlias[] = [];
            aipPreset.init(new PresetMetadataToAipPresetDataAdapter().convert(res.preset, aliases));
        }

        const aipSearch: AipSearch | null = aipSearchRequestBuilder.build(searchText, nodeId, aipConfig, aipPreset);

        if (!aipSearch) {
            // ошибка в запросе. Тут нужно что-то сделать и как-то сообщить пользователю
            return;
        }
        const sr: SearchResponse = yield AipDaoService.search(aipSearch);

        const response: SearchResult[] = sr.resultList ? sr.resultList : [];

        const searchResult: TSearchDataListItem[] = response.map((item) => ({
            multilingualName: item.multilingualName,
            path: `${item.path}`,
            type: item.nodeType as TreeItemType,
            elementType: item.elementTypeId || '',
            nodeId: {
                ...item.nodeId,
                serverId: nodeId.serverId,
            },
            deleted: item.deleted,
        }));

        yield put(setAipSearchData({ id, searchResult }));
    } finally {
        yield put(setAipSearchData({ id, isLoading: false }));
    }
}


function* handleGetAllAliases() {
    const aa: AipAlias[] = yield AipDaoService.getAll();
    yield put(getAipSearchAliasesRequestSuccess(aa));
}


function* createAlias(action: TAipSearchCreateAliasRequestAction) {
    yield AipDaoService.create(action.payload.alias);
    yield handleGetAllAliases();
}


function* deleteAliasById(action: TAipSearchDeleteByIdAliasRequestAction) {
    yield AipDaoService.deleteById(action.payload.id);
    yield handleGetAllAliases();
}


export function* searchAipSaga() {
    yield takeEvery(GET_AIP_SEARCH_RESULT, handleGetSearchResult);
    yield takeEvery(AIP_SEARCH_ALIASES_REQUEST, handleGetAllAliases);
    yield takeEvery(AIP_SEARCH_CREATE_ALIAS_REQUEST, createAlias);
    yield takeEvery(AIP_SEARCH_DELETE_BY_ID_ALIAS_REQUEST, deleteAliasById);
}
