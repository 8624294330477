// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ButtonStyleColorPicker__changeColorBlock__AoLWh{width:17px;height:17px;border:1px solid gray;border-radius:3px}.ButtonStyleColorPicker__formItem__p2t34{margin:0}.ButtonStyleColorPicker__formItem__p2t34 .ant-form-item-control-input-content{-webkit-box-flex:0;-ms-flex-positive:0;flex-grow:0;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center}.ButtonStyleColorPicker__formItem__p2t34 .ant-form-item-row,.ButtonStyleColorPicker__formItem__p2t34 .ant-form-item-control-input{-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center}", "",{"version":3,"sources":["webpack://./src/modules/AdminTools/ServerSettings/components/ColorSchemeSettings/components/ButtonSettings/components/ButtonRow/components/ButtonEventTypeColorPickers/components/ButtonStyleColorPicker/ButtonStyleColorPicker.scss"],"names":[],"mappings":"AAAA,iDACI,UAAA,CACA,WAAA,CACA,qBAAA,CACA,iBAAA,CAGJ,yCACI,QAAA,CAGI,8EACI,kBAAA,CAAA,mBAAA,CAAA,WAAA,CACA,uBAAA,CAAA,oBAAA,CAAA,sBAAA,CAGJ,kIAEI,uBAAA,CAAA,oBAAA,CAAA,sBAAA","sourcesContent":[".changeColorBlock {\r\n    width: 17px;\r\n    height: 17px;\r\n    border: 1px solid grey;\r\n    border-radius: 3px;\r\n}\r\n\r\n.formItem {\r\n    margin: 0;\r\n\r\n    :global {\r\n        .ant-form-item-control-input-content {\r\n            flex-grow: 0;\r\n            justify-content: center;\r\n        }\r\n\r\n        .ant-form-item-row,\r\n        .ant-form-item-control-input {\r\n            justify-content: center;\r\n        }\r\n    }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"changeColorBlock": "ButtonStyleColorPicker__changeColorBlock__AoLWh",
	"formItem": "ButtonStyleColorPicker__formItem__p2t34"
};
export default ___CSS_LOADER_EXPORT___;
