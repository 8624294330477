import React, { FC } from 'react';
import theme from './Summary.scss';
import messages from '../widget.messages';
import { TSummaryData, TWidgetProps } from '../widget.types';
import { useIntl } from 'react-intl';

const initData = { data: '0', legend: '' };

export const Summary: FC<TWidgetProps> = ({ data: summaryData = initData, title }) => {
    const { data, legend } = summaryData as TSummaryData;
    const intl = useIntl();

    return (
        <div className={theme.summaryContainer} data-test="dashboard-editor_container_widget_summary">
            <div className={theme.header} data-test="dashboard-editor_container_widget_summary-header">{title}</div>

            {data ? (
                <div className={theme.summaryDataWrapper}>
                    <div className={theme.summaryData} data-test="dashboard-editor_container_widget_summary-data">{data}</div>
                    <div className={theme.legend} data-test="dashboard-editor_container_widget_summary-legend">{legend}</div>
                </div>
            ) : (
                <div className={theme.noDataText} data-test="dashboard-editor_container_widget_summary-data">{intl.formatMessage(messages.noData)}</div>
            )}
        </div>
    );
};
