import { TAppState } from '@/reducers/app.reducer.types';
import { TRootState } from '../reducers/root.reducer.types';
import { createSelector } from 'reselect';
import { AppColorScheme, ButtonVisualStyle } from '../serverapi/api';
import { getInitialColor } from '../modules/AdminTools/ServerSettings/components/ColorSchemeSettings/components/ButtonSettings/components/ButtonRow/components/ButtonEventTypeColorPickers/components/ButtonStyleColorPicker/ButtonStyleColorPicker.utils';
import {
    TButtonColorSchemeItem,
    TButtonType,
    TEventType,
} from '../modules/AdminTools/ServerSettings/components/ColorSchemeSettings/components/ButtonSettings/components/ButtonRow/components/ButtonEventTypeColorPickers/components/ButtonStyleColorPicker/ButtonStyleColorPicker.types';

const getState = (state: TRootState) => state.app;

export const getStatus = createSelector(getState, (state) => state.status);

export const getIsStarted = createSelector(getState, (state) => state.isStarted);

export const getFullModelDefinition = createSelector(getState, (state) => state.fullModelDefinition);

export const getAppColor = createSelector(getState, (state) => state.appColor);

export const getAppColorScheme = createSelector<TRootState, TAppState, AppColorScheme>(
    getState,
    (state) => state.appColorScheme,
);

export const getButtonColorScheme = createSelector<TRootState, AppColorScheme, ButtonVisualStyle>(
    getAppColorScheme,
    (state) => state.button || {},
);

export const getTmpBtnStyles = createSelector<TRootState, TAppState, ButtonVisualStyle>(
    getState,
    (state) => state.temporaryAppColorScheme?.button || {},
);

export const getTmpBtnItemColor = (buttonType: TButtonType, eventType: TEventType, item: TButtonColorSchemeItem) =>
    createSelector<TRootState, ButtonVisualStyle | undefined, string>(getTmpBtnStyles, (styles) => {
        return getInitialColor(styles, buttonType, eventType, item);
    });

export const getIsEnLocaleAllowed = createSelector<TRootState, TAppState, boolean>(
    getState,
    (state: TAppState) => state.isEnLocaleAllowed,
);
