import React, { useState, useEffect, useMemo, ChangeEvent } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Table, Popover, Progress } from 'antd';
import theme from './ScriptExecutionsTable.scss';
import messages from '../../messages/ScriptDashboardTable.messages';
import statusMessages from '../../../App/messages/AppScriptExecutionStatuses.messages';
import { isUndefined } from 'is-what';
import icStatusFail from '../../../../resources/icons/ic-script-exec-fail.svg';
import icStatusSuccess from '../../../../resources/icons/ic-script-exec-success.svg';
import icStatusStopped from '../../../../resources/icons/ic-script-exec-stopped.svg';
import icStatusRunning from '../../../../resources/icons/ic-script-exec-running.svg';
import icStatusWait from '../../../../resources/icons/ic-script-exec-wait.svg';
import icEdit from '../../../../resources/icons/ic-edit.svg';
import dots from '../../../../resources/icons/dotsGrey.svg';
import { TSpriteSymbol } from '../../../../models/spriteSymbol.types';
import { Icon } from '../../../UIKit/components/Icon/Icon.component';
import {
    NextStepParameters,
    NodeId,
    OperationDataStatusEnum,
    OperationProgress,
    PercentOperationProgress,
    ScriptOperationData,
} from '../../../../serverapi/api';
import { isEqual } from 'lodash-es';
import { Button } from '@/modules/UIKit/components/Button/Button.component';
import { TableUIKit } from '@/modules/UIKit/components/Table/TableUIKit.component';
import { TColumn, TTableData } from '@/modules/UIKit/components/Table/TableUIKit.types';
import { ColumnProps } from 'antd/es/table';
import { Controls } from './ScriptExecutionsControl.component';
import { sortByAlpha } from '@/utils/sortByAlpha';

const getFilteredData = (
    data: ScriptOperationData[],
    searchFilter: string,
    statusFilter: 'all' | OperationDataStatusEnum,
): ScriptOperationData[] => {
    const searchableColumns: string[] = ['scriptName', 'startDate', 'stopDate', 'step'];

    const filteredByStatus = statusFilter !== 'all' ? data.filter((d) => d.status === statusFilter) : data;

    return searchFilter
        ? filteredByStatus.filter((item) =>
              searchableColumns.some((column) => {
                  const value = item[column];

                  if (Array.isArray(value)) {
                      return value.some((param) => param.toString().toLowerCase().includes(searchFilter));
                  }
                  return value && value.toString().toLowerCase().includes(searchFilter);
              }),
          )
        : filteredByStatus;
};

type TScriptExecutionsTableProps = {
    data: ScriptOperationData[];
    serverId: string;
    downloadFile: (fileId: NodeId) => void;
    downloadLogFile: (scriptExecutionId: string) => void;
    runScriptStep: (operationId: string, serverId: string) => void;
    openScript: (scriptId: NodeId) => void;
} & JSX.IntrinsicAttributes;

type TParameter = {
    name: string;
    value: string;
};

type TColumnDataItem = Record<string, React.ReactNode | Date>;

export const ScriptExecutionsTable = (props: TScriptExecutionsTableProps) => {
    const { serverId } = props;
    const intl = useIntl();

    const [data, setData] = useState<ScriptOperationData[]>(props.data);
    const [searchFilter, setSearchFilter] = useState<string>('');
    const [statusFilter, setStatusFilter] = useState<OperationDataStatusEnum | 'all'>('all');

    const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.value !== searchFilter) {
            setSearchFilter(e.target.value.toLowerCase());
        }
    };

    const handleClearSearch = () => setSearchFilter('');

    const columns: TColumn[] = useMemo(
        () => [
            {
                title: intl.formatMessage(messages.scriptColumnName),
                dataKey: 'name',
                sortFunction: (a: TTableData, b: TTableData) => {
                    const nameA = (a.name as React.ReactElement).props.children[0].props.children;
                    const nameB = (b.name as React.ReactElement).props.children[0].props.children;

                    return sortByAlpha(nameA, nameB);
                },
            },
            {
                width: 130,
                title: intl.formatMessage(messages.statusColumnName),
                dataKey: 'statusLabel',
                sortFunction: (a: TTableData, b: TTableData) => {
                    const statusA = (a.statusLabel as React.ReactElement).props.children.props.children[2];
                    const statusB = (b.statusLabel as React.ReactElement).props.children.props.children[2];

                    return sortByAlpha(statusA, statusB);
                },
            },
            {
                width: 160,
                title: intl.formatMessage(messages.startDateColumnName),
                dataKey: 'createdDateStr',
                sortFunction: (a: TTableData, b: TTableData) =>
                    new Date(a.createdDate as string).getTime() - new Date(b.createdDate as string).getTime(),
            },
            {
                width: 160,
                title: intl.formatMessage(messages.finishDateColumnName),
                dataKey: 'finishedDateStr',
                sortFunction: (a: TTableData, b: TTableData) =>
                    new Date(a.finishedDate as string).getTime() - new Date(b.finishedDate as string).getTime(),
            },
            {
                width: 120,
                title: intl.formatMessage(messages.resultColumnName),
                dataKey: 'scriptExecutionId',
                withoutSorter: true,
            },
            {
                width: 110,
                title: intl.formatMessage(messages.parameters),
                dataKey: 'parameters',
                withoutSorter: true,
            },
            {
                width: 120,
                title: intl.formatMessage(messages.stepDialogColumnName),
                dataKey: 'nextStep',
                withoutSorter: true,
            },
            {
                width: 100,
                title: intl.formatMessage(messages.stepColumnName),
                dataKey: 'step',
                withoutSorter: true,
            },
            {
                width: 100,
                title: intl.formatMessage(messages.logColumnName),
                dataKey: 'download',
                withoutSorter: true,
            },
        ],
        [intl],
    );

    const parametersColumns: ColumnProps<TParameter>[] = useMemo(
        () => [
            {
                width: 150,
                title: intl.formatMessage(messages.paramNameColumnName),
                dataIndex: 'name',
            },
            {
                width: 300,
                title: intl.formatMessage(messages.paramValueColumnName),
                dataIndex: 'value',
            },
        ],
        [intl],
    );

    useEffect(() => {
        if (!isEqual(data, props.data)) {
            setData(props.data);
        }

        const filteredBySearch = getFilteredData(props.data, searchFilter, statusFilter);

        setData(filteredBySearch);
    }, [props.data, searchFilter, statusFilter]);

    const renderParamsColumn = (value: TParameter[]) => {
        if (value.length) {
            return (
                <Popover
                    trigger="hover"
                    placement="left"
                    content={
                        <Table
                            rowKey={(record: TParameter) => `${record.name}`}
                            columns={parametersColumns}
                            dataSource={value}
                            size="small"
                            className={theme.table}
                            bordered
                            pagination={false}
                        />
                    }
                >
                    <div className={theme.params}>
                        <Icon className={theme.iconParams} spriteSymbol={dots} />
                    </div>
                </Popover>
            );
        }

        return (
            <div>
                <FormattedMessage {...messages.noParameters} />
            </div>
        );
    };

    const renderStringColumn = (value: string) => (
        <div>
            <span>{value}</span>
        </div>
    );

    const renderScriptColumn = (value: string | undefined, scriptId: NodeId) => (
        <span className={theme.script_name} onClick={() => props.openScript(scriptId)}>
            <span>{value}</span>
            <div className={theme.icon_edit}>
                <span>
                    <Icon spriteSymbol={icEdit} />
                </span>
            </div>
        </span>
    );

    const renderStepColumn = (value: string | undefined) => (
        <div>
            <span>{value}</span>
        </div>
    );

    const renderStepDialogColumn = (nextStep: NextStepParameters | undefined, scriptExecutionId: string | undefined) => {
        if (!nextStep) {
            return (
                <Button size="small" disabled>
                    <FormattedMessage {...messages.noData} />
                </Button>
            );
        }

        return (
            <div>
                <Button
                    size="small"
                    onClick={() => {
                        if (!scriptExecutionId) return;

                        props.runScriptStep(scriptExecutionId, serverId);
                    }}
                >
                    <FormattedMessage {...messages.openStepDialog} />
                </Button>
            </div>
        );
    };

    const renderStatusColumn = (
        value: string,
        status: OperationDataStatusEnum | undefined,
        operationProgress: OperationProgress | undefined,
        statusIcon: TSpriteSymbol | undefined,
    ) => {
        if (status === 'RUN' && operationProgress && operationProgress.type === 'PERCENTS') {
            return (
                <div>
                    <span>
                        <Progress
                            type="circle"
                            percent={Math.floor((operationProgress as PercentOperationProgress).percents || 0)}
                            size={25}
                        />
                        &nbsp;
                        {value}
                    </span>
                </div>
            );
        }

        return (
            <div>
                <span>
                    <Icon className={theme.icon} spriteSymbol={statusIcon} />
                    &nbsp;
                    {value}
                </span>
            </div>
        );
    };

    const noDataButton = () => (
        <Button size="small" disabled>
            <FormattedMessage {...messages.noData} />
        </Button>
    );

    const tableButton = (onClick: () => void) => (
        <Button size="small" onClick={onClick}>
            <FormattedMessage {...messages.download} />
        </Button>
    );

    const renderDownloadColumn = (executionResult: NodeId | undefined) => {
        if (!executionResult) {
            return (
                <Button size="small" disabled>
                    <FormattedMessage {...messages.noData} />
                </Button>
            );
        }

        return (
            <Button
                size="small"
                onClick={() => {
                    props.downloadFile({ ...executionResult, serverId });
                }}
            >
                <FormattedMessage {...messages.download} />
            </Button>
        );
    };

    const renderLogDownloadColumn = (scriptExecutionId: string | undefined) => {
        if (!scriptExecutionId) {
            return noDataButton();
        }

        return tableButton(() => props.downloadLogFile(scriptExecutionId));
    };

    const statuses = useMemo(
        () => ({
            WAIT: intl.formatMessage(statusMessages.appScriptExecutionStatusWait),
            RUN: intl.formatMessage(statusMessages.appScriptExecutionStatusRunning),
            SUCCESS: intl.formatMessage(statusMessages.appScriptExecutionStatusSuccess),
            FAIL: intl.formatMessage(statusMessages.appScriptExecutionStatusFail),
            CANCEL: intl.formatMessage(statusMessages.appScriptExecutionStatusStopped),
        }),
        [intl],
    );

    const statusesIcons = {
        WAIT: icStatusWait,
        RUN: icStatusRunning,
        SUCCESS: icStatusSuccess,
        FAIL: icStatusFail,
        CANCEL: icStatusStopped,
    };

    let columnsData: TColumnDataItem[] =
        data &&
        data.map((e) => {
            const {
                status,
                progress: operationProgress,
                result: executionResult,
                nextStep,
                id: scriptExecutionId,
                scriptName,
                startDate,
                stopDate,
                step,
            } = e;

            const scriptId = { id: e.scriptId, repositoryId: 'script-root-id', serverId } as NodeId;

            const statusIcon = statusesIcons[status!];

            let parameters: TParameter[] = [];
            if (e.parameters) {
                parameters = e.parameters.map((p) => ({ name: p.name, value: p.value } as TParameter));
            }

            const item: TColumnDataItem = {};

            item.name = renderScriptColumn(scriptName, scriptId);
            item.statusLabel = renderStatusColumn(statuses[status!], status, operationProgress, statusIcon);
            item.createdDate = startDate && new Date(startDate);
            item.createdDateStr = renderStringColumn(new Date(startDate!).toLocaleString());
            item.finishedDate = stopDate && new Date(stopDate);
            item.finishedDateStr = renderStringColumn(
                isUndefined(stopDate) || stopDate == null
                    ? intl.formatMessage(messages.noData)
                    : new Date(stopDate!).toLocaleString(),
            );
            item.scriptExecutionId = renderDownloadColumn(executionResult);
            item.parameters = renderParamsColumn(parameters);
            item.nextStep = renderStepDialogColumn(nextStep, scriptExecutionId);
            item.step = renderStepColumn(step);
            item.download = renderLogDownloadColumn(scriptExecutionId);

            return item;
        });

    columnsData =
        columnsData &&
        columnsData.sort((n1, n2) => {
            return ((n2.createdDate as Date)?.getTime() || 0) - ((n1.createdDate as Date)?.getTime() || 0);
        });

    return (
        <div className={theme.container}>
            <Controls
                searchFilter={searchFilter}
                statusFilter={statusFilter}
                statuses={statuses}
                onSearch={handleSearch}
                onClearSearch={handleClearSearch}
                onChangeStatus={(value: OperationDataStatusEnum | 'all') => setStatusFilter(value)}
            />

            <TableUIKit columns={columns} tableData={columnsData as TTableData[]} />
        </div>
    );
};
