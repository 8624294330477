import { addIfExist } from '@/utils/redux.utils';
import { AIP_SEARCH_ALIASES_REQUEST_SUCCESS, SET_AIP_SEARCH_DATA } from '../actionsTypes/assistantInterface.actionTypes';
import { TSearchAipState } from './searchAssistantInterface.reducer.types';

const initial: { [key: string]: TSearchAipState } = {};

export const SearchAipReducer = (state = initial, action) => {
    switch (action.type) {
        case SET_AIP_SEARCH_DATA: {
            const { id, searchResult, isLoading, isShowingHelp } = action.payload;

            if (id) {
                const newState = { ...state };

                newState[id] = {
                    searchResult: addIfExist(searchResult, state[id]?.searchResult || []),
                    isLoading: addIfExist(isLoading, state[id]?.isLoading || false),
                    isShowingHelp
                };

                return newState;
            }

            return state;
        }
        case AIP_SEARCH_ALIASES_REQUEST_SUCCESS: {
            const newState = { ...state };
            newState.aliases = action.payload.aliases;

            return newState;
        }
        default: {
            return state;
        }
    }
};
