import { defineMessages } from 'react-intl';

export default defineMessages({
    formName: {
        id: 'WikiImageLinkDialog.formName',
        defaultMessage: 'Вставить изображение по ссылке',
    },
    formConfirmButton: {
        id: 'WikiImageLinkDialog.formConfirmButton',
        defaultMessage: 'Вставить',
    },
    formDeclineButton: {
        id: 'WikiImageLinkDialog.wikiFormDeclineButton',
        defaultMessage: 'Отмена',
    },
    formValidationMessageRequiredUrl: {
        id: 'WikiImageLinkDialog.formValidationMessageRequiredUrl',
        defaultMessage: 'Укажите url изображения',
    },
    urlLabel: {
        id: 'WikiImageLinkDialog.linkUrlLabel',
        defaultMessage: 'Url',
    },
    altLabel: {
        id: 'WikiImageLinkDialog.altLabel',
        defaultMessage: 'Альтернативный текст',
    },
    titleLabel: {
        id: 'WikiImageLinkDialog.titleLabel',
        defaultMessage: 'Текст подсказки',
    },
});

