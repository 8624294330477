import React, { useRef, useState } from 'react';
import { Tooltip } from 'antd';
import theme from './EllipsisText.scss';
import { getTextBlockRenderedSize } from '../../../../../../utils/render.utils';

const lineHeight = 22;

type TEllipsisTextProps = {
    text: string;
    linesQuantity?: number;
};

export const EllipsisText = React.memo(({ text, linesQuantity = 2 }: TEllipsisTextProps) => {
    const textRef = useRef<HTMLDivElement>(null);
    const [showTooltip, setShowTooltip] = useState(false);

    const handleMouseEnter = () => {
        if (!textRef.current) return;

        const parentWidth = textRef.current.offsetWidth;
        const { height } = getTextBlockRenderedSize(text, {
            width: `${parentWidth}px`,
            overflowWrap: 'wrap',
            fontFamily: 'Segoe UI',
            wordBreak: 'break-all',
            fontSize: '14px',
            lineHeight: `${lineHeight}px`,
        });

        setShowTooltip(height > linesQuantity * lineHeight);
    };

    return (
        <Tooltip mouseLeaveDelay={0} title={showTooltip ? text : ''}>
            <div
                ref={textRef}
                onMouseEnter={handleMouseEnter}
                className={theme.ellipsis}
                style={{ WebkitLineClamp: linesQuantity }}
            >
                {text}
            </div>
        </Tooltip>
    );
});
