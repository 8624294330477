import {
    TocIndex,
    TocView,
    type TGetTableOfContentIndexFunction,
    type TTableOfContentData,
    type TTableOfContentsAttributes,
    type TTableOfContentsStorage,
} from '../../extensions/table-of-contents.types';
import type { Node, NodeViewProps } from '@tiptap/react';
import { TextSelection } from '@tiptap/pm/state';
import { NodeViewWrapper } from '@tiptap/react';
import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import theme from './TableOfContents.styles.scss';
import {
    getFullHierarchicalIndexes,
    getHierarchicalIndexes,
    getReducedLevel,
} from '../../extensions/table-of-contents.utils';

type TTableOfContentsRendererProps = Omit<NodeViewProps, 'extension'> & {
    extension: Node<any, TTableOfContentsStorage>;
};

export const TocItem = ({ item, onItemClick }) => {
    const itemPadding = `${16 * (item.level - 1)}px`;

    return (
        <span style={{ paddingLeft: itemPadding }}>
            {item.itemIndex && <span>{item.itemIndex}</span>}
            <a onClick={(e) => onItemClick(e, item.id)} data-item-index={item.itemIndex}>
                {item.textContent}
            </a>
        </span>
    );
};

export const TocFlatItem = ({ item, onItemClick }) => {
    return (
        <span>
            <span>{'['}</span>
            {item.itemIndex && <span>{item.itemIndex}</span>}
            <a onClick={(e) => onItemClick(e, item.id)} data-item-index={item.itemIndex}>
                {item.textContent}
            </a>
            <span>{']'}</span>
        </span>
    );
};

export const TocEmptyState = ({ title }) => {
    return <p>{title}</p>;
};

const indexFnMap = {
    [TocIndex.HIDE]: () => '',
    [TocIndex.ALL_LEVELS]: getFullHierarchicalIndexes,
    [TocIndex.ONE_LEVEL]: getHierarchicalIndexes,
};

const getData = (content: TTableOfContentData, attrs: TTableOfContentsAttributes): TTableOfContentData => {
    const { levels, index } = attrs;
    const getIndexFn: TGetTableOfContentIndexFunction = indexFnMap[index];
    const filteredContent = content.filter((el) => levels.includes(el.originalLevel));
    const filteredLevels: number[] = [...new Set(filteredContent.map((item) => item.node.attrs.level))];
    const data: TTableOfContentData = filteredContent.reduce((acc, el) => {
        const currentLevel = getReducedLevel(el, acc, filteredLevels);
        const currentIndex = getIndexFn(el, acc, currentLevel);

        return [
            ...acc,
            {
                ...el,
                itemIndex: currentIndex,
                level: currentLevel,
            },
        ];
    }, []);

    return data;
};

export const TableOfContentsRenderer = (props: TTableOfContentsRendererProps) => {
    const { editor, extension, node, getPos } = props;
    const { storage } = extension;
    const [items, setItems] = useState<TTableOfContentData>(
        getData(storage?.content, node.attrs as TTableOfContentsAttributes) || [],
    );

    const handleUpdate = () => {
        const node = editor.state.doc.nodeAt(getPos());
        const { attrs } = node || {};
        const data = getData(storage?.content, attrs as TTableOfContentsAttributes);

        setItems(data);
    };

    useEffect(() => {
        if (!editor) {
            return;
        }

        editor.on('update', handleUpdate);

        return () => {
            editor.off('update', handleUpdate);
        };
    }, [editor]);

    const handleItemClick = (e: MouseEvent, id: string) => {
        e.preventDefault();

        if (editor) {
            const element = editor.view.dom.querySelector(`[data-toc-id="${id}"`);
            const pos = element ? editor.view.posAtDOM(element, 0) : null;

            if (!element || !pos) {
                return;
            }

            const tr = editor.view.state.tr;
            tr.setSelection(new TextSelection(tr.doc.resolve(pos)));
            editor.view.dispatch(tr);
            editor.view.focus();

            element.scrollIntoView({ behavior: 'smooth' });
        }
    };

    if (items.length === 0) {
        return (
            <NodeViewWrapper className={theme.tableOfContents}>
                <TocEmptyState title={extension.options.emptyContentTitle} />
            </NodeViewWrapper>
        );
    }

    const Item = node.attrs.view === TocView.TREE ? TocItem : TocFlatItem;

    return (
        <NodeViewWrapper
            className={cx(theme.tableOfContents, node.attrs.view === TocView.FLAT ? theme.tableOfContentsFlat : null)}
        >
            {items.map((item) => (
                <Item onItemClick={handleItemClick} key={item.id} item={item} />
            ))}
        </NodeViewWrapper>
    );
};
