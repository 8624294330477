import React, { FC } from 'react';
import theme from './Filters.scss';
import { useIntl } from 'react-intl';
import { Select } from '@/modules/UIKit/components/Select/Select.component';
import messages from './Filters.messages';
import { TFilterType, TNumericFilter, TNumericFilterRules } from '../../../reducers/reportEditor.reducer.types';
import { DatePickerProvider } from '@/modules/UIKit/H.O.C/DatePickerProvider/DatePickerProvider.hoc';
import { DatePicker } from 'antd';
import { dateFormat, dateTimeFormat, momentDateToTimestamp, timestampToMomentDate } from '@/utils/date.time.utils';
import { Dayjs } from 'dayjs';
import { AttributeTypeValueTypeEnum, NodeId } from '@/serverapi/api';
import { AttributeValueType } from '@/modules/FloatingAttributes/components/AttributesEditor/Attribute.types';
import { RangeValue } from 'rc-picker/lib/interface';
import { useDispatch, useSelector } from 'react-redux';
import { reportSetColumnFilter } from '@/modules/Report/actions/report.actions';
import { ReportSelectors } from '@/modules/Report/selectors/report.selectors';

const { RangePicker, TimePicker } = DatePicker;

type TDateFilterProps = {
    reportNodeId: NodeId;
    columnId: string;
    columnName: string;
    minWidth: number;
    valueType: AttributeTypeValueTypeEnum;
};

export const DateFilter: FC<TDateFilterProps> = ({ reportNodeId, columnId, columnName, minWidth, valueType }) => {
    const intl = useIntl();

    const { rule, value1, value2 }: TNumericFilter = useSelector(
        ReportSelectors.getFilterValue(reportNodeId, columnId, TFilterType.DATE),
    ) as TNumericFilter;

    const dispatch = useDispatch();

    const handleChangeColumnFilter = (data: TNumericFilter) => {
        dispatch(reportSetColumnFilter(reportNodeId, columnId, TFilterType.DATE, data));
    };

    const isWithoutTimeZone: boolean =
        valueType === AttributeValueType.DATE_TIME_WITHOUT_TIMEZONE ||
        valueType === AttributeValueType.TIME_WITHOUT_TIMEZONE;

    const isDateTimeFormat: boolean = valueType !== AttributeValueType.DATE;

    const handleRuleChange = (newRule: TNumericFilterRules | undefined) => {
        const newValue1: number | undefined = newRule && rule !== TNumericFilterRules.BETWEEN ? value1 : undefined;
        const newValue2: number | undefined = newRule === TNumericFilterRules.BETWEEN ? value2 : undefined;

        handleChangeColumnFilter({ rule: newRule, value1: newValue1, value2: newValue2 });
    };

    const onChangeRangeDate = (dates: RangeValue<Dayjs>) => {
        const date1: Dayjs | null = dates?.[0] as Dayjs;
        const date2: Dayjs | null = dates?.[1] as Dayjs;
        const value1: number | undefined = momentDateToTimestamp(date1, isWithoutTimeZone) || undefined;
        const value2: number | undefined = momentDateToTimestamp(date2, isWithoutTimeZone) || undefined;
        handleChangeColumnFilter({ rule, value1, value2 });
    };

    const onChangeDate = (date: Dayjs | null, secondValue?: boolean) => {
        const value: number | undefined = momentDateToTimestamp(date, isWithoutTimeZone) || undefined;
        if (!secondValue) {
            handleChangeColumnFilter({ rule, value1: value, value2 });
        } else {
            handleChangeColumnFilter({ rule, value1, value2: value });
        }
    };

    return (
        <div className={theme.container} style={{ minWidth, maxWidth: minWidth }} key={columnId}>
            <Select
                value={rule ? intl.formatMessage(messages[rule]) : ''}
                onChange={handleRuleChange}
                wrapperClassName={theme.ruleSelect}
                placeholder={columnName}
                allowClear
            >
                {Object.values(TNumericFilterRules).map((value) => (
                    <Select.Option
                        label={intl.formatMessage(messages[value])}
                        value={value}
                        checked={rule === value}
                        key={value}
                    />
                ))}
            </Select>
            {rule === TNumericFilterRules.BETWEEN ? (
                <DatePickerProvider>
                    {valueType === 'TIME' || valueType === 'TIME_WITHOUT_TIMEZONE' ? (
                        <>
                            <TimePicker
                                onChange={(date) => onChangeDate(date)}
                                value={value1 ? timestampToMomentDate(value1, isWithoutTimeZone) : undefined}
                                placeholder={intl.formatMessage(messages.selectTime)}
                                allowClear
                                className={theme.datePicker}
                            />
                            <TimePicker
                                onChange={(date) => onChangeDate(date, true)}
                                value={value2 ? timestampToMomentDate(value2, isWithoutTimeZone) : undefined}
                                placeholder={intl.formatMessage(messages.selectTime)}
                                allowClear
                                className={theme.datePicker}
                            />
                        </>
                    ) : (
                        <RangePicker
                            format={isDateTimeFormat ? dateTimeFormat : dateFormat}
                            size="middle"
                            onChange={onChangeRangeDate}
                            placeholder={[intl.formatMessage(messages.startDate), intl.formatMessage(messages.endDate)]}
                            showTime={isDateTimeFormat}
                            className={theme.datePicker}
                        />
                    )}
                </DatePickerProvider>
            ) : (
                <DatePickerProvider>
                    {valueType === 'TIME' || valueType === 'TIME_WITHOUT_TIMEZONE' ? (
                        <TimePicker
                            onChange={(date) => onChangeDate(date)}
                            value={value1 ? timestampToMomentDate(value1, isWithoutTimeZone) : undefined}
                            placeholder={intl.formatMessage(messages.selectTime)}
                            allowClear
                            className={theme.datePicker}
                        />
                    ) : (
                        <DatePicker
                            showTime={isDateTimeFormat}
                            format={isDateTimeFormat ? dateTimeFormat : dateFormat}
                            onChange={(date) => onChangeDate(date)}
                            value={value1 ? timestampToMomentDate(value1, isWithoutTimeZone) : undefined}
                            placeholder={
                                isDateTimeFormat
                                    ? intl.formatMessage(messages.selectDateAndTime)
                                    : intl.formatMessage(messages.selectDate)
                            }
                            allowClear
                            className={theme.datePicker}
                        />
                    )}
                </DatePickerProvider>
            )}
        </div>
    );
};
