export class AipToolbox {

    static getWordsFromString(str: string): string[] {
        return str
            .trim()
            .replaceAll('\n', ' ')
            .split(' ')
            .map(word => word.trim())
            .filter(word => word);
    }
}
