import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { closeDialog } from '../../../actions/dialogs.actions';
import { setSearchRootNodes } from '../../../actions/search.actions';
import { filterTreeExcludeTypes, TreeSelectors } from '../../../selectors/tree.selectors';
import { DialogType } from '../../DialogRoot/DialogRoot.constants';
import { Dialog } from '../../UIKit/components/Dialog/Dialog.component';
import style from './SearchPathDialog.scss';
import { TreeNode } from '../../../models/tree.types';
import { useIntl } from 'react-intl';
import messages from './SearchPathDialog.messages';
import { DEFAULT_DIALOG_WIDTH } from '../../../config/config';
import { Tree } from '../../Tree/components/Tree/Tree.component';
import { SelectedNodesSelector } from '@/selectors/selectedNodes.selectors';
import { DialogFooterButtons } from '../../UIKit/components/DialogFooterButtoms/DialogFooterButtons.component';
import { TreeItemType } from '@/modules/Tree/models/tree';
import { TSearchPathDialogProps } from './SearchPathDialog.types';
import { treeItemsClearExpandStatus, treeItemsClearSelection, treeSelectNodesByNodeIds } from '@/actions/tree.actions';
import { SearchSelectors } from '@/selectors/dbSearch.selector';
import { NodeId } from '@/serverapi/api';

export const SearchPathDialog: FC<TSearchPathDialogProps> = ({ searchNodeId }) => {
    const dispatch = useDispatch();
    const intl = useIntl();

    const treeName: string = `${searchNodeId.repositoryId}_${searchNodeId.id}`;

    const allNodes: TreeNode[] = useSelector(TreeSelectors.treeStructureConnected);
    const nodesWithoutAdminTools: TreeNode[] = filterTreeExcludeTypes(allNodes, [TreeItemType.AdminTool]);
    const selectedNodes: TreeNode[] = useSelector(SelectedNodesSelector.getNodesWithoutChildren(treeName));
    const rootSearchNodeIds: NodeId[] = useSelector(SearchSelectors.getRootSearchNodeIds(searchNodeId));

    const isDisable = selectedNodes.length === 0;

    useEffect(() => {
        if (rootSearchNodeIds.length !== 0) {
            dispatch(treeSelectNodesByNodeIds(rootSearchNodeIds, treeName));
        }
    }, []);

    const handleClose = () => {
        dispatch(closeDialog(DialogType.SEARCH_PATH));
        dispatch(treeItemsClearSelection());
        dispatch(treeItemsClearExpandStatus());
    };

    const handleSelectPath = () => {
        if (selectedNodes.length !== 0) {
            dispatch(setSearchRootNodes(searchNodeId, selectedNodes));
        }

        handleClose();
    };

    const footer = (
        <DialogFooterButtons
            buttons={[
                {
                    key: 'cancel',
                    onClick: handleClose,
                    value: intl.formatMessage(messages.cancelButtonText),
                },
                {
                    key: 'ok',
                    onClick: handleSelectPath,
                    value: intl.formatMessage(messages.selectButtonText),
                    visualStyle: 'primary',
                    disabled: isDisable,
                },
            ]}
        />
    );

    return (
        <Dialog
            width={DEFAULT_DIALOG_WIDTH}
            onOk={handleSelectPath}
            onCancel={handleClose}
            open
            title={intl.formatMessage(messages.header)}
            footer={footer}
        >
            <div className={style.treeContainer}>
                <Tree
                    treeName={treeName}
                    data={nodesWithoutAdminTools}
                    allowMultiSelect
                    excludeFilterForSelect={[TreeItemType.AdminTool, TreeItemType.Server]}
                />
            </div>
        </Dialog>
    );
};
