import React, { FC } from 'react';
import theme from './PieChartSourceInput.scss';
import { Col, Input } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { TabsSelectors } from '@/selectors/tabs.selectors';
import { TWorkspaceTab } from '@/models/tab.types';
import { DashboardSelector } from '@/selectors/entities/dashboard.selectors';
import { TWidget, TWidgetDataset, UpperCaseAlp } from '../../Dashboard.types';
import { setWidgetSource } from '@/actions/dashboard.actions';
import { useIntl } from 'react-intl';
import messages from './PieChartSourceInput.messages';
import { SourceTableSelect } from '../SourceTableSelect/SourceTableSelect';

const initDatasete: TWidgetDataset[] = [{ col1: '', col2: '', legend: '' }];

export const PieChartSourceInput: FC = () => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const activeTab: TWorkspaceTab | undefined = useSelector(TabsSelectors.getActiveTab);
    const currentWidget: TWidget | undefined = useSelector(DashboardSelector.selectedWidget(activeTab?.nodeId));

    const dataSet: TWidgetDataset[] = currentWidget?.source?.dataset?.length
        ? currentWidget?.source?.dataset
        : initDatasete;

    const selectColumnHandler = (event: React.ChangeEvent<HTMLInputElement>, key: string) => {
        if (!activeTab || !currentWidget || !currentWidget.source) return;
        const value = event.currentTarget.value.toUpperCase();
        if (Object.keys(UpperCaseAlp).includes(value) || value === '') {
            const newDataSet = [...dataSet];
            if (newDataSet[0]) {
                newDataSet[0] = {
                    ...newDataSet[0],
                    [key]: value,
                };
                dispatch(
                    setWidgetSource(activeTab.nodeId, currentWidget.id, {
                        ...currentWidget.source,
                        dataset: newDataSet,
                    }),
                );
            }
        }
    };

    return (
        <>
            <SourceTableSelect
                nodeId={activeTab?.nodeId}
                widgetId={currentWidget?.id}
                tableName={currentWidget?.source?.name}
            />
            {currentWidget?.source && (
                <>
                    <Col className={theme.inputContainer}>
                        <div className={theme.label}>{intl.formatMessage(messages.dataCol)}</div>
                        <Input
                            value={currentWidget.source?.dataset?.[0]?.col1 || ''}
                            onChange={(e) => selectColumnHandler(e, 'col1')}
                            className={theme.input}
                            data-test="dashboard_widget-settings-panel_input-data-table-column"
                        />
                    </Col>
                    <Col className={theme.inputContainer}>
                        <div className={theme.label}>{intl.formatMessage(messages.legendCol)}</div>
                        <Input
                            value={currentWidget.source?.dataset?.[0]?.col2 || ''}
                            onChange={(e) => selectColumnHandler(e, 'col2')}
                            className={theme.input}
                            data-test="dashboard_widget-settings-panel_input-data-table-legend"
                        />
                    </Col>
                </>
            )}
        </>
    );
};
