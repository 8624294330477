import {
    FETCH_AUDIT,
    FETCH_DETAILED_AUDIT,
    FETCH_DETAILED_AUDIT_SUCCESS,
    FETCH_AUDIT_SUCCESS,
    DOWNLOAD_CSV,
    DELETE_AUDIT_RANGE,
    DELETE_SESSION_RANGE,
    FINISH_DOWNLOAD_CSV,
    SET_AUDIT_FILTERED_TYPES,
    SET_AUDIT_FILTERED_ACCESSES,
    FINISH_FETCH_AUDIT,
    SET_AUDIT_FILTERED_USERS,
    FETCH_AUDIT_PROPERTIES_SUCCESS,
    FETCH_AUDIT_PROPERTIES,
    SAVE_AUDIT_PROPERTIES,
    SAVE_AUDIT_PROPERTIES_SUCCESS,
    DELETE_AUDIT_PROPERTIES,
} from '../actionsTypes/audit.actionTypes';
import { AuditDto, AuditProperties, AuditRequest, DetailedAuditDTO } from '../serverapi/api';
import {
    IDeleteAuditRange,
    IDeleteSessionRange,
    IFetchDetailedAudit,
    IFetchDetailedAuditSuccess,
    IFetchAudit,
    IFetchAuditSuccess,
    TDownloadCSVAction,
    TFinishDownloadCSVAction,
    IFilteredTypes,
    IFilteredAccesses,
    accessTypes,
    TFinishFetchAuditAction,
    IFilteredUsers,
    TFetchAuditPropertiesAction,
    TFetchAuditPropertiesSuccessAction,
    TSaveAuditPropertiesAction,
    TSaveAuditPropertiesSuccessAction,
    TDeleteAuditPropertiesAction,
} from './audit.actions.types';

export const fetchAudit = (serverId: string, params: AuditRequest): IFetchAudit => ({
    type: FETCH_AUDIT,
    params,
    serverId,
});

export const fetchDetailedAudit = (payload: { serverId: string; id: number }): IFetchDetailedAudit => ({
    type: FETCH_DETAILED_AUDIT,
    payload,
});

export const deleteAuditRange = (payload: {
    start: number;
    end: number;
    serverId: string;
    password: string;
}): IDeleteAuditRange => ({
    type: DELETE_AUDIT_RANGE,
    payload,
});

export const deleteSessionRange = (payload: {
    start: number;
    end: number;
    serverId: string;
    password: string;
}): IDeleteSessionRange => ({
    type: DELETE_SESSION_RANGE,
    payload,
});

export const fetchDetailedAuditSuccess = (payload: {
    serverId: string;
    detailedAudit: DetailedAuditDTO;
}): IFetchDetailedAuditSuccess => ({
    type: FETCH_DETAILED_AUDIT_SUCCESS,
    payload,
});

export const fetchAuditSuccess = (serverId: string, audit: AuditDto[]): IFetchAuditSuccess => ({
    type: FETCH_AUDIT_SUCCESS,
    serverId,
    audit,
});

export const finishAuditFecth = (): TFinishFetchAuditAction => ({
    type: FINISH_FETCH_AUDIT,
});

export const finishDownloadCSV = (): TFinishDownloadCSVAction => ({
    type: FINISH_DOWNLOAD_CSV,
});

export const downloadCSV = (params: AuditRequest): TDownloadCSVAction => ({
    type: DOWNLOAD_CSV,
    payload: { params },
});

export const setAuditFilteredTypes = (filteredTypes: string[]): IFilteredTypes => ({
    type: SET_AUDIT_FILTERED_TYPES,
    payload: { filteredTypes },
});

export const setAuditFilteredAccesses = (filteredAccesses: accessTypes[]): IFilteredAccesses => ({
    type: SET_AUDIT_FILTERED_ACCESSES,
    payload: { filteredAccesses },
});

export const setAuditFilteredUsers = (filteredUsers: number[]): IFilteredUsers => ({
    type: SET_AUDIT_FILTERED_USERS,
    payload: { filteredUsers },
});

export const fetchAuditProperties = (): TFetchAuditPropertiesAction => ({
    type: FETCH_AUDIT_PROPERTIES,
});

export const fetchAuditPropertiesSuccess = (auditProperties: AuditProperties): TFetchAuditPropertiesSuccessAction => ({
    type: FETCH_AUDIT_PROPERTIES_SUCCESS,
    payload: { auditProperties },
});

export const saveAuditProperties = (auditProperties: AuditProperties): TSaveAuditPropertiesAction => ({
    type: SAVE_AUDIT_PROPERTIES,
    payload: { auditProperties },
});

export const saveAuditPropertiesSuccess = (auditProperties: AuditProperties): TSaveAuditPropertiesSuccessAction => ({
    type: SAVE_AUDIT_PROPERTIES_SUCCESS,
    payload: { auditProperties },
});

export const deleteAuditProperties = (): TDeleteAuditPropertiesAction => ({
    type: DELETE_AUDIT_PROPERTIES,
});
