import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { NodeId, NodeTypeEnum } from '@/serverapi/api';
import { LOCK_TIMEOUT } from '@/utils/consts';
import { relock } from '@/actions/lock.actions';

export const useRelock = (isEditMode: boolean, nodeId: NodeId, type: NodeTypeEnum) => {
    const dispatch = useDispatch();

    useEffect(() => {
        const interval: number = window.setInterval(() => isEditMode && dispatch(relock(nodeId, type)), LOCK_TIMEOUT);

        return () => {
            clearInterval(interval);
        };
    }, [isEditMode]);
};
