import { InternationalString, SearchRequestNodeTypesEnum } from "@/serverapi/api";

export enum TAipPresetGroupsNames {
    aliasTypes = 'aliasTypes',
    attributeTypes = 'attributeTypes',
    edgeTypes = 'edgeTypes',
    folderTypes = 'folderTypes',
    modelTypes = 'modelTypes',
    objectTypes = 'objectTypes'
};

export type TAipPresetData = Partial<Record<TAipPresetGroupsNames, TReducedOriginalPresetCommonItem[]>> | null;

export type TAipPresetTypesMappingValue = { attributeTypeId: string, nodeType: SearchRequestNodeTypesEnum | 'ALIAS' } | undefined;
export type TAipPresetTypesMapping = Partial<Record<TAipPresetGroupsNames, TAipPresetTypesMappingValue>>;

export type TFoundInPresetHelper = {
    group: TAipPresetGroupsNames,
    id: string,
    distance: number
};

export type TFoundValueGroupAndIds = { aipPresetGroup: TAipPresetGroupsNames, ids: string[] };

export type TNgFoundInPreset = (TFoundValueGroupAndIds & {
    alternate?: TFoundValueGroupAndIds[],
    found: string,
    source: string
}) | null;

export type TFoundInPreset = Partial<Record<TAipPresetGroupsNames, Record<string, string>>> | null;

export type TSearchGroupsFilter = Partial<Record<TAipPresetGroupsNames, number>> | undefined;

export type TReducedOriginalPresetData = {
    attributeTypes: TReducedOriginalPresetCommonItem[],
    folderTypes: TReducedOriginalPresetCommonItem[],
    objectTypes: TReducedOriginalPresetCommonItem[],
    modelTypes: TReducedOriginalPresetModelTypeItem[]
};

export type TReducedOriginalPresetModelTypeItem = {
    id: string,
    multilingualName: InternationalString,
    edgeTypes: Array< { id: string, multilingualName: InternationalString } >
}

export type TReducedOriginalPresetCommonItem = {
    id: string,
    multilingualName: InternationalString
}
