import type { IWikiNode } from '@/models/bpm/bpm-model-impl.types';
import type { NodeId, WikiContent } from '@/serverapi/api';
import { isUndefined } from 'lodash-es';

export const WIKI_CONTENT_PROP_NAME = 'content';

export const EMPTY_WIKI_CONTENT = { content: [], type: 'doc' };

export const getEditorData = (wikiNode?: IWikiNode): string | WikiContent => {
    return wikiNode?.[WIKI_CONTENT_PROP_NAME] || wikiNode?.source || '';
};

export const isNewEditorData = (wikiNode?: IWikiNode): boolean => {
    return !isUndefined(wikiNode?.[WIKI_CONTENT_PROP_NAME]);
};

export const getWikiImageSrc = (fileId: NodeId, modelId: NodeId) =>
    `${window.location.origin}/api/wiki/${fileId.repositoryId}/${modelId.id}/image/${fileId.id}/`;

export const getModelImageSrc = (modelId: NodeId) =>
    `${window.location.origin}/api/model/${modelId.repositoryId}/${modelId.id}/image/png`;

export const getFileImageSrc = (fileNodeId: NodeId) =>
    `${window.location.origin}/api/file/download/${fileNodeId.repositoryId}/${fileNodeId.id}/`;
