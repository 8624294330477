import { defineMessages } from 'react-intl';

export default defineMessages({
    today: {
        id: 'RangePicker.today',
        defaultMessage: 'Сегодня ',
    },
    currentMonth: {
        id: 'RangePicker.currentMonth',
        defaultMessage: 'Текущий месяц',
    },
    startDate: {
        id: 'RangePicker.startDate',
        defaultMessage: 'Начальная дата ',
    },
    endDate: {
        id: 'RangePicker.endDate',
        defaultMessage: 'Конечная дата',
    },
});
