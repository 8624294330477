import { ButtonVisualStyle } from '@/serverapi/api';

function capitalizeString(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export const setButtonColorScheme = (
    colorScheme: ButtonVisualStyle | undefined,
    element = document.documentElement,
) => {
    if (!colorScheme) {
        return;
    }

    const res: Record<string, string> = {};
    Object.keys(colorScheme).forEach((button) => {
        const buttonKeys = Object.keys(colorScheme[button]);
        buttonKeys.forEach((mode) => {
            const modeKeys = Object.keys(colorScheme[button][mode]);
            modeKeys.forEach((styleKey) => {
                const styleValue = colorScheme[button][mode][styleKey];

                res[`--${button}${capitalizeString(mode)}${capitalizeString(styleKey)}`] = styleValue;
            });
        });
    });

    Object.entries(res).forEach(([cssVar, value]) => {
        element.style.setProperty(cssVar, value);
    });
};
