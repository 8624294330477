import React, { useEffect } from 'react';
import messages from './ApprovalDialog.messages';
import { useIntl } from 'react-intl';
import { Form, InputNumber } from 'antd';
import { ApprovalRateOfSuccess, ApprovalStageDTOType } from './ApprovalDialog.types';
import { StagesBllService } from '@/services/bll/ApprovalDialogStagesBLLService';
import { TRateAndVoteStrategyProps } from './StageSettingsTab.types';
import { TRadioOption } from '../UIKit/components/Radio/Radio.types';
import { RadioGroup } from '../UIKit/components/Radio/RadioGroup.component';

export const RateOfSuccessComponent = ({
    selectedStage,
    isEditingDisabled,
    isTemplate,
    stageFormRef,
    onChangeStages,
    stages,
    stageId,
}: TRateAndVoteStrategyProps) => {
    const intl = useIntl();

    useEffect(() => {
        if (selectedStage.type !== ApprovalStageDTOType.PARALLEL) {
            onRateOfSuccessTypeChange(ApprovalRateOfSuccess.ALL_POSITIVE);
        }
    }, [selectedStage.type]);

    const onRateOfSuccessTypeChange = (value: ApprovalRateOfSuccess) => {
        stageFormRef.current?.setFieldsValue({
            RateOfSuccessType: value,
            RateOfSuccess: value === ApprovalRateOfSuccess.ALL_POSITIVE ? 100 : null,
        });

        onRateOfSuccessChange(value === ApprovalRateOfSuccess.ALL_POSITIVE ? 100 : null);
    };

    const onRateOfSuccessChange = (rate: number | null) => {
        const rateOfSuccess: number | undefined = rate === null ? undefined : rate;
        if (rate === 100) {
            stageFormRef.current?.setFieldsValue({
                RateOfSuccessType: ApprovalRateOfSuccess.ALL_POSITIVE,
            });
        }

        const stageList = StagesBllService.changeRateOfSuccess(stages, stageId, rateOfSuccess);

        onChangeStages(stageList);
    };

    const radioOptions: TRadioOption<ApprovalRateOfSuccess>[] = [
        {
            title: intl.formatMessage(messages.percentage),
            value: ApprovalRateOfSuccess.PERCENTAGE,
        },
        {
            title: intl.formatMessage(messages.allVotedPositive),
            value: ApprovalRateOfSuccess.ALL_POSITIVE,
        },
    ];

    return (
        <>
            <Form.Item
                label={intl.formatMessage(messages.rateOfSuccessLabel)}
                name="RateOfSuccessType"
                initialValue={
                    selectedStage.rateOfSuccess === 100
                        ? ApprovalRateOfSuccess.ALL_POSITIVE
                        : ApprovalRateOfSuccess.PERCENTAGE
                }
            >
                <RadioGroup<ApprovalRateOfSuccess>
                    disabled={
                        (!isTemplate && isEditingDisabled) || selectedStage.type !== ApprovalStageDTOType.PARALLEL
                    }
                    onChange={onRateOfSuccessTypeChange}
                    value={
                        selectedStage.rateOfSuccess === 100
                            ? ApprovalRateOfSuccess.ALL_POSITIVE
                            : ApprovalRateOfSuccess.PERCENTAGE
                    }
                    options={radioOptions}
                    direction="Row"
                    margin={8}
                />
            </Form.Item>
            <Form.Item
                initialValue={selectedStage.rateOfSuccess}
                name="RateOfSuccess"
                required
                rules={[
                    {
                        required: true,
                        message: intl.formatMessage(messages.thisFieldIsRequired),
                    },
                ]}
            >
                <InputNumber
                    placeholder={intl.formatMessage(messages.selectPositiveVotePercentage)}
                    data-test={isTemplate ? 'approval-template-percentage-input' : 'approval-percentage-input'}
                    size="middle"
                    min={1}
                    max={100}
                    value={selectedStage.rateOfSuccess}
                    controls={false}
                    onChange={onRateOfSuccessChange}
                    disabled={selectedStage.rateOfSuccess === 100 || (!isTemplate && isEditingDisabled)}
                />
            </Form.Item>
        </>
    );
};
