import React, { FC, useState } from 'react';
import iRanger from '@/resources/icons/iRangePicker.svg';
import { dateFormat } from '@/utils/date.time.utils';
import { DatePicker } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import { RangeValue } from 'rc-picker/lib/interface';
import { useIntl } from 'react-intl';
import { DatePickerProvider } from '../../H.O.C/DatePickerProvider/DatePickerProvider.hoc';
import { Icon } from '../Icon/Icon.component';
import messages from './RangePicker.messages';

type TRangePickerProps = {
    className: string;
    onChange: (values: RangeValue<Dayjs>, formatString: [string, string]) => void;
};

const RangePicker: FC<TRangePickerProps> = ({ className, onChange }) => {
    const intl = useIntl();

    const [showPlaceHolder, setShowPlaceHolder] = useState<boolean>(true);

    const handleChange = (e: RangeValue<Dayjs>, formatString: [string, string]) => {
        onChange(e, formatString);
        if (e) {
            setShowPlaceHolder(false);
        } else {
            setShowPlaceHolder(true);
        }
    };

    const getPlaceholder = (showPlaceHolder: boolean): [string, string] => {
        return showPlaceHolder
            ? [intl.formatMessage(messages.startDate), intl.formatMessage(messages.endDate)]
            : ['', ''];
    };

    const getPresets = (): { label: string; value: [Dayjs, Dayjs] }[] => {
        return [
            {
                label: intl.formatMessage(messages.today),
                value: [dayjs(), dayjs()],
            },
            {
                label: intl.formatMessage(messages.currentMonth),
                value: [dayjs().startOf('month'), dayjs().endOf('month')],
            },
        ];
    };

    return (
        <DatePickerProvider>
            <DatePicker.RangePicker
                size="middle"
                className={className}
                format={dateFormat}
                changeOnBlur
                placeholder={getPlaceholder(showPlaceHolder)}
                onChange={handleChange}
                suffixIcon={<Icon spriteSymbol={iRanger} />}
                presets={getPresets()}
            />
        </DatePickerProvider>
    );
};


export default RangePicker;