import {
    NODE_STATISTICS_DELETE,
    NODE_STATISTICS_LOAD,
    NODE_STATISTICS_LOAD_SUCCESS,
} from '@/actionsTypes/nodeStatistics.actionTypes';
import { NodeId, NodeStats } from '@/serverapi/api';
import {
    TNodeStatisticsDeleteAction,
    TNodeStatisticsLoadAction,
    TNodeStatisticsLoadSuccessAction,
} from './nodeStatistics.actions.types';

export const nodeStatisticsLoad = (nodeId: NodeId): TNodeStatisticsLoadAction => ({
    type: NODE_STATISTICS_LOAD,
    payload: {
        nodeId,
    },
});

export const nodeStatisticsLoadSuccess = (statistics: NodeStats, nodeId: NodeId): TNodeStatisticsLoadSuccessAction => ({
    type: NODE_STATISTICS_LOAD_SUCCESS,
    payload: {
        statistics,
        nodeId,
    },
});

export const nodeStatisticsDelete = (nodeId: NodeId): TNodeStatisticsDeleteAction => ({
    type: NODE_STATISTICS_DELETE,
    payload: {
        nodeId,
    },
});
