import React, { FC } from 'react';
import theme from './WidgetSourceInput.scss';
import { Col, Input, Row } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { TabsSelectors } from '@/selectors/tabs.selectors';
import { TWorkspaceTab } from '@/models/tab.types';
import { DashboardSelector } from '@/selectors/entities/dashboard.selectors';
import { TWidget, TWidgetDataset, UpperCaseAlp } from '../../Dashboard.types';
import { addNewDataset, deleteDataset, setWidgetSource } from '@/actions/dashboard.actions';
import { useIntl } from 'react-intl';
import messages from './WidgetSourceInput.messages';
import { Icon } from '@/modules/UIKit';
import icRibbonClose from '../../../../resources/icons/closeIcon.svg';
import { SourceTableSelect } from '../SourceTableSelect/SourceTableSelect';
import { Button } from '@/modules/UIKit/components/Button/Button.component';

const initDatasete: TWidgetDataset[] = [{ col1: '', col2: '', legend: '' }];

export const WidgetSourceInput: FC = () => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const activeTab: TWorkspaceTab | undefined = useSelector(TabsSelectors.getActiveTab);
    const currentWidget: TWidget | undefined = useSelector(DashboardSelector.selectedWidget(activeTab?.nodeId));

    const dataSet: TWidgetDataset[] = currentWidget?.source?.dataset?.length
        ? currentWidget?.source?.dataset
        : initDatasete;

    const setDataSourceHandler = (event: React.ChangeEvent<HTMLInputElement>, key: string, index: number) => {
        if (!activeTab || !currentWidget || !currentWidget.source) return;
        const value = key === 'legend' ? event.currentTarget.value : event.currentTarget.value.toUpperCase();
        if (Object.keys(UpperCaseAlp).includes(value) || key === 'legend') {
            const newDataSet = [...dataSet];
            if (newDataSet[index]) {
                newDataSet[index] = {
                    ...newDataSet[index],
                    [key]: value,
                };
                dispatch(
                    setWidgetSource(activeTab.nodeId, currentWidget.id, {
                        ...currentWidget.source,
                        dataset: newDataSet,
                    }),
                );
            }
        }
    };

    const addNewLineHandler = () => {
        if (!activeTab || !currentWidget) return;
        dispatch(addNewDataset(activeTab.nodeId, currentWidget.id));
    };

    const deleteLineHandler = (index: number) => {
        if (!activeTab || !currentWidget) return;
        dispatch(deleteDataset(activeTab.nodeId, currentWidget.id, index));
    };

    const settingsLines = dataSet.map(({ col1, col2, legend }, index) => (
        <div key={'widgetSourceInput' + index} style={{ marginBottom: '16px' }}>
            <Row className={theme.header}>
                <div className={theme.title}>{intl.formatMessage(messages.line, { number: index + 1 })}</div>
                <Icon
                    className={theme.deleteIcon}
                    spriteSymbol={icRibbonClose}
                    onClick={() => deleteLineHandler(index)}
                />
            </Row>
            <Col className={theme.inputContainer}>
                <div className={theme.label}>{intl.formatMessage(messages.yAxis)}</div>
                <Input
                    value={col1 || ''}
                    onChange={(e) => setDataSourceHandler(e, 'col1', index)}
                    className={theme.input}
                    data-test="dashboard_widget-settings-panel_input-data-table-yaxis"
                />
            </Col>
            <Col className={theme.inputContainer}>
                <div className={theme.label}>{intl.formatMessage(messages.xAxis)}</div>
                <Input
                    value={col2 || ''}
                    onChange={(e) => setDataSourceHandler(e, 'col2', index)}
                    className={theme.input}
                    data-test="dashboard_widget-settings-panel_input-data-table-xaxis"
                />
            </Col>
            <Col className={theme.inputContainer}>
                <div className={theme.label}>{intl.formatMessage(messages.legend)}</div>
                <Input
                    value={legend || ''}
                    onChange={(e) => setDataSourceHandler(e, 'legend', index)}
                    className={theme.input}
                    data-test="dashboard_widget-settings-panel_input-data-table-legend"
                />
            </Col>
        </div>
    ));

    return (
        <>
            <SourceTableSelect
                nodeId={activeTab?.nodeId}
                widgetId={currentWidget?.id}
                tableName={currentWidget?.source?.name}
            />
            {currentWidget?.source && (
                <>
                    {settingsLines}
                    <Row className={theme.addLineButtonWrapper}>
                        <Button onClick={addNewLineHandler} dataTest="dashboard_widget-settings-panel_add-line-button">
                            {intl.formatMessage(messages.addLine)}
                        </Button>
                    </Row>
                </>
            )}
        </>
    );
};
