// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Filters__container___v9gy{display:-webkit-box;display:-ms-flexbox;display:flex;grid-gap:4px;gap:4px}.Filters__select__Bs_nA{width:100%}.Filters__select__Bs_nA button{border-radius:4px;margin-bottom:0}.Filters__ruleSelect__GW1c2{max-width:130px}.Filters__ruleSelect__GW1c2 button{border-radius:4px;margin-bottom:0}.Filters__input__n0cBN{max-width:50px}.Filters__input__n0cBN input{font-size:14px;font-family:\"Segoe UI\";height:32px;color:var(--textGrey)}.Filters__datePicker__skQeT{height:32px;border-radius:4px;-webkit-box-flex:1;-ms-flex-positive:1;flex-grow:1}", "",{"version":3,"sources":["webpack://./src/modules/Report/ReportEditor/ReportEditorFilterBar/FilterComponents/Filters.scss"],"names":[],"mappings":"AAAA,2BACI,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,YAAA,CAAA,OAAA,CAGJ,wBACI,UAAA,CACA,+BACI,iBAAA,CACA,eAAA,CAIR,4BACI,eAAA,CACA,mCACI,iBAAA,CACA,eAAA,CAIR,uBACI,cAAA,CACA,6BACI,cAAA,CACA,sBAAA,CACA,WAAA,CACA,qBAAA,CAIR,4BACI,WAAA,CACA,iBAAA,CACA,kBAAA,CAAA,mBAAA,CAAA,WAAA","sourcesContent":[".container {\r\n    display: flex;\r\n    gap: 4px;\r\n}\r\n\r\n.select {\r\n    width: 100%;\r\n    button {\r\n        border-radius: 4px;\r\n        margin-bottom: 0;\r\n    }\r\n}\r\n\r\n.ruleSelect {\r\n    max-width: 130px;\r\n    button {\r\n        border-radius: 4px;\r\n        margin-bottom: 0;\r\n    }\r\n}\r\n\r\n.input {\r\n    max-width: 50px;\r\n    input {\r\n        font-size: 14px;\r\n        font-family: 'Segoe UI';\r\n        height: 32px;\r\n        color: var(--textGrey);\r\n    }\r\n}\r\n\r\n.datePicker {\r\n    height: 32px;\r\n    border-radius: 4px;\r\n    flex-grow: 1;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "Filters__container___v9gy",
	"select": "Filters__select__Bs_nA",
	"ruleSelect": "Filters__ruleSelect__GW1c2",
	"input": "Filters__input__n0cBN",
	"datePicker": "Filters__datePicker__skQeT"
};
export default ___CSS_LOADER_EXPORT___;
