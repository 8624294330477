import { isNullOrUndefined } from 'is-what';
import { NodeId, Node, SimulationRun, FullModelDefinition, FavoriteNodeDTO } from '../serverapi/api';
import { INode as NodeExtendNodeOriginal } from '../models/bpm/bpm-model-impl.types';
import { DIVIDER } from '../../src/modules/ObjectPropertiesDialog/components/ScriptContext/scriptContext.types';
import { ReportSearchDialogPrefix } from './constants/dbSearch.const';

export const compareNodeIds = (nodeId1?: NodeId | undefined | null, nodeId2?: NodeId | undefined | null): boolean => {
    if (!isNullOrUndefined(nodeId1) && !isNullOrUndefined(nodeId2)) {
        return (
            nodeId1.id === nodeId2.id &&
            nodeId1.repositoryId === nodeId2.repositoryId &&
            nodeId1.serverId === nodeId2.serverId
        );
    }

    return false;
};

export const setServerIdToNodeOriginal = (node: NodeExtendNodeOriginal, serverId: string) => {
    if (!serverId) {
        console.error('server id is empty');
    }
    if (node) {
        node.serverId = serverId;
    }
    setServerIdToNode(node, serverId);
};

export const setServerIdToSimulationRun = (simulationRun: SimulationRun, serverId: string) => {
    return {
        ...simulationRun,
        id: { ...(simulationRun.id || {}), serverId } as NodeId,
        simulationId: { ...(simulationRun.simulationId || {}), serverId } as NodeId,
    };
};

export const setServerIdToNodeInterface = <T extends Node>(node: T, serverId: string): T => {
    if (!serverId) {
        console.error('server id is empty');
    }
    setServerIdToNode(node, serverId);

    return node;
};

const setServerIdToNode = (node: Node, serverId: string) => {
    if (node) {
        if (node.nodeId) {
            node.nodeId.serverId = serverId;
        }
        if (node.parentNodeId && node.parentNodeId.id) {
            node.parentNodeId.serverId = serverId;
        }
    }
};

export const setServerIdToFavoriteNode = (node: FavoriteNodeDTO, serverId: string): FavoriteNodeDTO => {
    if (!serverId) {
        console.error('server id is empty');
    }

    if (node?.nodeId) {
        node.nodeId.serverId = serverId;
    }

    return node;
};

export const setServerIdToModelDefinition = (model: FullModelDefinition, serverId: string): FullModelDefinition => {
    if (model) {
        model.objects?.forEach((o) => setServerIdToNodeInterface(o, serverId));
        model.edges?.map((e) => setServerIdToNodeInterface(e, serverId));
        setServerIdToNodeOriginal({ ...model.model, serverId }, serverId);
    }

    return model;
};

// Todo: при отсутствии каких состовляющих эта функция должна вернуть undefined или пустую строку
// сейчас даже если все три составляющие равны пустой строке функция возвращает __ (невалидное значение)
// допилить функцию
// export const INVALID_NODEID_STRING = '__';

export const toString = (nodeId: NodeId, divider: string = DIVIDER): string => {
    return `${nodeId.serverId || ''}${divider}${nodeId.repositoryId || ''}${divider}${nodeId.id || ''}`;
};

export const toNodeId = (str: string, divider: string = DIVIDER): NodeId => {
    const arr: string[] = str.split(divider);

    return {
        serverId: arr[0] || '',
        repositoryId: arr[1] || '',
        id: arr[2] || '',
    } as NodeId;
};

export const compareStringIds = (id1: string | undefined | null, id2: string | undefined | null): boolean => {
    if (!isNullOrUndefined(id1) && !isNullOrUndefined(id2)) {
        return id1 === id2;
    }

    return false;
};

export const generateCustomNodeId = (nodeId: NodeId, prefix: string): NodeId => ({
    id: `${prefix}${nodeId.id}`,
    repositoryId: `${prefix}${nodeId.repositoryId}`,
    serverId: `${prefix}${nodeId.serverId}`,
});

export const checkIsDialogSearch = (searchNodeId: NodeId) => searchNodeId.id.includes(ReportSearchDialogPrefix);
