import { defineMessages } from 'react-intl';

export default defineMessages({
    // Columns
    methodologyName: {
        id: 'MethodologySetting.methodologyName',
        defaultMessage: 'Название методологии',
    },
    copyPreset: {
        id: 'MethodologySetting.copyPreset',
        defaultMessage: 'Копировать',
    },
    makeDefault: {
        id: 'MethodologySetting.makeDefault',
        defaultMessage: 'Выставить по умолчанию',
    },
    edit: {
        id: 'MethodologySetting.edit',
        defaultMessage: 'Редактировать',
    },
    export: {
        id: 'MethodologySetting.export',
        defaultMessage: 'Экспортировать',
    },
    excelExport: {
        id: 'MethodologySetting.excelExport',
        defaultMessage: 'Экспортировать в Excel',
    },

    db: {
        id: 'MethodologySetting.db',
        defaultMessage: 'Применяется для базы данных',
    },
    modDate: {
        id: 'MethodologySetting.modDate',
        defaultMessage: 'Дата изменения',
    },
    rowActions: {
        id: 'MethodologySetting.rowActions',
        defaultMessage: 'Действия',
    },
    title: {
        id: 'MethodologySetting.title',
        defaultMessage: 'Управление методологиями',
    },
    addMethodology: {
        id: 'MethodologySetting.addMethodology',
        defaultMessage: 'Добавить методологию',
    },
    addPreset: {
        id: 'MethodologySetting.addPreset',
        defaultMessage: 'Создать новую',
    },
    importPreset: {
        id: 'MethodologySetting.importPreset',
        defaultMessage: 'Загрузить',
    },
    newObject: {
        id: 'MethodologySetting.newObject',
        defaultMessage: 'Новый объект',
    },
    newObjectTitle: {
        id: 'MethodologySetting.newObjectTitle',
        defaultMessage: 'Создание нового типа объекта',
    },
    newModel: {
        id: 'MethodologySetting.newModel',
        defaultMessage: 'Новая модель',
    },
    newModelTitle: {
        id: 'MethodologySetting.newModelTitle',
        defaultMessage: 'Создание нового типа модели',
    },
    newPresetImage: {
        id: 'MethodologySetting.newPresetImage',
        defaultMessage: 'Новая иконка',
    },
    newPresetImageTitle: {
        id: 'MethodologySetting.newPresetImageTitle',
        defaultMessage: 'Создание новой иконки',
    },
    newGroup: {
        id: 'MethodologySetting.newGroup',
        defaultMessage: 'Новая группа',
    },
    newModelTypeGroupTitle: {
        id: 'MethodologySetting.newModelTypeGroupTitle',
        defaultMessage: 'Создание новой группы типов моделей',
    },
    newObjectTypeGroupTitle: {
        id: 'MethodologySetting.newObjectTypeGroupTitle',
        defaultMessage: 'Создание новой группы типов объектов',
    },
    newPresetImageGroupTitle: {
        id: 'MethodologySetting.newPresetImageGroupTitle',
        defaultMessage: 'Создание новой группы иконок методологии',
    },
    newEdgeTypeGroupTitle: {
        id: 'MethodologySetting.newEdgeTypeGroupTitle',
        defaultMessage: 'Создание новой группы типов связей',
    },
    newAttributeTypeGroupTitle: {
        id: 'MethodologySetting.newAttributeTypeGroupTitle',
        defaultMessage: 'Создание новой группы типов атрибутов',
    },
    typeObject: {
        id: 'MethodologySetting.typeObject',
        defaultMessage: 'Тип объекта',
    },
    presetImage: {
        id: 'MethodologySetting.presetImage',
        defaultMessage: 'Иконка методологии',
    },
    saveExportedFile: {
        id: 'MethodologySetting.saveExportedFile',
        defaultMessage: 'Сохранить файл экспорта методологии',
    },
    model: {
        id: 'MethodologySetting.model',
        defaultMessage: '. Типы моделей',
    },
    object: {
        id: 'MethodologySetting.object',
        defaultMessage: '. Типы объектов',
    },
    image: {
        id: 'MethodologySetting.image',
        defaultMessage: '. Библиотека иконок',
    },
    attribute: {
        // Используется в экспорте из раздела Типы атрибутов
        id: 'MethodologySetting.attribute',
        defaultMessage: '. Типы атрибутов',
    },
    edge: {
        id: 'MethodologySetting.edge',
        defaultMessage: '. Типы связей',
    },
    newPreset: {
        id: 'MethodologySetting.newPreset',
        defaultMessage: 'Новая методология',
    },
    cpPreset: {
        id: 'MethodologySetting.cpPreset',
        defaultMessage: 'Копирование методологии {name}',
    },
    newModelType: {
        id: 'MethodologySetting.newModelType',
        defaultMessage: 'Тип модели: {type}',
    },
    folderType: {
        id: 'MethodologySetting.folderType',
        defaultMessage: 'Тип папки',
    },
    modelGroupChange: {
        id: 'MethodologySetting.modelGroupChange',
        defaultMessage: 'Изменение группы типов моделей {name}',
    },
    objectGroupChange: {
        id: 'MethodologySetting.objectGroupChange',
        defaultMessage: 'Изменение группы типов объектов {name}',
    },
    presetImageGroupChange: {
        id: 'MethodologySetting.presetImageGroupChange',
        defaultMessage: 'Изменение группы иконок методологии: {name}',
    },
    edgeGroupChange: {
        id: 'MethodologySetting.edgeGroupChange',
        defaultMessage: 'Изменение группы типов связей {name}',
    },
    attributeGroupChange: {
        id: 'MethodologySetting.attributeGroupChange',
        defaultMessage: 'Изменение группы типов атрибутов {name}',
    },
    newSymbol: {
        id: 'MethodologySetting.newSymbol',
        defaultMessage: 'Новый символ',
    },
    newEdge: {
        id: 'MethodologySetting.newEdge',
        defaultMessage: 'Новая связь',
    },
    newEdgeType: {
        id: 'MethodologySetting.newEdgeType',
        defaultMessage: 'Создание нового типа связи',
    },
    edgeType: {
        id: 'MethodologySetting.edgeType',
        defaultMessage: 'Тип связи: {name}',
    },
    delete: {
        id: 'MethodologySetting.delete',
        defaultMessage: 'Удалить',
    },
    idAlreadyTaken: {
        id: 'MethodologySetting.idAlreadyTaken',
        defaultMessage: 'Такой ID уже существует',
    },
    typeSymbol: {
        id: 'MethodologySetting.typeSymbol',
        defaultMessage: 'Тип символа',
    },
    createFolderType: {
        id: 'MethodologySetting.createFolderType',
        defaultMessage: 'Создание нового типа папки',
    },
    newFolderType: {
        id: 'MethodologySetting.newFolderType',
        defaultMessage: 'Новый тип папки',
    },
    id: {
        id: 'MethodologySetting.id',
        defaultMessage: 'ID',
    },
    methodologyDescription: {
        id: 'MethodologySetting.methodologyDescription',
        defaultMessage: 'Описание',
    },
    deleteMethodologyDialogTitle: {
        id: 'MethodologySetting.deleteMethodologyDialogTitle',
        defaultMessage: 'Удалить методологию "{methodologyName}"?',
    },
    deleteMethodologyDialogContent: {
        id: 'MethodologySetting.deleteMethodologyDialogContent',
        defaultMessage: 'Методология используется для БД: {dbNames}',
    },
    cardForKanbanBoard: {
        id: 'MethodologySetting.cardForKanbanBoard',
        defaultMessage: 'Карточка (для Канбан-доски)',
    },
    modelFolderType: {
        id: 'MethodologySetting.modelFolderType',
        defaultMessage: 'моделей',
    },
    objectFolderType: {
        id: 'MethodologySetting.objectFolderType',
        defaultMessage: 'объектов',
    },
    edgeFolderType: {
        id: 'MethodologySetting.edgeFolderType',
        defaultMessage: 'связей',
    },
    attributeFolderType: {
        id: 'MethodologySetting.attributeFolderType',
        defaultMessage: 'атрибутов',
    },
    presetImageFolderType: {
        id: 'MethodologySetting.presetImageFolderType',
        defaultMessage: 'иконок',
    },
    noData: {
        id: 'MethodologySetting.noData',
        defaultMessage: 'Нет данных',
    },
    changeDefaultMethodologyQuestion: {
        id: 'MethodologySetting.changeDefaultMethodologyQuestion',
        defaultMessage: 'Активная методология {defaultPresetName} будет изменена на {newDefaultPresetName}',
    },
    changeDefaultMethodologyTitle: {
        id: 'MethodologySetting.changeDefaultMethodologyTitle',
        defaultMessage: 'Смена методологии',
    },
    changeDefaultMethodologySubmit: {
        id: 'MethodologySetting.changeDefaultMethodologySubmit',
        defaultMessage: 'Применить',
    },
    modelTypeTemplate: {
        id: 'MethodologySetting.modelTypeTemplate',
        defaultMessage: 'Шаблон модели: {name}',
    },
});
