import { FeatureSettingDTO } from '@/serverapi/api';
import { ExperimentalFeaturesEnum, TExperimentalFeatures } from './ExperimentalFeatures.types';

let experimentalFeatures: TExperimentalFeatures = {};

/**
 * Класс отвечающий за работу отключаемой функциональности в приложении
 */
export class ExperimentalFeatures {
    public static setExperimentalFeatures(settings: FeatureSettingDTO[]): void {
        settings.forEach((setting) => {
            experimentalFeatures[setting.code] = !!setting.enable;
        });
    }

    public static clearExperimentalFeatures(): void {
        experimentalFeatures = {};
    }

    public static isAutomaticNavigatorUpdateEnabled(): boolean {
        return experimentalFeatures[ExperimentalFeaturesEnum.AUTOMATIC_NAVIGATOR_UPDATE];
    }

    public static isExtendedModelTypesCreationEnabled(): boolean {
        return experimentalFeatures[ExperimentalFeaturesEnum.EXTENDED_MODEL_TYPES_CREATION];
    }

    public static isUmlSymbolsCreationEnabled(): boolean {
        return experimentalFeatures[ExperimentalFeaturesEnum.UML_SYMBOLS_CREATION];
    }

    public static isNotificationsSubscriptionEnabled(): boolean {
        return experimentalFeatures[ExperimentalFeaturesEnum.NOTIFICATIONS_SUBSCRIPTION];
    }

    public static isEmailNotificationsEnabled(): boolean {
        return experimentalFeatures[ExperimentalFeaturesEnum.EMAIL_NOTIFICATIONS];
    }

    public static isModelTemplatesEnabled(): boolean {
        return experimentalFeatures[ExperimentalFeaturesEnum.MODEL_TEMPLATES];
    }

    public static isSearchAssistantEnabled(): boolean {
        return experimentalFeatures[ExperimentalFeaturesEnum.SEARCH_ASSISTANT];
    }

    public static isSearchAssistantPrompterEnabled(): boolean {
        return experimentalFeatures[ExperimentalFeaturesEnum.SEARCH_ASSISTANT_PROMPTER];
    }
}
