import { TreeNode } from '../models/tree.types';
import { TreeItemType } from '../modules/Tree/models/tree';
import { NodeId, Node, UserDTO } from '../serverapi/api';
import { TChangedProperties } from './changedProperties.types';
import {
    NODE_PROPERTIES_UPDATE,
    TREE_EXPORT_NODE,
    TREE_FILTER,
    TREE_IMPORT_NODE,
    TREE_ITEMS_EXPAND_WITHOUT_LOAD,
    TREE_ITEM_ADD,
    TREE_ITEM_CHILD_ADD,
    TREE_ITEM_CLICK_DRAG_ICON,
    TREE_ITEM_COLLAPSE_ALL,
    TREE_ITEM_COLLAPSE_SUCCESS,
    TREE_ITEM_CONTEXT_MENU_ACTION,
    TREE_ITEM_DELETE,
    TREE_ITEM_DELETE_FROM_SERVER,
    TREE_ITEM_DELETE_FROM_SERVER_REQUEST,
    TREE_ITEM_ERASE_REQUEST,
    TREE_ITEM_DELETE_NODE_SERVER,
    TREE_ITEM_END_DRAG,
    TREE_ITEM_EXPAND,
    TREE_ITEM_EXPAND_SUCCESS,
    TREE_ITEM_FETCH_CHILD_FAILURE,
    TREE_ITEM_FETCH_CHILD_SUCCESS,
    TREE_ITEM_MANAGE_PERMISSIONS_REQUEST,
    TREE_ITEM_MOVE,
    TREE_ITEM_PROPERTIES_CHANGE_REQUEST,
    TREE_ITEM_REFRESH,
    TREE_ITEM_RENAME_CANCEL,
    TREE_ITEM_RENAME_REQUEST,
    TREE_ITEM_SCROLL,
    TREE_ITEM_SECONDARY_ACTION,
    TREE_ITEM_SELECT,
    TREE_ITEM_START_DRAG,
    TREE_ITEM_TITLE_CHANGE_REQUEST,
    TREE_ITEM_UPDATE,
    TREE_PART_FETCH_FAILURE,
    TREE_PART_FETCH_REQUEST,
    TREE_PART_FETCH_SUCCESS,
    TREE_ITEMS_SELECT_SLICE,
    TREE_ITEMS_SELECT_SEVERAL,
    OPEN_CHOOSE_TREE_NODE,
    TREE_ITEM_EXPANDING,
    TREE_ITEM_OPEN_PROPERTY_ACTION,
    TREE_ITEM_UML_CLASS_OBJECT_SAVE,
    TREE_ITEM_UML_OBJECTS_UPDATE,
    TREE_PART_FETCH_ALL_SCRIPTS_REQUEST,
    SET_SHOW_DELETED_OBJECTS_FILTER_REQUEST,
    SET_SHOW_DELETED_OBJECTS_FILTER_SUCCESS,
    TREE_NODE_SUBSCRIBE,
    TREE_NODE_UNSUBSCRIBE,
    TREE_NODE_ADD_SUBSCRIBTION_TO_STORE,
    TREE_NODE_DELETE_SUBSCRIBTION_FROM_STORE,
    TREE_ITEMS_CLEAR_SELECTION,
    APPLY_TREE_FILTERS_BY_PARENT_TYPES,
    TREE_COLLAPSE,
    DELETE_SEVERAL_NODES_FROM_SERVER_REQUEST,
    DELETE_SEVERAL_NODES_FROM_SERVER,
    TREE_SELECT_NODES_BY_NODE_IDS,
    TREE_SELECT_NODES,
    TREE_ITEMS_CLEAR_EXPAND_STATUS,
} from '../actionsTypes/tree.actionTypes';
import {
    TPayloadTreeItemChildAddAction,
    TPayloadTreeItemFetchChildFailureAction,
    TPayloadTreeItemFetchChildSuccessAction,
    TPayloadTreeItemManagePermissionsRequestAction,
    TPayloadTreeItemUpdateAction,
    TNodePropertiesUpdateAction,
    TTreeItemTitleChangeRequestPayload,
    TTreeFilterAction,
    TTreeItemAddAction,
    TTreeItemChildAddAction,
    TTreeItemCollapseAllAction,
    TTreeItemCollapseSuccessAction,
    TTreeItemDeleteAction,
    TTreeItemDeleteNodeFromServer,
    TTreeItemExpandAction,
    TTreeItemExpandSuccessAction,
    TTreeItemFetchChildSuccessAction,
    TTreeItemManagePermissionsRequestAction,
    TTreeItemsExpandWithoutLoadAction,
    TTreeItemTitleChangeRequestAction,
    TTreeItemUpdateAction,
    TTreePartFetchAction,
    TTreePartFetchFailureAction,
    TTreePartFetchSuccessAction,
    TTreeItemFetchChildFailureAction,
    TTreeItemRefreshAction,
    TPayloadTreeItemContextMenuAction,
    TTreeItemContextMenuAction,
    TTreeItemSecondaryAction,
    TPayloadTreeItemSecondaryAction,
    TTreeItemSelectAction,
    TTreeItemScrollAction,
    TTreeItemStartDragAction,
    TTreeItemEndDragAction,
    TTreeItemClickDragIconAction,
    TTreeImportNodeAction,
    TPayloadTreeImportNodeAction,
    TTreeItemPropertiesChangeRequest,
    TTreeItemRenameRequest,
    TTreeItemRenameCancel,
    TTreeItemMoveAction,
    TTreeItemMovePayload,
    TPayloadTreeExportNodeAction,
    TTreeExportNodeAction,
    TTreeItemSelectSliceAction,
    TTreeItemSelectSeveralAction,
    TTreeItemDeleteNodeFromServerRequest,
    TTreeItemEraseRequest,
    TTreeItemDeleteServer,
    TOpenChooseTreeNodeAction,
    TFileReplaceAttributesUpdateAction,
    TTreeItemExpandingAction,
    TTreeItemOpenPropertyAction,
    TTreeItemUmlClassObjectSavePayload,
    TTreeItemUmlClassObjectSaveAction,
    TTreeItemUmlObjectsUpdatePayload,
    TTreeItemUmlObjectsUpdateAction,
    TTreePartFetchAllScriptsAction,
    TSetShowDeletedObjectsFilterRequestAction,
    TSetShowDeletedObjectsFilterSuccessAction,
    TTreeNodeDeleteSubscribtionFromStoreAction,
    TTreeNodeAddSubscribtionToStoreAction,
    TTreeNodeUnsubscribeAction,
    TTreeNodeSubscribeAction,
    TTreeItemsClearSelectionAction,
    TApplyTreeFiltersByParentTypesAction,
    TTreeCollapseAction,
    TDeleteSeveralNodesFromServerRequest,
    TDeleteSeveralNodesFromServer,
    TTreeSelectNodesByNodeIdsAction,
    TTreeSelectNodesAction,
    TTreeItemsClearExpandStatusAction,
} from './tree.actions.types';
import { FILE_REPLACE_ATTRIBUTES_UPDATE } from '../actionsTypes/navigator.actionTypes';
import { DeleteNodeRequest } from '../services/bll/DeleteNodeBllService';
import { TObjectTypesForLink } from '@/services/bll/ExternalLinkBLLService.types';

export const setShowDeletedObjectsFilterRequest = (payload: boolean): TSetShowDeletedObjectsFilterRequestAction => ({
    type: SET_SHOW_DELETED_OBJECTS_FILTER_REQUEST,
    payload,
});

export const setShowDeletedObjectsFilterSuccess = (payload: boolean): TSetShowDeletedObjectsFilterSuccessAction => ({
    type: SET_SHOW_DELETED_OBJECTS_FILTER_SUCCESS,
    payload,
});

export const treeFilter = (checked: boolean, filterTypes: TreeItemType[]): TTreeFilterAction => ({
    type: TREE_FILTER,
    payload: {
        checked,
        filterTypes,
    },
});

export const applyTreeFiltersByParentTypes = (
    parentList: NodeId[],
    elementType: TreeItemType,
): TApplyTreeFiltersByParentTypesAction => ({
    type: APPLY_TREE_FILTERS_BY_PARENT_TYPES,
    payload: {
        parentList,
        elementType,
    },
});

export const treeItemDelete = (nodeId: NodeId): TTreeItemDeleteAction => ({
    type: TREE_ITEM_DELETE,
    payload: { nodeId },
});

export const treeItemDeleteServer = (serverId: string): TTreeItemDeleteServer => ({
    type: TREE_ITEM_DELETE_NODE_SERVER,
    payload: { serverId },
});

export const treeItemDeleteNodeFromServer = (deleteNodeRequest: DeleteNodeRequest): TTreeItemDeleteNodeFromServer => ({
    type: TREE_ITEM_DELETE_FROM_SERVER,
    payload: { deleteNodeRequest },
});

export const treeItemDeleteNodeFromServerRequest = (nodeId: NodeId): TTreeItemDeleteNodeFromServerRequest => ({
    type: TREE_ITEM_DELETE_FROM_SERVER_REQUEST,
    payload: { nodeId },
});

export const deleteSeveralNodesFromServer = (
    deleteNodeRequestData: DeleteNodeRequest[],
): TDeleteSeveralNodesFromServer => ({
    type: DELETE_SEVERAL_NODES_FROM_SERVER,
    payload: { deleteNodeRequestData },
});

export const deleteSeveralNodesFromServerRequest = (nodeIds: NodeId[]): TDeleteSeveralNodesFromServerRequest => ({
    type: DELETE_SEVERAL_NODES_FROM_SERVER_REQUEST,
    payload: { nodeIds },
});

export const treeItemEraseRequest = (nodeId: NodeId): TTreeItemEraseRequest => ({
    type: TREE_ITEM_ERASE_REQUEST,
    payload: { nodeId },
});

export const treeItemManagePermissionsRequest = (
    payload: TPayloadTreeItemManagePermissionsRequestAction,
): TTreeItemManagePermissionsRequestAction => ({
    type: TREE_ITEM_MANAGE_PERMISSIONS_REQUEST,
    payload,
});

export const treeItemCollapseSuccess = (nodesId: NodeId[], treeName: string): TTreeItemCollapseSuccessAction => ({
    type: TREE_ITEM_COLLAPSE_SUCCESS,
    payload: { nodesId, treeName },
});

export const treeItemCollapseAll = (treeName: string): TTreeItemCollapseAllAction => ({
    type: TREE_ITEM_COLLAPSE_ALL,
    payload: { treeName },
});

export const treeItemExpand = (nodeId: NodeId, treeName: string): TTreeItemExpandAction => ({
    type: TREE_ITEM_EXPAND,
    payload: { nodeId, treeName },
});

export const treeItemExpanding = (nodeId: NodeId, treeName: string): TTreeItemExpandingAction => ({
    type: TREE_ITEM_EXPANDING,
    payload: { nodeId, treeName },
});

export const treeItemExpandSuccess = (nodeId: NodeId, treeName: string): TTreeItemExpandSuccessAction => ({
    type: TREE_ITEM_EXPAND_SUCCESS,
    payload: { nodeId, treeName },
});

export const treeItemsExpandWithoutLoad = (nodesId: NodeId[], treeName: string): TTreeItemsExpandWithoutLoadAction => ({
    type: TREE_ITEMS_EXPAND_WITHOUT_LOAD,
    payload: { nodesId, treeName },
});

export const treeItemUpdate = (payload: TPayloadTreeItemUpdateAction): TTreeItemUpdateAction => ({
    type: TREE_ITEM_UPDATE,
    payload,
});

/**
 *
 * @param nodeId - идентификатор обновляемого узла
 * @param type   - тип узла (объект, модель, wiki ..)
 * @param node   - объект содержит отлько те поля которые должны быть изменены
 *
 */
export const nodePropertiesUpdate = (nodeId: NodeId, type: TreeItemType, node: Node): TNodePropertiesUpdateAction => ({
    type: NODE_PROPERTIES_UPDATE,
    payload: { nodeId, type, node },
});

export const fileReplaceAttributesUpdate = (nodeId: NodeId): TFileReplaceAttributesUpdateAction => ({
    type: FILE_REPLACE_ATTRIBUTES_UPDATE,
    payload: {
        nodeId,
    },
});

export const treeItemTitleChangeRequest = (
    payload: TTreeItemTitleChangeRequestPayload,
): TTreeItemTitleChangeRequestAction => ({
    type: TREE_ITEM_TITLE_CHANGE_REQUEST,
    payload,
});

export const treePartFetchRequest = (
    nodeId: NodeId | undefined,
    serverId: string,
    treeName: string,
    user?: UserDTO,
): TTreePartFetchAction => ({
    type: TREE_PART_FETCH_REQUEST,
    payload: { nodeId, serverId, user, treeName },
});

export const treePartFetchAllScriptsRequest = (nodeId: NodeId): TTreePartFetchAllScriptsAction => ({
    type: TREE_PART_FETCH_ALL_SCRIPTS_REQUEST,
    payload: { nodeId },
});

export const treePartFetchSuccess = (
    parentNodeId: NodeId | undefined,
    nodes: Node[],
    serverId: string,
): TTreePartFetchSuccessAction => ({
    type: TREE_PART_FETCH_SUCCESS,
    payload: { parentNodeId, nodes, serverId },
});

export const treePartFetchFailure = (): TTreePartFetchFailureAction => ({ type: TREE_PART_FETCH_FAILURE });

export const treeItemChildAdd = (payload: TPayloadTreeItemChildAddAction): TTreeItemChildAddAction => ({
    type: TREE_ITEM_CHILD_ADD,
    payload,
});

export const treeItemAdd = (item: TreeNode): TTreeItemAddAction => ({
    type: TREE_ITEM_ADD,
    payload: {
        item,
    },
});

export const treeItemFetchChildSuccess = (
    payload: TPayloadTreeItemFetchChildSuccessAction,
): TTreeItemFetchChildSuccessAction => ({
    type: TREE_ITEM_FETCH_CHILD_SUCCESS,
    payload,
});

export const treeItemFetchChildFailure = (
    payload: TPayloadTreeItemFetchChildFailureAction,
): TTreeItemFetchChildFailureAction => ({
    type: TREE_ITEM_FETCH_CHILD_FAILURE,
    payload,
});

export const treeItemRefresh = (nodeId: NodeId | undefined, type?: TreeItemType): TTreeItemRefreshAction => ({
    type: TREE_ITEM_REFRESH,
    payload: { nodeId, type },
});

export const treeItemContextMenuAction = (payload: TPayloadTreeItemContextMenuAction): TTreeItemContextMenuAction => ({
    type: TREE_ITEM_CONTEXT_MENU_ACTION,
    payload,
});

export const treeItemOpenPropertyAction = (
    nodeId: NodeId,
    type: TObjectTypesForLink,
    elementIds?: string[],
): TTreeItemOpenPropertyAction => ({
    type: TREE_ITEM_OPEN_PROPERTY_ACTION,
    payload: { nodeId, type, elementIds },
});

export const treeItemSecondaryAction = (payload: TPayloadTreeItemSecondaryAction): TTreeItemSecondaryAction => ({
    type: TREE_ITEM_SECONDARY_ACTION,
    payload,
});

export const treeItemSelect = (selectedNode?: TreeNode, treeName?: string): TTreeItemSelectAction => {
    return {
        type: TREE_ITEM_SELECT,
        payload: {
            selectedNode,
            treeName,
        },
    };
};

export const treeItemsSelectSlice = (
    selectedNodes: TreeNode[],
    lastClickedNode: TreeNode,
    treeName: string,
): TTreeItemSelectSliceAction => ({
    type: TREE_ITEMS_SELECT_SLICE,
    payload: {
        selectedNodes,
        lastClickedNode,
        treeName,
    },
});

export const treeItemsSelectSeveral = (selectedNode: TreeNode, treeName: string): TTreeItemSelectSeveralAction => ({
    type: TREE_ITEMS_SELECT_SEVERAL,
    payload: {
        selectedNode,
        treeName,
    },
});

export const treeSelectNodesByNodeIds = (
    selectedNodeIds: NodeId[],
    treeName: string,
): TTreeSelectNodesByNodeIdsAction => ({
    type: TREE_SELECT_NODES_BY_NODE_IDS,
    payload: {
        selectedNodeIds,
        treeName,
    },
});

export const treeSelectNodes = (selectedNodes: TreeNode[], treeName: string): TTreeSelectNodesAction => ({
    type: TREE_SELECT_NODES,
    payload: {
        selectedNodes,
        treeName,
    },
});

export const treeItemsClearSelection = (): TTreeItemsClearSelectionAction => ({
    type: TREE_ITEMS_CLEAR_SELECTION,
});

export const treeItemsClearExpandStatus = (): TTreeItemsClearExpandStatusAction => ({
    type: TREE_ITEMS_CLEAR_EXPAND_STATUS,
});

export const treeItemScroll = (scrolledNodeId?: NodeId): TTreeItemScrollAction => ({
    type: TREE_ITEM_SCROLL,
    payload: {
        scrolledNodeId,
    },
});

export const treeItemStartDrag = (selectedNode: TreeNode): TTreeItemStartDragAction => ({
    type: TREE_ITEM_START_DRAG,
    payload: {
        selectedNode,
    },
});

export const treeItemEndDrag = (): TTreeItemEndDragAction => ({
    type: TREE_ITEM_END_DRAG,
    payload: {},
});

export const treeItemClickDragIcon = (selectedNode: TreeNode): TTreeItemClickDragIconAction => ({
    type: TREE_ITEM_CLICK_DRAG_ICON,
    payload: {
        selectedNode,
    },
});

export const treeImportNode = (payload: TPayloadTreeImportNodeAction): TTreeImportNodeAction => ({
    type: TREE_IMPORT_NODE,
    payload,
});

export const treeItemProperiesChangeRequest = (
    node: Node,
    oldNode: Node,
    changedProperties?: TChangedProperties,
): TTreeItemPropertiesChangeRequest => ({
    type: TREE_ITEM_PROPERTIES_CHANGE_REQUEST,
    payload: {
        node,
        oldNode,
        changedProperties,
    },
});

export const treeItemRenameRequest = (nodeId: NodeId): TTreeItemRenameRequest => ({
    type: TREE_ITEM_RENAME_REQUEST,
    payload: {
        nodeId,
    },
});

export const treeItemRenameCancel = (): TTreeItemRenameCancel => ({
    type: TREE_ITEM_RENAME_CANCEL,
});

export const treeItemMove = (payload: TTreeItemMovePayload): TTreeItemMoveAction => ({
    type: TREE_ITEM_MOVE,
    payload,
});

export const treeExportNode = (payload: TPayloadTreeExportNodeAction): TTreeExportNodeAction => ({
    type: TREE_EXPORT_NODE,
    payload,
});

export const openChooseTreeNode = (nodeId: NodeId): TOpenChooseTreeNodeAction => ({
    type: OPEN_CHOOSE_TREE_NODE,
    payload: {
        nodeId,
    },
});

export const treeItemUmlClassObjectSave = (
    payload: TTreeItemUmlClassObjectSavePayload,
): TTreeItemUmlClassObjectSaveAction => ({
    type: TREE_ITEM_UML_CLASS_OBJECT_SAVE,
    payload,
});

export const treeItemUmlObjectsUpdate = (
    payload: TTreeItemUmlObjectsUpdatePayload,
): TTreeItemUmlObjectsUpdateAction => ({
    type: TREE_ITEM_UML_OBJECTS_UPDATE,
    payload,
});

export const treeNodeSubscribe = (nodeId: NodeId): TTreeNodeSubscribeAction => ({
    type: TREE_NODE_SUBSCRIBE,
    payload: { nodeId },
});

export const treeNodeUnsubscribe = (nodeId: NodeId): TTreeNodeUnsubscribeAction => ({
    type: TREE_NODE_UNSUBSCRIBE,
    payload: { nodeId },
});

export const treeNodeAddSubscriptionToStore = (nodeId: NodeId): TTreeNodeAddSubscribtionToStoreAction => ({
    type: TREE_NODE_ADD_SUBSCRIBTION_TO_STORE,
    payload: { nodeId },
});

export const treeNodeDeleteSubscribtionFromStore = (nodeId: NodeId): TTreeNodeDeleteSubscribtionFromStoreAction => ({
    type: TREE_NODE_DELETE_SUBSCRIBTION_FROM_STORE,
    payload: { nodeId },
});

export const treeCollapse = (): TTreeCollapseAction => ({
    type: TREE_COLLAPSE,
});
