
import { TRootState } from '@/reducers/root.reducer.types';
import { createSelector } from 'reselect';
import { TSearchAipState, TSearchDataListItem } from '../reducers/searchAssistantInterface.reducer.types';

export const getState = (state: TRootState) => state.searchAip;

export namespace AssistantInterfaceSearchSelectors {
    export const getSearchResult = (id: string) =>
        createSelector<TRootState, TSearchAipState, TSearchDataListItem[]>(
            getState,
            (state) => {
                return state?.[id]?.searchResult || []
            },
        );
    export const isLoading = (id: string) =>
        createSelector<TRootState, TSearchAipState, boolean>(
            getState,
            (state) => {
                return state?.[id]?.isLoading
            },
        );
    export const isShowingHelp = (id: string) =>
        createSelector<TRootState, TSearchAipState, boolean>(
            getState,
            (state) => {
                return state?.[id]?.isShowingHelp
            },
        );
}
