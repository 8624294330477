import React from 'react';
import { NodeId } from '@/serverapi/api';
import { useIntl } from 'react-intl';
import { closeDialog } from '@/actions/dialogs.actions';
import { DialogType } from '@/modules/DialogRoot/DialogRoot.constants';
import { useDispatch, useSelector } from 'react-redux';
import { TreeSelectors, filterTreeIncludeTypes } from '@/selectors/tree.selectors';
import messages from './SelectModelToCompareDialog.messages';
import { TreeNode } from '@/models/tree.types';
import { Dialog } from '@/modules/UIKit/components/Dialog/Dialog.component';
import { DEFAULT_DIALOG_WIDTH } from '@/config/config';
import { Tree } from '@/modules/Tree/components/Tree/Tree.component';
import { TreeItemType } from '@/modules/Tree/models/tree';
import { SelectedNodesSelector } from '@/selectors/selectedNodes.selectors';
import { treeItemsClearSelection } from '@/actions/tree.actions';
import { DialogFooterButtons } from '@/modules/UIKit/components/DialogFooterButtoms/DialogFooterButtons.component';
import theme from './SelectModelToCompareDialog.scss';
import { openCompareModelsTab } from '@/actions/compareModels.actions';

type TSelectModelToCompareDialogProps = {
    nodeId: NodeId;
};

export const SelectModelToCompareDialog = (props: TSelectModelToCompareDialogProps) => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const treeStructure: TreeNode[] = useSelector(TreeSelectors.treeStructure);
    const selectedNode: TreeNode | undefined = useSelector(
        SelectedNodesSelector.getNode(DialogType.SELECT_MODEL_TO_COMPARE_DIALOG),
    );
    const selectedNodeId = selectedNode?.nodeId || { id: '', repositoryId: '', serverId: '' };
    const allRepos = treeStructure[0]?.children || [];
    const filteredRepos = filterTreeIncludeTypes(allRepos, [
        TreeItemType.Repository,
        TreeItemType.Folder,
        TreeItemType.Model,
    ]);

    const isConfirmDisabled = selectedNode?.type !== TreeItemType.Model;

    const handleCloseDialog = () => {
        dispatch(treeItemsClearSelection());
        dispatch(closeDialog(DialogType.SELECT_MODEL_TO_COMPARE_DIALOG));
    };

    const handleSubmit = () => {
        handleCloseDialog();
        dispatch(openCompareModelsTab({ comparedModelNodeId1: props.nodeId, comparedModelNodeId2: selectedNodeId }));
    };

    const footer = (
        <DialogFooterButtons
            buttons={[
                {
                    key: 'cancel',
                    onClick: handleCloseDialog,
                    value: intl.formatMessage(messages.cancel),
                },

                {
                    key: 'ok',
                    onClick: handleSubmit,
                    value: intl.formatMessage(messages.confirm),
                    visualStyle: 'primary',
                    disabled: isConfirmDisabled,
                },
            ]}
        />
    );

    return (
        <Dialog
            footer={footer}
            className={theme.modal}
            onOk={handleSubmit}
            onCancel={handleCloseDialog}
            title={<div className={theme.title}>{intl.formatMessage(messages.selectModelToCompare)}</div>}
            open
            width={DEFAULT_DIALOG_WIDTH}
        >
            <Tree treeName={DialogType.SELECT_MODEL_TO_COMPARE_DIALOG} data={filteredRepos} disableContextMenu />
        </Dialog>
    );
};
