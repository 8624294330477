export enum ImageNodeType {
    // TODO сохранено устаревшее имя блока для обратной совместимости
    // в дальнейшем необходимо изменить на актуальное - externalImage
    EXTERNAL_IMAGE_NODE_NAME = 'extendedImage',
    FILE_IMAGE_NODE_NAME = 'fileImage',
    WIKI_IMAGE_NODE_NAME = 'wikiImage',
    MODEL_IMAGE_NODE_NAME = 'modelImage',
}

export const IMAGE_NODES = [
    ImageNodeType.EXTERNAL_IMAGE_NODE_NAME,
    ImageNodeType.FILE_IMAGE_NODE_NAME,
    ImageNodeType.WIKI_IMAGE_NODE_NAME,
    ImageNodeType.MODEL_IMAGE_NODE_NAME,
];

export const LINK_NODE_NAME = 'linkBlock';

export const CODE_BLOCK_NODE_NAME = 'codeBlock';

export const DOCUMENT_START_POS = 1;

export const MIN_BLOCK_NODE_SIZE = 2;
