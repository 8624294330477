import { ButtonVisualStyle } from '@/serverapi/api';
import { BUTTON_COLOR_SCHEME_KEY, COLOR_SCHEME_KEY, DEFAULT_BUTTONS_COLORS } from './ButtonStyleColorPicker.constants';

export const getInitialColor = (
    buttonColorScheme: ButtonVisualStyle | undefined,
    buttonType: string,
    eventType: string,
    buttonColorSchemeItem: string,
): string => {
    return (
        buttonColorScheme?.[buttonType]?.[eventType]?.[buttonColorSchemeItem] ||
        DEFAULT_BUTTONS_COLORS[buttonType][eventType][buttonColorSchemeItem]
    );
};

export const setTmpBtnColors = (button: HTMLElement, buttonStyle: ButtonVisualStyle | undefined, type: string) => {
    button.style.setProperty('background', getInitialColor(buttonStyle, type, 'normal', 'background'));
    button.style.setProperty('color', getInitialColor(buttonStyle, type, 'normal', 'text'));
    button.style.setProperty('border', `1px solid ${getInitialColor(buttonStyle, type, 'normal', 'border')}`);

    button.onmouseover = function () {
        button.style.backgroundColor = getInitialColor(buttonStyle, type, 'hover', 'background');
        button.style.color = getInitialColor(buttonStyle, type, 'hover', 'text');
        button.style.border = `1px solid ${getInitialColor(buttonStyle, type, 'hover', 'border')}`;
    };

    button.onmouseleave = function () {
        button.style.backgroundColor = getInitialColor(buttonStyle, type, 'normal', 'background');
        button.style.color = getInitialColor(buttonStyle, type, 'normal', 'text');
        button.style.border = `1px solid ${getInitialColor(buttonStyle, type, 'normal', 'border')}`;
    };
};

export const getBtnItemFieldName = (buttonType: string, eventType: string, item: string) => {
    return [COLOR_SCHEME_KEY, BUTTON_COLOR_SCHEME_KEY, buttonType, eventType, item];
};
