import { defineMessages } from 'react-intl';

export default defineMessages({
    autoLoginFail: {
        id: 'ServerDialog.autoLoginFail',
        defaultMessage: 'Автоматический вход не выполнен',
    },
    invalidCredentials: {
        id: 'ServerDialog.invalidCredentials',
        defaultMessage: 'Введенные логин и пароль не найдены',
    },
    invalidLicense: {
        id: 'ServerDialog.invalidLicense',
        defaultMessage: 'Недостаточно лицензий',
    },
    userBlocked: {
        id: 'ServerDialog.userBlocked',
        defaultMessage: 'Учетная запись заблокирована, обратитесь к администратору',
    },
    userTmpBlocked: {
        id: 'ServerDialog.userTmpBlocked',
        defaultMessage: 'Учетная запись временно заблокирована. Вход возможен через {min} минут',
    },
    serverConnectionError: {
        id: 'ServerDialog.error',
        defaultMessage: 'Не удалось установить соединение с сервером',
    },
    loadDataError: {
        id: 'ServerDialog.loadDataError',
        defaultMessage: 'Не удалось загрузить данные с сервера',
    },
    loadNotationError: {
        id: 'ServerDialog.loadNotationError',
        defaultMessage: 'Не удалось загрузить метаданные с сервера',
    },
    invalidVersion: {
        id: 'ServerDialog.invalidVersion',
        defaultMessage: 'Ваша версия клиентского приложения несовместима с данным сервером',
    },
    requirementLicense: {
        id: 'ServerDialog.requirementLicense',
        defaultMessage: 'Для входа в систему требуется лицензия',
    },
    authorizationIsDisabled: {
        id: 'ServerDialog.authorizationIsDisabled',
        defaultMessage: 'Авторизация отключена',
    },
    passwordExpired: {
        id: 'ServerDialog.passwordExpired',
        defaultMessage: 'Срок действия пароля истёк. Для смены пароля обратитесь к администратору',
    },
    invalidKeycloakToken: {
        id: 'ServerDialog.invalidKeycloakToken',
        defaultMessage: 'Невалидный токен Keycloack',
    },
    technicalUserError: {
        id: 'ServerDialog.technicalUserError',
        defaultMessage: 'Вход технической учетной записи запрещен',
    },
});
