import React, { FC, useState } from 'react';
import { useIntl } from 'react-intl';
import messages from '../../messages/InstancePermissionsDialog.messages';
import { Dialog } from '../../../UIKit/components/Dialog/Dialog.component';
import { FormGroup } from '../../../UIKit/components/Forms/components/FormGroup/FormGroup.component';
import { Input, Form, Tabs } from 'antd';
import { Icon } from '../../../UIKit/components/Icon/Icon.component';
import theme from './InstancePermissionsDialog.scss';
import icDeleteBinActive from '../../../../resources/icons/ic-deleteBinActive.svg';
import iconAdd from '../../../../resources/icons/ic-add.svg';
import { InstancePermissionsTable } from '../PermissionTables/InstancePermissionsTable.component';
import { ChangeEvent } from 'react';
import { TreeItemType } from '../../../Tree/models/tree';
import { SearchOutlined } from '@ant-design/icons';
import { TInstancePermissionsDialogProps } from './InstancePermissionsDialog.types';
import { AllPermissionsResultTable } from '../PermissionTables/AllPermissionsTable.component';
import { AdditionalPermissionsTable } from '../PermissionTables/AdditionalPermissionsTable.component';
import { DialogFooterButtons } from '../../../UIKit/components/DialogFooterButtoms/DialogFooterButtons.component';
import { Button } from '@/modules/UIKit/components/Button/Button.component';
import { getEntityIcon, getPreTitleMessage } from './InstancePermissionsUtils';

const { TabPane } = Tabs;

export const InstancePermissionsDialog: FC<TInstancePermissionsDialogProps> = (props) => {
    const {
        nodeId,
        loading,
        changed,
        title,
        objectType,
        principalPermissions,
        onSubmit,
        onClose,
        onChange,
        onAddPrincipal,
        onDelete,
        open,
        principals,
        error,
    } = props;

    const [selectedIds, setSelectedIds] = useState<number[]>([]);
    const [filter, setFilter] = useState<string>('');
    const intl = useIntl();

    const onFilterChange = ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
        setFilter(value.trim());
    };

    const preTitleMessage = getPreTitleMessage(objectType, intl);

    const entityIcon = getEntityIcon(objectType);

    const dialogTitle = (
        <>
            {preTitleMessage} <Icon className={theme.subTitleIcon} spriteSymbol={entityIcon} /> {title}
        </>
    );

    const instancePermError = error === '' ? intl.formatMessage(messages.fetchError) : error;

    const footer = (
        <DialogFooterButtons
            buttons={[
                {
                    key: 'cancel',
                    onClick: onClose,
                    value: intl.formatMessage(messages.cancelButton),
                    dataTest: 'permission-dialog_cancel-button',
                },
                {
                    key: 'ok',
                    onClick: onSubmit,
                    value: intl.formatMessage(messages.saveButton),
                    visualStyle: 'primary',
                    dataTest: 'permission-dialog_confirm-button',
                    disabled: !changed,
                },
            ]}
        />
    );

    return (
        <Dialog
            className={theme.dialog}
            title={dialogTitle}
            open={open}
            width="960px"
            footer={footer}
            onCancel={onClose}
            onOk={() => {
                if (changed) onSubmit();
            }}
        >
            <FormGroup className={theme.formGroup}>
                <Tabs>
                    <TabPane tab={intl.formatMessage(messages.currentPermTab)} key="currentPermTab">
                        <Form.Item>
                            <div className={theme.searchAndActionsWrapper}>
                                <Input
                                    suffix={<SearchOutlined />}
                                    onChange={(e) => onFilterChange(e)}
                                    className={theme.searchInput}
                                />
                                <div className={theme.permissionActions}>
                                    <Button
                                        disabled={loading}
                                        visualStyle="primary"
                                        onClick={onAddPrincipal}
                                        dataTest="user-add-permission-button"
                                        icon={iconAdd}
                                    >
                                        {intl.formatMessage(messages.addButton)}
                                    </Button>
                                    <div className={theme.permissionActionsInner}>
                                        <Button
                                            disabled={loading || selectedIds.length === 0}
                                            danger
                                            onClick={() => {
                                                onDelete([...selectedIds] as number[]);
                                                setSelectedIds([]);
                                            }}
                                            dataTest="delete-user-rights-button"
                                            icon={icDeleteBinActive}
                                        >
                                            {intl.formatMessage(messages.deleteButton)}
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </Form.Item>
                        <Form.Item data-test="permission-dialog_permission-table">
                            <InstancePermissionsTable
                                selectedIds={selectedIds}
                                loading={loading}
                                data={principalPermissions}
                                onChange={onChange}
                                principals={principals}
                                onSelectionChanged={(_selectedIds: number[]) => setSelectedIds(_selectedIds)}
                                filter={filter}
                                error={instancePermError}
                            />
                        </Form.Item>
                    </TabPane>
                    <TabPane tab={intl.formatMessage(messages.resultPermTab)} key="resultPermTab">
                        <AllPermissionsResultTable nodeId={nodeId} principals={principals} />
                    </TabPane>
                    {objectType === TreeItemType.Repository || objectType === TreeItemType.ScriptFolder ? (
                        <TabPane tab={intl.formatMessage(messages.additionalPermTab)} key="additionalPermTab">
                            <AdditionalPermissionsTable
                                data={principalPermissions}
                                onChange={onChange}
                                principals={principals}
                            />
                        </TabPane>
                    ) : null}
                </Tabs>
            </FormGroup>
        </Dialog>
    );
};
