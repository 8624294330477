import React, { FC, useState } from 'react';
import { useIntl } from 'react-intl';
import { Table } from 'antd';
import messages from './messages/DataTypesTab.messages';
import theme from './DataTypesTab.scss';
import { TabHeader } from '../Header/TabHeader.component';
import { Icon } from '../../../../../UIKit/components/Icon/Icon.component';
import { TSpriteSymbol } from '../../../../../../models/spriteSymbol.types';
import { FolderTypeValidNodeTypesEnum } from '../../../../../../serverapi/api';
import { Checkbox } from '@/modules/UIKit/components/Checkbox/Checkbox.component';

type TDataType = {
    id: string;
    name: string;
    icon: TSpriteSymbol;
};

type TDataTypesTabProps = {
    dataTypes: TDataType[];
    validTypes: FolderTypeValidNodeTypesEnum[];
    setValidTypes: (validTypes: FolderTypeValidNodeTypesEnum[]) => void;
    isAllowAny: boolean;
    setIsAllowAny: (isAllowAny: boolean) => void;
};

export const DataTypesTab: FC<TDataTypesTabProps> = ({
    dataTypes,
    validTypes,
    setValidTypes,
    isAllowAny,
    setIsAllowAny,
}) => {
    const intl = useIntl();
    const [filter, setFilter] = useState<string>('');
    const isRowDisabled = isAllowAny;

    const searchElements = (type: TDataType): boolean => {
        const searchText = filter.toLowerCase().trim();
        const searchFields = [type.id, type.name];
        const found = searchFields.some((field) => field?.toLowerCase().trim().includes(searchText));

        return found;
    };

    const filteredTypes = dataTypes.filter(searchElements);

    function handleCheck(dataId: FolderTypeValidNodeTypesEnum, isChecked: boolean) {
        if (isChecked) {
            setValidTypes(validTypes.filter((el) => el !== dataId));
        } else {
            setValidTypes([...validTypes, dataId]);
        }
    }

    function handleCheckAll() {
        if (isAllowAny) {
            setValidTypes([]);
            setIsAllowAny(false);
        } else {
            const allDataIds = dataTypes.map((mt) => mt.id);
            setValidTypes(allDataIds as FolderTypeValidNodeTypesEnum[]);
            setIsAllowAny(true);
        }
    }

    function renderCheckboxColumn(dataId: FolderTypeValidNodeTypesEnum) {
        const isChecked = validTypes.includes(dataId) || isAllowAny;

        return (
            <div className={theme.checkboxTableWrapper}>
                <Checkbox
                    disabled={isRowDisabled}
                    checked={isChecked}
                    onChange={() => handleCheck(dataId, isChecked)}
                />
            </div>
        );
    }

    function renderNameWithIconColumn(row: TDataType) {
        return (
            <div className={theme.nameWithIcon}>
                <Icon spriteSymbol={row.icon} />
                <span className={theme.nameRow}>{row.name}</span>
            </div>
        );
    }

    const columns = [
        {
            title: intl.formatMessage(messages.name),
            id: 'multilingualName',
            key: 'name',
            render: renderNameWithIconColumn,
        },
        {
            title: intl.formatMessage(messages.id),
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: intl.formatMessage(messages.allowed),
            dataIndex: 'id',
            key: 'checkbox',
            render: renderCheckboxColumn,
            width: 50,
        },
    ];

    return (
        <section className={theme.container}>
            <div className={theme.header}>
                <TabHeader onSearchChange={setFilter} />
                <div className={theme.checkboxWrapper}>
                    <Checkbox checked={isAllowAny} onChange={handleCheckAll}>{intl.formatMessage(messages.allowAll)}</Checkbox>
                </div>
            </div>
            <Table
                className={theme.table}
                rowKey={(record) => record.id}
                dataSource={filteredTypes}
                columns={columns}
                pagination={false}
                scroll={{ y: 'max-content', x: 'max-content' }}
            />
        </section>
    );
};
