import { AipAlias, AipSearch, SearchResponse } from '../../../serverapi/api';
import { getOrigin, replaceLastSlash } from '../../../utils/url.utils';
import { ApiBundle } from '../../../services/api/api-bundle';

export class AipDaoService {

    private static getApi() {
        const hostName = replaceLastSlash(getOrigin());
        const bundle: ApiBundle = new ApiBundle(hostName);

        return bundle;
    }

    public static async getAll(): Promise<Array<AipAlias>> {
        const api = this.getApi();
        const aliases = await api.aip.getAll();

        return aliases;
    }

    public static async create(alias: AipAlias): Promise<AipAlias> {
        const api = this.getApi();
        const resp = await api.aip.create({body: alias});

        return resp;
    }

    public static async deleteById(id: string): Promise<void> {
        const api = this.getApi();
        const resp = await api.aip.deleteById({id});

        return resp;
    }

    public static async search(req: AipSearch): Promise<SearchResponse> {
        const api = this.getApi();
        const resp = await api.aip.search({body: req});

        return resp;
    }
}
