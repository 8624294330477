import React, { useState } from 'react';
import theme from '../ApprovalTab/approvalTab.scss';
import { useIntl } from 'react-intl';
import messages from '../messages/approval.messages';
import { TApprovalTableData } from '../ApprovalsTable/approvalsTable.types';
import { isEqual } from 'lodash-es';
import { ApprovalDTOStatusEnum, NodeId } from '@/serverapi/api';
import { useDispatch, useSelector } from 'react-redux';
import { ApprovalSelectors } from '@/selectors/approval.selectors';
import { RangeValue } from 'rc-picker/lib/interface';
import { Dayjs } from 'dayjs';
import { ApprovalTable } from '../ApprovalsTable/ApprovalTable.component';
import { DeleteButton } from '../ApprovalsTable/DeleteButton.component';
import { Select } from '../../UIKit/components/Select/Select.component';
import { CreatorSelector } from '../ApprovalsTable/CreatorsSelector.component';
import { openDialog } from '@/actions/dialogs.actions';
import { DialogType } from '@/modules/DialogRoot/DialogRoot.constants';
import { deleteApprovals } from '@/actions/approval.actions';
import { RangePicker } from '@/modules/UIKit';

type TStatusArr = ApprovalDTOStatusEnum[];
const statusArr: TStatusArr = ['APPROVED', 'NOT_APPROVED', 'CANCELLED', 'IN_PROCESS', 'DRAFT'];

export const ApprovalPanel = () => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const [statusFilter, setStatusFilter] = useState<TStatusArr>([]);
    const [creatorFilter, setCreatorFilter] = useState<string[]>([]);
    const [createdAtRangeFilter, setCreatedAtRangeFilter] = useState<RangeValue<Dayjs>>(null);
    const filteredTableData: TApprovalTableData[] = useSelector(
        ApprovalSelectors.getFilteredApprovalTableData(statusFilter, creatorFilter, createdAtRangeFilter),
        (oldData: TApprovalTableData[], newData: TApprovalTableData[]) => isEqual(oldData, newData),
    );
    const checkedNodeIds: NodeId[] = useSelector(ApprovalSelectors.getCheckedApprovalIds);
    const isDisabled: boolean = !checkedNodeIds.length;

    const handleChange = (e: RangeValue<Dayjs>) => {
        setCreatedAtRangeFilter(e);
    };

    const handleChangeStatusFilter = (statusArr: TStatusArr) => {
        setStatusFilter(statusArr);
    };

    const handleClick = () => {
        dispatch(
            openDialog(DialogType.CONFIRMATION, {
                onSubmit: () => {
                    dispatch(
                        deleteApprovals({
                            approvalNodeIds: checkedNodeIds,
                        }),
                    );
                },
                title: intl.formatMessage(messages.deletingApproval),
                question: intl.formatMessage(messages.deletingApprovalDescription),
                OKButtonText: intl.formatMessage(messages.delete),
            }),
        );
    };

    return (
        <div className={theme.approvalTab}>
            <div className={theme.headerContainer}>
                <div className={theme.headerSelectWrapper}>
                    <div className={theme.rangePickerContainer}>
                        <RangePicker className={theme.rangePicker} onChange={handleChange} />
                    </div>
                    <CreatorSelector creatorFilter={creatorFilter} setCreatorFilter={setCreatorFilter} />
                    <div className={theme.selectContainer}>
                        <Select
                            isMultiSelect
                            value={intl.formatMessage(messages.status)}
                            onChange={handleChangeStatusFilter}
                            data-test="filter-status"
                        >
                            {statusArr.map((status) => (
                                <Select.Option
                                    key={status}
                                    value={status}
                                    checked={statusFilter.includes(status)}
                                    label={intl.formatMessage(messages[status])}
                                />
                            ))}
                        </Select>
                    </div>
                </div>
                <DeleteButton handleClick={handleClick} isDisabled={isDisabled} />
            </div>
            <div className={theme.tableContainer}>
                <ApprovalTable approvalTableData={filteredTableData} />
            </div>
        </div>
    );
};
