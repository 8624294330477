import { defineMessages } from 'react-intl';

export default defineMessages({
    title: {
        id: 'ObjectPropertiesDialog.title',
        defaultMessage: 'Свойства {type}:',
    },
    objectInstanceAttributesTabTitle: {
        id: 'ObjectPropertiesDialog.objectInstanceAttributesTabTitle',
        defaultMessage: 'Атрибуты экземпляра',
    },
    nodeAttributesTabTitle: {
        id: 'ObjectPropertiesDialog.attributesNodeTabTitle',
        defaultMessage: 'Имя и Атрибуты',
    },
    unknownAttribute: {
        id: 'ObjectPropertiesDialog.unknownAttribute',
        defaultMessage: 'Неизвестный атрибут',
    },
    objectInstances: {
        id: 'ObjectPropertiesDialog.objectInstances',
        defaultMessage: 'Экземпляры объекта',
    },
    detailingTabTitle: {
        id: 'ObjectPropertiesDialog.detailingTabTitle',
        defaultMessage: 'Декомпозиции',
    },
    parents: {
        id: 'ObjectPropertiesDialog.parents',
        defaultMessage: 'Элемент - родитель',
    },
    settings: {
        id: 'ObjectPropertiesDialog.settings',
        defaultMessage: 'Настройки',
    },
    deleteButton: {
        id: 'ObjectPropertiesDialog.deleteButton',
        defaultMessage: 'Удалить атрибуты',
    },
    addButton: {
        id: 'ObjectPropertiesDialog.addAttributesButton',
        defaultMessage: 'Добавить атрибуты',
    },
    name: {
        id: 'ObjectPropertiesDialog.name',
        defaultMessage: 'Наименование',
    },
    attributes: {
        id: 'ObjectPropertiesDialog.attributes',
        defaultMessage: 'Атрибуты',
    },
    attributeName: {
        id: 'ObjectPropertiesDialog.attributeName',
        defaultMessage: 'Название',
    },
    attribute: {
        id: 'ObjectPropertiesDialog.attribute',
        defaultMessage: 'Значение',
    },
    elementName: {
        id: 'ObjectPropertiesDialog.elementName',
        defaultMessage: 'Имя элемента',
    },
    elementType: {
        id: 'ObjectPropertiesDialog.elementType',
        defaultMessage: 'Тип элемента',
    },
    modelName: {
        id: 'ObjectPropertiesDialog.modelName',
        defaultMessage: 'Имя модели',
    },
    onModel: {
        id: 'ObjectPropertiesDialog.onModel',
        defaultMessage: 'На модели',
    },
    object: {
        id: 'ObjectPropertiesDialog.object',
        defaultMessage: 'Объект',
    },
    count: {
        id: 'ObjectPropertiesDialog.count',
        defaultMessage: 'Количество',
    },
    symbols: {
        id: 'ObjectPropertiesDialog.symbols',
        defaultMessage: 'Символы',
    },
    changeMode: {
        id: 'ObjectPropertiesDialog.changeMode',
        defaultMessage: 'Переключить режим отображения',
    },
    modelPath: {
        id: 'ObjectPropertiesDialog.modelPath',
        defaultMessage: 'Путь',
    },
    modelType: {
        id: 'ObjectPropertiesDialog.modelType',
        defaultMessage: 'Тип',
    },
    create: {
        id: 'ObjectPropertiesDialog.create',
        defaultMessage: 'Создать',
    },
    needLicense: {
        id: 'ObjectPropertiesDialog.needLicense',
        defaultMessage: 'Для этого действия вам необходима лицензия',
    },
    open: {
        id: 'ObjectPropertiesDialog.open',
        defaultMessage: 'Открыть',
    },
    delete: {
        id: 'ObjectPropertiesDialog.delete',
        defaultMessage: 'Удалить',
    },
    okButton: {
        id: 'ObjectPropertiesDialog.okButton',
        defaultMessage: 'Сохранить',
    },
    cancelButton: {
        id: 'ObjectPropertiesDialog.cancelButton',
        defaultMessage: 'Отмена',
    },
    emptyValue: {
        id: 'ObjectPropertiesDialog.emptyValue',
        defaultMessage: 'Нет данных',
    },
    FOLDER: {
        id: 'ObjectPropertiesDialog.FOLDER',
        defaultMessage: 'папки',
    },
    EDGE: {
        id: 'ObjectPropertiesDialog.EDGE',
        defaultMessage: 'линии связи',
    },
    EDGE_DEFENITION: {
        id: 'ObjectPropertiesDialog.EDGE_DEFENITION',
        defaultMessage: 'определения связи',
    },
    MODEL: {
        id: 'ObjectPropertiesDialog.MODEL',
        defaultMessage: 'модели',
    },
    OBJECT: {
        id: 'ObjectPropertiesDialog.OBJECT',
        defaultMessage: 'объекта',
    },
    DB: {
        id: 'ObjectPropertiesDialog.DB',
        defaultMessage: 'базы данных',
    },
    SCRIPT: {
        id: 'ObjectPropertiesDialog.SCRIPT',
        defaultMessage: 'скрипта',
    },
    WIKI: {
        id: 'ObjectPropertiesDialog.WIKI',
        defaultMessage: 'wiki-страницы',
    },
    SPREADSHEET: {
        id: 'ObjectPropertiesDialog.SPREADSHEET',
        defaultMessage: 'таблицы',
    },
    KANBAN: {
        id: 'ObjectPropertiesDialog.KANBAN',
        defaultMessage: 'канбана',
    },
    MATRIX: {
        id: 'ObjectPropertiesDialog.MATRIX',
        defaultMessage: 'матрицы',
    },
    REPORT: {
        id: 'ObjectPropertiesDialog.REPORT',
        defaultMessage: 'справочник',
    },
    SIMULATION: {
        id: 'ObjectPropertiesDialog.SIMULATION',
        defaultMessage: 'имитационной модели',
    },
    SCRIPT_FOLDER: {
        id: 'ObjectPropertiesDialog.SCRIPT_FOLDER',
        defaultMessage: 'папки скриптов',
    },
    FILE_FOLDER: {
        id: 'ObjectPropertiesDialog.FILE_FOLDER',
        defaultMessage: 'папки файлов',
    },
    FILE: {
        id: 'ObjectPropertiesDialog.FILE',
        defaultMessage: 'файла',
    },
    DASHBOARD: {
        id: 'ObjectPropertiesDialog.DASHBOARD',
        defaultMessage: 'дашборда',
    },
    noInstance: {
        id: 'ObjectPropertiesDialog.noInstance',
        defaultMessage: 'нет экземпляров',
    },
    onlyInEditModeAvailable: {
        id: 'ObjectPropertiesDialog.onlyInEditModeAvailable',
        defaultMessage: 'Доступно только в режиме редактирования',
    },
    noAccess: {
        id: 'ObjectPropertiesDialog.noAccess',
        defaultMessage: 'Действие запрещено профилем пользователя',
    },
    attributeIsNotReadable: {
        id: 'ObjectPropertiesDialog.attributeIsNotReadable',
        defaultMessage: 'Нет доступа',
    },
    multiLangUrlDialogHeader: {
        id: 'ObjectPropertiesDialog.multiLangUrlDialogHeader',
        defaultMessage: 'Введите значения на разных языках',
    },
    multiLangUrlDialogName: {
        id: 'ObjectPropertiesDialog.multiLangUrlDialogName',
        defaultMessage: 'Имя',
    },
    multiLangUrlDialogUrl: {
        id: 'ObjectPropertiesDialog.multiLangUrlDialogUrl',
        defaultMessage: 'Адрес',
    },
    floatingAttributes: {
        id: 'ObjectPropertiesDialog.floatingAttributes',
        defaultMessage: 'Атрибуты вокруг объекта',
    },
    floatingEdgeAttributes: {
        id: 'ObjectPropertiesDialog.floatingEdgeAttributes',
        defaultMessage: 'Атрибуты около связи',
    },
    defaultFolderName: {
        id: 'ObjectPropertiesDialog.defaultFolderName',
        defaultMessage: 'Стандартная папка',
    },
    methodologySelectionTabTitle: {
        id: 'ObjectPropertiesDialog.methodologySelectionTabTitle',
        defaultMessage: 'Выбор методологии',
    },
    additionalTabTitle: {
        id: 'ObjectPropertiesDialog.additionalTabTitle',
        defaultMessage: 'Дополнительные функции',
    },
    methodologyName: {
        id: 'ObjectPropertiesDialog.methodologyName',
        defaultMessage: 'Название',
    },
    desc: {
        id: 'ObjectPropertiesDialog.desc',
        defaultMessage: 'Описание',
    },
    changeDate: {
        id: 'ObjectPropertiesDialog.changeDate',
        defaultMessage: 'Дата изменения',
    },
    maxSizeFile: {
        id: 'ObjectPropertiesDialog.maxSizeFile',
        defaultMessage: 'Максимальный размер файла для загрузки, МБ:',
    },
    receptionName: {
        id: 'ObjectPropertiesDialog.receptionName',
        defaultMessage: 'Имя приема',
    },
    receptionVisibility: {
        id: 'ObjectPropertiesDialog.receptionVisibility',
        defaultMessage: 'Видимость приема',
    },
    receptionType: {
        id: 'ObjectPropertiesDialog.receptionType',
        defaultMessage: 'Тип приема',
    },
    receptionAdd: {
        id: 'ObjectPropertiesDialog.receptionAdd',
        defaultMessage: 'Добавить прием',
    },
    receptionsDelete: {
        id: 'ObjectPropertiesDialog.receptionsDelete',
        defaultMessage: 'Удалить приемы',
    },
    manualFilling: {
        id: 'ObjectPropertiesDialog.manualFilling',
        defaultMessage: 'Ручное заполнение',
    },
    selectFromList: {
        id: 'ObjectPropertiesDialog.selectFromList',
        defaultMessage: 'Выбор из списка',
    },
    selectFromNavigator: {
        id: 'ObjectPropertiesDialog.selectFromNavigator',
        defaultMessage: 'Выбор из навигатора',
    },
    classReceptions: {
        id: 'ObjectPropertiesDialog.classReceptions',
        defaultMessage: 'Приемы класса',
    },
    classMethods: {
        id: 'ObjectPropertiesDialog.classMethods',
        defaultMessage: 'Методы класса',
    },
    classProperties: {
        id: 'ObjectPropertiesDialog.classProperties',
        defaultMessage: 'Свойства класса',
    },
    propertyAdd: {
        id: 'ObjectPropertiesDialog.propertyAdd',
        defaultMessage: 'Добавить свойство',
    },
    propertiesDelete: {
        id: 'ObjectPropertiesDialog.propertiesDelete',
        defaultMessage: 'Удалить свойства',
    },
    propertyType: {
        id: 'ObjectPropertiesDialog.propertyType',
        defaultMessage: 'Тип свойства',
    },
    propertyVisibility: {
        id: 'ObjectPropertiesDialog.propertyVisibility',
        defaultMessage: 'Видимость свойства',
    },
    propertyName: {
        id: 'ObjectPropertiesDialog.propertyName',
        defaultMessage: 'Имя свойства',
    },
    methodDelete: {
        id: 'ObjectPropertiesDialog.methodDelete',
        defaultMessage: 'Удалить метод',
    },
    methodAdd: {
        id: 'ObjectPropertiesDialog.methodAdd',
        defaultMessage: 'Добавить метод',
    },
    methodReturnType: {
        id: 'ObjectPropertiesDialog.methodReturnType',
        defaultMessage: 'Тип возвращаемого значения метода',
    },
    methodVisibility: {
        id: 'ObjectPropertiesDialog.methodVisibility',
        defaultMessage: 'Видимость метода',
    },
    methodName: {
        id: 'ObjectPropertiesDialog.methodName',
        defaultMessage: 'Имя метода',
    },
    parametersDelete: {
        id: 'ObjectPropertiesDialog.parametersDelete',
        defaultMessage: 'Удалить параметры',
    },
    parameterType: {
        id: 'ObjectPropertiesDialog.parameterType',
        defaultMessage: 'Тип параметра',
    },
    parameterAdd: {
        id: 'ObjectPropertiesDialog.parameterAdd',
        defaultMessage: 'Добавить параметр',
    },
    defaultValue: {
        id: 'ObjectPropertiesDialog.defaultValue',
        defaultMessage: 'Значение по умолчанию',
    },
    parameterName: {
        id: 'ObjectPropertiesDialog.parameterName',
        defaultMessage: 'Имя параметра',
    },
    newProperty: {
        id: 'ObjectPropertiesDialog.newProperty',
        defaultMessage: 'Новое свойство',
    },
    newMethod: {
        id: 'ObjectPropertiesDialog.newMethod',
        defaultMessage: 'Новый метод',
    },
    newParameter: {
        id: 'ObjectPropertiesDialog.newParameter',
        defaultMessage: 'Новый параметр',
    },
    newReception: {
        id: 'ObjectPropertiesDialog.newReception',
        defaultMessage: 'Новый прием',
    },
    edgeInstances: {
        id: 'ObjectPropertiesDialog.edgeInstances',
        defaultMessage: 'Экземпляры связи',
    },
    scriptRunningContext: {
        id: 'ObjectPropertiesDialog.scriptRunningContext',
        defaultMessage: 'Контекст запуска',
    },
    approvals: {
        id: 'ObjectPropertiesDialog.approvals',
        defaultMessage: 'Согласования',
    },
    statistics: {
        id: 'ObjectPropertiesDialog.statistics',
        defaultMessage: 'Статистика',
    },
});
