import React, { FC, useState } from 'react';
import { useIntl } from 'react-intl';
import { Table } from 'antd';
import messages from './messages/FoldersTab.messages';
import theme from './FolderTypesTab.scss';
import { TabHeader } from '../Header/TabHeader.component';
import icFolder from '../../../../../../resources/icons/ic-tree-folder.svg';
import { FolderType, InternationalString, PresetImage } from '../../../../../../serverapi/api';
import { useSelector } from 'react-redux';
import { getCurrentLocale } from '../../../../../../selectors/locale.selectors';
import { Icon } from '../../../../../UIKit/components/Icon/Icon.component';
import { LocalesService } from '../../../../../../services/LocalesService';
import { PresetImageSelectors } from '../../../../../../selectors/presetSettings/presetImage.selectors';
import { Checkbox } from '@/modules/UIKit/components/Checkbox/Checkbox.component';

type TChildElementsFoldersTabProps = {
    serverId: string;
    presetId: string;
    folderTypes: FolderType[];
    validTypes: string[];
    setValidFolderTypes: (validTypes: string[]) => void;
    isAllowAny: boolean;
    setIsAllowAny: (isAllowAny: boolean) => void;
};

export const FolderTypesTab: FC<TChildElementsFoldersTabProps> = ({
    serverId,
    presetId,
    folderTypes,
    validTypes,
    setValidFolderTypes,
    isAllowAny,
    setIsAllowAny,
}) => {
    const intl = useIntl();

    const [filter, setFilter] = useState<string>('');
    const currentLocale = useSelector(getCurrentLocale);
    const presetImages: PresetImage[] = useSelector(PresetImageSelectors.listAllByPreset(serverId, presetId ));
    const isRowDisabled = isAllowAny;

    const searchElements = (type: FolderType): boolean => {
        const name = LocalesService.internationalStringToString(type.multilingualName, currentLocale);
        const description = LocalesService.internationalStringToString(type.multilingualDescription, currentLocale);
        const searchText = filter.toLowerCase().trim();
        const searchFields = [name, description, type.id];
        const found = searchFields.some((field) => field?.toLowerCase().trim().includes(searchText));

        return found;
    };

    const filteredTypes = folderTypes.filter(searchElements);

    function handleCheck(dataId: string, isChecked: boolean) {
        if (isChecked) {
            setValidFolderTypes(validTypes.filter((el) => el !== dataId));
        } else {
            setValidFolderTypes([...validTypes, dataId]);
        }
    }

    function handleCheckAll() {
        if (isAllowAny) {
            setValidFolderTypes([]);
            setIsAllowAny(false);
        } else {
            const allDataIds = folderTypes.map((mt) => mt.id);
            setValidFolderTypes(allDataIds);
            setIsAllowAny(true);
        }
    }

    function renderCheckboxColumn(dataId: string) {
        const isChecked = validTypes.includes(dataId) || isAllowAny;

        return (
            <div className={theme.checkboxTableWrapper}>
                <Checkbox
                    disabled={isRowDisabled}
                    checked={isChecked}
                    onChange={() => handleCheck(dataId, isChecked)}
                />
            </div>
        );
    }

    function renderDefaultColumn(value: InternationalString) {
        return (
            <span className={isRowDisabled ? theme.rowDisabled : undefined}>
                {LocalesService.internationalStringToString(value, currentLocale)}
            </span>
        );
    }

    function renderIconColumn(iconRow: string) {
        const icon = presetImages.find((img: PresetImage) => img.id === iconRow)?.graphical;

        return (
            <div className={theme.iconRow}>
                {icon ? <img src={icon} alt="" /> : <Icon spriteSymbol={icFolder} />}
            </div>
        );
    }

    function renderIdColumn(id: string) {
        return <span className={isRowDisabled ? theme.rowDisabled : undefined}>{id}</span>;
    }

    const columns = [
        {
            title: intl.formatMessage(messages.icon),
            dataIndex: 'graphical',
            render: renderIconColumn,
            width: 76,
        },
        {
            title: intl.formatMessage(messages.folderType),
            dataIndex: 'multilingualName',
            key: 'name',
            render: renderDefaultColumn,
        },
        {
            title: intl.formatMessage(messages.id),
            dataIndex: 'id',
            key: 'id',
            render: renderIdColumn,
        },
        {
            title: intl.formatMessage(messages.allowed),
            dataIndex: 'id',
            key: 'checkbox',
            render: renderCheckboxColumn,
            width: 100,
        },
    ];

    return (
        <section className={theme.container}>
            <div className={theme.header}>
                <TabHeader onSearchChange={setFilter} />
                <div className={theme.checkboxWrapper}>
                    <Checkbox checked={isAllowAny} onChange={handleCheckAll}>{intl.formatMessage(messages.allowAll)}</Checkbox>
                </div>
            </div>
            <Table
                className={theme.table}
                rowKey={(record) => record.id}
                dataSource={filteredTypes}
                columns={columns}
                pagination={false}
                scroll={{ y: 'max-content', x: 'max-content' }}
            />
        </section>
    );
};
