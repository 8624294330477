import { Form, Popover } from 'antd';
import React, { useEffect, useMemo } from 'react';
import { ColorResult, SketchPicker } from 'react-color';
import { TButtonStyleColorPickerProps } from './ButtonStyleColorPicker.types';
import theme from './ButtonStyleColorPicker.scss';
import { useDispatch, useSelector } from 'react-redux';
import { getTmpBtnItemColor } from '@/selectors/app.selector';
import { setTmpBtnColor } from '../../../../../../../../../../../../../actions/app.actions';
import { getBtnItemFieldName } from './ButtonStyleColorPicker.utils';

export const ButtonStyleColorPicker = ({
    buttonType,
    eventType,
    buttonColorSchemeItem,
    formRef,
}: TButtonStyleColorPickerProps) => {
    const color: string = useSelector(getTmpBtnItemColor(buttonType, eventType, buttonColorSchemeItem));

    const dispatch = useDispatch();

    const fieldName = useMemo(() => getBtnItemFieldName(buttonType, eventType, buttonColorSchemeItem), []);

    useEffect(() => {
        formRef.current?.setFieldValue(fieldName, color);
    }, [color]);

    const handleChangeColorPicker = (color: ColorResult): void => {
        dispatch(setTmpBtnColor({ buttonType, eventType, item: buttonColorSchemeItem, color: color.hex }));
    };

    return (
        <>
            <Form.Item className={theme.formItem} initialValue={color} name={fieldName}>
                <Popover
                    content={<SketchPicker color={color} onChange={handleChangeColorPicker} disableAlpha />}
                    trigger="click"
                >
                    <div className={theme.changeColorBlock} style={{ backgroundColor: `${color}` }} />
                </Popover>
            </Form.Item>
        </>
    );
};
