import { MultiLangInputDialog } from '../../../../../../../MultiLangInputDialog/MultiLangInputDialog.component';
import messages from '../../FolderTypeTab.messages';
import { Form, FormInstance } from 'antd';
import React, { ChangeEvent, FC } from 'react';
import { useIntl } from 'react-intl';
import theme from './FolderTypeEditorGeneralTab.scss';
import { InternationalString, PresetImage } from '../../../../../../../../serverapi/api';
import { Icon } from '../../../../../../../UIKit/components/Icon/Icon.component';
import icFolder from '../../../../../../../../resources/icons/ic-tree-folder.svg';
import { useDispatch } from 'react-redux';
import { folderTypeEditorEditValue } from '../../../../../../../../actions/folderTypeEditor.actions';
import { LocalesService } from '../../../../../../../../services/LocalesService';
import { InputId } from '../../../../../../../InputId/InputId.component';
import { InputSynonymsIds } from '../../../../../../../InputSynonymsIds/InputSynonymsIds.component';
import { convertStringToArray } from '../../../../../../../../utils/convertStringToArray';
import { Select } from '../../../../../../../UIKit/components/Select/Select.component';
import { Checkbox } from '../../../../../../../UIKit/components/Checkbox/Checkbox.component';

type TFolderTypeEditorGeneralTabProps = {
    generalForm: FormInstance;
    presetImages: PresetImage[];
    folderTypeId: string;
    createMode: boolean;
    imageId?: string;
    multilingualName?: InternationalString;
    multilingualDescription?: InternationalString;
    synonymsIds?: string[];
    allowApprovals?: boolean;
};

export const FolderTypeEditorGeneralTab: FC<TFolderTypeEditorGeneralTabProps> = ({
    generalForm,
    multilingualName,
    multilingualDescription,
    presetImages,
    imageId,
    folderTypeId,
    createMode,
    synonymsIds,
    allowApprovals,
}) => {
    const intl = useIntl();
    const dispatch = useDispatch();

    function onChangeIcon(graphical: string) {
        dispatch(folderTypeEditorEditValue({ graphical }));
    }

    function changeNameHandler(name: InternationalString) {
        dispatch(folderTypeEditorEditValue({ multilingualName: name }));
    }

    function onChangeFolderAllowApprovals(allowApprovals: boolean) {
        dispatch(folderTypeEditorEditValue({ allowApprovals }));
    }

    function changeFolderTypeIdHandler(id: string) {
        dispatch(folderTypeEditorEditValue({ id }));
    }

    function changeDescriptionHandler(description: InternationalString) {
        dispatch(folderTypeEditorEditValue({ multilingualDescription: description }));
    }

    function changeFolderTypeSynonymsIdsHandler(e: ChangeEvent<HTMLInputElement>): void {
        dispatch(folderTypeEditorEditValue({ synonymsIds: convertStringToArray(e.target.value) }));
    }

    return (
        <Form form={generalForm} layout="vertical" className={theme.form}>
            <MultiLangInputDialog
                placeholder={intl.formatMessage(messages.folderType)}
                label={intl.formatMessage(messages.name)}
                multiLangValue={multilingualName}
                mainInputName="multilingualName"
                mainInputClassName={theme.input}
                generalForm={generalForm}
                onChange={changeNameHandler}
                required
                data-test="folder-type_name_input"
            />
            <InputId
                disabled={!createMode}
                value={folderTypeId}
                required
                mainInputName="GUID"
                mainInputClassName={theme.input}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    changeFolderTypeIdHandler(e.target.value.trim());
                }}
            />
            <InputSynonymsIds
                value={synonymsIds}
                mainInputName="synonymsIds"
                mainInputClassName={theme.input}
                onChange={changeFolderTypeSynonymsIdsHandler}
            />
            <MultiLangInputDialog
                placeholder={intl.formatMessage(messages.description)}
                label={intl.formatMessage(messages.description)}
                multiLangValue={multilingualDescription}
                textarea
                mainInputName="multilingualDescription"
                mainInputClassName={theme.textArea}
                generalForm={generalForm}
                onChange={changeDescriptionHandler}
            />
            <div className={theme.selectWrapper}>
                <Select
                    label={intl.formatMessage(messages.icon)}
                    originalTheme
                    data-test="edge-type-group-select"
                    onChange={onChangeIcon}
                    value={imageId || 'default icon'}
                >
                    {presetImages.map(({ multilingualName, graphical, id }) => {
                        const FolderIcon = graphical ? (
                            <img className={theme.iconGeometry} src={graphical} alt="" />
                        ) : (
                            <Icon spriteSymbol={icFolder} />
                        );

                        return (
                            <Select.Option
                                key={id}
                                value={id}
                                label={
                                    <span>
                                        {FolderIcon}
                                        <span>{multilingualName[LocalesService.getLocale()]}</span>
                                    </span>
                                }
                            />
                        );
                    })}
                </Select>
            </div>
            <Form.Item className={theme.formItem}>
                <Checkbox
                    checked={allowApprovals}
                    onChange={() => onChangeFolderAllowApprovals(!allowApprovals)}
                >
                    {intl.formatMessage(messages.allowFoldersApproval)}
                </Checkbox>
            </Form.Item>
        </Form>
    );
};
