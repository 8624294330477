import { defineMessages } from 'react-intl';

export default defineMessages({
    refresh: {
        id: 'ReportEditorFilterBar.refresh',
        defaultMessage: 'Обновить',
    },
    deleteMessage: {
        id: 'ReportEditorFilterBar.deleteMessage',
        defaultMessage: 'Удалить выбранные элементы',
    },
    deleteQuestion: {
        id: 'ReportEditorFilterBar.deleteQuestion',
        defaultMessage: 'Вы действительно хотите удалить выбранные элементы?',
    },
    delete: {
        id: 'ReportEditorFilterBar.delete',
        defaultMessage: 'Удалить',
    },
    cancel: {
        id: 'ReportEditorFilterBar.cancel',
        defaultMessage: 'Отмена',
    },
    showFilters: {
        id: 'ReportEditorFilterBar.showFilters',
        defaultMessage: 'Показать все фильтры',
    },
    hideFilters: {
        id: 'ReportEditorFilterBar.hideFilters',
        defaultMessage: 'Скрыть фильтры',
    },
});
