// @test-ignore_ru
import { AipToolbox } from './AipToolbox';
import {
    TTokensInfo,
    TTokenDescription,
    TFoundInConfig,
    TFoundTokenGroup,
    TTokensGroupsName
} from './types/AipConfig.types';


export class AipConfig {

    private readonly tokensInfo: TTokensInfo = {
        commands: {
            HELP: {
                synonyms: ['ПОМОГИ', 'СПАСИ', 'ПОМОЩЬ', 'СПРАВКА', '?']
            },
            FIND: {
                canBeSearchTarget: true,
                synonyms: [
                    "ИСКАТЬ", "ИЩИ",
                    "НАЙТИ", "НАЙДИ",
                    "ПОИСК"
                ]
            },
        },
        items: {
            ELEMENT: {
                synonyms: [ "ЭЛЕМЕНТ", "ЭЛЕМЕНТЫ", "ЭЛЕМЕНТОМ", "ЭЛЕМЕНТАМИ", "ЭЛЕМЕНТОВ" ]
            },
            ALIAS: {
                canBeSearchTarget: true,
                synonyms: [
                    "АЛИАС", "АЛИАСЫ", "АЛИАСОМ", "АЛИАСАМИ", "АЛИАСОВ",
                    "ПСЕВДОНИМ", "ПСЕВДОНИМЫ", "ПСЕВДОНИМОМ", "ПСЕВДОНИМАМИ", "ПСЕВДОНИМОВ"
                ]
            },
            DECOMPOSITION: {
                synonyms: [ "ДЕКОМПОЗИЦИЯ", "ДЕКОМПОЗИЦИЮ", "ДЕКОМПОЗИЦИИ", "ДЕКОМПОЗИЦИЕЙ", "ДЕКОМПОЗИЦИЯМИ" ]
            },
            EDGE: {
                synonyms: [
                    "РЕБРО", "РЁБРА", "РЕБРА", "РЁБЕР", "РЕБРОМ",
                    "СВЯЗЬ", "СВЯЗИ", "СВЯЗЕЙ", "СВЯЗЬЮ", "СВЯЗЯМИ"
                ]
            },
            FOLDER: {
                canBeSearchTarget: true,
                synonyms: [
                    "ДИРЕКТОРИЯ", "ДИРЕКТОРИЮ", "ДИРЕКТОРИИ", "ДИРЕКТОРИЙ", "ДИРЕКТОРИЕЙ", "ДИРЕКТОРИЯМИ",
                    "ПАПКА", "ПАПКУ", "ПАПКИ", "ПАПОК", "ПАПКОЙ", "ПАПКАМИ",
                    "КАТАЛОГ", "КАТАЛОГА", "КАТАЛОГОВ", "КАТАЛОГИ", "КАТАЛОГОМ", "КАТАЛОГАМИ"
                ]
            },
            MODEL: {
                canBeSearchTarget: true,
                synonyms: [
                    "МОДЕЛЬ", "МОДЕЛИ", "МОДЕЛЕЙ", "МОДЕЛЬЮ", "МОДЕЛЯМИ",
                    "ПРОЦЕСС", "ПРОЦЕССЫ", "ПРОЦЕССОВ", "ПРОЦЕССОМ", "ПРОЦЕССАМИ",
                    "ДИАГРАММА", "ДИАГРАММУ", "ДИАГРАММЫ", "ДИАГРАММ", "ДИАГРАММОЙ", "ДИАГРАММАМИ"
                ]
            },
            OBJECT_DEFINITION: {
                canBeSearchTarget: true,
                synonyms: [
                    "ОБЪЕКТ", "ОБЪЕКТЫ", "ОБЪЕКТОВ", "ОБЪЕКТОМ", "ОБЪЕКТАМИ",
                    "ОПРЕДЕЛЕНИЕ", "ОПРЕДЕЛЕНИЯ", "ОПРЕДЕЛЕНИЙ", "ОПРЕДЕЛЕНИЕМ", "ОПРЕДЕЛЕНИЯМИ"
                ]
            },
            OBJECT_INSTANCE: {
                synonyms: [ "ЭКЗЕМПЛЯР", "ЭКЗЕМПЛЯРА", "ЭКЗЕМПЛЯРОВ", "ЭКЗЕМПЛЯРЫ", "ЭКЗЕМПЛЯРОМ", "ЭКЗЕМПЛЯРАМИ" ]
            }
        },
        attributes: {
            ATTRIBUTE: {
                synonyms: [ "АТРИБУТ", "АТРИБУТОМ", "АТРИБУТАМИ", "АТРИБУТА" ]
            },
            TYPE: {
                synonyms: [ "ТИП", "ТИПА", "ТИПОМ", "ТИПЫ", "ТИПОВ" ]
            },
            NAME: {
                synonyms: [ "ИМЯ", "ИМЕНИ", "ИМЕНЕМ", "ИМЕНА", "ИМЁН", "ИМЕН" ]
            },
            ID: {
                synonyms: [ "ИД", "ИДЕНТИФИКАТОРЫ", "ИДЕНТИФИКАТОРОВ", "ИДЕНТИФИКАТОРОМ" ]
            },
            VALUE: {
                synonyms: [ "ЗНАЧЕНИЕ", "ЗНАЧЕНИЯ", "ЗНАЧЕНИЙ", "ЗНАЧЕНИЕМ" ]
            }
        },
        adjectives: {
            EQUAL: {
                synonyms: [ "РАВНО", "РАВНЫМ" ]
            },
            LINKED: {
                synonyms: [
                    "СВЯЗАННЫЕ", "СВЯЗАННЫЙ", "СВЯЗАННАЯ", "СВЯЗАННОЕ", "СВЯЗАННУЮ", "СВЯЗАННОГО", "СВЯЗАН", "СВЯЗАНА", "СВЯЗАНО", "СВЯЗАНЫ",
                    "СОЕДИНЁННЫЕ", "СОЕДИНЁННЫЙ", "СОЕДИНЁННАЯ", "СОЕДИНЁННОЕ", "СОЕДИНЁННУЮ", "СОЕДИНЁННОГО", "СОЕДИНЕННЫЕ", "СОЕДИНЕННЫЙ", "СОЕДИНЕННАЯ",
                        "СОЕДИНЕННОЕ", "СОЕДИНЕННУЮ", "СОЕДИНЕННОГО", "СОЕДИНЕН", "СОЕДИНЁН", "СОЕДИНЕНА", "СОЕДИНЕНО", "СОЕДИНЕНЫ"
                ]
            }
        },
        prepositions: {
            WITH: {
                synonyms: [ "С", "СО" ]
            },
            TO: {
                synonyms: [ "НА" ]
            }
        },
        conjunctions: {
            AND: {
                synonyms: [ "И" ]
            },
            THAT: {
                synonyms: [ "КОТОРЫЙ", "КОТОРАЯ", "КОТОРОЕ", "КОТОРЫЕ" ]
            }
        },
    };


    getTokens(): TTokensInfo {
        return this.tokensInfo;
    }


    findMultiwordTokenGroup(str: string): TFoundInConfig {
        const sourceWords: string[] = AipToolbox.getWordsFromString(str);

        const wordsAndMaybeTokens: string[] = sourceWords.map(word => {
            const token: string | undefined = this.findTokenBySynonym(word);
            return token ? token : word
        });

        const len: number = sourceWords.length;

        // если в группе меньше двух слов, значит это явно не то что нам нужно
        if (len < 2) return null;

        // это, возможно, группа токенов, нужно проверить первую пару слов в каждой из двух групп чтобы понять не группа ли это
        let result: TFoundInConfig | null;
        result = AipConfig.findTokenGroupHelper('WITH_SOMETHING', sourceWords, wordsAndMaybeTokens);
        if (result) return result;
        result = AipConfig.findTokenGroupHelper('LINKED_WITH', sourceWords, wordsAndMaybeTokens);
        if (result) return result;
        result = AipConfig.findTokenGroupHelper('AND_VALUE', sourceWords, wordsAndMaybeTokens);
        if (result) return result;
        return null;
    }


    findSinglewordToken(str: string): TFoundInConfig {
        const firstWord: string = AipToolbox.getWordsFromString(str)[0];
        if (!firstWord) return null;
        const token: string | undefined = this.findTokenBySynonym(firstWord);
        if (!token) return null;

        const tokensInfo = this.tokensInfo;
        if (token in tokensInfo.commands) return { aipConfigGroup: 'COMMAND', tokens: [ token ], source: firstWord };
        if (token in tokensInfo.items) return { aipConfigGroup: 'ITEM', tokens: [ token ], source: firstWord };
        if (token in tokensInfo.attributes) return { aipConfigGroup: 'ATTRIBUTE', tokens: [ token ], source: firstWord };
        if (token in tokensInfo.prepositions) return { aipConfigGroup: 'PREPOSITION', tokens: [ token ], source: firstWord };
        if (token in tokensInfo.conjunctions) return { aipConfigGroup: 'CONJUNCTION', tokens: [ token ], source: firstWord };
        return null;
    }


    private findTokenBySynonym(synonym: string): string | undefined {
        const tokensInfo = this.tokensInfo;
        const tokenToSynonyms: Record<string, string[]> = Object.keys(tokensInfo)
            .reduce((acc: Record<string, string[]>, key: TTokensGroupsName) => {
                const tokenGroup: TTokenDescription = tokensInfo[key];
                const tokensNamesInGroup: string[] = Object.keys(tokenGroup);
                tokensNamesInGroup.forEach((token: string) => {
                    acc[token] = tokenGroup[token].synonyms.slice();
                })
                return acc;
            }, {});
        synonym = synonym.toUpperCase();
        return Object.keys(tokenToSynonyms).find((token: string) => tokenToSynonyms[token].includes(synonym));
    }


    private static findTokenGroupHelper(groupType: TFoundTokenGroup, sourceWords: string[], wordsAndMaybeTokens: string[]): TFoundInConfig | null {
        let index: number;
        let group: string[][];
        switch (groupType) {
            case 'WITH_SOMETHING':
                group = [
                    [ 'WITH', 'ATTRIBUTE' ],
                    [ 'WITH', 'TYPE' ],
                    [ 'WITH', 'NAME' ],
                    [ 'WITH', 'VALUE' ]
                ];
                break;
            case 'LINKED_WITH':
                group = [
                    [ 'LINKED', 'WITH' ],
                    [ 'LINKED', 'TO' ]
                ];
                break;
            case 'AND_VALUE':
                group = [
                    [ 'AND', 'VALUE' ]
                ];
                break;
            default: group = [];
        }

        index = group.findIndex(([token0, token1]: string[]) => token0 === wordsAndMaybeTokens[0] && token1 === wordsAndMaybeTokens[1]);
        if (index === -1) return null;
        return { aipConfigGroup: groupType, tokens: [group[index][0], group[index][1]], source: [sourceWords[0], sourceWords[1]].join(' ') };
    }
};

export const aipConfig = new AipConfig();
