import { defineMessages } from 'react-intl';

export default defineMessages({
    selectObject: {
        id: 'SelectExistingDefinitionDialog.selectObject',
        defaultMessage: 'Выбор объекта',
    },
    selectEdge: {
        id: 'SelectExistingDefinitionDialog.selectEdge',
        defaultMessage: 'Выбор связи',
    },
    confirmButton: {
        id: 'SelectExistingDefinitionDialog.confirmButton',
        defaultMessage: 'Выбрать',
    },
    cancelButton: {
        id: 'SelectExistingDefinitionDialog.cancelButton',
        defaultMessage: 'Отмена',
    },
    objectHeaderMessage: {
        id: 'SelectExistingDefinitionDialog.objectHeaderMessage',
        defaultMessage: 'Объект типа {objectTypeTitle} с таким  именем уже существует в базе данных',
    },
    edgeHeaderMessage: {
        id: 'SelectExistingDefinitionDialog.edgeHeaderMessage',
        defaultMessage: 'Определение связи между объектами "{sourceObjectName}" и "{targetObjectName}" уже существует',
    },
    createNewObject: {
        id: 'SelectExistingDefinitionDialog.createNewObject',
        defaultMessage: 'Создать новый объект',
    },
    createNewEdgeDefinition: {
        id: 'SelectExistingDefinitionDialog.createNewEdgeDefinition',
        defaultMessage: 'Создать новое определение',
    },
    showExistingObject: {
        id: 'SelectExistingDefinitionDialog.showExistingObject',
        defaultMessage: 'Показать существующий объект в дереве',
    },
    useSelectedObject: {
        id: 'SelectExistingDefinitionDialog.useSelectedObject',
        defaultMessage: 'Использовать выбранный объект',
    },
    useSelectedEdgeDefinition: {
        id: 'SelectExistingDefinitionDialog.useSelectedEdgeDefinition',
        defaultMessage: 'Использовать существующее',
    },
    name: {
        id: 'SelectExistingDefinitionDialog.name',
        defaultMessage: 'Наименование',
    },
    path: {
        id: 'SelectExistingDefinitionDialog.path',
        defaultMessage: 'Расположение',
    },
    author: {
        id: 'SelectExistingDefinitionDialog.author',
        defaultMessage: 'Автор',
    },
    objectTypeLabel: {
        id: 'SelectExistingDefinitionDialog.objectTypeLabel',
        defaultMessage: 'Тип объекта',
    },
    egdeTypeLabel: {
        id: 'SelectExistingDefinitionDialog.egdeTypeLabel',
        defaultMessage: 'Тип связи',
    },
    objectTypePlaceHolder: {
        id: 'SelectExistingDefinitionDialog.objectTypePlaceHolder',
        defaultMessage: 'Тип объекта',
    },
    deleteObjectNotification: {
        id: 'SelectExistingDefinitionDialog.deleteObjectNotification',
        defaultMessage: 'Определение объекта {name} больше не используется и будет удалено',
    },
    useExisting: {
        id: 'SelectExistingDefinitionDialog.useExisting',
        defaultMessage: 'Использовать выбранный',
    },
});
