import React, { useState } from 'react';
import messages from './ModelTemplatesTab.messages';
import { useIntl } from 'react-intl';
import theme from './ModelTemplatesTab.scss';
import { ModelTemplateDTO } from '@/serverapi/api';
import { DeleteSelected, TabHeader } from '../../Header/TabHeader.component';
import { ModelTemplatesList } from './ModelTemplatesList.component';
import { useDispatch } from 'react-redux';
import { Alert } from 'antd';
import { deleteTemplateEditModelType } from '@/actions/workspaceTab/editModelTypeWorkspaceTab.actions';
import { LocalesService } from '@/services/LocalesService';
import { TreeNode } from '@/models/tree.types';
import { editModelTypeTemplate } from '@/actions/presetSettings/presetSettingsModelType.actions';
import { debounce } from 'lodash-es';

type TModelTemplatesTabProps = {
    templates: ModelTemplateDTO[];
    presetId: string;
    serverNode: TreeNode;
    modelTypeId: string;
};

export const ModelTemplatesTab = (props: TModelTemplatesTabProps) => {
    const { templates, presetId, serverNode, modelTypeId } = props;
    const intl = useIntl();
    const dispatch = useDispatch();
    const [searchInput, setSearchInput] = useState<string>('');
    const [checkedTemplates, setCheckedTemplates] = useState<ModelTemplateDTO[]>([]);

    const templateNames: string = checkedTemplates
        .map((template: ModelTemplateDTO) => `"${LocalesService.internationalStringToString(template.name)}"`)
        .join(', ');

    const handleDeleteTemplates = (templateIds: string[]) => {
        dispatch(
            deleteTemplateEditModelType({
                templateIds,
                modelTypeId,
                serverId: serverNode.nodeId.serverId,
                presetId,
            }),
        );
        setCheckedTemplates([]);
    };

    const handleEditModelTemplate = (template: ModelTemplateDTO) => {
        dispatch(
            editModelTypeTemplate({
                template,
                modelTypeId,
                serverNode,
                presetId,
            }),
        );
    };

    const debouncedSearchChange = debounce(setSearchInput, 250);

    return (
        <div className={theme.container}>
            <TabHeader
                buttons={[
                    DeleteSelected.build(
                        () => {
                            const templateIds: string[] = checkedTemplates.map((template) => template.id);
                            handleDeleteTemplates(templateIds);
                        },
                        !checkedTemplates.length,
                        undefined,
                        intl.formatMessage(messages.deleteTemplatesQuestion),
                        <Alert
                            message={intl.formatMessage(messages.deleteTemplatesDialogContent, { templateNames })}
                            type="warning"
                        />,
                    ),
                ]}
                onSearchChange={debouncedSearchChange}
            />
            <ModelTemplatesList
                searchInput={searchInput}
                templates={templates}
                checkedTemplates={checkedTemplates}
                setCheckedTemplates={setCheckedTemplates}
                handleDeleteTemplates={handleDeleteTemplates}
                handleEditModelTemplate={handleEditModelTemplate}
            />
        </div>
    );
};
