import { TAipPresetData, TReducedOriginalPresetCommonItem, TReducedOriginalPresetData, TReducedOriginalPresetModelTypeItem } from "./types/AipPreset.types";
import { AipAlias, PresetMetaData } from "@/serverapi/api";
import { uniqBy } from 'lodash-es';
import { ReducedOriginalPreset } from "./ReducedOriginalPreset";


export class PresetMetadataToAipPresetDataAdapter {

    convert(originalPreset: PresetMetaData, aliases: AipAlias[]): TAipPresetData {
        const rop: TReducedOriginalPresetData = new ReducedOriginalPreset(originalPreset).getData();
        return {
            aliasTypes: aliases.map((a: AipAlias) => {return { id: a.id, multilingualName: { ru: a.name.ru } } }),
            attributeTypes: rop.attributeTypes.slice(),
            edgeTypes: this.getEdgeTypesFromModelTypes(rop.modelTypes),
            folderTypes: rop.folderTypes.slice(),
            modelTypes: this.removeEdgeTypesFromModelTypes(rop.modelTypes),
            objectTypes: rop.objectTypes.slice()
        };
    }


    private getEdgeTypesFromModelTypes(mt: TReducedOriginalPresetModelTypeItem[]): TReducedOriginalPresetCommonItem[] {
        return uniqBy(mt.flatMap((item: TReducedOriginalPresetModelTypeItem) => item.edgeTypes ), 'id');
    }


    private removeEdgeTypesFromModelTypes(mt: TReducedOriginalPresetModelTypeItem[]): TReducedOriginalPresetCommonItem[] {
        return mt.map((item: TReducedOriginalPresetModelTypeItem) => {
            return {
                id: item.id,
                multilingualName: {...item.multilingualName}
            };
        });
    }
}
