import { ButtonVisualStyle } from '../../../../../../../../../../../../../serverapi/api';
import { TButtonColorSchemeItem, TEventType } from './ButtonStyleColorPicker.types';

export const DEFAULT_BUTTONS_COLORS: ButtonVisualStyle = {
    primary: {
        normal: {
            background: '#059BE0',
            text: '#FFFFFF',
            border: '#059BE0',
        },
        hover: {
            background: '#2CB6F1',
            text: '#FFFFFF',
            border: '#2CB6F1',
        },
    },
    primaryDanger: {
        normal: {
            background: '#FF4D50',
            text: '#FFFFFF',
            border: '#FF4D50',
        },
        hover: {
            background: '#F37174',
            text: '#FFFFFF',
            border: '#F37174',
        },
    },
    secondary: {
        normal: {
            background: '#FFFFFF',
            text: '#363636',
            border: '#D4D4D4',
        },
        hover: {
            background: '#FFFFFF',
            text: '#2CB6F1',
            border: '#2CB6F1',
        },
    },
    secondaryDanger: {
        normal: {
            background: '#FFFFFF',
            text: '#ff4d50',
            border: '#ff4d50',
        },
        hover: {
            background: '#FFFFFF',
            text: '#F37174',
            border: '#F37174',
        },
    },
    buttonText: {
        normal: {
            background: 'transparent',
            text: '#059BE0',
            border: 'transparent',
        },
        hover: {
            background: 'transparent',
            text: '#2CB6F1',
            border: 'transparent',
        },
    },
    buttonTextDanger: {
        normal: {
            background: 'transparent',
            text: '#FF4D50',
            border: 'transparent',
        },
        hover: {
            background: 'transparent',
            text: '#F37174',
            border: 'transparent',
        },
    },
};

export const COLOR_SCHEME_KEY: string = 'appColorScheme';

export const BUTTON_COLOR_SCHEME_KEY: string = 'button';

export const EVENT_TYPES: TEventType[] = ['normal', 'hover'];

export const BUTTON_COLOR_SCHEME_ITEMS: TButtonColorSchemeItem[] = ['background', 'text', 'border'];
