import { Popover } from 'antd';
import React, { FC } from 'react';
import { ColorResult, SketchPicker } from 'react-color';
import theme from './EdgeStyleSettings.scss';

type TColorPickerProps = {
    label: string;
    color: string;
    setColor: (color: string) => void;
    'data-test'?: string
};

export const ColorPicker: FC<TColorPickerProps> = ({ color, label, setColor }) => {
    return (
        <div className={theme.colorPickerContainer}>
            <div className={theme.colorLabel}>{label}</div>
            <div className={theme.colorContainer}>
                <Popover
                    content={
                        <SketchPicker
                            color={color}
                            onChange={(color: ColorResult) => {
                                setColor(color.hex);
                            }}
                        />
                    }
                >
                    <div className={theme.colorButton} style={{ backgroundColor: `${color}` }} data-test='color-picker_button'/>
                </Popover>
            </div>
        </div>
    );
};
