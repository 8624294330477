import React, { FC } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import messages from './ModelTemplatesTab.messages';
import theme from './ModelTemplatesTab.scss';
import icDelete from '../../../../../../../resources/icons/Deleted.svg';
import icEdit from '../../../../../../../resources/icons/edit.svg';
import { TableUIKit } from '@/modules/UIKit/components/Table/TableUIKit.component';
import { TColumn, TTableData } from '@/modules/UIKit/components/Table/TableUIKit.types';
import { sortByAlpha } from '@/utils/sortByAlpha';
import { Button } from '@/modules/UIKit/components/Button/Button.component';
import { Alert } from 'antd';
import { openDialog } from '@/actions/dialogs.actions';
import { DialogType } from '@/modules/DialogRoot/DialogRoot.constants';
import { ModelTemplateDTO } from '@/serverapi/api';
import { LocalesService } from '@/services/LocalesService';

type TModelList = {
    searchInput: string;
    templates: ModelTemplateDTO[];
    checkedTemplates: ModelTemplateDTO[];
    setCheckedTemplates: (templates: ModelTemplateDTO[]) => void;
    handleDeleteTemplates: (templateIds: string[]) => void;
    handleEditModelTemplate: (template: ModelTemplateDTO) => void;
};

export const ModelTemplatesList: FC<TModelList> = (props) => {
    const {
        searchInput,
        checkedTemplates,
        templates,
        setCheckedTemplates,
        handleDeleteTemplates,
        handleEditModelTemplate,
    } = props;
    const intl = useIntl();
    const dispatch = useDispatch();

    const deleteTemplateHandler = (template: ModelTemplateDTO) => {
        const onDelete = () => {
            handleDeleteTemplates([template.id]);
            setCheckedTemplates(checkedTemplates.filter((checkedTemplate) => checkedTemplate.id !== template.id));
        };

        const templateNames: string = `"${LocalesService.internationalStringToString(template.name)}"`;

        dispatch(
            openDialog(DialogType.DELETE_CONFIRMATION, {
                onDelete,
                deleteQuestion: intl.formatMessage(messages.deleteTemplatesQuestion),
                dialogContent: (
                    <Alert
                        message={intl.formatMessage(messages.deleteTemplatesDialogContent, { templateNames })}
                        type="warning"
                    />
                ),
            }),
        );
    };

    const editTemplateHandler = (template: ModelTemplateDTO) => handleEditModelTemplate(template);

    const renderRowBtns = (tableData: ModelTemplateDTO) => {
        return (
            <div className={theme.rowBtnContainer}>
                <div className={theme.buttonsBox}>
                    <Button
                        visualStyle={{ type: 'text' }}
                        onClick={(e) => {
                            e.stopPropagation();
                            editTemplateHandler(tableData);
                        }}
                        icon={icEdit}
                        tooltip={intl.formatMessage(messages.edit)}
                    ></Button>
                </div>

                <div className={theme.buttonsBox}>
                    <Button
                        visualStyle={{ type: 'text' }}
                        onClick={(e) => {
                            e.stopPropagation();
                            deleteTemplateHandler(tableData);
                        }}
                        icon={icDelete}
                        tooltip={intl.formatMessage(messages.delete)}
                    ></Button>
                </div>
            </div>
        );
    };

    const getDataSource = (): ModelTemplateDTO[] => {
        const filteredData: ModelTemplateDTO[] =
            searchInput === ''
                ? templates
                : templates.filter(
                      (item) =>
                          [item.id, LocalesService.internationalStringToString(item.name), item.createdBy].filter(
                              (s) => {
                                  if (!s) return false;
                                  const lowerCased = s.trim().toLowerCase();

                                  return lowerCased.includes(searchInput.trim().toLowerCase());
                              },
                          ).length,
                  );

        return filteredData;
    };

    const handleCheckTemplates = (rows: TTableData[], checked: boolean) => {
        const filteredTemplates: ModelTemplateDTO[] = templates.filter((template) =>
            rows.find((row) => template.id === row.id),
        );
        if (checked) {
            setCheckedTemplates([...checkedTemplates, ...filteredTemplates]);
        } else {
            setCheckedTemplates(checkedTemplates.filter((template) => !rows.find((row) => template.id === row.id)));
        }
    };

    const tableData: TTableData[] = getDataSource().map((template) => ({
        id: template.id,
        name: LocalesService.internationalStringToString(template.name),
        createdBy: template.createdBy,
        hiddenTemplate: template.hiddenTemplate ? intl.formatMessage(messages.no) : intl.formatMessage(messages.yes),
        manageBtn: renderRowBtns(template),
        checked: !!checkedTemplates.find((checkedTemplate) => checkedTemplate.id === template.id),
    }));

    const columns: TColumn[] = [
        {
            dataKey: 'id',
            title: 'ID',
            sortFunction: (a: TTableData, b: TTableData) => sortByAlpha(a.id as string, b.id as string),
        },
        {
            dataKey: 'name',
            title: intl.formatMessage(messages.name),
            sortFunction: (a: TTableData, b: TTableData) => sortByAlpha(a.name as string, b.name as string),
        },
        {
            dataKey: 'createdBy',
            title: intl.formatMessage(messages.createdBy),
            sortFunction: (a: TTableData, b: TTableData) => sortByAlpha(a.createdBy as string, b.createdBy as string),
        },
        {
            dataKey: 'hiddenTemplate',
            title: intl.formatMessage(messages.hiddenTemplate),
            sortFunction: (a: TTableData, b: TTableData) =>
                sortByAlpha(a.hiddenTemplate as string, b.hiddenTemplate as string),
        },
        {
            dataKey: 'manageBtn',
            title: '',
            withoutSorter: true,
            width: 80,
        },
    ];

    return <TableUIKit columns={columns} tableData={tableData} withCkeckBox checkRows={handleCheckTemplates} />;
};

