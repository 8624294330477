import { TreeItemType } from '@/modules/Tree/models/tree';
import folderIcon from '../../../../resources/icons/ic-tree-folder.svg';
import modelIcon from '../../../../resources/icons/ic-tree-model-active.svg';
import objectIcon from '../../../../resources/icons/ic-tree-object-org-chart.svg';
import messages from '../../messages/InstancePermissionsDialog.messages';
import { IntlShape } from 'react-intl';
import { TIcon } from './InstancePermissionsDialog.types';

export const getEntityIcon = (objectType: TreeItemType): TIcon => {
    let entityIcon: TIcon;

    switch (objectType) {
        case TreeItemType.Model:
            entityIcon = modelIcon;
        case TreeItemType.ObjectDefinition:
            entityIcon = objectIcon;
        default:
            entityIcon = folderIcon;
    }

    return entityIcon;
};

export const getPreTitleMessage = (objectType: TreeItemType, intl: IntlShape): string => {
    switch (objectType) {
        case TreeItemType.Repository:
            return intl.formatMessage(messages.repositoryTitle);
        case TreeItemType.Model:
            return intl.formatMessage(messages.modelTitle);
        case TreeItemType.ObjectDefinition:
            return intl.formatMessage(messages.objectTitle);
        case TreeItemType.Script:
            return intl.formatMessage(messages.scriptTitle);
        case TreeItemType.ScriptFolder:
            return intl.formatMessage(messages.scriptFolderTitle);
        case TreeItemType.Wiki:
            return intl.formatMessage(messages.wikiTitle);
        case TreeItemType.Matrix:
            return intl.formatMessage(messages.matrixTitle);
        case TreeItemType.SimulationModeling:
            return intl.formatMessage(messages.simulationModelTitle);
        case TreeItemType.File:
            return intl.formatMessage(messages.fileTitle);
        case TreeItemType.FileFolder:
            return intl.formatMessage(messages.fileFolderTitle);
        case TreeItemType.Spreadsheet:
            return intl.formatMessage(messages.spreadsheetTitle);
        case TreeItemType.Dashboard:
            return intl.formatMessage(messages.dashboardTitle);
        default:
            return intl.formatMessage(messages.folderTitle);
    }
};
