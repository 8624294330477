import { defineMessages } from 'react-intl';

export default defineMessages({
    resultsCount: {
        id: 'AssistantInterfacePanel.resultsCount',
        defaultMessage: 'Всего найдено',
    },
    results: {
        id: 'AssistantInterfacePanel.results',
        defaultMessage: 'результатов',
    },
    howto: {
        id: 'AssistantInterfacePanel.howto',
        defaultMessage: 'Помощник позволяет искать элементы базы данных по типу, части имени, атрибутам и связям с объектами\n\nПримеры запросов:\n1. модель структуры с именем общее представление\n2. роли связанные с функциями\n3. носители информации с именем заявка связанные с функциями\n4. функции с шагом процесса 43\n5. функции с именем МТР с атрибутом шаг процесса 43 связанные с объектами с типом событие связанные с носителями информации с именем отчет',
    },
    placeholder: {
        id: 'AssistantInterfacePanel.placeholder',
        defaultMessage: 'Введите запрос и нажмите ENTER'
    },
    help: {
        id: 'AssistantInterfacePanel.help',
        defaultMessage: 'Помощь'
    },
    clear: {
        id: 'AssistantInterfacePanel.clear',
        defaultMessage: 'Очистить'
    },
    search: {
        id: 'AssistantInterfacePanel.search',
        defaultMessage: 'Поиск'
    },
    searchResult: {
        id: 'AssistantInterfacePanel.searchResult',
        defaultMessage: 'Результаты поиска:'
    },
});
