export const PRESET_SETTINGS_GET_ALL_MODEL_TYPES = 'PRESET_SETTINGS_GET_ALL_MODEL_TYPES';

export const PRESET_SETTINGS_GET_ALL_MODEL_TYPES_SUCCESS = 'PRESET_SETTINGS_GET_ALL_MODEL_TYPES_SUCCESS';

export const PRESET_SETTINGS_SUBMIT_MODEL_TYPE = 'PRESET_SETTINGS_SUBMIT_MODEL_TYPE';

export const PRESET_SETTINGS_ADD_MODEL_TYPE = 'PRESET_SETTINGS_ADD_MODEL_TYPE';

export const PRESET_SETTINGS_CREATE_MODEL_TYPE = 'PRESET_SETTINGS_CREATE_MODEL_TYPE';

export const PRESET_SETTINGS_DELETE_MODEL_TYPE = 'PRESET_SETTINGS_DELETE_MODEL_TYPE';

export const PRESET_SETTINGS_TRANSFER_MODEL_TYPE = 'PRESET_SETTINGS_TRANSFER_MODEL_TYPE';

export const PRESET_SETTINGS_EDIT_MODEL_TYPE = 'PRESET_SETTINGS_EDIT_MODEL_TYPE';

export const PRESET_SETTINGS_EDIT_MODEL_TYPE_TEMPLATE = 'PRESET_SETTINGS_EDIT_MODEL_TYPE_TEMPLATE';
