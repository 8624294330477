import { MxCell, Sidebar } from '../mxgraph';

export class DrawioSidebarDecorator extends Sidebar {
    createVertexTemplateFromCells(
        cells: MxCell[] | null,
        width: number,
        height: number,
        title: string,
        showLabel: boolean,
        showTitle: boolean,
        allowCellsInserted: boolean,
    ): any {
        const validCells = (cells || []).filter((cell: MxCell) =>
            cell.value
                ? typeof cell.value === 'string'
                    ? !(cell.value.includes('<') && cell.value.includes('</'))
                    : true
                : true,
        );
        if (validCells.length > 0) {
            return super.createVertexTemplateFromCells(
                validCells,
                width,
                height,
                title,
                showLabel,
                showTitle,
                allowCellsInserted,
            );
        }
        const dummyElement = document.createElement('a');
        dummyElement.style.display = 'none';

        return dummyElement;
    }

    createItem(
        cells: MxCell[],
        title: string,
        showLabel: boolean,
        showTitle: boolean,
        width: number,
        height: number,
        allowCellsInserted: boolean,
        showTooltip: boolean,
    ) {
        const item: HTMLLinkElement = super.createItem(
            cells,
            title,
            showLabel,
            showTitle,
            width,
            height,
            allowCellsInserted,
            showTooltip,
        );

        item.onmousedown = (ev) => {
            ev.preventDefault();
        };

        return item;
    }
}
