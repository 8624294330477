import type { EdgeType, ModelType, Symbol } from '../../../../../serverapi/api';
import type { ApiBundle } from '../../../../../services/api/api-bundle';
import { notification } from 'antd';
import React, { Component } from 'react';
import { SHORT_NOTIFICATION_DURATION } from '../../../../../models/notificationType';
import { ComplexSymbolManager } from '../../../../../mxgraph/ComplexSymbols/ComplexSymbolManager.class';
import { MethodologiesGraph } from '../../../../../mxgraph/MethodologiesGraph';
import messages from './ModelType/ModelType.messages';

type TSymbolToImageConverterGraphIntlProps = {
    modelType?: ModelType;
    symbols?: Symbol[]; // todo: delete
};

type TSymbolToImageConverterGraphIntlActionProps = {
    initialized: (graph: MethodologiesGraph) => void;
};

type TSymbolToImageConverterGraphIntlFullProps = TSymbolToImageConverterGraphIntlProps &
    TSymbolToImageConverterGraphIntlActionProps;

type TSymbolToImageConverterGraphState = {
    graph?: MethodologiesGraph;
};

export class SymbolToImageConverterGraph extends Component<
    TSymbolToImageConverterGraphIntlFullProps,
    TSymbolToImageConverterGraphState
> {
    constructor(props: TSymbolToImageConverterGraphIntlFullProps) {
        super(props);
        this.state = {};
    }

    componentWillUnmount() {
        this.state.graph && this.state.graph.destroy();
    }

    initGraphRef = (element: HTMLDivElement) => {
        if (!this.state.graph) {
            const graph = new MethodologiesGraph({ container: element, modelType: this.props.modelType });
            this.setState({
                ...this.state,
                graph,
            });
            graph.bpmMxGraphContext = {
                api: {} as ApiBundle,
                serverId: '',
                objectDefinitionCopyPasteContext: {},
            };
            this.props.initialized(graph);
        }
    };

    public static convertSymbol(symbol: Symbol, intl, graph?: MethodologiesGraph): JSX.Element {
        graph?.setSymbolName(symbol.name);

        const renderStrategy = () => {
            const base64String = ComplexSymbolManager.getBase64String(symbol, graph);

            return <div>{base64String && <img style={{ width: 120, height: 50 }} src={base64String} />}</div>;
        };

        return SymbolToImageConverterGraph.convert(intl, renderStrategy);
    }

    public static renderSymbol(symbol: Symbol, intl, graph?: MethodologiesGraph): JSX.Element {
        graph?.setSymbolName(symbol.name);

        const renderStrategy = () => {
            const imgUrl: string | undefined = ComplexSymbolManager.getImgUrl(symbol, graph);

            return <div>{imgUrl && <img style={{ width: 120, height: 50 }} src={imgUrl} />}</div>;
        };

        return SymbolToImageConverterGraph.convert(intl, renderStrategy);
    }

    static convert(intl, renderStrategy) {
        try {
            return renderStrategy();
        } catch (e) {
            // tslint:disable-next-line: no-console
            console.error(e);
            notification.info({
                message: intl.formatMessage(messages.errorSymbolDrawTitle),
                description: intl.formatMessage(messages.errorSymbolDrawMessage),
                duration: SHORT_NOTIFICATION_DURATION,
            });
        }
    }

    public static convertEdge(
        edgeType: EdgeType,
        intl,
        graph?: MethodologiesGraph,
        height: number = 26,
        width: number = 150,
        destroyCellsOnDone: boolean = true,
    ) {
        const renderStrategy = () => {
            const svg = graph?.convertEdgeTypeToSvgImage(edgeType, destroyCellsOnDone);

            return (
                <div style={{ textAlign: 'center' }}>
                    {svg && (
                        <img
                            style={{ width, height }}
                            src={`data:image/svg+xml;base64,${Base64.encode(svg.svgString)}`}
                        />
                    )}
                </div>
            );
        };

        const svgImage = SymbolToImageConverterGraph.convert(intl, renderStrategy);

        return svgImage;
    }

    public static renderEdge(
        edgeType: EdgeType,
        intl,
        graph?: MethodologiesGraph,
        height: number = 26,
        width: number = 150,
        destroyCellsOnDone: boolean = true,
    ) {
        const renderStrategy = () => {
            const svg = graph?.convertEdgeTypeToSvgImage(edgeType, destroyCellsOnDone);
            let blob: Blob = new Blob([svg?.svgString || ''], { type: 'image/svg+xml' });
            let url: string = URL.createObjectURL(blob);

            return <div style={{ textAlign: 'center' }}>{svg && <img style={{ width, height }} src={url} />}</div>;
        };

        const svgImage = SymbolToImageConverterGraph.convert(intl, renderStrategy);

        return svgImage;
    }

    render() {
        return <div style={{ display: 'none' }} ref={this.initGraphRef} />;
    }
}
