import { all, call, CallEffect, put, select, takeEvery } from 'redux-saga/effects';
import { addModelType, getAllModelTypesSuccess } from '../../actions/presetSettings/presetSettingsModelType.actions';
import {
    TCreateModelTypeAction,
    TEditModelTypeAction,
    TEditModelTypeTemplateAction,
    TGetAllModelTypesAction,
    TSubmitModelTypeAction,
} from '../../actions/presetSettings/presetSettingsModelType.actions.types';
import {
    PRESET_SETTINGS_CREATE_MODEL_TYPE,
    PRESET_SETTINGS_EDIT_MODEL_TYPE,
    PRESET_SETTINGS_EDIT_MODEL_TYPE_TEMPLATE,
    PRESET_SETTINGS_GET_ALL_MODEL_TYPES,
    PRESET_SETTINGS_SUBMIT_MODEL_TYPE,
} from '../../actionsTypes/presetSettings/presetSettingsModelTypes.actionTypes';
import { TServerEntity } from '../../models/entities.types';
import { ServerSelectors } from '../../selectors/entities/server.selectors';
import { getCurrentLocale } from '../../selectors/locale.selectors';
import { LocalesService } from '../../services/LocalesService';
import messages from '../../modules/AdminTools/Methodology/messages/MethodologySetting.messages';
import { workspaceAddTab, workspaceRemoveTabByNodeId } from '../../actions/tabs.actions';
import { defaultWorkspaceTabActions } from '../../models/tab';
import { EditorMode } from '../../models/editorMode';
import { WorkSpaceTabTypes } from '../../modules/Workspace/WorkSpaceTabTypesEnum';
import {
    IWorkspaceTabItemModelEditParams,
    IWorkspaceTabItemModelTypeTemplateEditParams,
    TWorkspaceTab,
} from '../../models/tab.types';
import { v4 as uuid } from 'uuid';
import { KanbanBoardType, ModelType, ModelTypeGroup } from '../../serverapi/api';
import { IPresetSettingsModelTypeData } from '../../reducers/presetSettings/presetSettingsModelType.reducer.types';
import { PresetSettingsModelTypeSelectors } from '../../selectors/presetSettings/presetSettingsModelType.selectors';
import { modelTypeRequestSuccess } from '../../actions/modelType.actions';
import { addStoreEditModelTypeWorkspaceTab } from '../../actions/workspaceTab/editModelTypeWorkspaceTab.actions';
import { TPresetId } from '../../modules/AdminTools/Methodology/components/Presets/TPresetId.types';
import { ServerErrorType } from '../../models/serverErrorType';
import {
    TCreateKanbanModelTypeAction,
    TEditKanbanModelTypeAction,
    TSubmitKanbanModelTypeAction,
    TUpdateSymbolsAddedToKanbanModelTypeAction,
} from '../../actions/presetSettings/presetSettingsKanbanModelType.actions.types';
import { KanbanSizeType } from '../../models/kanban.types';
import { addStoreEditKanbanModelTypeWorkspaceTab } from '../../actions/workspaceTab/editKanbanModelTypeWorkspaceTab.actions';
import { KanbanDaoService } from '../../services/dao/KanbanDaoService';
import {
    PRESET_SETTINGS_CREATE_KANBAN_MODEL_TYPE,
    PRESET_SETTINGS_EDIT_KANBAN_MODEL_TYPE,
    PRESET_SETTINGS_SUBMIT_KANBAN_MODEL_TYPE,
    PRESET_SETTINGS_UPDATE_SYMBOLS_ADDED_TO_KANBAN_MODEL_TYPE,
} from '../../actionsTypes/presetSettings/presetSettingsKanbanModelTypes.actionTypes';
import { IPresetSettingsKanbanModelTypeData } from '../../reducers/presetSettings/presetSettingsKanbanModelType.reducer.types';
import { PresetSettingsKanbanModelTypeSelectors } from '../../selectors/presetSettings/presetSettingsKanbanModelType.selectors';
import { addKanbanModelType } from '../../actions/presetSettings/presetSettingsKanbanModelType.actions';
import { ModelTypeGroupSelectors } from '../../selectors/modelTypeGroup.selectors';
import { TreeSelectors } from '@/selectors/tree.selectors';
import { TreeNode } from '@/models/tree.types';
import { PresetSelectors } from '@/selectors/preset.selectors';

function* handleGetAllModelTypes({ payload }: TGetAllModelTypesAction) {
    const { presetId, serverId } = payload;
    const server: TServerEntity = yield select(ServerSelectors.server(serverId));
    const modelTypes: ModelType[] = yield call(() => server.api.modelType.byPresetId({ presetId }));
    yield put(getAllModelTypesSuccess({ modelTypes, presetId, serverId }));
}

function* handleSubmitModelType({ payload }: TSubmitModelTypeAction) {
    const { serverId, modelTypes, presetId, createMode, needTabClose, tabNodeId } = payload;
    const server: TServerEntity = yield select(ServerSelectors.server(serverId));
    const intl = LocalesService.useIntl(yield select(getCurrentLocale));

    if (serverId) {
        if (createMode) {
            yield call(() =>
                server.api.modelType.create({ body: modelTypes[0] }).catch((err) => {
                    if (ServerErrorType.DUPLICATE_ENTITY === err.status) {
                        throw Error(intl.formatMessage(messages.idAlreadyTaken));
                    } else {
                        throw err;
                    }
                }),
            );
        } else {
            yield call(() => server.api.modelType.bulkSave({ body: modelTypes }));
        }
    }

    if (needTabClose && tabNodeId) {
        yield put(workspaceRemoveTabByNodeId(tabNodeId));
    }
    yield put(addModelType({ serverId, presetId, modelTypes }));
}

function* handleSubmitKanbanModelType(action: TSubmitKanbanModelTypeAction) {
    const { presetId, requestBody, needTabClose, tabNodeId, createMode } = action.payload;
    const serverId = yield select(ServerSelectors.serverId);

    if (createMode) {
        yield KanbanDaoService.createKanbanBoardType(serverId, requestBody);
    } else {
        yield KanbanDaoService.saveKanbanBoardType(serverId, requestBody);
    }

    if (needTabClose && tabNodeId) {
        yield put(workspaceRemoveTabByNodeId(tabNodeId));
    }

    yield put(addKanbanModelType({ presetId, kanbanModelTypes: [requestBody.body] }));
}

function* handleCreateModelType(action: TCreateModelTypeAction) {
    const { serverNode, preset } = action.payload;
    const server: TServerEntity = yield select(ServerSelectors.server(serverNode.nodeId.serverId));
    const intl = LocalesService.useIntl(yield select(getCurrentLocale));

    const modelType: ModelType = {
        id: uuid(),
        symbols: [],
        edgeTypes: [],
        modelEdgeDefinitions: [],
        attributes: [],
        name: intl.formatMessage(messages.newModel),
        description: '',
        objectTypes: [],
        modelTypeGroup: undefined!,
        decompositions: [],
        groupId: uuid(),
        presetId: preset.id,
        enableModel: true,
        symbolAttributeStyles: [],
        edgeAttributeStyles: [],
    };

    const createModelTypeTab: TWorkspaceTab & TPresetId = {
        title: intl.formatMessage(messages.newModelTitle),
        nodeId: {
            ...serverNode.nodeId,
            id: `${serverNode.nodeId.serverId}_${preset.id}_${modelType.id}`,
        },
        type: WorkSpaceTabTypes.EDIT_MODEL_TYPE_TAB,
        mode: EditorMode.Read,
        presetId: preset.id,
        params: {
            serverNode,
            preset,
            createMode: true,
            modelTypeId: modelType.id,
        } as IWorkspaceTabItemModelEditParams,
        actions: {
            ...defaultWorkspaceTabActions,
        },
    };
    yield put(addStoreEditModelTypeWorkspaceTab({ serverId: server.id, presetId: preset.id, modelType }));
    yield put(workspaceAddTab(createModelTypeTab));
}

function* handleEditModelType(action: TEditModelTypeAction) {
    const { serverNode, preset, modelType } = action.payload;
    const server: TServerEntity = yield select(ServerSelectors.server(serverNode.nodeId.serverId));
    const intl = LocalesService.useIntl(yield select(getCurrentLocale));
    const createModelTypeTab: TWorkspaceTab & TPresetId = {
        title: intl.formatMessage(messages.newModelType, { type: modelType.name }),
        nodeId: {
            ...serverNode.nodeId,
            id: `${serverNode.nodeId.serverId}_${preset.id}_${modelType.id}`,
        },
        type: WorkSpaceTabTypes.EDIT_MODEL_TYPE_TAB,
        mode: EditorMode.Read,
        presetId: preset.id,
        params: {
            serverNode,
            preset,
            createMode: false,
            modelTypeId: modelType.id,
        } as IWorkspaceTabItemModelEditParams,
        actions: {
            ...defaultWorkspaceTabActions,
        },
    };

    yield put(addStoreEditModelTypeWorkspaceTab({ serverId: server.id, presetId: preset.id, modelType }));
    yield put(workspaceAddTab(createModelTypeTab));
}

function* handleEditKanbanModelType(action: TEditKanbanModelTypeAction) {
    const { serverNodeId, presetId, kanbanModelType } = action.payload;

    const serverNode: TreeNode = yield select(TreeSelectors.itemById(serverNodeId));
    const preset = yield select(PresetSelectors.byId({ presetId, serverId: serverNodeId.serverId }));

    const currentLocale = yield select(getCurrentLocale);
    const intl = LocalesService.useIntl(currentLocale);
    const multilingualName = kanbanModelType.multilingualName!;

    const createModelTypeTab: TWorkspaceTab & TPresetId = {
        title: intl.formatMessage(messages.newModelType, { type: multilingualName[currentLocale] }),
        nodeId: {
            ...serverNodeId,
            id: `${serverNodeId.serverId}_${presetId}_${kanbanModelType.id}`,
        },
        type: WorkSpaceTabTypes.EDIT_KANBAN_MODEL_TYPE_TAB,
        mode: EditorMode.Read,
        presetId,
        params: {
            serverNode,
            preset,
            createMode: false,
            modelTypeId: kanbanModelType.id,
        } as IWorkspaceTabItemModelEditParams,
        actions: {
            ...defaultWorkspaceTabActions,
        },
    };

    yield put(
        addStoreEditKanbanModelTypeWorkspaceTab({
            presetId,
            modelType: kanbanModelType,
        }),
    );
    yield put(workspaceAddTab(createModelTypeTab));
}

export function* saveModelTypeChanges(serverId: string, presetId: string) {
    const server: TServerEntity = yield select(ServerSelectors.server(serverId));

    const modelTypesData: IPresetSettingsModelTypeData = yield select(
        PresetSettingsModelTypeSelectors.byServerIdPresetId({ serverId, presetId }),
    );

    const effects: CallEffect<void>[] = [];

    if (modelTypesData.modelTypesForSave.length) {
        effects.push(call(() => server.api.modelType.bulkSave({ body: modelTypesData.modelTypesForSave })));
    }

    if (modelTypesData.modelTypesForDelete.length) {
        effects.push(call(() => server.api.modelType.bulkDelete({ body: modelTypesData.modelTypesForDelete })));
    }

    yield all(effects);

    const modelTypes = Object.keys(modelTypesData.byId || {}).map((k) => modelTypesData.byId[k]) || [];

    if (modelTypes.length) {
        const modelTypeGroups = modelTypes.map((mt) => mt.modelTypeGroup);
        yield put(modelTypeRequestSuccess(serverId, modelTypes, presetId, modelTypeGroups));
    }
}

export function* saveKanbanModelTypeChanges(serverId: string, presetId: string) {
    const kanbanModelTypesData: IPresetSettingsKanbanModelTypeData = yield select(
        PresetSettingsKanbanModelTypeSelectors.byPresetId(presetId),
    );

    const effects: CallEffect<void>[] = [];

    if (kanbanModelTypesData.kanbanModelTypesForSave.length) {
        kanbanModelTypesData.kanbanModelTypesForSave.forEach((type) => {
            if (!kanbanModelTypesData.kanbanModelTypesForDelete.some((typeToDelete) => typeToDelete.id === type.id))
                effects.push(call(() => KanbanDaoService.saveKanbanBoardType(serverId, { body: type })));
        });
    }

    if (kanbanModelTypesData.kanbanModelTypesForDelete.length) {
        kanbanModelTypesData.kanbanModelTypesForDelete.forEach((type) => {
            effects.push(call(() => KanbanDaoService.deleteKanbanBoardType(serverId, presetId, type.id)));
        });
    }

    yield all(effects);
}

function* handleCreateKanbanModelType(action: TCreateKanbanModelTypeAction) {
    const { serverNodeId, presetId } = action.payload;

    const serverNode: TreeNode = yield select(TreeSelectors.itemById(serverNodeId));
    const preset = yield select(PresetSelectors.byId({ presetId, serverId: serverNodeId.serverId }));

    const intl = LocalesService.useIntl(yield select(getCurrentLocale));
    const modelTypeGroups: ModelTypeGroup[] = yield select(
        ModelTypeGroupSelectors.byPresetIdExcludeDeleted({
            serverId: serverNodeId.serverId,
            presetId,
        }),
    );

    const modelType: KanbanBoardType = {
        id: uuid(),
        presetId,
        multilingualName: {},
        multilingualDescription: {},
        groupId: modelTypeGroups[0].id,
        availableSymbolsCardTypesIds: {
            symbolsIds: [],
            cardTypesIds: [],
        },
        rules: {
            placingRules: [],
            objectChangingRules: [],
        },
        columnsSettings: {
            columnsControlAllowed: false,
            columnsTitleHidden: false,
            sameColumnsWidth: true,
            widthType: KanbanSizeType.RELATIVE,
            columns: [],
        },
    };

    const createModelTypeTab: TWorkspaceTab & TPresetId = {
        title: intl.formatMessage(messages.newModelTitle),
        nodeId: {
            ...serverNodeId,
            id: `${serverNodeId.serverId}_${presetId}_${modelType.id}`,
        },
        type: WorkSpaceTabTypes.EDIT_KANBAN_MODEL_TYPE_TAB,
        mode: EditorMode.Read,
        presetId,
        params: {
            serverNode,
            preset,
            createMode: true,
            modelTypeId: modelType.id,
        } as IWorkspaceTabItemModelEditParams,
        actions: {
            ...defaultWorkspaceTabActions,
        },
    };

    yield put(
        addStoreEditKanbanModelTypeWorkspaceTab({
            presetId,
            modelType,
        }),
    );
    yield put(workspaceAddTab(createModelTypeTab));
}

function* handleUpdateSymbolsAddedToKanbanModelType(action: TUpdateSymbolsAddedToKanbanModelTypeAction) {
    const { serverId, symbols } = action.payload;

    const server: TServerEntity = yield select(ServerSelectors.server(serverId));
    yield all(symbols.map((symbol) => call(() => server.api.symbol.save({ body: symbol }))));
}

function* handleEditModelTypeTemplate(action: TEditModelTypeTemplateAction) {
    const { serverNode, presetId, modelTypeId, template } = action.payload;
    const intl = LocalesService.useIntl(yield select(getCurrentLocale));
    const createModelTypeTemplateTab: TWorkspaceTab & TPresetId = {
        title: intl.formatMessage(messages.modelTypeTemplate, {
            name: LocalesService.internationalStringToString(template.name),
        }),
        nodeId: {
            ...serverNode.nodeId,
            id: `${serverNode.nodeId.serverId}_${presetId}_${modelTypeId}_${template.id}`,
        },
        type: WorkSpaceTabTypes.EDIT_MODEL_TYPE_TEMPLATE_TAB,
        mode: EditorMode.Read,
        presetId,
        params: {
            serverId: serverNode.nodeId.serverId,
            presetId,
            modelTypeId,
            templateId: template.id,
        } as IWorkspaceTabItemModelTypeTemplateEditParams,
        actions: {
            ...defaultWorkspaceTabActions,
        },
    };

    yield put(workspaceAddTab(createModelTypeTemplateTab));
}

export function* presetSettingsModelTypesSaga() {
    yield takeEvery(PRESET_SETTINGS_GET_ALL_MODEL_TYPES, handleGetAllModelTypes);
    yield takeEvery(PRESET_SETTINGS_SUBMIT_MODEL_TYPE, handleSubmitModelType);
    yield takeEvery(PRESET_SETTINGS_CREATE_MODEL_TYPE, handleCreateModelType);
    yield takeEvery(PRESET_SETTINGS_EDIT_MODEL_TYPE, handleEditModelType);
    yield takeEvery(PRESET_SETTINGS_EDIT_KANBAN_MODEL_TYPE, handleEditKanbanModelType);
    yield takeEvery(PRESET_SETTINGS_CREATE_KANBAN_MODEL_TYPE, handleCreateKanbanModelType);
    yield takeEvery(PRESET_SETTINGS_SUBMIT_KANBAN_MODEL_TYPE, handleSubmitKanbanModelType);
    yield takeEvery(
        PRESET_SETTINGS_UPDATE_SYMBOLS_ADDED_TO_KANBAN_MODEL_TYPE,
        handleUpdateSymbolsAddedToKanbanModelType,
    );
    yield takeEvery(PRESET_SETTINGS_EDIT_MODEL_TYPE_TEMPLATE, handleEditModelTypeTemplate);
}
