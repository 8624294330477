import React, { FC, useEffect, useState } from 'react';
import { NodeId, SearchRequestNodeTypesEnum, SearchResult } from '@/serverapi/api';
import { compareNodeIds } from '@/utils/nodeId.utils';
import { DeletedElementsTable } from '../DeletedElementsTable/DeletedElementsTable.component';
import { useDispatch, useSelector } from 'react-redux';
import { getSearchedElementsByRepositoryIdById } from '@/selectors/searchedDeletedElements.selectors';
import { TWorkspaceTab } from '@/models/tab.types';
import { SearchOutlined } from '@ant-design/icons';
import {
    clearSearchedDeletedElements,
    eraseSearchedDeletedElements,
    restoreSearchedDeletedElements,
    searchDeletedElements,
} from '@/actions/searchedDeletedElements.actions';
import theme from './SearchDeletedElementsTab.scss';
import { Select } from '../UIKit/components/Select/Select.component';
import { TreeItemType } from '../Tree/models/tree';
import icDelete from '../../resources/icons/Deleted.svg';
import icRestore from '../../resources/icons/restore.svg';
import { useIntl } from 'react-intl';
import messages from './SearchDeletedElementsTab.messages';
import { Input } from 'antd';
import { Icon } from '../UIKit';
import { openDialog } from '@/actions/dialogs.actions';
import { DialogType } from '../DialogRoot/DialogRoot.constants';
import { Spinner } from '../Spinner/Spinner.component';
import { TreeNode } from '@/models/tree.types';
import { moveToDirectAction } from '@/actions/editor.actions';
import { TNavigatorTab } from '@/reducers/navigator.reducer.types';
import { getActiveTabs } from '@/selectors/navigator.selectors';
import { navigatorTabSelect } from '@/actions/navigator.actions';
import { getShowDeletedObjectsFilter } from '@/selectors/tree.selectors';
import { setShowDeletedObjectsFilterRequest } from '@/actions/tree.actions';
import { Button } from '../UIKit/components/Button/Button.component';
import { NAVIGATOR_STRUCTURE } from '@/utils/consts';

type TSearchDeletedElementsTabProps = {
    tab: TWorkspaceTab;
};

const objectTypesSearchParamOptions: SearchRequestNodeTypesEnum[] = [TreeItemType.Folder, TreeItemType.Model];

export const SearchDeletedElementsTab: FC<TSearchDeletedElementsTabProps> = (props): JSX.Element => {
    const { nodeId } = props.tab;
    const intl = useIntl();
    const [selectedDataNodeIds, setSelectedDataNodeIds] = useState<NodeId[]>([]);
    const [sortDirection, setSortDirection] = useState<string>('desc');
    const [searchText, setSearchText] = useState<string>('');
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [objectTypesSearchParam, setObjectTypesSearchParam] = useState<SearchRequestNodeTypesEnum[]>([]);
    const tableData: SearchResult[] = useSelector(
        getSearchedElementsByRepositoryIdById(nodeId.repositoryId, nodeId.id),
    );
    const showDeletedObjectsFilter = useSelector(getShowDeletedObjectsFilter);
    const activeTabs: TNavigatorTab[] = useSelector(getActiveTabs);
    const isTreeTabActive = activeTabs.includes(TNavigatorTab.Tree);

    const dispatch = useDispatch();
    useEffect(() => {
        return () => {
            dispatch(clearSearchedDeletedElements({ repositoryId: nodeId.repositoryId, id: nodeId.id }));
        };
    }, []);

    useEffect(() => {
        if (isLoading) setIsLoading(false);
    }, [tableData]);

    const onRowClick = (nodeId: NodeId) => {
        if (selectedDataNodeIds.some((selected) => compareNodeIds(selected, nodeId))) {
            setSelectedDataNodeIds(selectedDataNodeIds.filter((selected) => !compareNodeIds(selected, nodeId)));
        } else {
            setSelectedDataNodeIds([...selectedDataNodeIds, nodeId]);
        }
    };

    const onAllRowsClick = () => {
        if (selectedDataNodeIds.length === tableData.length) {
            setSelectedDataNodeIds([]);
        } else {
            setSelectedDataNodeIds(tableData.map((data) => data.nodeId));
        }
    };

    const onSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchText(e.target.value.toLowerCase());
    };

    const onObjectTypesSearchParamChange = (types: SearchRequestNodeTypesEnum[]) => {
        setObjectTypesSearchParam(types);
    };

    const getSelectValueLabel = () => {
        if (objectTypesSearchParam.length === 0) return intl.formatMessage(messages.searchAllObjectTypes);

        return objectTypesSearchParam.map((param) => intl.formatMessage(messages[param])).join(', ');
    };

    const onSearchBtnClick = () => {
        setSelectedDataNodeIds([]);
        setIsLoading(true);
        dispatch(
            searchDeletedElements({
                nodeId,
                searchText,
                nodeTypes: objectTypesSearchParam,
            }),
        );
    };

    const onDelete = (nodeId: NodeId) => {
        dispatch(
            openDialog(DialogType.CONFIRMATION, {
                onSubmit: () => {
                    setSelectedDataNodeIds([]);
                    dispatch(eraseSearchedDeletedElements({ nodeId: props.tab.nodeId, nodeIdsToErase: [nodeId] }));
                },
                title: intl.formatMessage(messages.eraseNode),
                question: intl.formatMessage(messages.eraseNodeQuestion),
                OKButtonText: intl.formatMessage(messages.delete),
            }),
        );
    };

    const onDeleteSelected = () => {
        dispatch(
            openDialog(DialogType.CONFIRMATION, {
                onSubmit: () => {
                    setSelectedDataNodeIds([]);
                    dispatch(
                        eraseSearchedDeletedElements({ nodeId: props.tab.nodeId, nodeIdsToErase: selectedDataNodeIds }),
                    );
                },
                title: intl.formatMessage(messages.eraseSelectedNodes),
                question: intl.formatMessage(messages.eraseSelectedNodesQuestion),
                OKButtonText: intl.formatMessage(messages.delete),
            }),
        );
    };

    const onRestore = (nodeId: NodeId) => {
        setSelectedDataNodeIds([]);
        setIsLoading(true);
        dispatch(restoreSearchedDeletedElements({ nodeId: props.tab.nodeId, nodeIdsToRestore: [nodeId] }));
    };
    const onRestoreSelected = () => {
        setSelectedDataNodeIds([]);
        setIsLoading(true);
        dispatch(restoreSearchedDeletedElements({ nodeId: props.tab.nodeId, nodeIdsToRestore: selectedDataNodeIds }));
    };

    const onMoveTo = (treeNode: TreeNode) => {
        if (!showDeletedObjectsFilter) {
            dispatch(setShowDeletedObjectsFilterRequest(true));
        }

        if (!isTreeTabActive) {
            dispatch(navigatorTabSelect(TNavigatorTab.Tree));
        }

        dispatch(moveToDirectAction(treeNode, NAVIGATOR_STRUCTURE));
    };

    const getSortedTableData = (): SearchResult[] => {
        return tableData.sort((a, b) => {
            if (a.deletedAt && b.deletedAt) {
                return sortDirection === 'asc' ? a.deletedAt - b.deletedAt : b.deletedAt - a.deletedAt;
            }
            return 0;
        });
    };

    return (
        <div className={theme.deletedElementsTab} style={{ height: 'inherit', padding: '20px' }}>
            <div className={theme.header}>
                <div className={theme.flex}>
                    <div className={theme.searchInputContainer}>
                        <Input suffix={<SearchOutlined />} onChange={onSearchChange} />
                    </div>
                    <div className={theme.selectContainer}>
                        <Select isMultiSelect value={getSelectValueLabel()} onChange={onObjectTypesSearchParamChange}>
                            {objectTypesSearchParamOptions.map((type) => (
                                <Select.Option
                                    key={type}
                                    value={type}
                                    checked={objectTypesSearchParam.includes(type)}
                                    label={intl.formatMessage(messages[type])}
                                />
                            ))}
                        </Select>
                    </div>
                    <Button visualStyle="primary" disabled={isLoading} onClick={onSearchBtnClick}>
                        <span>{intl.formatMessage(messages.search)}</span>
                    </Button>
                </div>

                <div className={theme.flex}>
                    <Button onClick={onDeleteSelected} disabled={!selectedDataNodeIds.length}>
                        <span className={theme.buttonContent}>
                            <Icon spriteSymbol={icDelete} />
                            <span>{intl.formatMessage(messages.erase)}</span>
                        </span>
                    </Button>
                    <Button onClick={onRestoreSelected} disabled={!selectedDataNodeIds.length}>
                        <span className={theme.buttonContent}>
                            <Icon spriteSymbol={icRestore} />
                            <span>{intl.formatMessage(messages.restore)}</span>
                        </span>
                    </Button>
                </div>
            </div>
            <Spinner loading={isLoading}>
                <DeletedElementsTable
                    tableData={getSortedTableData()}
                    onRowClick={onRowClick}
                    onAllRowsClick={onAllRowsClick}
                    onDelete={onDelete}
                    onRestore={onRestore}
                    onMoveTo={onMoveTo}
                    selectedDataNodeIds={selectedDataNodeIds}
                    sortDirection={sortDirection}
                    setSortDirection={setSortDirection}
                />
            </Spinner>
        </div>
    );
};
