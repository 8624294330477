import { getOrigin, replaceLastSlash } from '../../utils/url.utils';
import { ServerInfo } from '../../serverapi/api';
import { ApiBundle } from '../api/api-bundle';

export class VersionDaoService {
    private static getApi() {
        const hostName = replaceLastSlash(getOrigin());
        const bundle: ApiBundle = new ApiBundle(hostName);

        return bundle;
    }

    public static async checkBackend(): Promise<ServerInfo | undefined> {
        const api = this.getApi();
        try {
            const serverInfo: ServerInfo = await api.version.checkBackend();

            return serverInfo;
        } catch (e) {
            return undefined;
        }
    }
}
