import {
    PRESET_SETTINGS_ADD_MODEL_TYPE,
    PRESET_SETTINGS_CREATE_MODEL_TYPE,
    PRESET_SETTINGS_DELETE_MODEL_TYPE,
    PRESET_SETTINGS_EDIT_MODEL_TYPE,
    PRESET_SETTINGS_EDIT_MODEL_TYPE_TEMPLATE,
    PRESET_SETTINGS_GET_ALL_MODEL_TYPES,
    PRESET_SETTINGS_GET_ALL_MODEL_TYPES_SUCCESS,
    PRESET_SETTINGS_SUBMIT_MODEL_TYPE,
    PRESET_SETTINGS_TRANSFER_MODEL_TYPE,
} from '../../actionsTypes/presetSettings/presetSettingsModelTypes.actionTypes';
import {
    TAddModelTypeAction,
    TAddModelTypePayload,
    TCreateModelTypeAction,
    TCreateModelTypePayload,
    TDeleteModelTypeAction,
    TDeleteModelTypePayload,
    TEditModelTypeAction,
    TEditModelTypePayload,
    TEditModelTypeTemplateAction,
    TEditModelTypeTemplatePayload,
    TGetAllModelTypesAction,
    TGetAllModelTypesPayload,
    TGetAllModelTypesSuccessAction,
    TGetAllModelTypesSuccessPayload,
    TSubmitModelTypeAction,
    TSubmitModelTypePayload,
    TTransferModelTypeAction,
    TTransferModelTypePayload,
} from './presetSettingsModelType.actions.types';

export const getAllModelTypes = (payload: TGetAllModelTypesPayload): TGetAllModelTypesAction => ({
    type: PRESET_SETTINGS_GET_ALL_MODEL_TYPES,
    payload,
});

export const getAllModelTypesSuccess = (payload: TGetAllModelTypesSuccessPayload): TGetAllModelTypesSuccessAction => ({
    type: PRESET_SETTINGS_GET_ALL_MODEL_TYPES_SUCCESS,
    payload,
});

export const submitModelType = (payload: TSubmitModelTypePayload): TSubmitModelTypeAction => ({
    type: PRESET_SETTINGS_SUBMIT_MODEL_TYPE,
    payload,
});

export const addModelType = (payload: TAddModelTypePayload): TAddModelTypeAction => ({
    type: PRESET_SETTINGS_ADD_MODEL_TYPE,
    payload,
});

export const transferModelType = (payload: TTransferModelTypePayload): TTransferModelTypeAction => ({
    type: PRESET_SETTINGS_TRANSFER_MODEL_TYPE,
    payload,
});

export const createModelType = (payload: TCreateModelTypePayload): TCreateModelTypeAction => ({
    type: PRESET_SETTINGS_CREATE_MODEL_TYPE,
    payload,
});

export const editModelType = (payload: TEditModelTypePayload): TEditModelTypeAction => ({
    type: PRESET_SETTINGS_EDIT_MODEL_TYPE,
    payload,
});

export const deleteModelType = (payload: TDeleteModelTypePayload): TDeleteModelTypeAction => ({
    type: PRESET_SETTINGS_DELETE_MODEL_TYPE,
    payload,
});

export const editModelTypeTemplate = (payload: TEditModelTypeTemplatePayload): TEditModelTypeTemplateAction => ({
    type: PRESET_SETTINGS_EDIT_MODEL_TYPE_TEMPLATE,
    payload,
});
