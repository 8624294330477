import type { IExtendedImageOptions, TImageAttributes } from './image.types';
import type { Editor, NodePos } from '@tiptap/core';
import { ReactNodeViewRenderer } from '@tiptap/react';
import Image from '@tiptap/extension-image';
import { ImageRenderer } from '../renderers/image/ImageRenderer.component';
import { ImageNodeType } from './constants';
import { WIKI_IMAGE_DEFAULT_HEIGHT, WIKI_IMAGE_DEFAULT_WIDTH } from '../Editor.constants';

type TImageCommands<T> = {
    /**
     * Add an image
     */
    setImage: (attrs: TImageAttributes) => T;
    /**
     * Refresh image node
     */
    refreshImage: (imageId: string, updatedAttributes?: Partial<TImageAttributes>) => T;
    /**
     * Remove image node
     */
    removeImage: (imageId: string) => T;
};

declare module '@tiptap/core' {
    interface Commands<ReturnType> {
        [ImageNodeType.EXTERNAL_IMAGE_NODE_NAME]: TImageCommands<ReturnType>;
        [ImageNodeType.FILE_IMAGE_NODE_NAME]: TImageCommands<ReturnType>;
        [ImageNodeType.WIKI_IMAGE_NODE_NAME]: TImageCommands<ReturnType>;
        [ImageNodeType.MODEL_IMAGE_NODE_NAME]: TImageCommands<ReturnType>;
    }
}

const findImageNodes = (editor: Editor): NodePos[] => {
    const externalImages = editor.$nodes(ImageNodeType.EXTERNAL_IMAGE_NODE_NAME) || [];
    const fileImages = editor.$nodes(ImageNodeType.FILE_IMAGE_NODE_NAME) || [];
    const wikiImages = editor.$nodes(ImageNodeType.WIKI_IMAGE_NODE_NAME) || [];
    const modelImages = editor.$nodes(ImageNodeType.MODEL_IMAGE_NODE_NAME) || [];

    return [...externalImages, ...fileImages, ...wikiImages, ...modelImages];
};

const ExternalImage = Image.extend<IExtendedImageOptions>({
    name: ImageNodeType.EXTERNAL_IMAGE_NODE_NAME,

    draggable: false,

    addAttributes() {
        return {
            ...this.parent?.(),
            id: {
                default: null,
                parseHTML: (element) => element.getAttribute('id'),
                renderHTML: (attributes) => {
                    if (!attributes.id) {
                        return {};
                    }

                    return {
                        id: attributes.id,
                    };
                },
            },
            width: {
                default: WIKI_IMAGE_DEFAULT_WIDTH,
                parseHTML: (element) => {
                    const widthAttr = element.getAttribute('width');

                    if (!widthAttr) {
                        return 0;
                    }

                    // TODO нужно адаптировать под разные величины
                    // const widthNumber = parseInt(widthAttr, 10);
                    // return !isNaN(widthNumber) ? widthNumber : widthAttr;
                    return widthAttr;
                },
                renderHTML: (attributes) => {
                    return {
                        width: attributes.width,
                    };
                },
            },
            height: {
                default: WIKI_IMAGE_DEFAULT_HEIGHT,
                parseHTML: (element) => {
                    const heightAttr = element.getAttribute('height');

                    if (!heightAttr) {
                        return 0;
                    }

                    // TODO нужно адаптировать под разные величины
                    // const heightNumber = parseInt(heightAttr, 10);
                    // return !isNaN(heightNumber) ? heightNumber : heightAttr;
                    return heightAttr;
                },
                renderHTML: (attributes) => {
                    return {
                        height: attributes.height,
                    };
                },
            },
        };
    },

    addCommands() {
        return {
            ...this.parent?.(),
            refreshImage:
                (imageId: string, updatedAttributes?: TImageAttributes) =>
                ({ editor }) => {
                    const images = findImageNodes(editor);
                    const refreshingImage = images.find((image: NodePos) => image.node.attrs['id'] === imageId);

                    if (!refreshingImage) {
                        return false;
                    }

                    const pos = refreshingImage.pos;
                    const node = refreshingImage.node;
                    const attrs = { ...node.attrs, ...(updatedAttributes || {}) };
                    const content = [{ type: node.type.name, attrs }];

                    setTimeout(() => {
                        editor.commands.deleteRange({ from: pos, to: pos + node.nodeSize });
                        editor.commands.insertContentAt(pos, content);
                    }, 0);

                    return true;
                },
            removeImage:
                (imageId: string) =>
                ({ editor }) => {
                    const images = findImageNodes(editor);
                    const refreshingImage = images.find((image: NodePos) => image.node.attrs['id'] === imageId);

                    if (!refreshingImage) {
                        return false;
                    }

                    const pos = refreshingImage.pos;
                    const node = refreshingImage.node;

                    setTimeout(() => {
                        editor.commands.deleteRange({ from: pos, to: pos + node.nodeSize });
                    }, 0);

                    return true;
                },
        };
    },

    addNodeView() {
        return ReactNodeViewRenderer(ImageRenderer, { className: this.options.HTMLAttributes.class });
    },
});

const FileImage = ExternalImage.extend({
    name: ImageNodeType.FILE_IMAGE_NODE_NAME,
});

const WikiImage = ExternalImage.extend({
    name: ImageNodeType.WIKI_IMAGE_NODE_NAME,
});

const ModelImage = ExternalImage.extend({
    name: ImageNodeType.MODEL_IMAGE_NODE_NAME,
});

export { ExternalImage, FileImage, WikiImage, ModelImage };
