// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SelectModelToCompareDialog__modal__tE3sM .ant-modal-body{height:500px}.SelectModelToCompareDialog__modal__tE3sM .ant-tooltip-disabled-compatible-wrapper{margin-left:8px}.SelectModelToCompareDialog__title__qmgqt{font-size:16px}", "",{"version":3,"sources":["webpack://./src/modules/SelectModelToCompareDialog/SelectModelToCompareDialog.scss"],"names":[],"mappings":"AAEQ,0DACI,YAAA,CAEJ,mFACI,eAAA,CAKZ,0CACI,cAAA","sourcesContent":[".modal {\r\n    :global {\r\n        .ant-modal-body {\r\n            height: 500px;\r\n        }\r\n        .ant-tooltip-disabled-compatible-wrapper {\r\n            margin-left: 8px;\r\n        }\r\n    }\r\n}\r\n\r\n.title {\r\n    font-size: 16px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal": "SelectModelToCompareDialog__modal__tE3sM",
	"title": "SelectModelToCompareDialog__title__qmgqt"
};
export default ___CSS_LOADER_EXPORT___;
