import { ISearchRuleWithValueId } from '@/modules/Search/AttributeFilter/AttributeFilter.types';
import {
    GET_SEARCH_RESULT,
    OPEN_MODEL_ON_CANVAS,
    OPEN_SEARCH_IN_DIALOG,
    RESET_SEARCH_DATA,
    SEARCH_SET_LOADING_STATUS,
    SET_ROOT_SEARCH_NODE_ID,
    SET_SEARCH_DATA,
    SET_SEARCH_NODE_TYPES,
    SET_SEARCH_ROOT_NODES,
    SET_SEARCH_RULES,
    SET_SEARCH_TEXT,
    SET_SEARCH_VISIBILITY,
} from '../actionsTypes/search.actionTypes';
import { NodeId, SearchRequest, SearchRequestNodeTypesEnum, SearchRequestSearchVisibilityEnum } from '../serverapi/api';
import {
    TGetSearchResultAction,
    TOpenModelOnCanvasAction,
    TOpenModelOnCanvasPayload,
    TOpenSearchInDialogAction,
    TResetSearchDataAction,
    TSearchDataAction,
    TSearchDataPayload,
    TSearchSetLoadingStatusAction,
    TSetRootSearchNodeIdAction,
    TSetSearchNodeTypesAction,
    TSetSearchPathElementAction,
    TSetSearchRulesAction,
    TSetSearchTextAction,
    TSetSearchVisibilityAction,
} from './search.actions.types';
import { TreeNode } from '@/models/tree.types';

export const openModelOnCanvas = (payload: TOpenModelOnCanvasPayload): TOpenModelOnCanvasAction => ({
    type: OPEN_MODEL_ON_CANVAS,
    payload,
});

export const setSearchData = (payload: TSearchDataPayload): TSearchDataAction => ({
    type: SET_SEARCH_DATA,
    payload,
});

export const resetSearchData = (searchNodeId: NodeId): TResetSearchDataAction => ({
    type: RESET_SEARCH_DATA,
    payload: { searchNodeId },
});

export const setSearchRootNodes = (
    searchNodeId: NodeId,
    searchRootNodes: TreeNode[],
): TSetSearchPathElementAction => ({
    type: SET_SEARCH_ROOT_NODES,
    payload: {
        searchNodeId,
        searchRootNodes,
    },
});

export const getSearchResult = (
    searchNodeId: NodeId,
    searchText: string,
    searchRules: ISearchRuleWithValueId[],
    searchVisibility: SearchRequestSearchVisibilityEnum,
    searchNodeTypes: SearchRequestNodeTypesEnum[],
    rootSearchNodeIds: NodeId[],
): TGetSearchResultAction => ({
    type: GET_SEARCH_RESULT,
    payload: {
        searchNodeId,
        searchText,
        searchRules,
        searchVisibility,
        searchNodeTypes,
        rootSearchNodeIds,
    },
});

export const setSearchRules = (nodeId: NodeId, searchRules: ISearchRuleWithValueId[]): TSetSearchRulesAction => ({
    type: SET_SEARCH_RULES,
    payload: {
        nodeId,
        searchRules,
    },
});

export const setSearchNodeTypes = (
    nodeId: NodeId,
    searchNodeTypes: SearchRequestNodeTypesEnum[],
): TSetSearchNodeTypesAction => ({
    type: SET_SEARCH_NODE_TYPES,
    payload: {
        nodeId,
        searchNodeTypes,
    },
});

export const setSearchText = (nodeId: NodeId, searchText: string): TSetSearchTextAction => ({
    type: SET_SEARCH_TEXT,
    payload: {
        nodeId,
        searchText,
    },
});

export const setSearchVisibility = (
    nodeId: NodeId,
    visibility: SearchRequestSearchVisibilityEnum,
): TSetSearchVisibilityAction => ({
    type: SET_SEARCH_VISIBILITY,
    payload: {
        nodeId,
        visibility,
    },
});

export const setRootSearchNodeId = (searchNodeId: NodeId, rootSearchNodeIds: NodeId[]): TSetRootSearchNodeIdAction => ({
    type: SET_ROOT_SEARCH_NODE_ID,
    payload: {
        searchNodeId,
        rootSearchNodeIds: rootSearchNodeIds,
    },
});

export const openSearchInDialog = (
    searchNodeId: NodeId,
    nodeId: NodeId,
    searchRequests: SearchRequest[],
): TOpenSearchInDialogAction => ({
    type: OPEN_SEARCH_IN_DIALOG,
    payload: {
        searchNodeId,
        nodeId,
        searchRequests,
    },
});

export const searchSetLoadingStatus = (searchNodeId: NodeId, isLoading: boolean): TSearchSetLoadingStatusAction => ({
    type: SEARCH_SET_LOADING_STATUS,
    payload: {
        searchNodeId,
        isLoading,
    },
});
