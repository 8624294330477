import { ModelType, PresetMetaData } from "@/serverapi/api";
import { TReducedOriginalPresetCommonItem, TReducedOriginalPresetData, TReducedOriginalPresetModelTypeItem } from "./types/AipPreset.types"
import { TPresetMetaDataArrayItem } from "./types/PresetMetadataToAipPresetDataAdapter.types";


export class ReducedOriginalPreset {

    private attributeTypes: TReducedOriginalPresetCommonItem[];
    private folderTypes: TReducedOriginalPresetCommonItem[];
    private modelTypes: TReducedOriginalPresetModelTypeItem[];
    private objectTypes: TReducedOriginalPresetCommonItem[]


    constructor(originalPreset: PresetMetaData) {
        this.attributeTypes = this.filterArray(originalPreset.attributeTypes);
        this.folderTypes = this.filterArray(originalPreset.folderTypes);
        this.objectTypes = this.filterArray(originalPreset.objectTypes);
        this.modelTypes = this.filterModelTypes(originalPreset.modelTypes);
    }


    getData(): TReducedOriginalPresetData {
        return {
            attributeTypes: this.attributeTypes,
            folderTypes: this.folderTypes,
            objectTypes: this.objectTypes,
            modelTypes: this.modelTypes
        }
    }


    private filterArray(array: TPresetMetaDataArrayItem[] | undefined): TReducedOriginalPresetCommonItem[] {
        if (!array) return [];
        return array.map((item: TPresetMetaDataArrayItem) => {
            return { id: item.id, multilingualName: Object.assign({}, item.multilingualName) };
        });
    }


    private filterModelTypes(array: ModelType[] | undefined): TReducedOriginalPresetModelTypeItem[] {
        if (!array) return [];
        return array.map((item: ModelType) => {
            return {
                id: item.id,
                multilingualName: Object.assign({}, item.multilingualName),
                edgeTypes: this.filterArray(item.edgeTypes)
            };
        });
    }
}
