import { defineMessages } from 'react-intl';

export default defineMessages({
    formName: {
        id: 'WikiLinkDialog.formName',
        defaultMessage: 'Установить ссылку',
    },
    formConfirmButton: {
        id: 'WikiLinkDialog.formConfirmButton',
        defaultMessage: 'Создать',
    },
    formDeclineButton: {
        id: 'WikiLinkDialog.wikiFormDeclineButton',
        defaultMessage: 'Отмена',
    },
    formValidationMessageRequiredUrl: {
        id: 'WikiLinkDialog.formValidationMessageRequiredUrl',
        defaultMessage: 'Укажите url ссылки',
    },
    formValidationMessageInternalUrl: {
        id: 'WikiLinkDialog.formValidationMessageInternalUrl',
        defaultMessage: 'Укажите ссылку на внутренний ресурс',
    },
    formValidationMessageRequiredLabel: {
        id: 'WikiLinkDialog.formValidationMessageRequiredLabel',
        defaultMessage: 'Укажите текст ссылки',
    },
    linkUrlLabel: {
        id: 'WikiLinkDialog.linkUrlLabel',
        defaultMessage: 'Url',
    },
    linkTextLabel: {
        id: 'WikiLinkDialog.linkTextLabel',
        defaultMessage: 'Текст ссылки',
    },
});
