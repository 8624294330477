import { IPresetSettingsModelTypeData } from './../../reducers/presetSettings/presetSettingsModelType.reducer.types';
import { TRootState } from '../../reducers/root.reducer.types';
import { createSelector } from 'reselect';
import { PRESET_SETTINGS_INITIAL_MODEL_TYPE_SERVER_STATE } from '../../reducers/presetSettings/presetSettingsModelType.reducer';
import { KanbanBoardType, MatrixType, ModelType, ModelTypeGroup, ReportType } from '../../serverapi/api';
import { PresetSettingsReportModelTypeSelectors } from './presetSettingsReportModelType.selectors';
import { PresetSettingsMatrixModelTypeSelectors } from './presetSettingsMatrixModelType.selectors';
import { PresetSettingsKanbanModelTypeSelectors } from './presetSettingsKanbanModelType.selectors';
import { IPresetSettingsReportModelTypeData } from '@/reducers/presetSettings/presetSettingsReportModelType.reducer.types';
import { IPresetSettingsMatrixModelTypeData } from '@/reducers/presetSettings/presetSettingsMatrixModelType.reducer.types';
import { IPresetSettingsKanbanModelTypeData } from '@/reducers/presetSettings/presetSettingsKanbanModelType.reducer.types';
import { ModelTypeGroupSelectors } from '../modelTypeGroup.selectors';

type TOtherModelsWithGroup = (ReportType | MatrixType | KanbanBoardType) & { modelTypeGroup: ModelTypeGroup };

const modelTypeStateSelector = (state: TRootState) => state.presetSettings.modelTypes;

export namespace PresetSettingsModelTypeSelectors {
    export const byServerIdPresetId = (compositeId: { presetId: string; serverId: string }) =>
        createSelector(modelTypeStateSelector, (state) => {
            const { serverId, presetId } = compositeId;

            return state.byServerId[serverId]?.[presetId] || PRESET_SETTINGS_INITIAL_MODEL_TYPE_SERVER_STATE;
        });

    export const listByPresetId = (serverId: string, presetId: string) =>
        createSelector<TRootState, IPresetSettingsModelTypeData, ModelType[]>(
            byServerIdPresetId({ serverId, presetId }),
            (state) => Object.values(state.byId),
        );

    export const getAllByServerIdPresetId = (presetId: string, serverId: string) =>
        createSelector<
            TRootState,
            IPresetSettingsModelTypeData,
            IPresetSettingsReportModelTypeData,
            IPresetSettingsMatrixModelTypeData,
            IPresetSettingsKanbanModelTypeData,
            {
                byId: {
                    [id: string]: ModelTypeGroup;
                };
            },
            {
                byId: {
                    [id: string]: ModelType | TOtherModelsWithGroup;
                };
            }
        >(
            byServerIdPresetId({ presetId, serverId }),
            PresetSettingsReportModelTypeSelectors.byPresetId(presetId),
            PresetSettingsMatrixModelTypeSelectors.byPresetId(presetId),
            PresetSettingsKanbanModelTypeSelectors.byPresetId(presetId),
            ModelTypeGroupSelectors.byPresetId({ presetId, serverId }),
            (modelTypes, reportModelTypes, matrixModelTypes, kanbanModelTypes, modelTypeGroups) => {
                const otherModelTypes = {
                    ...reportModelTypes.byId,
                    ...matrixModelTypes.byId,
                    ...kanbanModelTypes.byId,
                };

                const otherModelTypesWithGroup: { [id: string]: TOtherModelsWithGroup } = {};
                Object.keys(otherModelTypes).forEach((key) => {
                    const currentModelType: ReportType | MatrixType | KanbanBoardType = otherModelTypes[key];
                    const groupsById: { [id: string]: ModelTypeGroup } = modelTypeGroups.byId;
                    const currentGroup: ModelTypeGroup = groupsById[currentModelType.groupId];

                    otherModelTypesWithGroup[key] = {
                        ...currentModelType,
                        modelTypeGroup: {
                            ...currentGroup,
                        },
                    };
                });

                const res = {
                    byId: {
                        ...modelTypes.byId,
                        ...otherModelTypesWithGroup,
                    },
                };

                return res;
            },
        );
}
