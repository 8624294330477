import React, { FC, ChangeEvent } from 'react';
import { Select } from '@/modules/UIKit/components/Select/Select.component';
import { SearchInput } from '@/modules/UIKit/components/Select/SearchInput.component';
import { useIntl } from 'react-intl';
import messages from '../../messages/ScriptDashboardTable.messages';
import theme from './ScriptExecutionsTable.scss';

type TControlsProps = {
    searchFilter: string;
    statusFilter: string;
    statuses: Record<string, string>;
    onSearch: (e: ChangeEvent<HTMLInputElement>) => void;
    onClearSearch: () => void;
    onChangeStatus: (value: string) => void;
};

export const Controls: FC<TControlsProps> = ({
    searchFilter,
    statusFilter,
    statuses,
    onSearch,
    onClearSearch,
    onChangeStatus,
}) => {
    const intl = useIntl();
    const statusSelectOptions = [
        { value: 'all', label: intl.formatMessage(messages.allStatuses) },
        ...Object.entries(statuses).map(([key, label]) => ({
            value: key,
            label,
        })),
    ];

    return (
        <div className={theme.controlContainer}>
            <SearchInput
                showSearch
                originalTheme
                searchValue={searchFilter}
                onSearch={onSearch}
                allowClear
                onClear={onClearSearch}
                debounceTimeout={250}
            />
            <Select
                value={statuses[statusFilter]}
                onChange={onChangeStatus}
                originalTheme
            >
                {statusSelectOptions.map((option) => (
                    <Select.Option label={option.label} value={option.value} />
                ))}
            </Select>
        </div>
    );
};
