import React, { FC, useCallback, useEffect, useState } from 'react';
import messages from '../SelectExistingDefinitionDialogs.messages';
import { useIntl } from 'react-intl';
import { ObjectDefinitionImpl } from '../../../../../models/bpm/bpm-model-impl';
import { SelectedStrategy } from '../../../../../models/selectObjectDialog.types';
import theme from '../SelectExistingDefinitionDialogs.scss';
import Flag from '../../../../../resources/icons/ic-tree-e-epc-event.svg';
import { useDispatch, useSelector } from 'react-redux';
import { SelectObjectDefinitionDialogSelectors } from '../../../../../selectors/selectObjectDefinitionDialog.selectors';
import { selectObjectDialogPrepareDataRequest } from '../../../../../actions/selectObjectDefinitionDialog.actions';
import { Table } from 'antd';
import { closeDialog } from '../../../../../actions/dialogs.actions';
import { DialogType } from '../../../../DialogRoot/DialogRoot.constants';
import { useObjectTableData } from '../hooks/useObjectTableData';
import { Icon } from '../../../../UIKit';
import { TreeItemType } from '../../../../Tree/models/tree';
import { moveToDirectAction } from '../../../../../actions/editor.actions';
import { saveObjectDefinition } from '../../../../../actions/entities/objectDefinition.actions';
import { Dialog } from '@/modules/UIKit/components/Dialog/Dialog.component';
import { DialogFooterButtons } from '@/modules/UIKit/components/DialogFooterButtoms/DialogFooterButtons.component';

type TSelectObjectTreeDialogContainerProps = {
    open: boolean;
    instances: ObjectDefinitionImpl[];
    objectDefinition: ObjectDefinitionImpl;
};

export const SelectObjectTreeDialogContainer: FC<TSelectObjectTreeDialogContainerProps> = ({
    open,
    instances,
    objectDefinition,
}) => {
    const intl = useIntl();
    const dispatch = useDispatch();

    const [selectedInstanceId, setSelectedInstanceId] = useState<string | null>(instances?.[0]?.nodeId.id || null);

    useEffect(() => {
        dispatch(selectObjectDialogPrepareDataRequest(instances));
    }, []);

    const nameRenderer = (obj: ObjectDefinitionImpl) => {
        const isSelected = obj.nodeId.id === selectedInstanceId;

        return (
            <div className={!isSelected ? theme.name : ''}>
                {isSelected && <Icon className={theme.flag} spriteSymbol={Flag} />}
                {obj.name}
            </div>
        );
    };

    const { columns, dataSource, onRow } = useObjectTableData(instances, nameRenderer, (key) =>
        setSelectedInstanceId(key),
    );
    const { objectTypeTitle } = useSelector(SelectObjectDefinitionDialogSelectors.selectObjectDefinitionDialogState);

    const onClose = useCallback(() => {
        dispatch(closeDialog(DialogType.CHECK_OBJECT_DIALOG));
    }, []);

    const handleSubmit = (selectedStrategy: SelectedStrategy) => () => {
        const selectedInstance = instances.find(({ nodeId: { id } }) => id === selectedInstanceId);

        if (!selectedInstance) return;

        switch (selectedStrategy) {
            case SelectedStrategy.save: {
                dispatch(saveObjectDefinition(objectDefinition.nodeId.serverId, objectDefinition));
                break;
            }
            case SelectedStrategy.showInTree: {
                const { nodeId, type, name } = selectedInstance;
                dispatch(
                    moveToDirectAction({
                        nodeId,
                        name,
                        type: type as TreeItemType,
                        hasChildren: false,
                        countChildren: 0,
                    }),
                );
                break;
            }
            default:
                break;
        }

        dispatch(closeDialog(DialogType.CHECK_OBJECT_DIALOG));
    };

    const footer = (
        <DialogFooterButtons
            buttons={[
                {
                    key: 'cancel',
                    onClick: onClose,
                    value: intl.formatMessage(messages.cancelButton),
                },
                {
                    key: 'useSelected',
                    onClick: handleSubmit(SelectedStrategy.showInTree),
                    value: intl.formatMessage(messages.useExisting),
                    visualStyle: 'primary',
                },
                {
                    key: 'createNew',
                    onClick: handleSubmit(SelectedStrategy.save),
                    value: intl.formatMessage(messages.createNewObject),
                    visualStyle: 'primary',
                },
            ]}
        />
    );

    return (
        <Dialog
            open={open}
            onCancel={onClose}
            title={intl.formatMessage(messages.selectObject)}
            width="620px"
            footer={footer}
        >
            <div className={theme.container} data-test="existing_object_dialog">
                <div className={theme.message}>
                    {intl.formatMessage(messages.objectHeaderMessage, { objectTypeTitle })}
                </div>
                <div>{intl.formatMessage(messages.useSelectedObject)}</div>
                {dataSource && (
                    <div className={theme.tableContainer}>
                        <Table
                            className={theme.table}
                            rowKey={(record) => record.key}
                            onRow={onRow}
                            dataSource={dataSource}
                            bordered
                            pagination={false}
                            size="middle"
                            columns={columns}
                            scroll={{
                                y: 'max-content',
                                x: 'max-content',
                            }}
                        />
                    </div>
                )}
            </div>
        </Dialog>
    );
};
