import React, { FC, useCallback, useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import messages from '../SelectExistingDefinitionDialogs.messages';
import { EdgeDefinitionNode } from '../../../../../serverapi/api';
import { SelectedStrategy } from '../../../../../models/selectObjectDialog.types';
import { Table } from 'antd';
import { Icon } from '../../../../UIKit';
import theme from '../SelectExistingDefinitionDialogs.scss';
import Flag from '../../../../../resources/icons/ic-tree-e-epc-event.svg';
import { setFocusAndStartEditAction } from '../../../../../actions/editor.actions';
import { DialogType } from '../../../../DialogRoot/DialogRoot.constants';
import { useDispatch, useSelector } from 'react-redux';
import { closeDialog } from '../../../../../actions/dialogs.actions';
import { SelectEdgeDefinitionDialogSelectors } from '../../../../../selectors/selectEdgeDefinitionDialog.selectors';
import {
    selectEdgeDialogPrepareDataRequest,
    selectEdgeDialogSubmit,
    selectEdgeDialogSubmitFromMatrix,
} from '../../../../../actions/selectEdgeDefinitionDialog.actions';
import { useEdgeTableData } from '../hooks/useEdgeTableData';
import { Dialog } from '@/modules/UIKit/components/Dialog/Dialog.component';
import { DialogFooterButtons } from '@/modules/UIKit/components/DialogFooterButtoms/DialogFooterButtons.component';

type TSelectEdgeDefinitionDialogContainerProps = {
    instances: EdgeDefinitionNode[];
    cellId: string;
    sourceObjectDefinitionId: string;
    targetObjectDefinitionId: string;
    isCreatingFromMatrix?: boolean;
    open: boolean;
};

export const SelectEdgeDefinitionDialogContainer: FC<TSelectEdgeDefinitionDialogContainerProps> = ({
    instances,
    cellId,
    sourceObjectDefinitionId,
    targetObjectDefinitionId,
    isCreatingFromMatrix,
    open,
}) => {
    const dispatch = useDispatch();
    const intl = useIntl();

    const [selectedInstanceId, setSelectedInstanceId] = useState<string | null>(instances?.[0]?.nodeId.id || null);

    const { sourceObjectName, targetObjectName } = useSelector(
        SelectEdgeDefinitionDialogSelectors.selectEdgeDefinitionDialogState,
    );

    useEffect(() => {
        dispatch(selectEdgeDialogPrepareDataRequest(instances, sourceObjectDefinitionId, targetObjectDefinitionId));
    }, []);

    const nameRenderer = useCallback(
        (edgeDefinition: EdgeDefinitionNode) => {
            const isSelected = edgeDefinition.nodeId.id === selectedInstanceId;

            return (
                <div className={!isSelected ? theme.name : ''}>
                    {isSelected && <Icon className={theme.flag} spriteSymbol={Flag} />}
                    {edgeDefinition.name || ''}
                </div>
            );
        },
        [selectedInstanceId],
    );

    const { columns, dataSource, onRow } = useEdgeTableData(instances, nameRenderer, (key) =>
        setSelectedInstanceId(key),
    );

    const handleSubmit = (selectedStrategy: SelectedStrategy) => () => {
        const selectedInstance = instances.find((instance) => instance.nodeId.id === selectedInstanceId);

        if (!selectedInstance) return;

        if (isCreatingFromMatrix) {
            dispatch(selectEdgeDialogSubmitFromMatrix(selectedStrategy, selectedInstance));
        } else {
            dispatch(selectEdgeDialogSubmit(selectedStrategy, selectedInstance, cellId));
        }
    };

    const onClose = useCallback(() => {
        dispatch(setFocusAndStartEditAction(cellId));
        dispatch(closeDialog(DialogType.SELECT_EDGE_DIALOG));
    }, [cellId]);

    const footer = (
        <DialogFooterButtons
            buttons={[
                {
                    key: 'cancel',
                    onClick: onClose,
                    value: intl.formatMessage(messages.cancelButton),
                },
                {
                    key: 'useSelected',
                    onClick: handleSubmit(SelectedStrategy.useExisting),
                    value: intl.formatMessage(messages.useExisting),
                    visualStyle: 'primary',
                },
                {
                    key: 'createNew',
                    onClick: handleSubmit(SelectedStrategy.createNew),
                    value: intl.formatMessage(messages.createNewObject),
                    visualStyle: 'primary',
                },
            ]}
        />
    );

    return (
        <Dialog
            open={open}
            onCancel={onClose}
            title={intl.formatMessage(messages.selectEdge)}
            width="620px"
            footer={footer}
        >
            <div className={theme.container} data-test="existing_object_dialog">
                <div className={theme.message}>
                    {intl.formatMessage(messages.edgeHeaderMessage, {
                        sourceObjectName,
                        targetObjectName,
                    })}
                </div>
                <div>{intl.formatMessage(messages.useSelectedEdgeDefinition)}</div>
                {dataSource && (
                    <div className={theme.tableContainer}>
                        <Table
                            className={theme.table}
                            rowKey={(record) => record.key}
                            onRow={onRow}
                            dataSource={dataSource}
                            bordered
                            pagination={false}
                            size="middle"
                            columns={columns}
                            scroll={{
                                y: 'max-content',
                                x: 'max-content',
                            }}
                        />
                    </div>
                )}
            </div>
        </Dialog>
    );
};
