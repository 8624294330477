import React, { ChangeEvent, FC, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { Form, FormInstance, Input } from 'antd';
import theme from './MultiLangInputDialog.scss';
import { useSelector } from 'react-redux';
import messages from './MultiLangInputDialog.messages';
import { getCurrentLocale } from '../../selectors/locale.selectors';
import { InternationalString } from '../../serverapi/api';
import locales from '../../static/locales/index.json';
import footerMessages from '../Footer/FooterButtons.messages';
import { LANG } from '../../config/config';
import { LocalesService } from '../../services/LocalesService';
import { Dialog } from '../UIKit/components/Dialog/Dialog.component';
import { OverflowTipInput } from './OverflowTipInput.component';
import cx from 'classnames';
import { DialogFooterButtons } from '../UIKit/components/DialogFooterButtoms/DialogFooterButtons.component';

type TMultiLangInputDialogProps = {
    mainInputName: string;
    generalForm: FormInstance<any> | undefined | null;
    mainInputClassName?: string;
    formItemClassName?: string;
    placeholder?: string;
    multiLangValue?: InternationalString;
    disabled?: boolean;
    maxLength?: number;
    textarea?: boolean;
    required?: boolean;
    label: string | JSX.Element;
    autoSize?: { maxRows: number; minRows: number };
    'data-test'?: string;
    containerDataTest?: string;
    autoFocus?: boolean;
    onChange: (multiLangValue: InternationalString) => void;
    isAgreement?: boolean;
    wrapperClassName?: string;
    showCounter?: boolean;
};

const MultiLangInputDialog: FC<TMultiLangInputDialogProps> = (props) => {
    const {
        maxLength,
        disabled,
        placeholder,
        mainInputClassName,
        formItemClassName,
        textarea,
        multiLangValue,
        required,
        generalForm,
        mainInputName,
        label,
        autoFocus,
        autoSize,
        containerDataTest,
        onChange,
        isAgreement,
        wrapperClassName,
        showCounter,
    } = props;

    const [isModalVisible, setIsModalVisible] = useState(false);
    const currentLocale = useSelector(getCurrentLocale);
    const intl = useIntl();
    const [modalForm] = Form.useForm();
    const localString = LocalesService.internationalStringToString(multiLangValue, currentLocale).trim();

    useEffect(() => {
        if (generalForm) generalForm.setFieldValue(mainInputName, localString);
    }, [localString]);

    const showModal = () => {
        modalForm.setFieldsValue(multiLangValue);
        setIsModalVisible(true);
    };

    const handleOk = () => {
        modalForm
            .validateFields()
            .then((formValues: InternationalString) => {
                generalForm?.setFieldsValue({
                    [mainInputName]: formValues[currentLocale],
                });
                modalForm.resetFields();
                onChange(formValues);
                setIsModalVisible(false);
            })
            .catch(() => {});
    };

    const handleCancel = () => {
        modalForm.resetFields();
        setIsModalVisible(false);
    };

    const changeMainInput = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        onChange({
            ...multiLangValue,
            [currentLocale]: e.target.value.trim(),
        });
    };

    const MainInputProps = {
        className: mainInputClassName,
        placeholder,
        disabled,
        maxLength,
        autoFocus,
        'data-test': props['data-test'],
        onChange: changeMainInput,
    };
    const AutoSizeProps = {
        autoSize,
    };

    const customFooter = (
        <DialogFooterButtons
            buttons={[
                {
                    key: 'back',
                    onClick: handleCancel,
                    value: intl.formatMessage(footerMessages.cancel),
                    dataTest: 'translation-window-cancel_btn',
                },
                {
                    key: 'submit',
                    onClick: handleOk,
                    value: intl.formatMessage(footerMessages.save),
                    visualStyle: 'primary',
                    dataTest: 'translation-window-ok_btn',
                },
            ]}
        />
    );

    const localesLabel = !disabled && (
        <span className={theme.openBtn} onClick={showModal}>
            (<u data-test="name-change-for-two-locales_btn">{currentLocale.toUpperCase()}</u>)
        </span>
    );

    const showCount = ({ maxLength, count }: { maxLength?: number; count: number }) => {
        return maxLength ? maxLength - count : count;
    };

    return (
        <div data-test={containerDataTest} className={cx(wrapperClassName, theme.container)}>
            <Form.Item
                label={
                    isAgreement ? (
                        <div className={theme.labelWrapper}>
                            {localesLabel}
                            <div>{label}</div>
                        </div>
                    ) : (
                        <div className={theme.labelWrapper}>
                            <span>{label}</span>
                            {localesLabel}
                        </div>
                    )
                }
                name={mainInputName}
                initialValue={localString || ''}
                className={formItemClassName}
                rules={[
                    {
                        whitespace: true,
                        message: intl.formatMessage(messages.whitespacesOnly),
                    },
                    {
                        required,
                        message: intl.formatMessage(messages.requiredField),
                    },
                ]}
            >
                {textarea ? (
                    <Input.TextArea
                        {...MainInputProps}
                        {...AutoSizeProps}
                        showCount={
                            showCounter && {
                                formatter: showCount,
                            }
                        }
                    />
                ) : (
                    <OverflowTipInput
                        {...MainInputProps}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') e.preventDefault();
                        }}
                    />
                )}
            </Form.Item>

            <Dialog
                title={intl.formatMessage(messages.modalTitle)}
                open={isModalVisible}
                onCancel={handleCancel}
                footer={customFooter}
                className={theme.dialog}
            >
                <Form labelCol={{ span: 24 }} wrapperCol={{ span: 24 }} form={modalForm}>
                    {locales.map((locale: keyof InternationalString) => {
                        const LocaleInputProps = {
                            placeholder,
                            maxLength,
                            'data-test': `${props['data-test']}_${locale}`,
                        };

                        return (
                            <Form.Item
                                name={locale}
                                key={locale}
                                label={LANG[locale]}
                                className={theme.formItem}
                                rules={[
                                    {
                                        whitespace: true,
                                        message: intl.formatMessage(messages.whitespacesOnly),
                                    },
                                    {
                                        required: locale === currentLocale && required,
                                        message: intl.formatMessage(messages.requiredField),
                                    },
                                ]}
                            >
                                {textarea ? (
                                    <Input.TextArea
                                        className={theme.textArea}
                                        {...LocaleInputProps}
                                        {...AutoSizeProps}
                                        showCount={
                                            showCounter && {
                                                formatter: showCount,
                                            }
                                        }
                                    />
                                ) : (
                                    <Input className={theme.input} {...LocaleInputProps} />
                                )}
                            </Form.Item>
                        );
                    })}
                </Form>
            </Dialog>
        </div>
    );
};

export { MultiLangInputDialog };
