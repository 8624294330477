import { connect } from 'react-redux';
import { TRootState } from '../../../../reducers/root.reducer.types';
import { UsersSelectors } from '../../../../selectors/users.selectors';
import { MetaDataSelectors } from '../../../../selectors/admintools.selectors';
import { TUserDataEditingActionProps } from '../components/UserDataEditing.types';
import UserDataEditingComponent from '../components/UserDataEditing.component';
import { UserDTO, UserDTOLicensesEnum, UserDTOAccessesEnum } from '../../../../serverapi/api';
import { submitingUserData, fetchUserById, openEditUserPermissionsDialog } from '../../../../actions/users.actions';
import { TEditUserPermissionsParams } from '../../../../actions/users.actions.types';
import { workspaceRemoveTabRequest } from '../../../../actions/tabs.actions';
import { TabsSelectors } from '../../../../selectors/tabs.selectors';
import { TWorkspaceTab } from '../../../../models/tab.types';
import { openDialog } from '../../../../actions/dialogs.actions';
import { DialogType } from '../../../DialogRoot/DialogRoot.constants';
import { UsersDAOService } from '../../../../services/dao/UsersDAOService';

const mapStateToProps = (state: TRootState) => {
    const tab = TabsSelectors.getActiveTab(state);
    const userId = tab?.nodeId.id || '';
    const serverId = MetaDataSelectors.getCurrentServerId(state);
    const userData = UsersSelectors.getEditingUser(userId)(state);

    const licenses = Array.from(
        new Set<UserDTOLicensesEnum>([...(userData.licenses || []), ...(userData.licensesFromGroup || [])]),
    );
    const accesses = Array.from(
        new Set<UserDTOAccessesEnum>([...(userData.accesses || []), ...(userData.accessesFromGroup || [])]),
    );

    const userService = new UsersDAOService();
    const getAccessibleRepositories = () => userService.getAccessibleRepositories({ serverId, userId: userData.id });

    return {
        userData,
        licenses,
        accesses,
        tab,
        getAccessibleRepositories,
    };
};

const mapDispatchToProps = (dispatch): TUserDataEditingActionProps => ({
    onSubmit: (userData: UserDTO, isNewUser: boolean) => {
        dispatch(submitingUserData({ userData, isNewUser }));
    },
    onClose: (tab: TWorkspaceTab) => dispatch(workspaceRemoveTabRequest(tab)),
    fetchUserFullData: (userId: number) => dispatch(fetchUserById(userId)),
    onUserPermissionsEdit: (params: TEditUserPermissionsParams) => dispatch(openEditUserPermissionsDialog(params)),
    onShowSessions: (userId: number) => dispatch(openDialog(DialogType.USERS_SESSIONS_HISTORY, { userId })),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserDataEditingComponent);
