import { createSelector } from 'reselect';
import { EdgeType } from '../../src/serverapi/api';
import { INITIAL_EDGE_TYPE_SERVER_STATE } from '../reducers/edgeType.reducer';
import { TRootState } from '../reducers/root.reducer.types';
import { getCurrentLocale } from './locale.selectors';
import { Locale } from '@/modules/Header/components/Header/header.types';
import { LocalesService } from '@/services/LocalesService';

export const edgeTypeStateSelector = (state: TRootState) => state.edgeType;

export namespace EdgeTypeSelectors {
    export const byServerId = (serverId: string) =>
        createSelector(edgeTypeStateSelector, (state) => {
            const s = state.byServerId[serverId];
            if (s) {
                return { ...s };
            }

            return { ...INITIAL_EDGE_TYPE_SERVER_STATE };
        });

    export const byPresetId = (compositeId: { serverId: string; presetId: string }) =>
        createSelector(byServerId(compositeId.serverId), (state) => state.byPresetId[compositeId.presetId]);

    export const listByPresetId = (serverId: string, presetId: string) =>
        createSelector<
            TRootState,
            {
                byId: {
                    [id: string]: EdgeType;
                };
            },
            EdgeType[]
        >(byPresetId({ serverId, presetId }), (state) => Object.values(state?.byId || {}));

    export const listByPresetIdWithName = (serverId: string, presetId: string) =>
        createSelector<TRootState, EdgeType[], Locale, EdgeType[]>(
            listByPresetId(serverId, presetId),
            getCurrentLocale,
            (edgeTypes, locale) =>
                edgeTypes.map((edgeType) => ({
                    ...edgeType,
                    name: LocalesService.internationalStringToString(edgeType.multilingualName, locale),
                })),
        );

    export const byId = (compositeId: { edgeTypeId: string; presetId: string; serverId: string }) =>
        createSelector(
            byPresetId({ serverId: compositeId.serverId, presetId: compositeId.presetId }),
            (state) => state?.byId?.[compositeId.edgeTypeId],
        );
}
