import { AipConfig } from "./AipConfig";
import { AipPreset } from './AipPreset';
import { sourceQueryProcessor} from './SourceQueryProcessor';
import { TAipPresetGroupsNames, TAipPresetTypesMapping, TAipPresetTypesMappingValue } from "./types/AipPreset.types";
import {
    AipLinkedSearch,
    AipSearch,
    NodeId,
    SearchRequestNodeTypesEnum,
    SearchRule,
    SearchRuleAttributeTypeEnum,
    SearchRuleQueryRuleEnum
} from "@/serverapi/api";
import { TProcessedSourceQueryItem } from "./types/SourceQueryProcessor.types";
import { TBuildSearchRuleReturnValue } from "./types/AipSearchRequestBuilder.types";


class AipSearchRequestBuilder {

    build(sourceQuery: string, nodeId: NodeId, aipConfig: AipConfig, aipPreset: AipPreset): AipSearch | null {
        const processedQuery: TProcessedSourceQueryItem[] = sourceQueryProcessor.process(sourceQuery);
        const len: number = processedQuery.length;
        if (!len) return null;
        // часть запроса до первого блока связанности
        const targetObjectDescriptionPart : TProcessedSourceQueryItem[] = this.getTargetObjectDescriptionPart(processedQuery);
        const textQuery: string = this.restoreSourceQuery(targetObjectDescriptionPart);
        const aipSearchRequest: AipSearch | null = this.buildBaseAipSearch(textQuery, targetObjectDescriptionPart, nodeId, aipPreset);

        if (!aipSearchRequest) return null;

        let rest: TProcessedSourceQueryItem[] = processedQuery.slice(targetObjectDescriptionPart.length);
        if (!rest.length) return aipSearchRequest; // больше ничего нету, возвращаем то что есть

        do {
            const closestLinkedWithItemIndex = this.getClosestLinkedWithItemIndex(rest, 1);
            const linkedWithPart: TProcessedSourceQueryItem[] = rest.slice(0, closestLinkedWithItemIndex === -1 ? rest.length : closestLinkedWithItemIndex);
            const partSource: string = this.restoreSourceQuery(linkedWithPart);
            const item: AipLinkedSearch | null= this.buildLinkedWithItem(partSource, linkedWithPart, nodeId, aipPreset);
            if (!item) {
                rest = rest.slice(linkedWithPart.length);
                continue;  // можно здесь и ошибку написать, но я считаю что такие вещи лучше просто игнорировать
            }
            aipSearchRequest.linkedWith?.push(item);
            rest = rest.slice(linkedWithPart.length);
        } while(rest.length);

        return aipSearchRequest;
    }


    private getAllAccessibleTypes(aipPreset: AipPreset): SearchRequestNodeTypesEnum[] {
        const types: TAipPresetTypesMapping = aipPreset.getTypesMapping();
        if (!types) return [];
        const tokenNameToNodeTypeMapping: Record<string, SearchRequestNodeTypesEnum> = {
            OBJECT_DEFINITION: 'OBJECT'
        };
        // пока мы работаем только с моделями, объектами и папками.Всё остальное, включая 'ALIAS' пока убираем
        return Object.keys(types as object)
            .filter(type => !(type in {aliasTypes: 1, edgeTypes: 1}))
            .map(value => {
                const type = types[value].nodeType;
                return type in tokenNameToNodeTypeMapping ? tokenNameToNodeTypeMapping[type] : type;
            });
    }


    private getNodeTypesValues(type: string): SearchRequestNodeTypesEnum[] {
        const tokenNameToNodeTypeMapping: Record<string, SearchRequestNodeTypesEnum[]> = {
            OBJECT_DEFINITION: ['OBJECT'],
            ELEMENT: ['FOLDER', 'MODEL', 'OBJECT']
        };
        return type in tokenNameToNodeTypeMapping ? tokenNameToNodeTypeMapping[type] : [type] as SearchRequestNodeTypesEnum[];
    }


    private restoreSourceQuery(processedQuery: TProcessedSourceQueryItem[]): string {
        return processedQuery.map(val => val?.value?.source).filter(val => val).join(' ');
    }


    private makeBasicAipSearch(textQuery: string, nodeId: NodeId, queryItem: TProcessedSourceQueryItem, aipPreset: AipPreset): AipSearch | null {
        const request: AipSearch = {
            filter: {
                rootSearchNodeId: nodeId,
                limit: -1,
                nodeTypes: [],
                searchRules: []
            },
            linkedWith: [],
            textQuery: textQuery
        }

        const types: TAipPresetTypesMapping = aipPreset.getTypesMapping();
        if (!queryItem.value) return null;
        if (queryItem.type === 'CONFIG') {
            // ищем один из элементов, которые указаны в конфиге в раздеде items
            request.filter.nodeTypes = this.getNodeTypesValues(queryItem.value.tokens[0]);
            return request;
        }
        if (queryItem.type === 'PRESET') {
            // ищем что-то тип чего нашли в пресете
            if (queryItem.value.aipPresetGroup === 'aliasTypes') {
                // это может быть алиас, и для него есть особое поле
                request.aliasId = queryItem.value.ids[0];
            }
            else {
                // значит это не алиас и нужно особым образом заполнить фильтр
                let group: TAipPresetGroupsNames = queryItem.value.aipPresetGroup;
                let attributeTypeId: string = '';
                let values: string[] = [];
                const typesGroup: TAipPresetTypesMappingValue = types[group];
                if (typesGroup && typesGroup.nodeType !== 'ALIAS') {
                    request.filter.nodeTypes = [ typesGroup.nodeType ];
                    attributeTypeId = typesGroup.attributeTypeId;
                    values = queryItem.value.ids;
                }
                else {
                    // значит в пресете вместо того что можно первым делом нашёлся атрибут. В этом случае нужно
                    // Проверить альтернативные варианты и посмотреть, нет ли там данных о чём-то другом.
                    if (queryItem.value.alternate) {
                        group = queryItem.value.alternate[0].aipPresetGroup;
                        const typesGroup: TAipPresetTypesMappingValue = types[group];
                        if (typesGroup && typesGroup.nodeType !== 'ALIAS') {
                            request.filter.nodeTypes = [ typesGroup.nodeType ];
                            attributeTypeId = typesGroup.attributeTypeId;
                            values = queryItem.value.alternate[0].ids;
                        }
                    }
                    else {
                        // альтернативных вариантов нет - значит то что мы восприняли как данные из пресета на самом деле было именем или частью имени
                        request.filter.nodeTypes = this.getAllAccessibleTypes(aipPreset);
                        request.filter.searchRules = [
                            {
                                attributeType: "SYSTEM",
                                attributeTypeId: 'name',
                                queryRule: "CONTAINS",
                                values: [queryItem.value.source]
                            }
                        ];
                        return request;
                    }
                }
                if (attributeTypeId && values.length) {
                    request.filter.searchRules = [
                        {
                            attributeType: "SYSTEM",
                            attributeTypeId: attributeTypeId,
                            queryRule: "EQUALS",
                            values: values
                        }
                    ];
                }
            }
            return request
        }
        if (queryItem.type === 'NOWHERE') {
            request.filter.nodeTypes = this.getAllAccessibleTypes(aipPreset);
            request.filter.searchRules = [
                {
                    attributeType: "SYSTEM",
                    attributeTypeId: 'name',
                    queryRule: "CONTAINS",
                    values: [queryItem.value.source]
                }
            ];
            return request;
        }
        return null;
    }


    private buildAdditionalSearchRuleResponse(attributeType: SearchRuleAttributeTypeEnum, attributeTypeId: string, queryRule: SearchRuleQueryRuleEnum, values: string[], len: number): TBuildSearchRuleReturnValue {
        return {
            rule: {
                attributeType: attributeType,
                attributeTypeId: attributeTypeId,
                queryRule: queryRule,
                values: values
            },
            len: len
        }
    }


    private makeAdditionalSearchRule(queryItem: TProcessedSourceQueryItem, itemIndex: number, queryPart: TProcessedSourceQueryItem[], aipPreset: AipPreset): TBuildSearchRuleReturnValue {
        const typesMapping: TAipPresetTypesMapping = aipPreset.getTypesMapping();
        if (!queryItem.value) return {rule: null, len: 1};
        const secondQueryItem: TProcessedSourceQueryItem = queryPart[itemIndex + 1];
        if (queryItem.type === 'CONFIG') {
            if (!queryItem.value) return {rule: null, len: 1};
            if (queryItem.value.aipConfigGroup === 'WITH_SOMETHING') {
                if (!secondQueryItem || !secondQueryItem.value) return {rule: null, len: 1};
                const tokens: string[] = queryItem.value.tokens;
                if (tokens[0] === 'WITH' && tokens[1] === 'NAME') {
                    return this.buildAdditionalSearchRuleResponse("SYSTEM", "name", "CONTAINS", [ secondQueryItem.value.source ], 2);
                }
                if (tokens[0] === 'WITH' && tokens[1] === 'TYPE') {
                    if (secondQueryItem.type !== 'PRESET') return {rule: null, len: 1}; // следом за конструкцией "с типом" должен быть тип из пресета
                    const grp = secondQueryItem.value.aipPresetGroup;
                    if (grp && typesMapping[grp]) {
                        const attributeTypeId: string | undefined = typesMapping[grp]?.attributeTypeId;
                        if (attributeTypeId) {
                            return this.buildAdditionalSearchRuleResponse("SYSTEM", attributeTypeId, "EQUALS", secondQueryItem.value.ids, 2);
                        }
                    }
                }
                if (tokens[0] === 'WITH' && tokens[1] === 'ATTRIBUTE') {
                    if (secondQueryItem.type == 'PRESET') {
                        // следом за конструкцией "с атрибутом" идёт что-то из пресета
                        if (secondQueryItem.value.aipPresetGroup in {attributeTypes: 1}) {
                            const attributeTypeId: string = secondQueryItem.value.ids[0];
                            const thirdQueryItem = queryPart[itemIndex + 2];
                            if (!thirdQueryItem) {
                                return this.buildAdditionalSearchRuleResponse('USER', attributeTypeId, 'HAS_VALUE', [], 2);
                            }
                            else {
                                if (thirdQueryItem.type === 'NOWHERE') {
                                    // это часть конструкции 'с атрибутом АТРИБУТ_ИЗ_ПРЕСЕТА ЗНАЧЕНИЕ'
                                    return this.buildAdditionalSearchRuleResponse('USER', attributeTypeId, 'CONTAINS', [ thirdQueryItem.value.source ], 3);
                                }
                                else {
                                    // это часть конструкции 'с атрибутом АТРИБУТ_ИЗ_ПРЕСЕТА', без значения
                                    return this.buildAdditionalSearchRuleResponse('USER', attributeTypeId, 'HAS_VALUE', [], 2);
                                }
                            }
                        }
                    }
                    if (secondQueryItem.type === 'CONFIG') {
                        const thirdQueryItem = queryPart[itemIndex + 2];
                        if (!thirdQueryItem || !thirdQueryItem.value) return {rule: null, len: 1};
                        if (secondQueryItem.value.aipConfigGroup === 'ATTRIBUTE' && secondQueryItem.value.tokens[0] === 'NAME') {
                            if (thirdQueryItem.type === 'NOWHERE') {
                                // это конструкция 'с атрибутом ИМЯ ЗНАЧЕНИЕ'
                                return this.buildAdditionalSearchRuleResponse('SYSTEM', 'name', 'CONTAINS', [ thirdQueryItem.value.source ], 3);
                            }
                            else {
                                // по идее после "с атрибутом имя" должен идти текстовый блок с именем. Надо будет подумать что сделать если его нет
                                // и написать этот код здесь
                                return {rule: null, len: 1};
                            }
                        }
                        if (secondQueryItem.value.aipConfigGroup === 'ATTRIBUTE' && secondQueryItem.value.tokens[0] === 'TYPE') {
                            if (thirdQueryItem.type === 'PRESET') {
                                const aipPresetGroup: TAipPresetGroupsNames = thirdQueryItem.value.aipPresetGroup;
                                // после конструкции 'с атрибутом тип' должен идти тип модели или объекта. если это не так - это ошибка
                                if (!(aipPresetGroup in {modelTypes: 1, objectTypes: 1})) return {rule: null, len: 1};
                                // это конструкция 'с атрибутом тип ТИП_ИЗ_ПРЕСЕТА'
                                const attributeTypeId: string = aipPresetGroup === 'modelTypes' ? 'modelTypeId' : 'objectTypeId';
                                return this.buildAdditionalSearchRuleResponse('SYSTEM', attributeTypeId, 'EQUALS', thirdQueryItem.value.ids, 3);
                            }
                            else {
                                // после конструкции 'с атрибутом тип' не указан типа из пресета
                                return {rule: null, len: 1};
                            }
                        }
                    }
                }
            }
            if (queryItem.value.aipConfigGroup === 'PREPOSITION' && queryItem.value.tokens[0] === 'WITH') {
                if (secondQueryItem.type !== 'PRESET') return {rule: null, len: 1};
                // следом за словом "с" может быть только тип атрибута из пресета
                if (!secondQueryItem.value) return {rule: null, len: 1};
                if (!(secondQueryItem.value.aipPresetGroup in {attributeTypes: 1})) return {rule: null, len: 1};
                const attributeTypeId: string = secondQueryItem.value.ids[0];
                const thirdQueryItem = queryPart[itemIndex + 2];
                if (thirdQueryItem  && thirdQueryItem.type === 'NOWHERE') {
                    // это конструкции 'с АТРИБУТ_ИЗ_ПРЕСЕТА ЗНАЧЕНИЕ'
                    return this.buildAdditionalSearchRuleResponse('USER', attributeTypeId, 'CONTAINS', [ thirdQueryItem.value.source ], 3);
                }
                else {
                    if (!thirdQueryItem) {
                        // это конструкции 'с АТРИБУТ_ИЗ_ПРЕСЕТА'
                        return this.buildAdditionalSearchRuleResponse('USER', attributeTypeId, 'HAS_VALUE', [], 2);
                    }
                    else {
                        // это конструкции 'с АТРИБУТ_ИЗ_ПРЕСЕТА ТИП_ИЗ_ПРЕСЕТА'. Вероятно, мы неверно определили следующий (третий) элемент и его надо
                        // воспринимать не как тип из пресета, а как значение атрибута.
                        const values: string[] | null = thirdQueryItem.value?.source ? [thirdQueryItem.value?.source] : null
                        return this.buildAdditionalSearchRuleResponse('USER', attributeTypeId, values ? 'CONTAINS' : 'HAS_VALUE', values ? values : [], values ? 3 : 2);
                    }
                }
            }
            return {rule: null, len: 1};
        }
        if (queryItem.type === 'PRESET') {
            let group: TAipPresetGroupsNames = queryItem.value.aipPresetGroup;
            if (group in typesMapping) {
                const grp = queryItem.value.aipPresetGroup;
                if (grp && typesMapping && typesMapping[grp]) {
                    const attributeTypeId: string | undefined = typesMapping[grp]?.attributeTypeId;
                    if (attributeTypeId) {
                        return this.buildAdditionalSearchRuleResponse("SYSTEM", attributeTypeId, "EQUALS", queryItem.value.ids, 1);
                    }
                }
            }
            else {
                // значит мы нашли атрибут там где его не должно было быть. В этом случае считает текст из поискового запроса текстом для поиска
                return this.buildAdditionalSearchRuleResponse("SYSTEM", 'name', "CONTAINS", [ queryItem.value.source ], 1);
            }
        }
        if (queryItem.type === 'NOWHERE') {
            return this.buildAdditionalSearchRuleResponse("SYSTEM", "name", "CONTAINS", [ queryItem.value.source ], 1);
        }
        return {rule: null, len: 1};
    }


    private buildBaseAipSearch(textQuery: string, queryPart: TProcessedSourceQueryItem[], nodeId: NodeId, aipPreset: AipPreset): AipSearch | null {
        const len = queryPart.length;
        if (!len) return null;
        /*
            возможны 3 ситуации:
                1. указан только 1 элемент. Это может быть
                    - элемент из конфига (папка, модель, объект, элемент)
                    - тип объекта или модели из пресета
                    - часть имени - значит будем искать папка, модель или объект с таким текстом в имени
                2. указаны элементы 1 и 2. Это могут быть
                    - первый - элемент из конфига (папка, модель, объект, элемент), второй - тип из пресета (соответствующий первому)
                    - первый - элемент из конфига (папка, модель, объект, элемент), второй - часть его имени
                    - первый - тип (модели или объекта) из пресета,                 второй - часть его имени
                3. указаны элементы 1, 2 и 3. Это могут быть
                    - первый - элемент из конфига, второй - тип из пресета (соответствующий первому), третий - часть имени
            как бы там ни было - первая часть - это база, а остальные если есть - дополнительные правила
        */
        const request: AipSearch | null = this.makeBasicAipSearch(textQuery, nodeId, queryPart[0], aipPreset);

        if (!request) return request;

        for (let i = 1; i < len; ) {
            const item: TProcessedSourceQueryItem = queryPart[i];
            const resp: {rule: SearchRule | null, len: number} = this.makeAdditionalSearchRule(item, i, queryPart, aipPreset);
            if (!resp.rule) {
                i += resp.len;
                continue;  // можно здесь и ошибку написать, но я считаю что такие вещи лучше просто игнорировать
            }
            // TODO переделать этот преступный инкремент на что-то более цивильное
            i += resp.len;
            request.filter.searchRules?.push(resp.rule);
        }

        return request;
    }


    // возвращает индекс ближайшего элемента, задающего связанность
    private getClosestLinkedWithItemIndex(processedQuery: TProcessedSourceQueryItem[], startFrom: number = 0): number {
        return processedQuery.findIndex((val: TProcessedSourceQueryItem, index: number) => {
            return (index >= startFrom)
                && ((val.type === 'PRESET' && val.value?.aipPresetGroup === 'edgeTypes')
                    || (val.type === 'CONFIG' && val.value?.aipConfigGroup === 'LINKED_WITH'));
        });
    }


    private getTargetObjectDescriptionPart(processedQuery: TProcessedSourceQueryItem[]): TProcessedSourceQueryItem[] {
        const closestLinkedWithItemIndex = this.getClosestLinkedWithItemIndex(processedQuery);
        return processedQuery.slice(0, closestLinkedWithItemIndex === -1 ? processedQuery.length : closestLinkedWithItemIndex);
    }


    private buildLinkedWithItem(textQuery: string, queryPart: TProcessedSourceQueryItem[], nodeId: NodeId, aipPreset: AipPreset): AipLinkedSearch | null {
        const firstItem: TProcessedSourceQueryItem = queryPart[0];
        if (!firstItem.value) return null;
        if (firstItem.type === 'PRESET' && !(firstItem.value.aipPresetGroup in {edgeTypes: 1})) {
            return null; // по идее не должно такого быть раз это элемент с указанием связи
        }
        if (queryPart.length < 2) return null; // мало данных, невозможно понять с чем должен быть связан объект
        // первый элемент характеризует связь, поэтому в обработку в общую функцию мы его не отправляем, добавим её куда надо несколькими строками ниже если сам запрос будет успешный
        const aipSearchRequest: AipSearch | null = this.buildBaseAipSearch(textQuery, queryPart.slice(1), nodeId, aipPreset);
        if (!aipSearchRequest) return null;
        const linkedWithItem: AipLinkedSearch = {
            connected: aipSearchRequest
        };

        if (firstItem.type === 'PRESET') {
            linkedWithItem.edgeTypeId = firstItem.value.ids[0];
        }

        return linkedWithItem;
    }

};
export const aipSearchRequestBuilder = new AipSearchRequestBuilder();
