import { TExtendedNode } from '@/selectors/types/nodesSelector.types';
import { TNodeState } from '../../../reducers/entities/TNodeState';
import { SearchResult } from '../../../serverapi/api';

export enum TNumericFilterRules {
    EQUALS = 'EQUALS',
    GREATER = 'GREATER',
    LESS = 'LESS',
    BETWEEN = 'BETWEEN',
}

export enum TFilterType {
    STRING = 'STRING',
    NUMERIC = 'NUMERIC',
    DATE = 'DATE',
}

export type TNumericFilter = {
    rule?: TNumericFilterRules;
    value1?: number;
    value2?: number;
};

export type TReportFilterData = {
    filterType: TFilterType;
    data: TNumericFilter | string[];
};

export type TReportFilters = {
    [columnId: string]: TReportFilterData;
};

export type TReportEditor = {
    selectedColumnId?: string;
    searchValue?: string;
    unsaved?: boolean;
    loading?: boolean;
    searchResults?: SearchResult[];
    columnFilters?: TReportFilters;
};

export type TReportEditorState = {
    editors: TNodeState<TReportEditor>;
    nodes: TNodeState<TExtendedNode>;
};
