import type { TRichTextEditorHandlers } from '../RichTextEditor.types';
import type { Editor } from '@tiptap/react';
import type { IntlShape } from 'react-intl';
import React, { useState } from 'react';
import { useContextMenuDublicateDelete } from '@/hooks/useContextMenuDublicateDelete';
// TODO перенести сообщения в UIKit
import messages from '../../../../../Wiki/messages/WikiEditor.messages';
import { NodeId } from '@/serverapi/api';
import { useIntl } from 'react-intl';
import { MenuInfo } from 'rc-menu/lib/interface';
import { ContextMenu } from '@/modules/ContextMenu/components/ContextMenu.component';
import { handleComment, handleCopy, handleCut, handlePaste, handleTocSettings } from './ContextMenu.helpers';
import { hasSelectedText, isImageSelected, isTableSelected, isTocSelected } from '../../common/helpers';
import theme from '../RichTextEditor.component.scss';

type TContextMenuProps = {
    editor: Editor | null;
    modelId: NodeId | undefined;
    children: React.ReactNode;
} & Partial<TRichTextEditorHandlers>;

type TMenuItem = {
    label: string;
    disabled: boolean;
    key: string;
    'data-test': string;
};
enum EditorAction {
    // common
    CUT = 'cut',
    COPY = 'copy',
    PASTE = 'paste',
    DELETE = 'delete',
    COMMENT = 'comment',
    // toc
    CUSTOMIZE_TOC = 'customizeToc',
    // image
    DELETE_IMAGE = 'deleteImage',
    // table
    SET_CELL_BACKGROUND = 'setCellBackground',
    ADD_COLUMN_AFTER = 'addColumnAfter',
    ADD_ROW_AFTER = 'addRowAfter',
    DELETE_COLUMN = 'deleteColumn',
    DELETE_ROW = 'deleteRow',
    CLEAR_CELL = 'clearCell',
    SPLIT_CELL = 'splitCell',
    TOGGLE_HEADER_ROW = 'toggleHeaderRow',
    MERGE_CELLS = 'mergeCells',
    DELETE_TABLE = 'deleteTable',
}

const actions = {
    [EditorAction.CUT]: {
        key: EditorAction.CUT,
        label: messages.cut,
        dataTest: 'wiki_context-menu_cut',
        disabled: (editor: Editor) => !hasSelectedText(editor),
        action: handleCut,
    },
    [EditorAction.COPY]: {
        key: EditorAction.COPY,
        label: messages.copy,
        dataTest: 'wiki_context-menu_copy',
        disabled: (editor: Editor) => !hasSelectedText(editor),
        action: handleCopy,
    },
    [EditorAction.PASTE]: {
        key: EditorAction.PASTE,
        label: messages.paste,
        dataTest: 'wiki_context-menu_paste',
        disabled: (editor: Editor) => false,
        action: handlePaste,
    },
    [EditorAction.DELETE]: {
        key: EditorAction.DELETE,
        label: messages.delete,
        dataTest: 'wiki_context-menu_delete',
        disabled: (editor: Editor) => !hasSelectedText(editor),
        action: (editor: Editor) => editor.commands.deleteSelection(),
    },
    [EditorAction.COMMENT]: {
        key: EditorAction.COMMENT,
        label: messages.toggleComment,
        dataTest: 'wiki_context-menu_comment',
        // TODO
        disabled: (editor: Editor) => false,
        action: handleComment,
    },
    ////
    [EditorAction.CUSTOMIZE_TOC]: {
        key: EditorAction.CUSTOMIZE_TOC,
        label: messages.customizeToc,
        dataTest: '',
        disabled: (editor: Editor) => false,
        action: handleTocSettings,
    },
    ////
    [EditorAction.DELETE_IMAGE]: {
        key: EditorAction.DELETE_IMAGE,
        label: messages.deleteImage,
        dataTest: '',
        disabled: (editor: Editor) => false,
        action: (editor: Editor) => editor.commands.deleteSelection(),
    },
    ////
    [EditorAction.SET_CELL_BACKGROUND]: {
        key: EditorAction.SET_CELL_BACKGROUND,
        label: messages.setBackground, // TODO rename
        dataTest: '',
        // TODO
        disabled: (editor: Editor) => true,
        action: (editor: Editor) => null,
    },
    [EditorAction.ADD_COLUMN_AFTER]: {
        key: EditorAction.ADD_COLUMN_AFTER,
        label: messages.insertColumn,
        dataTest: '',
        disabled: (editor: Editor) => !editor.can().addColumnAfter(),
        action: (editor: Editor) => editor.chain().focus().addColumnAfter().run(),
    },
    [EditorAction.ADD_ROW_AFTER]: {
        key: EditorAction.ADD_ROW_AFTER,
        label: messages.insertRow,
        dataTest: '',
        disabled: (editor: Editor) => !editor.can().addRowAfter(),
        action: (editor: Editor) => editor.chain().focus().addRowAfter().run(),
    },
    [EditorAction.DELETE_COLUMN]: {
        key: EditorAction.DELETE_COLUMN,
        label: messages.deleteColumn,
        dataTest: '',
        disabled: (editor: Editor) => !editor.can().deleteColumn(),
        action: (editor: Editor) => editor.chain().focus().deleteColumn().run(),
    },
    [EditorAction.DELETE_ROW]: {
        key: EditorAction.DELETE_ROW,
        label: messages.deleteRow,
        dataTest: '',
        disabled: (editor: Editor) => !editor.can().deleteRow(),
        action: (editor: Editor) => editor.chain().focus().deleteRow().run(),
    },
    [EditorAction.CLEAR_CELL]: {
        key: EditorAction.CLEAR_CELL,
        label: messages.clearCell,
        dataTest: '',
        // TODO
        disabled: (editor: Editor) => true,
        action: (editor: Editor) => null,
    },
    [EditorAction.SPLIT_CELL]: {
        key: EditorAction.SPLIT_CELL,
        label: messages.divideCell, // TODO rename
        dataTest: '',
        disabled: (editor: Editor) => !editor.can().splitCell(),
        action: (editor: Editor) => editor.chain().focus().splitCell().run(),
    },
    [EditorAction.TOGGLE_HEADER_ROW]: {
        key: EditorAction.TOGGLE_HEADER_ROW,
        label: messages.headerRow, // TODO rename
        dataTest: '',
        disabled: (editor: Editor) => !editor.can().toggleHeaderRow(),
        action: (editor: Editor) => editor.chain().focus().toggleHeaderRow().run(),
    },
    [EditorAction.MERGE_CELLS]: {
        key: EditorAction.MERGE_CELLS,
        label: messages.unionCells, // TODO rename
        dataTest: '',
        disabled: (editor: Editor) => !editor.can().mergeCells(),
        action: (editor: Editor) => editor.chain().focus().mergeCells().run(),
    },
    [EditorAction.DELETE_TABLE]: {
        key: EditorAction.DELETE_TABLE,
        label: messages.deleteTable, // TODO rename
        dataTest: '',
        disabled: (editor: Editor) => !editor.can().deleteTable(),
        action: (editor: Editor) => editor.chain().focus().deleteTable().run(),
    },
};

const mapMenuItem = (editor: Editor, intl: IntlShape) => (item: EditorAction) => ({
    key: actions[item].key,
    label: intl.formatMessage(actions[item].label),
    disabled: actions[item].disabled(editor),
    'data-test': actions[item].dataTest,
});

const getMenuItems = (editor: Editor | null, intl: IntlShape): TMenuItem[] => {
    if (!editor) {
        return [];
    }

    const isTable = isTableSelected(editor);
    const isImage = isImageSelected(editor);
    const isToc = isTocSelected(editor);

    const commonItems = [
        EditorAction.CUT,
        EditorAction.COPY,
        EditorAction.PASTE,
        EditorAction.DELETE,
        EditorAction.COMMENT,
    ].map(mapMenuItem(editor, intl));

    if (isToc) {
        return [EditorAction.CUSTOMIZE_TOC, EditorAction.DELETE].map(mapMenuItem(editor, intl));
    }

    if (isImage) {
        return [EditorAction.DELETE_IMAGE].map(mapMenuItem(editor, intl));
    }

    if (isTable) {
        const singleItems = [
            // temporary disabled
            // EditorAction.SET_CELL_BACKGROUND,
            EditorAction.ADD_COLUMN_AFTER,
            EditorAction.ADD_ROW_AFTER,
            EditorAction.DELETE_COLUMN,
            EditorAction.DELETE_ROW,
            EditorAction.SPLIT_CELL,
            EditorAction.TOGGLE_HEADER_ROW,
            EditorAction.DELETE_TABLE,
        ].map(mapMenuItem(editor, intl));

        const canMergeCells = editor.can().mergeCells();
        const multiItems = [EditorAction.MERGE_CELLS].map(mapMenuItem(editor, intl));

        return canMergeCells ? multiItems : singleItems;
    }

    return commonItems;
};

const ContextMenuComponent = (props: TContextMenuProps) => {
    const { editor, readFromClipboard, copyToClipboard, openComment, openTocSettings } = props;
    const intl = useIntl();
    const [isOpen, setDropdownOpenState] = useContextMenuDublicateDelete('wiki', props.modelId);

    const handleItemClick = (action: MenuInfo) => {
        const act = actions[action.key];

        act.action(editor, { readFromClipboard, copyToClipboard, openComment, openTocSettings });
        setDropdownOpenState(false);
    };

    const [items, setItems] = useState<TMenuItem[]>([]);

    const handleOpenChange = (isVisible: boolean) => {
        setTimeout(() => {
            setItems(getMenuItems(editor, intl));
            setDropdownOpenState(isVisible);
            // TODO 100 ms нужны, чтобы при ПКМ по изображению вызывалось соответствующее меню
        }, 100);
    };

    return (
        <ContextMenu
            overlay={items}
            visible={isOpen}
            className={theme.overlayContainer}
            trigger={['contextMenu']}
            onClick={handleItemClick}
            onOpenChange={handleOpenChange}
        >
            {/* TODO разобраться, почему без этого враппера не работает ПКМ по imageRenderer */}
            <div /*onClick={(e) => e.stopPropagation()}*/>{props.children}</div>
        </ContextMenu>
    );
};

export { ContextMenuComponent as ContextMenu };
