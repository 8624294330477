import React from 'react';
import { buttonTypes } from './ButtonSettings.types';
import { ButtonRow } from './components/ButtonRow/ButtonRow.component';
import { TPropsWithFormRef } from '@/modules/AdminTools/ServerSettings/SystemProperties.types';
import theme from './ButtonSettings.scss';
import { useIntl } from 'react-intl';
import messages from '../../../../SystemProperties.messages';

export const ButtonSettings = ({ formRef }: TPropsWithFormRef) => {
    const intl = useIntl();

    return (
        <table className={theme.root}>
            <thead>
                <tr className={theme.groupHeader}>
                    <th>{intl.formatMessage(messages.buttons)}</th>
                    <th></th>
                    <th colSpan={3} />
                    <th colSpan={3} className={theme.headerInfo}>
                        {intl.formatMessage(messages.buttonHover)}
                    </th>
                </tr>
                <tr className={theme.header}>
                    <th>{intl.formatMessage(messages.colorThemeObject)}</th>
                    <th></th>
                    <th>{intl.formatMessage(messages.buttonBackgroundColor)}</th>
                    <th>{intl.formatMessage(messages.buttonTextColor)}</th>
                    <th>{intl.formatMessage(messages.buttonBorderColor)}</th>
                    <th>{intl.formatMessage(messages.buttonBackgroundColor)}</th>
                    <th>{intl.formatMessage(messages.buttonTextColor)}</th>
                    <th>{intl.formatMessage(messages.buttonBorderColor)}</th>
                </tr>
            </thead>
            <tbody>
                {buttonTypes.reduce((acc, buttonType) => {
                    return [
                        ...acc,
                        <ButtonRow buttonType={buttonType} danger={false} formRef={formRef} key={`${buttonType}`} />,
                        <ButtonRow
                            buttonType={buttonType}
                            danger={true}
                            formRef={formRef}
                            key={`${buttonType}danger`}
                        />,
                    ];
                }, [])}
            </tbody>
        </table>
    );
};
