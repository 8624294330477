import { TDistanceOfLevenshteinOperationsCosts } from "./types/StringSimilarityCheckToolbox.types";

export class StringsEqualityToolbox {

    /*
        Функция вычисляет расстояние Левенштейна/дистанцию Левенштейна/редакционное расстояние. Чем больше дистанция/расстояние,
        тем больше различаются строки (тем больше возращаемое значение). Для двух одинаковых строк расстояние равно 0.
        Расстояние вычисляется на основании количества удалений/вставок/замен, которые требуются проделать со строками для того чтобы
        в итоге они стали воспадать. Стоимость любой операции (удаление/вставка/замена/замена с учётом регистра) можно указать в
        аргументе costs. При его отсутствии стоимость каждой операции принимается за 1.
    */
    static distanceOfLevenshtein(str1: string, str2: string, costs: TDistanceOfLevenshteinOperationsCosts = {}): number {
        const costReplace: number = costs.replace || 1;
        const cri: number = costs.replaceCase || costs.replace || 1;
        const costInsert: number = costs.insert || 1;
        const costDelete: number = costs.delete || 1;

        const len1: number = str1.length;
        const len2: number = str2.length;

        const cutHalf: number = Math.max(len1, len2);
        let flip: number = cutHalf;

        const minCost: number = Math.min(costDelete, costInsert, costReplace);
        const minD: number = Math.max(minCost, (len1 - len2) * costDelete);
        const minI: number = Math.max(minCost, (len2 - len1) * costInsert);
        const buf: number[] = new Array((cutHalf * 2) - 1);

        for (let i = 0; i <= len2; ++i) {
            buf[i] = i * minD;
        }

        for (let i = 0; i < len1; ++i, flip = cutHalf - flip) {
            const ch = str1[i];
            const lowercasedChar = ch.toLowerCase();

            buf[flip] = (i + 1) * minI;

            let n = flip;
            let m = cutHalf - flip;

            for (let j = 0; j < len2; ++j, ++n, ++m) {
                const cost = (ch === str2[j] ? 0 : (lowercasedChar === str2[j].toLowerCase()) ? cri : costReplace);
                buf[n + 1] = Math.min(buf[m + 1] + costDelete, buf[n] + costInsert, buf[m] + cost);
            }
        }
        return buf[len2 + cutHalf - flip];
    }


    // Выполняет предварительные проверки которые говорят о том, стОит ли вообще сравнивать указанные строки.
    // Вернёт true если строки следует впоследствии сравнить. Если строки не удовлетворяют хотя бы одному из критериев - вернёт false.
    // Первым аргументом в функцию будет приходить значение которое ввёл пользователь, вторым - значение имени из пресета.
    // Нарушение этого порядка не смертельно
    static stringsMatchingPreCheck(str1: string, str2: string): boolean {
        str1 = str1.toLowerCase();
        str2 = str2.toLowerCase();

        // Разделяем строки на слова, удаляем крайние пробелы если они есть
        const wordsOfStr1: string[] = str1.split(' ').map(word => word.trim());
        const wordsOfStr2: string[] = str2.split(' ').map(word => word.trim());

        // Длина первого слова первой строки
        const firstWordOfStr1Length: number = wordsOfStr1[0].length;
        // количество символов в начале строки, которые обязательно должны совпадать у обеих строк
        // чем длинее строка, тем больше символов должны совпадать полностью
        const len: number = firstWordOfStr1Length < 4 ? 2 : (firstWordOfStr1Length < 6 ? 3 : 5);
         // у потенциально совпадающих строк первые len символов должны совпадать
        if (str2.slice(0, len) !== str1.slice(0, len)) return false;

        // потенциально совпадающие строки должны иметь одинаковое количество слов
        if (wordsOfStr2.length !== wordsOfStr1.length) return false;

        // первые буквы каждого слова (начиная со второго), в многословных строках должны совпадать
        if (wordsOfStr2.length > 1 && (wordsOfStr2.map(word => word[0]).join('') !== wordsOfStr1.map(word => word[0]).join(''))) return false;

        // если ищем многословное значение то длина соответствуюих слов не должна различаться больше чем на 2
        // для слов из искомого значения длиной не более 3 символов. Если слово изначально короткое - оно и должно быть коротким во
        // всех словоформах если на месте какого-то слово стоит что-то явно длинее - значит эти строки не нужно больше пытаться сравнивать
        if (wordsOfStr1.length > 1
            && (wordsOfStr1.some((word, index) => word.length < 3 && Math.abs(word.length - wordsOfStr2[index].length) > 2))) {
            return false;
        }

        return true;
    }
};
