export const GET_AIP_SEARCH_RESULT  = 'GET_AIP_SEARCH_RESULT';

export const SET_AIP_SEARCH_DATA  = 'SET_AIP_SEARCH_DATA';

export const AIP_SEARCH_ALIASES_REQUEST = 'AIP_SEARCH_ALIASES_REQUEST';

export const AIP_SEARCH_ALIASES_REQUEST_SUCCESS = 'AIP_SEARCH_ALIASES_REQUEST_SUCCESS';

export const AIP_SEARCH_CREATE_ALIAS_REQUEST = 'AIP_SEARCH_CREATE_ALIAS_REQUEST';

export const AIP_SEARCH_DELETE_BY_ID_ALIAS_REQUEST = 'AIP_SEARCH_DELETE_BY_ID_ALIAS_REQUEST';
