// @test-ignore_ru
import { AipToolbox } from '@/components/aip/bll/AipToolbox';
import  {
    TAipPresetData,
    TAipPresetGroupsNames,
    TAipPresetTypesMapping,
    TFoundInPreset,
    TFoundInPresetHelper,
    TFoundValueGroupAndIds,
    TNgFoundInPreset,
    TReducedOriginalPresetCommonItem,
    TSearchGroupsFilter
} from './types/AipPreset.types';
import { StringsEqualityToolbox } from './StringsEqualityToolbox';


export class AipPreset {

    private aipPreset: TAipPresetData = null;

    private synonyms: TAipPresetData = null;

    private isInitialized: boolean = false;

    get() {
        return this.aipPreset;
    }


    getTypesMapping(): TAipPresetTypesMapping {
        return {
            aliasTypes: {
                attributeTypeId: 'aliasTypeId',
                nodeType: 'ALIAS'
            },
            edgeTypes: {
                attributeTypeId: 'edgeTypeId',
                nodeType: 'EDGE'
            },
            folderTypes: {
                attributeTypeId: 'folderTypeId',
                nodeType: 'FOLDER'
            },
            modelTypes: {
                attributeTypeId: 'modelTypeId',
                nodeType: 'MODEL'
            },
            objectTypes: {
                attributeTypeId: 'objectTypeId',
                nodeType: 'OBJECT'
            }
        }
    }


    whetherHasBeenInitialized(): boolean {
        return this.isInitialized;
    }


    init(data: TAipPresetData) {
        if (this.isInitialized) return;
        this.aipPreset = data;
        this.synonyms = this.initSynonyms();
        this.isInitialized = true;
    }


    findFullString(str: string, searchGroups?: TSearchGroupsFilter): TNgFoundInPreset {
        // все слова строкИ, пробелы по краям отрезаны, пустых строк нет
        const words: string[] = AipToolbox.getWordsFromString(str);
        const len: number = words.length;
        const aipPreset = this.aipPreset;

        if (!len || !aipPreset) return null;

        const found = this.findArrayPartFromEnd(words, searchGroups);
        if (!found) return null;
        // исправить строчку ниже обязательно! as TAipPresetGroupsNames[] это признак того что тут что-то не то
        const foundGroups: TAipPresetGroupsNames[] = Object.keys(found.data) as TAipPresetGroupsNames[];
        const group: TAipPresetGroupsNames = foundGroups[0];
        const item = found.data[group];
        if (!item) return null;
        const result: TNgFoundInPreset = {
            aipPresetGroup: group,
            ids: Object.keys(item),
            found: Object.values(item)[0] as string,
            source: found.string
        };
        if (foundGroups.length > 1) {
            result.alternate = foundGroups.slice(1).reduce((acc: TFoundValueGroupAndIds[], group: TAipPresetGroupsNames) => {
                const item = found.data[group];
                if (!item) return acc;
                acc.push({
                    aipPresetGroup: group,
                    ids: Object.keys(item)
                });
                return acc;
            }, []);
        }
        return result;
    }


    findArrayPartFromEnd(array: string[], searchGroups?: TSearchGroupsFilter) {
        const len: number = array.length;
        for (let i: number = len; i > 0; i--) {
            const str: string = array.slice(0, i).join(' ');
            const dataInPreset: TFoundInPreset = this.find(str, searchGroups);
            if (dataInPreset) {
                return {
                    data: dataInPreset,
                    length: i,
                    string: str
                }
            }
        }
        return null;
    }


    find(name: string, searchGroups?: TSearchGroupsFilter): TFoundInPreset {
        // Это строка (имя), которую необходимо найти среди значений в пресете
        name = name.toLowerCase();
        let results: TFoundInPresetHelper[] = [];
        const aipPreset: TAipPresetData = this.aipPreset;
        const synonyms: TAipPresetData = this.synonyms;
        if (!aipPreset || !synonyms) return null; // возможно надо эксепшен выбросить, потому что эта ситуация ненормальная
        const typesStorages: TAipPresetData[] = [aipPreset, synonyms];

        typesStorages.forEach((storage: TAipPresetData) => {
            for (const group in storage) {
                if (searchGroups && !(group as keyof TAipPresetData in searchGroups)) continue;
                const texts: TReducedOriginalPresetCommonItem[] = storage[group];
                let foundItemsInfo = texts
                    .reduce((acc: TFoundInPresetHelper[], item) => {
                        // значение из пресета (тип модели, объекта, атрибута, связи и т.п.)
                        const txt: string | undefined = item.multilingualName?.ru?.toLowerCase();
                        if (!txt) return acc;
                        if (!StringsEqualityToolbox.stringsMatchingPreCheck(name, txt)) return acc;
                        // Если код дошёл до сюда, значит строки есть смысл сравнивать на похожесть Делать это будет
                        // с использованием дистанции Левенштейна между двумя строками

                        const nameLength = name.length;
                        const specificGroup: TAipPresetGroupsNames = TAipPresetGroupsNames.edgeTypes;
                        // при вводе запроса тип связи изменяется сильнее всего, поэтому при нахождении типа связи
                        // допустимую дистанцию делаем заведомо больше, чем для элементов из других групп
                        const limit: number = group ===  specificGroup ? (nameLength < 12 ? 4 : 10) : (nameLength < 7 ? 3 : 7);
                        const distance: number = StringsEqualityToolbox.distanceOfLevenshtein(txt, name);
                        if (distance < limit) acc.push({group: group as keyof TAipPresetData, id: item.id, distance: distance});
                        return acc;
                    }, []);
                if (foundItemsInfo.length) {
                    results = results.concat(foundItemsInfo);
                }
            }
        })
        if (results.length) {
            const bestMatch: TFoundInPresetHelper = results.sort((a: TFoundInPresetHelper, b: TFoundInPresetHelper) => a.distance - b.distance)[0];
            if (!bestMatch) return null;
            const distance: number = bestMatch.distance;
            return results.reduce((acc: TFoundInPreset, item: TFoundInPresetHelper) => {
                if (!acc) return acc;
                if (item.distance === distance) {
                    const group: TAipPresetGroupsNames = item.group;
                    if (!acc[group]) acc[group] = {};
                    const id: string = item.id;
                    if (!aipPreset[group] && !synonyms[group]) return acc;

                    const inPreset = aipPreset[group]?.find(item => item.id === id);
                    const inSynonyms = synonyms[group]?.find(item => item.id === id);
                    const value: string | undefined = inPreset ? inPreset?.multilingualName?.ru : inSynonyms?.multilingualName?.ru;
                    if (value) (acc[group] || {})[id] = value;
                }
                return acc;
            }, {});
        }
        else {
            return null;
        }
    }


    private initSynonyms(): TAipPresetData {
        const modelTypes: TReducedOriginalPresetCommonItem[] = [
            {
                id: '2c92808766edb7780166f290a33e000d',
                multilingualName: { ru: 'FAD'} // "Диаграмма окружения функции (FAD)"
            },
            {
                id: 'eEPCChart',
                multilingualName: { ru: 'EPC'} // "Событийная цепочка процессов (EPC)"
            },
            {
                id: 'm_bpmn',
                multilingualName: { ru: 'BPMN'} // "BPMN 2.0"
            },
            {
                id: 'psdChart',
                multilingualName: { ru: 'PSD'} // "Диаграмма выбора сценария процесса (PSD)"
            },
            {
                id: 'valueAddedDiagram1Level',
                multilingualName: { ru: 'VAD'} // "Диаграмма цепочки добавленной стоимости (VAD)"
            },
            {
                id: '2c9d8085675575050167834dfc6100ea',
                multilingualName: { ru: 'КПР'} // "Диаграмма ключевых показателей результативности (КПР)"
            }
        ];
        const aipPresetModelTypes:  TReducedOriginalPresetCommonItem[] | undefined = this.aipPreset?.modelTypes;
        if (!aipPresetModelTypes) return {};
        return {
            modelTypes: modelTypes.filter((item: TReducedOriginalPresetCommonItem) => aipPresetModelTypes.some((a: TReducedOriginalPresetCommonItem) => a.id === item.id))
        }
    }
};

export const aipPreset = new AipPreset();
