import { call, put, select, takeEvery } from 'redux-saga/effects';
import { workspaceAddTab, workspaceChangeTabTitle } from '../actions/tabs.actions';
import { EditorMode } from '../models/editorMode';
import { defaultWorkspaceTabActions } from '../models/tab';
import { TWorkspaceTabItemParams, TWorkspaceTab } from '../models/tab.types';
import { WorkSpaceTabTypes } from '../modules/Workspace/WorkSpaceTabTypesEnum';
import { TREE_ITEM_CONTEXT_MENU_ACTION, TREE_SELECT_NODES_BY_NODE_IDS } from '../actionsTypes/tree.actionTypes';
import { treeItemContextMenuAction, treeItemExpand, treeSelectNodes } from '../actions/tree.actions';
import { TTreeItemContextMenuAction, TTreeSelectNodesByNodeIdsAction } from '../actions/tree.actions.types';
import { TreeItemContextMenuAction, TreeItemType } from '../modules/Tree/models/tree';
import {
    GET_SEARCH_RESULT,
    OPEN_MODEL_ON_CANVAS,
    OPEN_SEARCH_IN_DIALOG,
    SET_SEARCH_ROOT_NODES,
} from '../actionsTypes/search.actionTypes';
import { searchSetLoadingStatus, setRootSearchNodeId, setSearchData } from '../actions/search.actions';
import {
    TSetSearchPathElementAction,
    TGetSearchResultAction,
    TOpenModelOnCanvasAction,
    TOpenSearchInDialogAction,
} from '../actions/search.actions.types';
import { TabsSelectors } from '../selectors/tabs.selectors';
import { NodeId, SearchRequest, SearchResult } from '../serverapi/api';
import { getCurrentLocale } from '../selectors/locale.selectors';
import { TSearchDataListItem, TSearcParams } from '../reducers/search.reducer.types';
import { OPEN_BD_SEARCH_ACTION } from '../actionsTypes/editor.actionTypes';
import { LocalesService } from '../services/LocalesService';
import messages from '../modules/Tree/messages/TreeContextMenu.messages';
import { openNode } from '../actions/openNode.actions';
import { checkIsDialogSearch, generateCustomNodeId } from '../utils/nodeId.utils';
import {
    isTabSearchable,
    searchRulesToSearchRuleWithValueId,
    searchRuleWithValueIdToSearchRules,
} from '../utils/bdSearchTab.utils';
import { SearchDaoService } from '@/services/dao/SearchDAOService';
import { filterTreeIncludeTypes, TreeSelectors } from '@/selectors/tree.selectors';
import { TreeNode, TTreeEntityState } from '@/models/tree.types';
import { initSearchParams } from '@/reducers/search.reducer';
import { treeExpandHandler } from './tree.saga';
import { TSeveralExtendedSearchResponse } from '@/services/types/SearchDaoService.types';
import { v4 as uuid } from 'uuid';

function* handleOpenSearchTab({ payload: { nodeId, name, action, type } }: TTreeItemContextMenuAction) {
    if (action === TreeItemContextMenuAction.DB_SEARCH) {
        const intl = LocalesService.useIntl(yield select(getCurrentLocale));

        const tabId: string = uuid();
        const searchNodeId: NodeId = generateCustomNodeId(nodeId, tabId);

        const contentLoadingPageTab: TWorkspaceTab = {
            title: `${intl.formatMessage(messages.dbSearch)} «${name}»`,
            nodeId: searchNodeId,
            type: WorkSpaceTabTypes.DB_SEARCH,
            mode: EditorMode.Read,
            params: {
                originalNodeId: nodeId,
                name,
                type,
            } as TWorkspaceTabItemParams,
            actions: {
                ...defaultWorkspaceTabActions,
            },
        };

        yield put(
            setSearchData({
                searchNodeId,
                searchParams: {
                    ...initSearchParams,
                    rootSearchNodeIds: [nodeId],
                },
            }),
        );
        yield put(workspaceAddTab(contentLoadingPageTab));
    }
}

function* handleOpenBdSearchAction() {
    const tab: TWorkspaceTab | undefined = yield select(TabsSelectors.getActiveTab);
    const treeStructure: TreeNode[] = yield select(TreeSelectors.treeStructure);
    const treeStructureChildren: TreeNode[] = treeStructure[0]?.children || [];
    const onlyRepositories: TreeNode[] = filterTreeIncludeTypes(treeStructureChildren, [TreeItemType.Repository]);
    const isSingleRepository: boolean = onlyRepositories.length === 1;

    let nodeId: NodeId | undefined;

    if (isSingleRepository) {
        const singleRepositoryNodeId: NodeId | undefined = treeStructureChildren[0]?.nodeId;

        if (!singleRepositoryNodeId) return;

        nodeId = singleRepositoryNodeId;
    } else if (tab) {
        if (!isTabSearchable(tab)) return;

        nodeId = tab.nodeId;
    } else {
        return;
    }

    yield openDbSearchTab(nodeId);
}

function* openDbSearchTab(nodeId: NodeId) {
    const { serverId, repositoryId } = nodeId;
    const repositoryNodeId: NodeId = { id: repositoryId, repositoryId, serverId };
    const repositoryName: string = yield select(TreeSelectors.getNodeNameById(repositoryNodeId));

    yield put(
        treeItemContextMenuAction({
            nodeId: repositoryNodeId,
            name: repositoryName,
            action: TreeItemContextMenuAction.DB_SEARCH,
            type: TreeItemType.Repository,
        }),
    );
}

function* handleSetSearchPathElement({ payload: { searchNodeId, searchRootNodes } }: TSetSearchPathElementAction) {
    const tab = yield select(TabsSelectors.getActiveTab);
    const isDialogSearch = checkIsDialogSearch(searchNodeId);
    const intl = LocalesService.useIntl(yield select(getCurrentLocale));

    if (tab && !isDialogSearch && searchRootNodes.length === 1) {
        yield put(
            workspaceChangeTabTitle(tab, `${intl.formatMessage(messages.dbSearch)} «${searchRootNodes[0].name}»`),
        );
    }

    const nodeIds: NodeId[] = searchRootNodes.map((node) => node.nodeId);

    yield put(setRootSearchNodeId(searchNodeId, nodeIds));
}

function* handleGetSearchResult({
    payload: { searchNodeId, searchText, searchRules, searchVisibility, searchNodeTypes, rootSearchNodeIds },
}: TGetSearchResultAction) {
    const [{ serverId }] = rootSearchNodeIds;

    try {
        yield put(searchSetLoadingStatus(searchNodeId, true));

        const searchRequests: SearchRequest[] = rootSearchNodeIds.map((rootSearchNodeId) => ({
            rootSearchNodeId,
            searchText,
            includePath: true,
            includeCount: true,
            searchVisibility,
            searchRules: searchRuleWithValueIdToSearchRules(searchRules),
            nodeTypes: searchNodeTypes,
        }));

        const { resultList, foundElementsCount }: TSeveralExtendedSearchResponse = yield call(
            SearchDaoService.getSeveralExtendedSearchResponse,
            serverId,
            {
                requests: searchRequests,
                includeCount: true,
            },
        );

        const searchResult: TSearchDataListItem[] = resultList.map(
            ({ multilingualName, path, nodeType, elementTypeId, nodeId, deleted }: SearchResult) => ({
                multilingualName,
                path,
                type: nodeType as TreeItemType,
                elementType: elementTypeId || '',
                nodeId: {
                    ...nodeId,
                    serverId,
                },
                deleted,
            }),
        );

        yield put(
            setSearchData({
                searchNodeId,
                searchResult,
                foundElementsCount,
            }),
        );
    } finally {
        yield put(searchSetLoadingStatus(searchNodeId, false));
    }
}

function* handleOpenModelOnCanvas({ payload: { nodeId, type, multilingualName } }: TOpenModelOnCanvasAction) {
    if (
        [
            TreeItemType.Model,
            TreeItemType.Matrix,
            TreeItemType.Wiki,
            TreeItemType.Spreadsheet,
            TreeItemType.Kanban,
            TreeItemType.SimulationModeling,
        ].includes(type)
    ) {
        yield put(openNode({ nodeId, type }));
    } else {
        if (type === TreeItemType.Folder) {
            yield put(openNode({ nodeId, type }));
        }

        yield put(
            treeItemContextMenuAction({
                nodeId,
                name: LocalesService.internationalStringToString(multilingualName),
                type,
                action: TreeItemContextMenuAction.PROPERTIES,
            }),
        );
    }
}

function* handleOpenInDialog({ payload: { searchNodeId, nodeId, searchRequests } }: TOpenSearchInDialogAction) {
    if (searchRequests.length === 0) {
        const repositoryNodeId: NodeId = { ...nodeId, id: nodeId.repositoryId };

        const treeNode: TTreeEntityState | undefined = yield select(TreeSelectors.itemById(repositoryNodeId));
        if (treeNode) {
            yield put(
                setSearchData({
                    searchNodeId,
                    searchParams: {
                        ...initSearchParams,
                        rootSearchNodeIds: [repositoryNodeId],
                    },
                }),
            );
        }
    } else {
        const searchRequest: SearchRequest = searchRequests[0];
        const rootSearchNodeIds: NodeId[] = searchRequests.map((searchRequest) => searchRequest.rootSearchNodeId);

        const { searchVisibility = 'NOT_DELETED', searchText = '', searchRules = [], nodeTypes = [] } = searchRequest;

        const searchParams: TSearcParams = {
            rootSearchNodeIds,
            nodeTypes,
            searchRules: searchRulesToSearchRuleWithValueId(searchRules),
            searchText,
            searchVisibility,
        };

        yield put(
            setSearchData({
                searchNodeId,
                searchParams,
            }),
        );
    }
}

function* handleSelectNodesByNodeIds({ payload: { selectedNodeIds, treeName } }: TTreeSelectNodesByNodeIdsAction) {
    const [{ serverId }] = selectedNodeIds;
    yield put(treeItemExpand({ serverId, repositoryId: serverId, id: serverId }, treeName));
    const treeNodes: TTreeEntityState[] = yield select(TreeSelectors.itemsByIds(selectedNodeIds));
    for (const treeNode of treeNodes) {
        if (treeNode.parentNodeId) {
            yield treeExpandHandler(treeItemExpand(treeNode.parentNodeId, treeName));
        }
    }
    yield put(treeSelectNodes(treeNodes, treeName));
}

export function* searchSaga() {
    yield takeEvery(TREE_ITEM_CONTEXT_MENU_ACTION, handleOpenSearchTab);
    yield takeEvery(TREE_SELECT_NODES_BY_NODE_IDS, handleSelectNodesByNodeIds);
    yield takeEvery(SET_SEARCH_ROOT_NODES, handleSetSearchPathElement);
    yield takeEvery(GET_SEARCH_RESULT, handleGetSearchResult);
    yield takeEvery(OPEN_MODEL_ON_CANVAS, handleOpenModelOnCanvas);
    yield takeEvery(OPEN_BD_SEARCH_ACTION, handleOpenBdSearchAction);
    yield takeEvery(OPEN_SEARCH_IN_DIALOG, handleOpenInDialog);
}
