export const APP_CLOSE_REQUEST = 'APP_CLOSE_REQUEST';

export const APP_SIZE_TOGGLE = 'APP_SIZE_TOGGLE';

export const APP_MINIMIZE_REQUEST = 'APP_MINIMIZE_REQUEST';

export const APP_LOADED = 'APP_LOADED';

export const APP_START = 'APP_START';

export const APP_STOP = 'APP_STOP';

export const APP_EXIT = 'APP_EXIT';

export const APP_BOOTSTRAP_SUCCESS = 'APP_BOOTSTRAP_SUCCESS';

export const APP_BOOTSTRAP_REQUEST = 'APP_BOOTSTRAP_REQUEST';

export const APP_CHANGE_APP_MODE_TO_ONLY_DIAGRAM = 'APP_CHANGE_APP_MODE_TO_ONLY_DIAGRAM';

export const APP_OPEN_URI = 'APP_OPEN_URI';

export const APP_ABOUT_CLICK = 'APP_ABOUT_CLICK';

export const APP_LOAD_INITIAL_VIEW_SETTING = 'APP_LOAD_INITIAL_VIEW_SETTING';

export const APP_LOAD_VIEW_COLORS = 'APP_LOAD_VIEW_COLORS';

export const APP_LOAD_VIEW_COLOR_SCHEME = 'APP_LOAD_VIEW_COLOR_SCHEME';

export const APP_SET_IS_EN_LOCALE_ALLOWED = 'APP_SET_IS_EN_LOCALE_ALLOWED';

export const DOWNLOAD_DOCX = 'DOWNLOAD_DOCX';

export const SET_TMP_BTN_COLOR = 'SET_TMP_BTN_COLOR';

export const CLEAR_TMP_BTNS_COLORS = 'CLEAR_TMP_BTNS_COLORS';

export const SET_TMP_BTNS_COLORS = 'SET_TMP_BTNS_COLORS';
