import { TRootState } from '../reducers/root.reducer.types';
import { createSelector } from 'reselect';
import { SUPERADMIN_LOGIN } from '../modules/AdminTools/data/admintool';
import {
    UserDTO,
    ServerProfile,
    UserProperty,
    UserDTOAccessesEnum,
    UserDTOTransitiveLicensesEnum,
    LoginResponseAuthorizationMethodEnum,
} from '../serverapi/api';
import licenseTypes from '../models/licenseTypes';
import { TAuthorizationState } from '../reducers/authorization.reducer.types';

export const getAuthorizationState = (state: TRootState): TAuthorizationState => state.authorization;

export const getUser = createSelector(getAuthorizationState, (state): UserDTO | undefined => state.user);

export const getUserLogin = createSelector<TRootState, UserDTO | undefined, string>(
    getUser,
    (user) => user?.login || '',
);

export const getUserId = createSelector(getUser, (user): number | undefined => user?.id);

export const getUserProperty = createSelector(
    getAuthorizationState,
    (state): UserProperty | undefined => state.usersProperties,
);

export const hasModelEditorLicense = createSelector(getUser, (user: UserDTO | undefined): boolean =>
    Boolean(
        user?.transitiveLicenses?.includes(licenseTypes.MODEL_EDITOR as UserDTOTransitiveLicensesEnum) ||
            user?.transitiveLicenses?.includes(
                licenseTypes.MODEL_EDITOR_COMPETITIVE as UserDTOTransitiveLicensesEnum,
            ) ||
            user?.login === SUPERADMIN_LOGIN,
    ),
);

export const hasViewerLicense = createSelector(getUser, (user: UserDTO | undefined): boolean => {
    return Boolean(user?.transitiveLicenses?.some((license) => license === licenseTypes.MODEL_READER));
});

export const getIsSuperAdmin = createSelector(getUser, (user) => user?.login === SUPERADMIN_LOGIN);

export const getAvailableUserProfiles = (serverProfiles: ServerProfile[]) =>
    createSelector(getUser, (user) => serverProfiles?.filter((p) => user?.transitiveProfilesIds?.includes(p.id)));

export const getIsAuthorized = createSelector(getAuthorizationState, (state) => state.authorized);

export const isUserHasAccess = (access: UserDTOAccessesEnum) =>
    createSelector<TRootState, UserDTO | undefined, boolean, boolean>(
        getUser,
        getIsSuperAdmin,
        (user: UserDTO | undefined, isSuperAdmin: boolean): boolean =>
            isSuperAdmin || Boolean(user?.transitiveAccesses?.includes(access)),
    );

export const getIsUserHasAccessToDeletedObjects = createSelector<TRootState, boolean, boolean, boolean>(
    isUserHasAccess('SHOW_DELETED_OBJECT'),
    isUserHasAccess('DB_AUDIT'),
    (a: boolean, b: boolean): boolean => a || b,
);

export const getAuthorizationMethod = createSelector<
    TRootState,
    TAuthorizationState,
    LoginResponseAuthorizationMethodEnum | undefined
>(getAuthorizationState, (state) => state.authorizationMethod);
