export type TExperimentalFeatures = {
    [key: string]: boolean;
};

export enum ExperimentalFeaturesEnum {
    AUTOMATIC_NAVIGATOR_UPDATE = 'BPM_5375',
    EXTENDED_MODEL_TYPES_CREATION = 'BPM_5462',
    UML_SYMBOLS_CREATION = 'BPM_6193',
    NOTIFICATIONS_SUBSCRIPTION = 'BPM_7515',
    EMAIL_NOTIFICATIONS = 'BPM_7510',
    MODEL_TEMPLATES = 'BPM_9181',
    SEARCH_ASSISTANT = 'BPM_9311',
    SEARCH_ASSISTANT_PROMPTER = 'BPM_9311_1',
}
