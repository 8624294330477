import React, { useRef } from 'react';
import { ReactSVG } from 'react-svg';

const CompareModelImage = ({ url, style = {}, className = '' }) => {
    const nocache = useRef<number>(new Date().getTime());

    if (!url) {
        return <></>;
    }

    const nonCachedUrl = `${url}?nocache=${nocache.current}`;

    return (
        <div className={className} style={style}>
            <ReactSVG src={nonCachedUrl} />
        </div>
    );
};

export default CompareModelImage;
