import { aipConfig, AipConfig } from "./AipConfig";
import { aipPreset, AipPreset } from "./AipPreset";
import { AipToolbox } from "./AipToolbox";
import { TFoundInConfig } from "./types/AipConfig.types";
import { TNgFoundInPreset, TSearchGroupsFilter } from "./types/AipPreset.types";
import { TProcessedSourceQueryItem, TTransitionalSourceQueryItem } from "./types/SourceQueryProcessor.types";

class SourceQueryProcessor {

    private aipConfig: AipConfig;

    private aipPreset: AipPreset;


    constructor(config: AipConfig, preset: AipPreset) {
        this.aipConfig = config;
        this.aipPreset = preset;
    }


    process(sourceQuery: string): TProcessedSourceQueryItem[] {
        return this.sourceQueryProcessing(sourceQuery);
    }


    private findFirstUntoutchedItemIndex(items: TTransitionalSourceQueryItem[]): number {
        return items.findIndex(item => typeof item.value === 'string');
    }


    private findTheNearestReplacedItemIndex(startsFrom: number, items: TTransitionalSourceQueryItem[]): number {
        return items.findIndex((item, index) => (index >= startsFrom) && (typeof item.value !== 'string'));
    }


    private replaceUntoutchedStrings(items: TTransitionalSourceQueryItem[]): TProcessedSourceQueryItem[] {
        let result: Array<TProcessedSourceQueryItem> = [];
        do {
            if (typeof items[0].value !== 'string') {
                // Это или элемент из конфига или элемент из пресета
                if (items[0].type === 'CONFIG') {
                    result.push({type: 'CONFIG', value: items[0].value as TFoundInConfig})
                }
                else {
                    result.push({type: 'PRESET', value: items[0].value as TNgFoundInPreset})
                }
                items = items.slice(1);
            }
            else {
                const sliceFrom: number = 0;
                const sliceTo: number = this.findTheNearestReplacedItemIndex(sliceFrom, items);
                const str = sliceTo > -1 ? items.slice(sliceFrom, sliceTo).map(val => val.value).join(' ') : items.slice(sliceFrom).map(val => val.value).join(' ');
                const item: TProcessedSourceQueryItem = {
                    type: 'NOWHERE',
                    value: {source: str}
                };
                result.push(item);
                items = items.slice(str.split(' ').length);
            }
        } while(items.length);
        return result;
    }


    private replaceFullTypeNamesFromPreset(items: TTransitionalSourceQueryItem[]): TTransitionalSourceQueryItem[] {
        let result: TTransitionalSourceQueryItem[] = [];
        do {
            const sliceFrom: number = this.findFirstUntoutchedItemIndex(items);
            if (sliceFrom < 0) {
                // в исходном массиве больше нечего менять, возвращаем уже переработанные элементы и то что ещё не обработали
                return result.concat(items);
            }
            if (sliceFrom > 0) {
                result = result.concat(items.slice(0, sliceFrom));
            }
            const sliceTo: number = this.findTheNearestReplacedItemIndex(sliceFrom, items);
            const str = sliceTo > -1 ? items.slice(sliceFrom, sliceTo).map(val => val.value).join(' ') : items.slice(sliceFrom).map(val => val.value).join(' ');
            // последнийиз только что проанализированных элементов
            const last: TTransitionalSourceQueryItem | undefined = result.at(-1);
            let searchGroups: TSearchGroupsFilter | null= undefined;
            if (last && last.value && typeof last.value === 'object') {
                if ((('aipPresetGroup' in last.value) && (last.value.aipPresetGroup === 'edgeTypes'))
                    || (('aipConfigGroup' in last.value) && last.value.aipConfigGroup === 'LINKED_WITH')) {
                    // после типа связи или конструкции "связанные с" ищём то что надо только среди объектов и алиасов
                    searchGroups = {aliasTypes: 1, objectTypes: 1}
                }
            }
            const info: TNgFoundInPreset = this.aipPreset.findFullString(str, searchGroups);
            const item: TTransitionalSourceQueryItem = info ? {type: 'PRESET', value: info} : items[sliceFrom];
            const sliceStartIndex: number = sliceFrom + (info ? info.source.split(' ').length : 1);
            result.push(item);
            items = items.slice(sliceStartIndex);
        } while(items.length);
        return result;
    }


    private replacePreDefinedQueryTokenGroups(items: TTransitionalSourceQueryItem[]): TTransitionalSourceQueryItem[] {
        let result: Array<TTransitionalSourceQueryItem> = [];
        do {
            const sliceFrom: number = this.findFirstUntoutchedItemIndex(items);
            if (sliceFrom < 0) {
                // в исходном массиве больше нечего менять, возвращаем уже переработанные элементы и то что ещё не обработали
                return result.concat(items);
            }
            if (sliceFrom > 0) {
                result = result.concat(items.slice(0, sliceFrom));
            }
            const sliceTo: number = this.findTheNearestReplacedItemIndex(sliceFrom, items);
            const str = sliceTo > -1 ? items.slice(sliceFrom, sliceTo).map(val => val.value).join(' ') : items.slice(sliceFrom).map(val => val.value).join(' ');

            const info: TFoundInConfig = this.aipConfig.findMultiwordTokenGroup(str);
            const item: TTransitionalSourceQueryItem = info ? {type: 'CONFIG', value: info} : items[sliceFrom];
            const sliceStartIndex: number = sliceFrom + (info ? info.source.split(' ').length : 1);
            result.push(item);
            items = items.slice(sliceStartIndex);
        } while(items.length);
        return result;
    }


    private replacePreDefinedQueryTokens(items: TTransitionalSourceQueryItem[]): TTransitionalSourceQueryItem[] {
        let result: Array<TTransitionalSourceQueryItem> = [];
        do {
            const sliceFrom: number = this.findFirstUntoutchedItemIndex(items);
            if (sliceFrom < 0) {
                // в исходном массиве больше нечего менять, возвращаем уже переработанные элементы и то что ещё не обработали
                return result.concat(items);
            }
            if (sliceFrom > 0) {
                result = result.concat(items.slice(0, sliceFrom));
            }
            const sliceTo: number = sliceFrom + 1; //findTheNearestReplacedItemIndex(sliceFrom, items);
            const str = sliceTo > -1 ? items.slice(sliceFrom, sliceTo).map(val => val.value).join(' ') : items.slice(sliceFrom).map(val => val.value).join(' ');

            const info: TFoundInConfig = this.aipConfig.findSinglewordToken(str);
            const item: TTransitionalSourceQueryItem = info ? {type: 'CONFIG', value: info} : items[sliceFrom];
            const sliceStartIndex: number = sliceFrom + (info ? info.source.split(' ').length : 1);
            result.push(item);
            items = items.slice(sliceStartIndex);
        } while(items.length);
        return result;
    }


    // удаляем первое слово найти если оно есть, удаляем части AND_VALUE если они есть
    private removeMeaninglessItems(items: TProcessedSourceQueryItem[]) {
        return items.filter((item: TProcessedSourceQueryItem, index: number, arr: TProcessedSourceQueryItem[]) => {
            // удаляем фразу "и значением"
            if (item.type === 'CONFIG' && item.value?.aipConfigGroup === 'AND_VALUE') return false;
            // удаляем первое слово "найти" либо его синоним
            if (item.type === 'CONFIG' && item.value?.aipConfigGroup === 'COMMAND' && item.value.tokens[0] === 'FIND') return false;
            const nextItem: TProcessedSourceQueryItem | undefined = arr[index + 1];
            if (nextItem) {
                // удаляем союз "который" или его формы, если после них стоит блок с именем связи из пресета
                if ((item.type === 'CONFIG' && item.value?.aipConfigGroup === 'CONJUNCTION' && item.value.tokens[0] === 'THAT')
                    && (nextItem.type === 'PRESET' && nextItem.value?.aipPresetGroup === 'edgeTypes')) return false;
                // удаляем союз "который" или его формы, если после них стоит блок конфига с типом LINKED_WITH
                if ((item.type === 'CONFIG' && item.value?.aipConfigGroup === 'CONJUNCTION' && item.value.tokens[0] === 'THAT')
                    && (nextItem.type === 'CONFIG' && nextItem.value?.aipConfigGroup === 'LINKED_WITH')) return false;
            }
            return true;
        });
    }


    private sourceQueryProcessing(source: string): TProcessedSourceQueryItem[] {
        let words: TTransitionalSourceQueryItem[] = AipToolbox.getWordsFromString(source).map(val => {return {type: 'NOWHERE', value: val};});
        if (!words.length) return [];
        let transitionalResult: TTransitionalSourceQueryItem[];
        transitionalResult = this.replacePreDefinedQueryTokenGroups(words);
        transitionalResult = this.replaceFullTypeNamesFromPreset(transitionalResult);
        transitionalResult = this.replacePreDefinedQueryTokens(transitionalResult);
        let result: TProcessedSourceQueryItem[] = this.replaceUntoutchedStrings(transitionalResult);
        result = this.removeMeaninglessItems(result);
        return result;
    }
}


export const sourceQueryProcessor = new SourceQueryProcessor(aipConfig, aipPreset);
