export const REPORT_CREATE = 'REPORT_CREATE';

export const REPORT_SAVE_REQUEST = 'REPORT_SAVE_REQUEST';

export const REPORT_SAVE_REQUEST_SUCCESS = 'SAVE_REQUESTS';

export const REPORT_SAVE_REQUEST_FAILURE = 'REPORT_SAVE_REQUEST_FAILURE';

export const REPORT_OPEN = 'REPORT_OPEN';

export const REPORT_OPEN_BY_NODE_ID = 'REPORT_OPEN_BY_NODE_ID';

export const REPORT_REQUEST_SUCCESS = 'REPORT_REQUEST_SUCCESS';

export const REPORT_DROP_NODES = 'REPORT_DROP_NODES';

export const REPORT_ADD_NODES = 'REPORT_ADD_NODES';

export const REPORT_SET_COLUMN_DATA = 'REPORT_SET_COLUMN_DATA';

export const REPORT_DELETE_COLUMN = 'REPORT_DELETE_COLUMN';

export const REPORT_CREATE_NEW_COLUMN = 'REPORT_CREATE_NEW_COLUMN';

export const REPORT_ADD_COLUMN = 'REPORT_ADD_COLUMN';

export const REPORT_SELECT_COLUMN = 'REPORT_SELECT_COLUMN';

export const REPORT_CLEAR_SELECT_COLUMN = 'REPORT_CLEAR_SELECT_COLUMN';

export const REPORT_CHANGE_FILLING_TYPE = 'REPORT_CHANGE_FILLING_TYPE';

export const REPORT_SET_SEARCH_REQUESTS = 'REPORT_SET_SEARCH_REQUESTS';

export const REPORT_SEARCH_REQUEST = 'REPORT_SEARCH_REQUEST';

export const REPORT_SEARCH_REQUEST_SUCCESS = 'REPORT_SEARCH_REQUEST_SUCCESS';

export const REPORT_SEARCH_REQUEST_FAILURE = 'REPORT_SEARCH_REQUEST_FAILURE';

export const REPORT_CLEAR_SEARCH_REQUEST = 'REPORT_CLEAR_SEARCH_REQUEST';

export const REPORT_REFRESH = 'REPORT_REFRESH';

export const REPORT_DELETE_NODES = 'REPORT_DELETE_NODES';

export const REPORT_SET_FILTER_VALUE = 'REPORT_SET_FILTER_VALUE';

export const FETCH_NODES_WITH_ATTRIBUTES_REQUEST = 'FETCH_NODES_WITH_ATTRIBUTES_REQUEST';

export const FETCH_NODES_WITH_ATTRIBUTES_REQUEST_SUCCESS = 'FETCH_NODES_WITH_ATTRIBUTES_REQUEST_SUCCESS';

export const REPORT_CHANGE_COLUMN_VISIBILITY = 'REPORT_CHANGE_COLUMN_VISIBILITY';

export const REPORT_CHANGE_COLUMN_FILTER_VISIBILITY = 'REPORT_CHANGE_COLUMN_FILTER_VISIBILITY';

export const REPORT_SET_COLUMN_FILTER = 'REPORT_SET_COLUMN_FILTER';
