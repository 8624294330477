import type { PrincipalDescriptor } from '@/serverapi/api';
import React from 'react';
import { Icon } from '@/modules/UIKit';
import icUpload from '@/resources/icons/icUpload.svg';
import theme from './WikiEditor.component.scss';
import messages from '../messages/WikiEditor.messages';
import { useIntl } from 'react-intl';
import { getShortDisplayName } from '@/utils/users.utils';
import { timestampToStringDate } from '@/utils/date.time.utils';

type THeaderProps = {
    title?: string;
    createdBy?: PrincipalDescriptor;
    updatedBy?: PrincipalDescriptor;
    updatedAt?: number;
};

export const Header = ({ title, createdBy, updatedBy, updatedAt }: THeaderProps) => {
    const intl = useIntl();
    const info =
        createdBy && updatedBy
            ? `${intl.formatMessage(messages.createdBy)} ${getShortDisplayName(createdBy)}, ${intl.formatMessage(
                  messages.updatedBy,
              )} ${getShortDisplayName(updatedBy)}`
            : '';
    const updateInfo = updatedAt ? ` ${timestampToStringDate(updatedAt, 'MMM DD, YYYY')}` : '';

    return (
        <div className={theme.header}>
            {createdBy && updatedBy && (
                <span className={theme.modelHeader}>
                    {`${info}${updateInfo}`}
                    <Icon spriteSymbol={icUpload} disabled={true} />
                </span>
            )}
            {title && <span className={theme.modelName}>{title}</span>}
        </div>
    );
};
