import type { AttributeTypeStyleFormatEnum, PresetImage } from '../../../../../../serverapi/api';
import React from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import messages from './OverlayTypeSelector.messages';
import theme from './OverlayTypeSelector.scss';
import { floatingAttributesChangeFormatAction } from '../../../../actions/FloatingAttributes.actions';
import { StyleFormat } from '../../../../FloatingAttributes.constants';
import { TRadioOption } from '@/modules/UIKit/components/Radio/Radio.types';
import { RadioGroup } from '@/modules/UIKit/components/Radio/RadioGroup.component';

type TOverlayTypeSelectorProps = {
    value?: AttributeTypeStyleFormatEnum;
    images: PresetImage[];
    disabled?: boolean;
};

export const OverlayTypeSelector = ({ value = StyleFormat.TEXT, images, disabled }: TOverlayTypeSelectorProps) => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const onChangeHandler = (value: AttributeTypeStyleFormatEnum) =>
        dispatch(floatingAttributesChangeFormatAction(value));

    const radioOptions: TRadioOption<AttributeTypeStyleFormatEnum>[] = [
        {
            title: intl.formatMessage(messages.text),
            value: 'TEXT',
            dataTest: 'attributes-around-object_text-format',
        },
        {
            title: intl.formatMessage(messages.icon),
            value: 'IMAGE',
            disabled: !images.length,
            dataTest: 'attributes-around-object_image-format',
        },
        {
            title: intl.formatMessage(messages.none),
            value: 'NONE',
            dataTest: 'attributes-around-object_do-not-display',
        },
    ];

    return (
        <div className={theme.container}>
            <RadioGroup<AttributeTypeStyleFormatEnum>
                value={value}
                onChange={onChangeHandler}
                options={radioOptions}
                margin={8}
                direction="Row"
            />
        </div>
    );
};
