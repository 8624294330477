import { defineMessages } from 'react-intl';

export default defineMessages({
    id: {
        id: 'MessagesList.id',
        defaultMessage: 'ID',
    },
    dateTime: {
        id: 'MessagesList.dateTime',
        defaultMessage: 'Дата и время',
    },
    notificationType: {
        id: 'MessagesList.notificationType',
        defaultMessage: 'Тип уведомления',
    },
    toWhom: {
        id: 'MessagesList.toWhom',
        defaultMessage: 'Кому',
    },
    status: {
        id: 'MessagesList.status',
        defaultMessage: 'Статус',
    },
    NEW: {
        id: 'MessagesList.NEW',
        defaultMessage: 'В очереди',
    },
    SENT: {
        id: 'MessagesList.SENT',
        defaultMessage: 'Отправлено',
    },
    ERROR: {
        id: 'MessagesList.ERROR',
        defaultMessage: 'Ошибка',
    },
    INCORRECT_SETTINGS: {
        id: 'MessagesList.INCORRECT_SETTINGS',
        defaultMessage: 'Почта не настроена'
    },
    update: {
        id: 'MessagesList.update',
        defaultMessage: 'Обновить',
    },
});
