import { apiBundle, ApiBundle } from '../api/api-bundle';
import { getOrigin, replaceLastSlash } from '../../utils/url.utils';
import { Node, NodeId, SearchRequest, SearchResponse, SearchResult, SeveralSearchRequest } from '@/serverapi/api';
import { compareNodeIds, setServerIdToNodeInterface } from '@/utils/nodeId.utils';
import { groupBy } from 'lodash-es';
import { TExtendedNode } from '@/selectors/types/nodesSelector.types';
import { TSeveralExtendedSearchResponse } from '../types/SearchDaoService.types';

export class SearchDaoService {
    private static getApi() {
        const hostName = replaceLastSlash(getOrigin());
        const bundle: ApiBundle = new ApiBundle(hostName);

        return bundle;
    }

    public static async getExtendedSearchResponse(searchRequest: SearchRequest): Promise<SearchResponse> {
        const serverId = searchRequest.rootSearchNodeId.serverId;
        const searchResponse: SearchResponse = await apiBundle(serverId).search.searchExtended({
            body: searchRequest,
        });

        return {
            resultList: searchResponse.resultList
                ? searchResponse.resultList.map((result) => ({
                      ...result,
                      nodeId: { ...result.nodeId, serverId: searchRequest.rootSearchNodeId.serverId },
                  }))
                : [],
            foundElementsCount: searchResponse.foundElementsCount,
        };
    }

    public static async getSeveralExtendedSearchResponse(
        serverId: string,
        severalSearchRequest: SeveralSearchRequest,
    ): Promise<TSeveralExtendedSearchResponse> {
        const searchResponse: SearchResponse = await apiBundle(serverId).search.severalSearchExtended({
            body: severalSearchRequest,
        });

        const resultList: SearchResult[] =
            searchResponse.resultList?.map(
                (searchResult) =>
                    ({
                        ...searchResult,
                        nodeId: {
                            ...searchResult.nodeId,
                            serverId,
                        },
                    } as SearchResult),
            ) || [];

        const foundElementsCount: number | undefined = searchResponse.foundElementsCount;

        return { resultList, foundElementsCount };
    }

    public static async getNodesWithAttributes(serverId: string, nodeIds: NodeId[]): Promise<TExtendedNode[]> {
        const api = this.getApi();

        const nodes: Node[] = await api.tree.loadBulk({ body: nodeIds });
        nodes.forEach((n) => setServerIdToNodeInterface(n, serverId));

        const groppedNodeIds: { [id: string]: NodeId[] } = groupBy(nodeIds, (nodeId: NodeId) => nodeId.repositoryId);

        const searchRequests: SearchRequest[] = Object.keys(groppedNodeIds).map((repositoryId) => {
            const nodeIds: NodeId[] = groppedNodeIds[repositoryId];
            return {
                rootSearchNodeId: {
                    id: repositoryId,
                    repositoryId: repositoryId,
                    serverId,
                },
                includeAttributes: true,
                searchRules: [
                    {
                        attributeType: 'SYSTEM',
                        attributeTypeId: 'nodeId',
                        queryRule: 'EQUALS',
                        values: nodeIds.map((nodeId) => nodeId.id),
                    },
                ],
                searchVisibility: 'ALL_INCLUDE_DELETED',
            };
        });

        const { resultList } = await this.getSeveralExtendedSearchResponse(serverId, {
            requests: searchRequests,
        });

        nodes.forEach((node) => {
            const searchResultForNode: SearchResult | undefined = resultList.find((searchResult) =>
                compareNodeIds(searchResult.nodeId, node.nodeId),
            );
            if (searchResultForNode) {
                node.attributes = searchResultForNode.attributes;
                (node as TExtendedNode).elementTypeId = searchResultForNode.elementTypeId;
            }
        });

        return nodes as TExtendedNode[];
    }
}
