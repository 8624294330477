import React from 'react';
import modelTypeMessages from '../../../models/modelType.messages';
import { SearchRequestNodeTypesEnum } from '../../../serverapi/api';
import messages from './SearchFilter.messages';
import style from './SearchFilter.scss';
import { useIntl } from 'react-intl';
import { Select } from '@/modules/UIKit/components/Select/Select.component';

type TSearchFilterProps = {
    searchNodeTypes: SearchRequestNodeTypesEnum[];
    setSearchNodeTypes: (searchEntities: SearchRequestNodeTypesEnum[]) => void;
};

const searchNodeTypesOptions: SearchRequestNodeTypesEnum[] = [
    'DB',
    'MODEL',
    'OBJECT',
    'FOLDER',
    'WIKI',
    'MATRIX',
    'SIMULATION',
    'FILE',
    'FILE_FOLDER',
    'SCRIPT',
    'SCRIPT_FOLDER',
    'SPREADSHEET',
    'KANBAN',
    'EDGE',
    'DASHBOARD',
    'REPORT',
];

export const SearchFilter = (props: TSearchFilterProps) => {
    const { searchNodeTypes, setSearchNodeTypes } = props;
    const intl = useIntl();

    const onChangeByType = (types: SearchRequestNodeTypesEnum[]) => {
        setSearchNodeTypes(types);
    };

    return (
        <div className={style.searchFilterContainer}>
            <Select
                isMultiSelect
                value={intl.formatMessage(messages.filter)}
                onChange={onChangeByType}
                data-test="search_search-filter"
            >
                {searchNodeTypesOptions.map((type) => (
                    <Select.Option
                        key={type}
                        value={type}
                        checked={searchNodeTypes.includes(type)}
                        label={intl.formatMessage(modelTypeMessages[type])}
                    />
                ))}
            </Select>
        </div>
    );
};
