import { defineMessages } from 'react-intl';

export default defineMessages({
    selectModelToCompare: {
        id: 'SelectModelToCompareDialog.selectModelToCompare',
        defaultMessage: 'Выберите модель для сравнения',
    },
    cancel: {
        id: 'SelectModelToCompareDialog.cancel',
        defaultMessage: 'Отмена',
    },
    confirm: {
        id: 'SelectModelToCompareDialog.confirm',
        defaultMessage: 'Выбрать',
    },
});
