import React, { useEffect, useState, FC, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    selectObjectDialogPrepareDataRequest,
    selectObjectDialogSubmit,
} from '../../../../../actions/selectObjectDefinitionDialog.actions';
import { SelectObjectDefinitionDialogSelectors } from '../../../../../selectors/selectObjectDefinitionDialog.selectors';
import { SelectedStrategy } from '../../../../../models/selectObjectDialog.types';
import messages from '../SelectExistingDefinitionDialogs.messages';
import { useIntl } from 'react-intl';
import Flag from '../../../../../resources/icons/ic-tree-e-epc-event.svg';
import { Table } from 'antd';
import { Icon } from '../../../../UIKit/components/Icon/Icon.component';
import theme from '../SelectExistingDefinitionDialogs.scss';
import { closeDialog } from '../../../../../actions/dialogs.actions';
import { DialogType } from '../../../../DialogRoot/DialogRoot.constants';
import { setFocusAndStartEditAction } from '../../../../../actions/editor.actions';
import { ObjectDefinitionImpl } from '../../../../../models/bpm/bpm-model-impl';
import { useObjectTableData } from '../hooks/useObjectTableData';
import { Dialog } from '@/modules/UIKit/components/Dialog/Dialog.component';
import { DialogFooterButtons } from '@/modules/UIKit/components/DialogFooterButtoms/DialogFooterButtons.component';

type TSelectObjectDefinitionContainerProps = {
    instances: ObjectDefinitionImpl[];
    cellId: string;
    open: boolean;
    objectName: string;
};

export const SelectObjectDefinitionContainer: FC<TSelectObjectDefinitionContainerProps> = ({
    instances,
    cellId,
    open,
    objectName,
}) => {
    const intl = useIntl();
    const dispatch = useDispatch();

    const [selectedInstanceId, setSelectedInstanceId] = useState<string | null>(instances?.[0]?.nodeId.id || null);

    const { oldName, objectTypeTitle, isObjectDeletable } = useSelector(
        SelectObjectDefinitionDialogSelectors.selectObjectDefinitionDialogState,
    );

    useEffect(() => {
        dispatch(selectObjectDialogPrepareDataRequest(instances, cellId));
    }, []);

    const nameRenderer = (objectDefinition: ObjectDefinitionImpl) => {
        const isSelected = objectDefinition.nodeId.id === selectedInstanceId;

        return (
            <div className={!isSelected ? theme.name : ''}>
                {isSelected && <Icon className={theme.flag} spriteSymbol={Flag} />}
                {objectDefinition.name}
            </div>
        );
    };

    const { columns, dataSource, onRow } = useObjectTableData(instances, nameRenderer, (key) =>
        setSelectedInstanceId(key),
    );

    const onClose = useCallback(() => {
        dispatch(setFocusAndStartEditAction(cellId));
        dispatch(closeDialog(DialogType.SELECT_OBJECT_DIALOG));
    }, [cellId]);

    const handleSubmit = (strategy: SelectedStrategy) => () => {
        const selectedInstance = instances.find(({ nodeId: { id } }) => id === selectedInstanceId);

        if (!selectedInstance) return;

        dispatch(selectObjectDialogSubmit({ strategy }, cellId, selectedInstance, isObjectDeletable, objectName));
    };

    const footer = (
        <DialogFooterButtons
            buttons={[
                {
                    key: 'cancel',
                    onClick: onClose,
                    value: intl.formatMessage(messages.cancelButton),
                },
                {
                    key: 'useSelected',
                    onClick: handleSubmit(SelectedStrategy.useExisting),
                    value: intl.formatMessage(messages.useExisting),
                    visualStyle: 'primary',
                },
                {
                    key: 'createNew',
                    onClick: handleSubmit(SelectedStrategy.setNewName),
                    value: intl.formatMessage(messages.createNewObject),
                    visualStyle: 'primary',
                },
            ]}
        />
    );

    return (
        <Dialog
            open={open}
            onCancel={onClose}
            title={intl.formatMessage(messages.selectObject)}
            width="620px"
            footer={footer}
        >
            <div className={theme.container} data-test="existing_object_dialog">
                <div className={theme.message}>
                    {intl.formatMessage(messages.objectHeaderMessage, { objectTypeTitle })}
                </div>
                <div>
                    {intl.formatMessage(messages.useSelectedObject)}
                    {isObjectDeletable ? (
                        <span style={{ color: 'red' }}>
                            &nbsp; ({intl.formatMessage(messages.deleteObjectNotification, { name: `"${oldName}"` })})
                        </span>
                    ) : (
                        ''
                    )}
                </div>
                {dataSource && (
                    <div className={theme.tableContainer}>
                        <Table
                            className={theme.table}
                            rowKey={(record) => record.key}
                            onRow={onRow}
                            dataSource={dataSource}
                            bordered
                            pagination={false}
                            size="middle"
                            columns={columns}
                            scroll={{
                                y: 'max-content',
                                x: 'max-content',
                            }}
                        />
                    </div>
                )}
            </div>
        </Dialog>
    );
};
